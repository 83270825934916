import {
  Box,
  Grid,
  Paper,
  Divider,
  IconButton,
  Modal,
  Input,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useFetchWishlist } from "../../Hooks/AllRecords/useAllRecords";
import * as Styles from "../../common/Styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import filter from "../../assets/filter.png";
import CloseIcon from "../../assets/CloseIcon.svg";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import PageLoader from "../CommonActions/PageLoader";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import FigmaIcon from "../../assets/figma-icon.png";
import axios from "axios";
import Searchbar from "../../common/Searchbar";

function Wishlist() {
  const navigate = useNavigate();

  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [AllCompanyName, setAllCompanyName] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [searchData, setSearchData] = useState("");
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );
  const [date, setDate] = useState("");

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const {
    data: fetchWishlist,
    isLoading: WishlistLoading,
    refetch: WishlistRefetch,
  } = useFetchWishlist(currentPage, date, selectedCompanyId, searchData);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (fetchWishlist && fetchWishlist?.totalPages !== undefined) {
      setInitialPaginationCount(fetchWishlist?.totalPages);
    }
  }, [fetchWishlist?.totalPages]);
  useEffect(() => {
    WishlistRefetch();
  }, [currentPage, date, selectedCompanyId, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [date, selectedCompanyId, searchData]);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const filterByCompany = async () => {
    await axios
      .get(`product/get_allproducts_bycompany`, { withCredentials: true })
      .then((res) => {
        setAllCompanyName(res.data);
        // console.log("Success", res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    filterByCompany();
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "620px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <React.Fragment>
        <Paper elevation={0}>
          <Box
            className="page-heading-section"
            style={{
              justifyContent: "start",
              gap: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <KeyboardBackspaceIcon />
            </Box>
            <Box className="page-heading">
              <h5>All Wishlist</h5>
              <Typography>Explore all the data by applying filters.</Typography>
            </Box>
          </Box>
          <Divider />
          {WishlistLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                ml: "500px",
                mt: 20,
              }}
            >
              <PageLoader />
            </Box>
          ) : (
            <Box
              sx={{
                background: "rgba(250, 251, 252, 1)",
                p: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "97%",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box sx={buddge}>
                  <Typography sx={textBudge}>
                    {fetchWishlist instanceof Object ? (
                      <Typography sx={textBudge}>
                        Total Wishlist(s): {fetchWishlist?.totalCount || 0}
                      </Typography>
                    ) : (
                      <Typography sx={textBudge}>
                        Total Wishlist(s): 0
                      </Typography>
                    )}
                  </Typography>
                </Box>
                <Searchbar onSendData={handleChildData} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                  }}
                >
                  <Box
                    className="marketplace-filters"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    {/* <span style={textBudge}>Select Company</span> */}
                    {/* <Box>
                      <div class="input-container">
                        <select
                          onChange={handleCompanyChange}
                          value={selectedCompany}
                        >
                          <option value="">All</option>
                          {Array.from(
                            new Set(
                              AllCompanyName?.map(
                                (option) => option?.SellerCompanyName || ""
                              )
                            )
                          ).map((name, index) => (
                            <option key={index} value={name}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Box> */}
                  </Box>
                  <Box
                    className="marketplace-filters"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    <span style={textBudge}>Days</span>
                    <Box>
                      <div class="input-container">
                        <select
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        >
                          <option value="">All</option>
                          <option value="1">1 Days</option>
                          <option value="3">3 Days</option>
                          <option value="5">5 Days</option>
                          <option value="7">7 Days</option>
                          <option value="15">15 Days</option>
                          <option value="30">1 Month</option>
                          <option value="90">3 Month</option>
                          <option value="180">6 Month</option>
                          <option value="365">1 Year</option>
                        </select>
                      </div>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Grid
                container
                sx={{
                  p: 2,
                }}
              >
                <Grid
                  item
                  xl={2.5}
                  lg={2.5}
                  md={2.5}
                  sm={2.5}
                  xs={2.5}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  Product Name
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{
                    ...Styles.textHead,
                  }}
                >
                  Seller Company Name
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={Styles.textHead}
                >
                  Buyer Company Name
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={Styles.textHead}
                >
                  Location
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={Styles.textHead}
                >
                  Price Per Unit
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  sx={Styles.textHead}
                >
                  Date
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={Styles.textHead}
                >
                  Type
                </Grid>
              </Grid>

              <Divider />
              <Box
                sx={{
                  background: "transparent",
                  p: 2,
                  mt: 2,
                  width: "100%",
                  borderRadius: 2,
                }}
              >
                {fetchWishlist?.wishlists?.length <= 0 ? (
                  <Box sx={setBox}>
                    <img
                      src={recyclebin}
                      alt="recycle"
                      style={{ height: "auto", width: "23%" }}
                    />
                    <Typography sx={empty}>Data is empty</Typography>
                  </Box>
                ) : (
                  <>
                    {fetchWishlist?.wishlists?.map((data) => {
                      //   const formattedDate = data ? formatDate(data?.PoDate) : "";
                      return (
                        <Grid container sx={Styles.containerStyles}>
                          <Grid
                            item
                            xl={2.5}
                            lg={2.5}
                            md={2.5}
                            sm={2.5}
                            xs={2.5}
                            sx={{
                              ...Styles.textHead,
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <Box
                              component={"img"}
                              src={
                                data?.ProductId?.ProductImages?.at(0)?.url ||
                                data?.ProductId?.VoucherImages?.at(0)?.url
                              }
                              sx={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "30px",
                              }}
                            />
                            <Box sx={Styles.headProductText}>
                              {data?.ProductId?.ProductName}
                            </Box>
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            sx={{
                              ...Styles.textHead,
                            }}
                          >
                            {data?.ProductId?.SellerCompanyName}
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            sx={Styles.textHead}
                          >
                            {data?.BuyerCompanyId?.companyName}
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            sx={Styles.textHead}
                          >
                            {data?.ProductId?.LocationDetails?.state ||
                              data?.ProductId?.GeographicalData?.state ||
                              data?.ProductId?.State ||
                              "--"}
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={{ ...Styles.textHead, overflow: "scroll" }}
                          >
                            {data?.ListingType === "Voucher"
                              ? data?.ProductId?.ProductsVariantions?.at(0)
                                  ?.PricePerUnit
                              : data?.ProductId?.ProductsVariantions?.at(0)
                                  ?.DiscountedPrice}
                          </Grid>

                          <Grid
                            align="center"
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={Styles.textHead}
                          >
                            {formatDate(data?.createdAt)}
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={Styles.textHead}
                          >
                            {data?.ProductId?.ListingType}
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: {
                          xl: "100%",
                          lg: "100%",
                          md: "100%",
                          sm: "100%",
                          xs: "100%",
                        },
                        mx: "auto",
                      }}
                      mt={2}
                    >
                      <Stack>
                        <StyledPagination
                          size="medium"
                          count={initialPaginationCount}
                          color="primary"
                          showFirstButton
                          showLastButton
                          page={currentPage}
                          onChange={handlePageChange}
                          strokeWidth={currentPage}
                        />
                      </Stack>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          )}
        </Paper>
      </React.Fragment>
    </Paper>
  );
}

export default Wishlist;

const RadioTextStyle = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};

const FilterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  // lineHeight: 20,
};
const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};
