import { createSlice } from "@reduxjs/toolkit";

export const GetTotalCreditIssue = createSlice({
    name: "CreditIssue",
    initialState: {
        CreditIssueCounts: []
    },
    reducers: {
        CreditIssueCounts_count_Request: (state) => {
            state.loading = true;
            state.CreditIssueCounts = [];
        },
        CreditIssueCounts_count_Success: (state, action) => {
            state.loading = false;
            state.CreditIssueCounts = action.payload;
        },
        CreditIssueCounts_count_Fail: (state, action) => {
            state.loading = false;
            state.CreditIssueCounts = action.payload;
        }
    }
})

export const { CreditIssueCounts_count_Fail, CreditIssueCounts_count_Request, CreditIssueCounts_count_Success } = GetTotalCreditIssue.actions

export default GetTotalCreditIssue.reducer