import axios from 'axios';
import { useMutation } from 'react-query';
import { GetTokenFunc } from '../../utils/GetToken';
export const useRemoveinvoice = (id) => {
  const { data, isLoading, error, mutate } = useMutation(
    (id) =>
      axios.post(
        `/api/v1/admin/invoice/delete-invoice`,
        {
          InvoiceId: id,
        },
        {
          withCredentials: true,
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      ),
    {
      onSuccess: (data) => {
        return 'Deleted';
      },
    },
    {
      onError: (error) => {
        return 'Error';
      },
    }
  );
  return { data, isLoading, error, mutate };
};
