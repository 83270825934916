import { Box, Button, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import ProductOrdersComponent from "./ProductOrders"; // Renamed the imported component
import MediaOrders from "./MediaOrders";
import VoucherOrders from "./VoucherOrders";

const AllOrdersList = () => {
  const [productOrders, setProductOrders] = useState(true);
  const [mediaOrders, setMediaOrders] = useState(false);
  const [voucherOrders, setVoucherOrders] = useState(false);

  const handleProductOrders = () => {
    setProductOrders(true);
    setMediaOrders(false);
    setVoucherOrders(false);
  };

  const handleMediaOrders = () => {
    setProductOrders(false);
    setMediaOrders(true);
    setVoucherOrders(false);
  };

  const handleVoucherOrders = () => {
    setProductOrders(false);
    setMediaOrders(false);
    setVoucherOrders(true);
  };

  return <ProductOrdersComponent />;
};

export default AllOrdersList;
