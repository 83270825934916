import axios from "axios";
import React, { useEffect, useState } from "react";
import { GetTokenFunc } from "../../utils/GetToken";

const GetPurchaseComp = (props) => {
  const [OrderData, setOrderData] = useState();
  const GetStatus = async (props) => {
    try {
      const response = await axios.get(
        "/api/v1/admin/LastPurchaseDateOfBuyer",
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
          params: {
            companyId: props.companyId,
          },
        }
      );
      setOrderData(response?.data?.TaxInvoice);
    } catch (error) {
      console.error(error);
    }
  };

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    if (props.companyId) {
      GetStatus(props);
    }
  }, [props.companyId]);

  return (
    <div>{OrderData?.createdAt ? formatDate(OrderData?.createdAt) : "N/A"}</div>
  );
};

export default GetPurchaseComp;
