import axios from "axios";
import React, { useEffect, useState } from "react";

const CompanyTypeComp = (props) => {
  const [companyTypeNameData, setCompanyTypeNameData] = useState();
  const GetCompanyByID = async (props) => {
    try {
      const response = await axios.get(
        `company_type/get_companyType/${props?.companyTypeId}`,
        {
          withCredentials: true,
        }
      );
      setCompanyTypeNameData(response?.data?.CompanyTypeName);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    GetCompanyByID(props);
  }, [props]);
  return <div>{companyTypeNameData}</div>;
};

export default CompanyTypeComp;
