import axios from "axios";

import { product_count_Request, product_count_Success, product_count_Fail } from '../../reduser/AdminDashboard/TotalProducts'
import { GetTokenFunc } from '../../../utils/GetToken';

export const ProductCountOnPlateform = () => async (dispatch) => {
    try {
        dispatch({ type: product_count_Request.toString() });
        const response = await axios.get(
            `/api/v1/admin/TotalProductOnPlateform`, {
            headers: {
                authorization: `Bearer ${GetTokenFunc()}`
            }
        }
        );
        dispatch({
            type: product_count_Success.toString(),
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: product_count_Fail,
            payload: error.response.data.message,
        });
    }
}