import axios from "axios";
import {
  Edit_Company_Request,
  Edit_Company_Success,
  Edit_Company_Fail,
} from "../../reduser/Company&User/EditCompanyDetails";
import { GetTokenFunc } from "../../../utils/GetToken";

export const EditCompanyDetailes = (id, formData) => async (dispatch) => {
  try {
    dispatch({ type: Edit_Company_Request });
    const {
      companyName,
      email,
      phone,
      gst,
      AddressLine,
      city,
      pincode,
      bankAccountNo,
      ifsc,
      branchName,
      website,
    } = formData;

    const response = await axios.post(
      `/api/v1/admin/AdminUpdateCompanyById/${id}`,
      {
        companyName,
        email,
        phone,
        gst,
        AddressLine,
        city,
        pincode,
        bankAccountNo,
        ifsc,
        branchName,
        website,
      },
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );

    dispatch({
      type: Edit_Company_Success,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: Edit_Company_Fail,
      payload: error.response.data.message,
    });
  }
};
