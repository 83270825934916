import React, { useEffect, useState } from "react";
import {
  XYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeriesCanvas,
  VerticalBarSeries,
  XAxis,
  YAxis,
  Hint,
  DiscreteColorLegend,
} from "react-vis";
import { GetTokenFunc } from "../../utils/GetToken";
import axios from "axios";
import PageLoader from "../CommonActions/PageLoader";
import "/node_modules/react-vis/dist/style.css";
import { Box } from "@material-ui/core";

const CustomDiscreteColorLegend = ({ items }) => {
  return (
    <div
      style={{
        margin: "10px 0",
        display: "flex",
        width: "700",
        justifyContent: "space-around",
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <div
            style={{
              height: "12px",
              width: "12px",
              backgroundColor: item.color,
              marginRight: "5px",
              borderRadius: "50%",
            }}
          />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};

const Transections = () => {
  const BarSeries = VerticalBarSeries;

  const [hoveredBar, setHoveredBar] = useState(null);
  const [label, setLabel] = useState([]);
  const [filter, setFilter] = useState("last-week");
  const [optionFilter, setOptionFilter] = useState("Media");
  const [subCategory, setSubCategory] = useState("All");
  const [isLoading, setIsLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [Legends, setLegends] = useState([]);
  const [Count, setCount] = useState([]);

  const handleBarHover = (value) => {
    setHoveredBar(value);
  };

  useEffect(() => {
    let newLegends = [];

    if (optionFilter == "Media")
      newLegends.push({ title: "Media (INR + Coins)", color: "#1D4ED8" });
    if (optionFilter == "Product")
      newLegends.push({ title: "Product (INR + Coins)", color: "#33BFFF" });
    if (optionFilter == "Voucher")
      newLegends.push({ title: "Voucher (INR + Coins)", color: "#EC4899" });

    setLegends(newLegends);
  }, [optionFilter]);

  const fetchData = async () => {
    setIsLoading(true);
    let apiUrl = "";

    // Media Transactions
    if (optionFilter == "Media" && filter == "last-week")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/MediaTransactionsWeek?SubCategory=${subCategory}`;
    if (optionFilter == "Media" && filter == "last-month")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/MediaTransactionMonth?SubCategory=${subCategory}`;
    if (optionFilter == "Media" && filter == "current-month")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/MediaTransactionCurrentMonth?SubCategory=${subCategory}`;
    if (optionFilter == "Media" && filter == "last-three-months")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/MediaTransactions3Months?SubCategory=${subCategory}`;
    if (optionFilter == "Media" && filter == "last-six-months")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/MediaTransactions6Months?SubCategory=${subCategory}`;
    if (optionFilter == "Media" && filter == "last-year")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/MediaTransactions12Months?SubCategory=${subCategory}`;
    if (optionFilter == "Media" && filter == "current-year")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/MediaTransactions12CurrentMonths?SubCategory=${subCategory}`;
    if (optionFilter == "Media" && filter == "last-five-years")
      apiUrl = `/api/v1/admin/AdminCompanyDetails/MediaTransactions5Years?SubCategory=${subCategory}`;

    // Media Offline Transactions
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOffline" &&
      filter == "last-week"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineTransactionsWeek`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOffline" &&
      filter == "last-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineTransactionMonth`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOffline" &&
      filter == "current-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineTransactionCurrentMonth`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOffline" &&
      filter == "last-three-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineTransactions3Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOffline" &&
      filter == "last-six-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineTransactions6Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOffline" &&
      filter == "last-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineTransactions12Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOffline" &&
      filter == "current-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineTransactionsCurrent12Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOffline" &&
      filter == "last-five-years"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineTransactions5Years`;

    // Media Online Transactions
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOnline" &&
      filter == "last-week"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOnlineTransactionsWeek`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOnline" &&
      filter == "last-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOnlineTransactionMonth`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOnline" &&
      filter == "current-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOnlineTransactionCurrentMonth`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOnline" &&
      filter == "last-three-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOnlineTransactions3Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOnline" &&
      filter == "last-six-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOnlineTransactions6Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOnline" &&
      filter == "last-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOnlineTransactions12Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOnline" &&
      filter == "current-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOnlineTransactionsCurrent12Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "MediaOnline" &&
      filter == "last-five-years"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOnlineTransactions5Years`;

    // All Media Offline & OnlineTransactions
    if (
      optionFilter == "Media" &&
      subCategory == "All" &&
      filter == "last-week"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineAndOnlineTransactionsWeek`;
    if (
      optionFilter == "Media" &&
      subCategory == "All" &&
      filter == "last-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineAndOnlineTransactionMonth`;
    if (
      optionFilter == "Media" &&
      subCategory == "All" &&
      filter == "current-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineAndOnlineTransactionCurrentMonth`;
    if (
      optionFilter == "Media" &&
      subCategory == "All" &&
      filter == "last-three-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineAndOnlineTransactions3Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "All" &&
      filter == "last-six-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineAndOnlineTransactions6Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "All" &&
      filter == "last-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineAndOnlineTransactions12Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "All" &&
      filter == "current-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineAndOnlineTransactionsCurrent12Months`;
    if (
      optionFilter == "Media" &&
      subCategory == "All" &&
      filter == "last-five-years"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllMediaOfflineAndOnlineTransactions5Years`;

    // Product & Voucher
    if (
      (optionFilter == "Product" || optionFilter == "Voucher") &&
      filter == "last-week"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/ProductTransactionsWeek?ProductCategory=${subCategory}&type=${
        optionFilter == "Voucher" ? true : false
      }`;
    if (
      (optionFilter == "Product" || optionFilter == "Voucher") &&
      filter == "last-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/ProductTransactionMonth?ProductCategory=${subCategory}&type=${
        optionFilter == "Voucher" ? true : false
      }`;
    if (
      (optionFilter == "Product" || optionFilter == "Voucher") &&
      filter == "current-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/ProductTransactionCurrentMonth?ProductCategory=${subCategory}&type=${
        optionFilter == "Voucher" ? true : false
      }`;
    if (
      (optionFilter == "Product" || optionFilter == "Voucher") &&
      filter == "last-three-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/ProductTransactions3Months?ProductCategory=${subCategory}&type=${
        optionFilter == "Voucher" ? true : false
      }`;
    if (
      (optionFilter == "Product" || optionFilter == "Voucher") &&
      filter == "last-six-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/ProductTransactions6Months?ProductCategory=${subCategory}&type=${
        optionFilter == "Voucher" ? true : false
      }`;
    if (
      (optionFilter == "Product" || optionFilter == "Voucher") &&
      filter == "last-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/ProductTransactions12Months?ProductCategory=${subCategory}&type=${
        optionFilter == "Voucher" ? true : false
      }`;
    if (
      (optionFilter == "Product" || optionFilter == "Voucher") &&
      filter == "current-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/ProductTransactionsCurrent12Months?ProductCategory=${subCategory}&type=${
        optionFilter == "Voucher" ? true : false
      }`;
    if (
      (optionFilter == "Product" || optionFilter == "Voucher") &&
      filter == "last-five-years"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/ProductTransactions5Years?ProductCategory=${subCategory}&type=${
        optionFilter == "Voucher" ? true : false
      }`;

    // All Product
    if (
      optionFilter == "Product" &&
      subCategory == "All" &&
      filter == "last-week"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllProductTransactionsWeek`;
    if (
      optionFilter == "Product" &&
      subCategory == "All" &&
      filter == "last-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllProductTransactionMonth`;
    if (
      optionFilter == "Product" &&
      subCategory == "All" &&
      filter == "current-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllProductTransactionCurrentMonth`;
    if (
      optionFilter == "Product" &&
      subCategory == "All" &&
      filter == "last-three-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllProductTransactions3Months`;
    if (
      optionFilter == "Product" &&
      subCategory == "All" &&
      filter == "last-six-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllProductTransactions6Months`;
    if (
      optionFilter == "Product" &&
      subCategory == "All" &&
      filter == "last-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllProductTransactions12Months`;
    if (
      optionFilter == "Product" &&
      subCategory == "All" &&
      filter == "current-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllProductTransactionsCurrent12Months`;
    if (
      optionFilter == "Product" &&
      subCategory == "All" &&
      filter == "last-five-years"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllProductTransactions5Years`;

    // All Voucher
    if (
      optionFilter == "Voucher" &&
      subCategory == "All" &&
      filter == "last-week"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllVoucherTransactionsWeek`;
    if (
      optionFilter == "Voucher" &&
      subCategory == "All" &&
      filter == "last-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllVoucherTransactionMonth`;
    if (
      optionFilter == "Voucher" &&
      subCategory == "All" &&
      filter == "current-month"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllVoucherTransactionCurrentMonth`;
    if (
      optionFilter == "Voucher" &&
      subCategory == "All" &&
      filter == "last-three-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllVoucherTransactions3Months`;
    if (
      optionFilter == "Voucher" &&
      subCategory == "All" &&
      filter == "last-six-months"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllVoucherTransactions6Months`;
    if (
      optionFilter == "Voucher" &&
      subCategory == "All" &&
      filter == "last-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllVoucherTransactions12Months`;
    if (
      optionFilter == "Voucher" &&
      subCategory == "All" &&
      filter == "current-year"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllVoucherTransactionsCurrent12Months`;
    if (
      optionFilter == "Voucher" &&
      subCategory == "All" &&
      filter == "last-five-years"
    )
      apiUrl = `/api/v1/admin/AdminCompanyDetails/AllVoucherTransactions5Years`;

    try {
      const res = await axios.get(apiUrl, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      });

      setCount(res.data.Count);
      setLabel(res.data.label);
      setData(res.data.TransactionAmount);
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, optionFilter, subCategory]);

  const DataArray = Data?.map((item, index) => ({
    x: index + 1,
    y: parseFloat(item),
    z: Count ? (Count.length > 0 ? Count[index] : Count) : null,
    color: Legends[0]?.color,
  }));

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <h3 className="graph-heading">Transactions</h3>

        <Box className="marketplace-filters">
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setOptionFilter(e.target.value);
              }}
              value={optionFilter}
            >
              <option value="Media">Media</option>
              <option value="Product">Product</option>
              <option value="Voucher">Voucher</option>
            </select>
          </Box>
        </Box>
        <Box className="marketplace-filters">
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setSubCategory(e.target.value);
              }}
              value={subCategory}
            >
              {optionFilter === "Media" ? (
                <>
                  <option
                    value="MediaOffline"
                    style={{
                      fontSize: "9px",
                      fontWeight: "bold",
                    }}
                  >
                    Media Offline
                  </option>

                  <option
                    value="MediaOnline"
                    style={{
                      fontSize: "9px",
                      fontWeight: "bold",
                    }}
                  >
                    Media Online
                  </option>

                  <option value="All">All</option>

                  <optgroup label="Offline Media">
                    <option value="650296faeaa5251874e8c711">
                      Drop Down Banners
                    </option>
                    <option value="650296faeaa5251874e8c708">Activation</option>
                    <option value="650296faeaa5251874e8c70c">
                      Bus Handles
                    </option>
                    <option value="650296faeaa5251874e8c722">
                      Ticket Branding
                    </option>
                    <option value="650296faeaa5251874e8c712">
                      Elevator Branding
                    </option>
                    <option value="650296faeaa5251874e8c71c">Posters</option>
                    <option value="650296faeaa5251874e8c721">Tent Card</option>
                    <option value="650296faeaa5251874e8c713">
                      Escalator Branding
                    </option>
                    <option value="650296faeaa5251874e8c714">
                      Flight Wrap
                    </option>

                    <option value="650296faeaa5251874e8c70a">Banners</option>
                    <option value="650296faeaa5251874e8c709">
                      Ambient Media
                    </option>
                    <option value="650296faeaa5251874e8c70f">Dangler</option>
                    <option value="650296faeaa5251874e8c70b">
                      Boarding Pass
                    </option>
                    <option value="650296faeaa5251874e8c71e">
                      Promoter Activity
                    </option>
                    <option value="650296faeaa5251874e8c718">
                      Mediaofflinecategory
                    </option>
                    <option value="650296faeaa5251874e8c723">Train Wrap</option>
                    <option value="650296faeaa5251874e8c71a">Others</option>
                    <option value="650296faeaa5251874e8c70e">Bus Wrap</option>
                    <option value="650296faeaa5251874e8c717">Kiosk</option>
                    <option value="650296faeaa5251874e8c710">
                      Door Handles
                    </option>
                    <option value="650296faeaa5251874e8c71b">Pole Kiosk</option>
                    <option value="650296faeaa5251874e8c71d">Poster</option>
                    <option value="650296faeaa5251874e8c720">Standee</option>
                    <option value="647713dcb530d22fce1f6c36">
                      News Papers / Magazines
                    </option>
                    <option value="643cdf01779bc024c189cf95">Hoardings</option>
                    <option value="643ce635e424a0b8fcbba6d6">
                      Gantry Gate
                    </option>
                    <option value="6530f4bca1995cdb658ce761">
                      Bus Shelters
                    </option>
                    <option value="643ce6fce424a0b8fcbbad42">
                      Wall Branding
                    </option>
                    <option value="643ce707e424a0b8fcbbad4c">Sky Walk</option>
                  </optgroup>

                  <optgroup label="Online Media">
                    <option value="65029534eaa5251874e8c6b8">
                      Display Video
                    </option>
                    <option value="65029534eaa5251874e8c6bd">Mobile</option>
                    <option value="65029534eaa5251874e8c6bc">
                      Influencer Marketing
                    </option>
                    <option value="65029534eaa5251874e8c6b7">
                      Digital Marketing
                    </option>
                    <option value="65029534eaa5251874e8c6c3">
                      Social Media ADVT
                    </option>
                    <option value="65029534eaa5251874e8c6bb">INAPP ADVT</option>
                    <option value="65029534eaa5251874e8c6c4">
                      Web Banners
                    </option>
                    <option value="65029534eaa5251874e8c6b4">
                      Airport Screens
                    </option>
                    <option value="65029534eaa5251874e8c6b5">
                      Café Digital Screens
                    </option>
                    <option value="65029534eaa5251874e8c6b6">
                      Digital ADs
                    </option>
                    <option value="65029534eaa5251874e8c6c2">
                      Single Screen
                    </option>
                    <option value="65029534eaa5251874e8c6b9">DOOH</option>
                    <option value="65029534eaa5251874e8c6bf">Others</option>
                    <option value="65029534eaa5251874e8c6c1">Radio</option>
                    <option value="65029534eaa5251874e8c6ba">
                      GYMS DIGITAL SCREENS
                    </option>
                    <option value="65029534eaa5251874e8c6c0">
                      RAILWAY DIGITAL SCREENS
                    </option>
                    <option value="643cda0c53068696706e3951">
                      Multiplex ADs
                    </option>
                  </optgroup>
                </>
              ) : optionFilter === "Product" ? (
                <>
                  <option value="All">All</option>
                  <option value="63e38b9ccc4c02b8a0c94b6f">Lifestyle</option>
                  <option value="63e38b96cc4c02b8a0c94b6c">Textile</option>
                  <option value="63e38bc6cc4c02b8a0c94b81">
                    Office Supply
                  </option>
                  <option value="63e38bcecc4c02b8a0c94b84">Electronics</option>
                  <option value="63e38bb9cc4c02b8a0c94b7b">FMCG</option>
                  <option value="63e38bb3cc4c02b8a0c94b78">Mobility</option>
                  <option value="63e38bddcc4c02b8a0c94b8a">Others</option>
                </>
              ) : (
                <>
                  <option value="All">All</option>
                  <option value="63e38b9ccc4c02b8a0c94b6f">Lifestyle</option>
                  <option value="63e38b96cc4c02b8a0c94b6c">Textile</option>
                  <option value="63e38ba3cc4c02b8a0c94b72">Hotel</option>
                  <option value="64218b189fe1b6ae750c11bd">
                    Entertainment & Events
                  </option>
                  <option value="63e38bbfcc4c02b8a0c94b7e">
                    Airlines Tickets
                  </option>
                  <option value="63e38bd5cc4c02b8a0c94b87">
                    Restaurant QSR
                  </option>
                  <option value="63e38bc6cc4c02b8a0c94b81">
                    Office Supply
                  </option>
                  <option value="63e38bcecc4c02b8a0c94b84">Electronics</option>
                  <option value="63e38bb9cc4c02b8a0c94b7b">FMCG</option>
                  <option value="63e38bb3cc4c02b8a0c94b78">Mobility</option>
                  <option value="63e38bddcc4c02b8a0c94b8a">Others</option>
                </>
              )}
            </select>
          </Box>
        </Box>

        <Box className="marketplace-filters">
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              value={filter}
            >
              <option value="last-week">last-week</option>
              <option value="last-month">last-month</option>
              <option value="current-month">current-month</option>
              <option value="last-three-months">last-three-months</option>
              <option value="last-six-months">last-six-months</option>
              <option value="last-year">last-year</option>
              <option value="current-year">current-year</option>
              <option value="last-five-years">last-five-years</option>
            </select>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 10,
            ml: 35,
          }}
        >
          <PageLoader />
        </Box>
      ) : (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <XYPlot
            height={350}
            width={700}
            stackBy="y"
            margin={{ left: 70, right: 10, top: 10, bottom: 30 }}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis
              tickValues={label?.map((item, index) => index + 1)}
              tickFormat={(v) => label[v - 1]}
            />

            <YAxis tickFormat={(v) => `${v}`} />

            <BarSeries
              barWidth={0.2}
              data={DataArray}
              color={Legends[0]?.color}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
            />

            {hoveredBar && (
              <Hint value={hoveredBar}>
                <div
                  className="grapharrow"
                  style={{
                    background: `${hoveredBar.color}`,
                    color: "white",
                    width: "130px",
                  }}
                >
                  <p>{hoveredBar.label}</p>
                  <p>{`${Legends[0]?.title}: ${hoveredBar.y} - ${hoveredBar.z}`}</p>
                </div>
              </Hint>
            )}
            <CustomDiscreteColorLegend items={Legends} />
          </XYPlot>
        </div>
      )}
    </div>
  );
};

export default Transections;
