import { useQuery } from "react-query";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";

export const useFetchInvoices = (currentPage, Type) => {
  return useQuery(["invoice", currentPage], async () => {
    const response = await axios.get(
      `/api/v1/admin/AdminCompanyDetails/AllInvoice?page=${currentPage}&Filter=${Type}`,
      {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      }
    );
    return response.data;
  });
};

export const useFetchAllProformaInvoices = (currentPage) => {
  return useQuery(["proforma", currentPage], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/AdminCompanyDetails/AllProformaInvoices?page=${currentPage}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};

export const useFetchAllPurchaseOrder = (currentPage) => {
  return useQuery(["proforma", currentPage], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/AdminCompanyDetails/AllPurchaseOrderInvoices?page=${currentPage}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};

export const useFetchAllTaxInvoice = (currentPage) => {
  return useQuery(["proforma", currentPage], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/AdminCompanyDetails/AllTaxInvoices?page=${currentPage}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};

export const useFetchAllCommissionInvoices = (currentPage) => {
  return useQuery(["proforma", currentPage], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/AdminCompanyDetails/AllCommissionInvoices?page=${currentPage}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};
export const useFetchOrders = (currentPage, search) => {
  return useQuery(["order", currentPage], async () => {
    try {
      const response = await axios.get(
        `api/v1/admin/companies-orders?page=${currentPage}&search=${search}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};

export const useFetchMediaOrders = (currentPage) => {
  return useQuery(["mediaorder", currentPage], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/companies-media-orders?page=${currentPage}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      // console.log('response', response);
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};

export const useFetchVoucherOrders = (currentPage) => {
  return useQuery(["mediaorder", currentPage], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/companies-voucher-orders?page=${currentPage}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      // console.log('response', response);
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};

export const useFetchApprovals = (currentPage, status, search) => {
  return useQuery(["order", currentPage, status], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/AdminCompanyDetails/AllApprovals?Type=${status}&page=${currentPage}&search=${search}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      // console.log('ApprovalsRes', response);
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};

export const useFetchDelivery = (currentPage, status, search) => {
  return useQuery(["delivery", currentPage, status], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/AdminCompanyDetails/AllDelivery?Status=${status}&page=${currentPage}&search=${search}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      // console.log('ApprovalsRes', response);
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};

export const useFetchWishlist = (
  currentPage,
  date,
  selectedCompanyId,
  search
) => {
  return useQuery(["wishlist", currentPage], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/AdminCompanyDetails/AllWishlist?page=${currentPage}&DateFilter=${date}&CompanyId=${selectedCompanyId}&search=${search}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      // console.log('ApprovalsRes', response);
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};

export const useFetchAllChats = (currentPage, CompanyName) => {
  return useQuery(["chats", currentPage, CompanyName], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/AdminCompanyDetails/AllChats?CompanyName=${CompanyName}&page=${currentPage}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      // console.log('chatres', response);
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};

const fetchCompaniesAllApprovals = async (queryType) => {
  const response = await axios.get(
    `/api/v1/admin/approvals/get-requests/6471f349c5e992e5f65ee10e${queryType}=${queryType}`,
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
  return response.data;
};

export const useFetchCompaniesRequests = (queryType) => {
  return useQuery("ApprovalRequests", () =>
    fetchCompaniesAllApprovals(queryType)
  );
};

export const useFetchCreditline = (
  dataTypes,
  currentPage,
  selectedCompany,
  productsearch
) => {
  return useQuery(
    ["creditline", dataTypes, currentPage, selectedCompany, productsearch],
    async () => {
      try {
        // console.log('Successfully', productsearch);
        const response = await axios.get(
          `/api/v1/admin/AdminCompanyDetails/AllCreditIssue?Search=${productsearch}&ProductType=${dataTypes}&CompanyName=${selectedCompany}&page=${currentPage} `,
          {
            headers: {
              Authorization: `Bearer ${GetTokenFunc()}`,
            },
          }
        );

        // console.log('ApprovalsRes', response);
        return response.data;
      } catch (error) {
        console.error("Error fetching credit line data:", error);
        throw error;
      }
    }
  );
};

export const useFetchAdminTrails = (currentPage, search) => {
  return useQuery(["admintrails", currentPage, search], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/get-admin-trail?page=${currentPage}&search=${search}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};
export const useFetchTopProducts = (category, companyName, filterType) => {
  return useQuery(["topproducts", category, companyName], async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/AdminCompanyDetails/TopProducts?CategoryId=${category}&CompanyName=${companyName}&filterType=${filterType}`,
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};
