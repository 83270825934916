import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, Button, Input } from '@mui/material';
import { useUpdateData } from '../../Hooks/GenieData/useUpdateData.js';

function BingoDealsEdit(props) {
  const {
    open,
    onClose,
    onSave,
    text,
    ButtonLabel,
    cancelButtonLabel,
    fieldOne,
    fieldTwo,
    productName,
    initialProductData,
    BingoDealsRefetch,
  } = props;

  const {
    mutate: updateProductData,
    error: updateWalletError,
    isLoading: updateWalletIsLoading,
    isSuccess: updateProductDataSuccess,
    refetch: productDataRefetch,
  } = useUpdateData();

  // console.log('success', updateProductDataSuccess);

  const [inputValues, setInputValues] = useState({
    fieldOne: Number(initialProductData?.Price),
    fieldTwo: Number(initialProductData?.Quantity),
  });

  const handleInputChange = (field, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
  };

  const handleSaveClick = async () => {
    onSave({
      ...initialProductData,
      TotalPrice: inputValues.fieldOne,
      ProductQuantity: inputValues.fieldTwo,
    });

    const updatedProductData = {
      id: initialProductData._id,
      TotalPrice: Number(inputValues.fieldOne),
      ProductQuantity: Number(inputValues.fieldTwo),
    };

    await updateProductData(updatedProductData, {
      onSuccess: () => {
        BingoDealsRefetch();
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            maxWidth: '80%',
            textAlign: 'center',
            borderRadius: 2,
          }}
        >
          <Typography>{text}</Typography>

          <Typography sx={InputHeader}> {productName} </Typography>

          <Box sx={MainFieldBox}>
            <Box sx={SubFieldBox}>
              <Typography sx={InputHeader}> {fieldOne} </Typography>

              <Input
                type="number"
                sx={InputStyle}
                variant="contained"
                disableUnderline
                min={initialProductData?.Price}
                max={initialProductData?.Price}
                onWheel={numberInputOnWheelPreventChange}
                defaultValue={initialProductData?.Price}
                value={inputValues.fieldOne}
                onChange={(e) => handleInputChange('fieldOne', e.target.value)}
              />
            </Box>
            <Box sx={SubFieldBox}>
              <Typography sx={InputHeader}> {fieldTwo} </Typography>

              <Input
                type="number"
                sx={InputStyle}
                variant="contained"
                disableUnderline
                min={initialProductData?.Quantity}
                max={initialProductData?.Quantity}
                onWheel={numberInputOnWheelPreventChange}
                defaultValue={initialProductData?.Quantity}
                value={inputValues.fieldTwo}
                onChange={(e) => handleInputChange('fieldTwo', e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={ButtonBox}>
            <Button
              onClick={handleSaveClick}
              variant="contained"
              sx={{ mt: 2, mr: 2 }}
            >
              {ButtonLabel}
            </Button>
            <Button onClick={onClose} variant="outlined" sx={{ mt: 2 }}>
              {cancelButtonLabel}
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default BingoDealsEdit;

const MainFieldBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  justifyContent: 'space-between',
  mt: 2,
};

const SubFieldBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  mt: 1,
};

const ButtonBox = {
  display: 'flex',
  gap: 1,
  justifyContent: 'space-between',
};

const InputStyle = {
  borderRadius: 4,
  width: '100%',
  background: '#F3F6F9 ',
  padding: '5px 10px',
  color: '#000',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontWeight: 500,
  fontStyle: 'normal',
};

const InputHeader = {
  fontFamily: 'Poppins',
  fontSize: 12,
  fontWeight: 500,
  fontStyle: 'normal',
  color: '#000',
};
