import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import axios from "axios";

const GetCompanyNameNProfile = (props) => {
  const [companyImages, setCompanyImage] = useState(null);
  const [loading, setLoading] = useState(true);

  async function getCompanyByName() {
    try {
      const response = await axios.get(`company/get_company/${props.CompanyId}`);
      setCompanyImage(response.data);
    } catch (error) {
      console.error("Error fetching company data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCompanyByName();
  }, [props.CompanyId]);

  if (loading) {
    return <p>Loading...</p>; // You can replace this with a loading spinner or other UI
  }

  let ImageUrl =
    props?.CompanyId === "admin"
      ? "https://bxidevelopment1.s3.ap-south-1.amazonaws.com/BXI.png"
      : companyImages?.CompanyAvatar?.url;
  let CompanyName =
    props?.CompanyId === "admin"
      ? "Barter Exchange of India"
      : companyImages?.companyName;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        alignContent: "center",
        gap: "8px",
      }}
    >
      {" "}
      <img src={ImageUrl} alt="Company Avatar" style={{ width: "50px", height: "50px" }} />
      <Box>
        <div
          style={{
            width: "200px",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontFamily: "Poppins",
          }}
        >
          {CompanyName}
        </div>
        <Typography sx={CompanyTypeNameStyle}>
          {companyImages?.CompanyTypeName}
        </Typography>
      </Box>
    </div>
  );
};

export default GetCompanyNameNProfile;

const CompanyTypeNameStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "12px",
  lineHeight: "18px",
  color: "#AFAFAF",
};
