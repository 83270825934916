import React, { useState } from 'react';
import { Box, Modal, Typography, Button } from '@mui/material';

function CommonDelete(props) {
  const { open, onClose, onDelete, text, ButtonLabel, cancelButtonLabel } =
    props;

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    // Perform the delete action here
    // You can call a function from props to handle the deletion
    props.onDelete();
    handleClose();
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            maxWidth: '80%',
            textAlign: 'center',
            borderRadius: 2,
          }}
        >
          <Typography>{text}</Typography>
          <Button onClick={onDelete} variant="contained" sx={{ mt: 2, mr: 2 }}>
            {ButtonLabel}
          </Button>
          <Button onClick={onClose} variant="outlined" sx={{ mt: 2 }}>
            {cancelButtonLabel}
          </Button>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default CommonDelete;
