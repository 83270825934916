import axios from "axios";
import { GetTokenFunc } from '../../../utils/GetToken';

import { membership_count_Request, membership_count_Success, membership_count_Fail } from '../../reduser/AdminDashboard/TotalMemberShip'

export const MemberShipCountOnPlateform = () => async (dispatch) => {
    try {
        dispatch({ type: membership_count_Request.toString() })
        const response = await axios.get(
            `/api/v1/admin/TotalMemberShipPlan`, {
            headers: {
                authorization: `Bearer ${GetTokenFunc()}`
            }
        }
        );
        dispatch({
            type: membership_count_Success.toString(),
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: membership_count_Fail,
            payload: error.response.data.message,
        });
    }
}