import { Paper, Box } from '@mui/material';
import React from 'react';
import '../../App.css';

const PageLoader = () => {
  return (
    <Paper
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        backgroundColor: 'transparent',
        zIndex: 1000,
        mx: 'auto',
        // ml: '100%',
      }}
      elevation={0}
    >
      <Box sx={{ width: '100%', minWidth: '100%' }}>
        <div className="triple-spinner"></div>
      </Box>
    </Paper>
  );
};

export default PageLoader;
