import React, { useState } from "react";
import { Box, Typography, Paper, IconButton } from "@mui/material";
import "./Adminhandles.Modules.css";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAddAdminMember } from "./Hooks";
import { toast } from "react-toastify";
import { ADMIN_ROLES, ADMIN_ACCESS } from "./Admin_Roles";
import DeleteIcon from "@mui/icons-material/Delete";
import { Visibility, VisibilityOff } from "@material-ui/icons";
const Addmember = () => {
  const { mutate: addMemberMutate, isLoading: AddMemberLoading } =
    useAddAdminMember();
  const navigate = useNavigate();
  const [rightsArr, setRigthtsArr] = React.useState([]);
  const [passField, setPassField] = React.useState(true);
  const [confrimPassField, setConfrimPassField] = React.useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [RoleName, setRoleName] = useState();
  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const handleShowPasswordClickConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(
      z
        .object({
          name: z
            .string()
            .trim()
            .min(3, { message: "Name must be at least 3 characters long" }),
          email: z.string().email(),
          password: z
            .string()
            .min(6)
            .regex(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
              "Password must be strong: include lowercase, uppercase, digit, and special characters"
            ),
          confirmpassword: z.string().min(6),
          role: z.string().min(3),
          rights: z.string().optional(2),
        })
        .refine((data) => data.password === data.confirmpassword, {
          message: "Password and Confirm Password must be same",
          path: ["confirmpassword"],
        })
    ),
  });
  const AddMemmberSubmit = handleSubmit((data) => {
    // if (data.password !== data.confirmpassword)
    //   return setError("confirmpassword", {
    //     message: "Password and Confirm Password must be same",
    //   });

    addMemberMutate(
      { ...data, rights: rightsArr },
      {
        onSuccess: (response) => {
          // console.log('response', response);
          if (
            response?.data === "Email Already Exists. Please Add Another one"
          ) {
            toast.error("Email Already Exists. Please Add Another one");
            return;
          }
          toast.success(
            response?.data === "Email Already Exists. Please Add Another one"
              ? "Email Already Exists. Please Add Another one"
              : "Added Member Succesfully"
          );
          setTimeout(() => {
            navigate("/admindashboard/memberdetails");
          }, [3000]);
        },
        onError: (error) => {
          console.log("error", error);
        },
      }
    );
  });
  const handleCancel = () => {
    reset();
  };
  // console.log('rightsArr', rightsArr);
  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <form onSubmit={AddMemmberSubmit}>
          <Box className="page-heading-section">
            <Box className="page-heading">
              <h5>You Can Add New Member Here</h5>
              <Typography>Select Right Roles Permissions Rights</Typography>
            </Box>

            <Box
              className="member-details-button"
              sx={{ border: "none", marginLeft: "70%" }}
              component={"button"}
              onClick={() => {
                navigate("/admindashboard/memberdetails");
              }}
            >
              Member Details
            </Box>
          </Box>
          <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 5, my: 2 }}>
            <Box className="fields-box">
              <Box>
                <label>Member Name </label>
                <input placeholder="Name" {...register("name")} />
                {errors.name && (
                  <Typography sx={ErrorStyle}>{errors.name.message}</Typography>
                )}
              </Box>
              <Box>
                <label>Member Email </label>
                <input placeholder="Email" {...register("email")} />
                {errors.email && (
                  <Typography sx={ErrorStyle}>
                    {errors.email.message}
                  </Typography>
                )}
              </Box>
              <Box>
                <label>Role </label>
                <select
                  {...register("role")}
                  onChange={(event) => {
                    setRoleName(event.target.value);
                  }}
                >
                  <option disabled={true} value={""}>
                    -- Select --
                  </option>
                  {ADMIN_ROLES.map((role) => {
                    return <option value={role.value}>{role.name}</option>;
                  })}
                </select>
                {errors.role && (
                  <Typography sx={ErrorStyle}>{errors.role.message}</Typography>
                )}
              </Box>
            </Box>
            <Box className="rights-of-user">
              <h6>Rights of the user</h6>
              <p>
                Grant permissions to the member mentioned above, allowing them
                access to the Admin panel.
              </p>
            </Box>
            <Box className="fields-box full-width ">
              <Box>
                <label>Rights </label>
                <div>
                  <select
                    {...register("rights")}
                    onChange={(e) => {
                      if (e.target.value === "") return;
                      if (rightsArr.includes(e.target.value)) return;
                      setRigthtsArr([...rightsArr, e.target.value]);
                    }}
                  >
                    <option disabled={true} value={""}>
                      -- Select --
                    </option>
                    {RoleName === "SUPER_ADMIN" ? (
                      <option value="All" disabled>
                        All
                      </option>
                    ) : (
                      Object.keys(ADMIN_ACCESS).map((key, value) => {
                        return (
                          <option value={ADMIN_ACCESS[key]}>
                            {ADMIN_ACCESS[key]}
                          </option>
                        );
                      })
                    )}
                  </select>
                </div>
                {errors.rights && (
                  <Typography sx={ErrorStyle}>
                    {errors.rights.message}
                  </Typography>
                )}
                {
                  // <Box>
                  //   {rightsArr?.map((right) => {
                  //     return (
                  //       <Box
                  //         sx={{
                  //           display: 'flex',
                  //           gap: '10px',
                  //           alignItems: 'center',
                  //         }}
                  //       >
                  //         <Typography
                  //           sx={{
                  //             fontSize: '12px',
                  //           }}
                  //         >
                  //           {right}
                  //         </Typography>
                  //         <Typography
                  //           sx={{
                  //             // color: "white",
                  //             cursor: 'pointer',
                  //           }}
                  //           onClick={() => {
                  //             setRigthtsArr(
                  //               rightsArr.filter((item) => item !== right)
                  //             );
                  //           }}
                  //         >
                  //           X
                  //         </Typography>
                  //       </Box>
                  //     );
                  //   })}
                  // </Box>
                }
                {/* <Box
                component={"select"}
                {...register("rights")}
                // defaultValue={null}
              >
                <Box component={"option"} value={null} disabled>
                  Select
                </Box>
                <Box component={"option"} value={"hello2"}>
                  Hello2
                </Box>
              </Box> */}

                {/* <select {...register("rights")}> */}
                {/* <option>Select</option>
              <option>hello</option> */}
                {/* </select> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "20%",
                  textAlign: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  mt: 2,
                }}
              >
                {rightsArr?.map((right, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      textAlign: "center",
                      justifyContent: "space-between",
                      alignItems: "center",
                      alignContent: "center",
                      padding: "5px",
                      backgroundColor: "#f0f0f0",
                      borderRadius: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      {right}
                    </Typography>
                    <DeleteIcon
                      sx={{
                        cursor: "pointer",
                        color: "#FF6F61",
                      }}
                      onClick={() => {
                        setRigthtsArr(
                          rightsArr.filter((item) => item !== right)
                        );
                      }}
                    />
                  </Box>
                ))}
              </Box>
              <Box>
                <label>Enter Password </label>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ display: "flex", position: "relative" }}>
                    <input
                      type={passField ? "password" : "text"}
                      placeholder="password"
                      {...register("password")}
                    />

                    <Typography
                      sx={{
                        fontSize: "12px",
                        mt: "0px",
                        cursor: "pointer",
                        position: "absolute",
                        left: "30%",
                      }}
                      onClick={() => {
                        setPassField(!passField);
                      }}
                    >
                      <IconButton onClick={handleShowPasswordClick}>
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Typography>
                  </Box>
                  {errors.password && (
                    <Typography sx={ErrorStyle}>
                      {errors.password.message}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box>
                <label>Enter Confirm Password</label>
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <input
                    type={confrimPassField ? "password" : "text"}
                    placeholder="Confirm password"
                    {...register("confirmpassword")}
                  />

                  <Typography
                    sx={{
                      fontSize: "12px",
                      mt: "0px",
                      cursor: "pointer",
                      position: "absolute",
                      left: "30%",
                    }}
                    onClick={() => {
                      setConfrimPassField(!confrimPassField);
                    }}
                  >
                    <IconButton onClick={handleShowPasswordClickConfirm}>
                      {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Typography>
                </Box>
                {errors.confirmpassword && (
                  <Typography sx={ErrorStyle}>
                    {errors.confirmpassword.message}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box className="center-buttons">
              <Box
                component="Button"
                variant="contained"
                className="cancel-button"
                // onClick={() => {
                //   navigate('/admindashboard/memberdetails');
                // }} .
                onClick={handleCancel}
                sx={{ ...ButtonStyle, backgroundColor: "white" }}
              >
                Cancel
              </Box>

              <Box
                component="Button"
                variant="contained"
                color="primary"
                type="submit"
                sx={{ ...ButtonStyle, background: "#445FD2", color: "#fff" }}
              >
                Add user
              </Box>
            </Box>
          </Box>
        </form>
      </Paper>
    </div>
  );
};

export default Addmember;
const ErrorStyle = {
  color: "red",
  fontSize: "12px",
  fontWeight: "bold",
  mt: "1px",
};

const ButtonStyle = {
  width: "100%",
  height: "40px",
  border: "none",
  borderRadius: 1,
  "&:hover": {
    cursor: "pointer",
    boxShadow: "1px 1px 5px 1px #ccc",
  },
};
