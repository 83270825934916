import axios from "axios";
import React, { useEffect, useState } from "react";

const PIAmountCalculator = (props) => {
  const [MemberShipData, setMemberShipData] = useState();

  let BuyerId = props?.Data?.BuyerDetails?.BuyerCompanyId;

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(`/company/get_company/${BuyerId}`, {
        withCredentials: true,
      });
      const data =
        response.data.memberships[response?.data?.memberships?.length - 1]
          .MembershipPlan;
      setMemberShipData(data);
    } catch (error) {}
  };

  useEffect(() => {
    GetCompanyByID();
  }, [props]);

  let BxiCommision = props?.Data?.orderData?.PurchaseOrderData?.CouponData
    ?.BXICommission
    ? Number(
        props?.Data?.orderData?.PurchaseOrderData?.CouponData?.BXICommission
      )
    : Number(MemberShipData?.GST);

  const ChargesArray = [];

  if (!props?.Data?.IsMedia) {
    ChargesArray.push({
      name: "Packaging charges",
      gst: Number(props?.Data?.PackagingData?.packagingcostgstfee || 0),
      hsn: "998540",
      taxableValue: Number(props?.Data?.PackagingData?.packagingcosts || 0),
      totalTaxAmount:
        (Number(props?.Data?.PackagingData?.packagingcosts || 0) *
          Number(props?.Data?.PackagingData?.packagingcostgstfee || 0)) /
        100,
    });

    ChargesArray.push({
      name: "Logistics Charges",
      gst: Number(props?.Data?.Transportationdata?.gstFee || 0),
      hsn: "996519",
      taxableValue: Number(
        props?.Data?.Transportationdata?.transportationfee || 0
      ),
      totalTaxAmount:
        (Number(props?.Data?.Transportationdata?.transportationfee || 0) *
          Number(props?.Data?.Transportationdata?.gstFee || 0)) /
        100,
    });
  }

  ChargesArray.push({
    name: "BXI Commission",
    gst: 18,
    commission: BxiCommision,
    hsn: "996211",
    taxableValue:
      (Number(props?.Data?.PITotals?.TotalCoinsWithAdditionalCost || 0) *
        BxiCommision) /
      100,
    totalTaxAmount:
      (((Number(props?.Data?.PITotals?.TotalCoinsWithAdditionalCost || 0) *
        BxiCommision) /
        100) *
        18) /
      100,
  });

  let totatlTaxableAmount = 0;
  let ChargesTaxableValue = 0;
  let ChargesTaxableAmount = 0;
  let AdcostTotalTaxableAmount = 0;
  let totaladditionalcostGst = 0;
  let totalGST = 0;

  props?.Data?.ProductData?.map((item) => {
    if (props?.Data?.IsMedia) {
      totatlTaxableAmount +=
        item.DiscountedPrice *
        item?.TimelineToBought *
        item.ProductQuantity *
        item?.BoughtSeconds;
      totalGST += item.GST;
      if (item?.AdditionCost.AdditionCostArrayData?.length > 0) {
        item?.AdditionCost.AdditionCostArrayData.forEach((item) => {
          if (item?.currencyType === "₹") {
            totaladditionalcostGst +=
              (item?.TotalTaxableAmount * item?.AdCostGST) / 100;
          } else if (item?.currencyType === "BXITokens") {
            totaladditionalcostGst +=
              (item?.TotalTaxableAmount * item?.AdCostGST) / 100;
          }
          AdcostTotalTaxableAmount += item?.TotalTaxableAmount;
        });
      }
    } else {
      item?.AdditionCost?.AdditionCostArray?.forEach((item, index) => {
        AdcostTotalTaxableAmount += item?.TotalTaxableAmount;
      });
      totatlTaxableAmount += item.PricePerUnitWithoutGST * item.ProductQuantity;
    }
  });
  ChargesArray?.map((res, idx) => {
    ChargesTaxableValue += Number(res?.taxableValue);
    ChargesTaxableAmount += Number(res?.totalTaxAmount);
  });

  let amountbeforeTax =
    ChargesTaxableValue + totatlTaxableAmount + AdcostTotalTaxableAmount;

  let totalTaxAmount = props?.Data?.IsMedia
    ? props?.Data?.PITotals?.TotalGstAmount +
      totaladditionalcostGst +
      ChargesTaxableAmount
    : props?.Data?.PITotals?.TotalGstAmount +
      props?.Data?.PITotals?.TotalAdCostGstAmount +
      ChargesTaxableAmount;
  let invoiceTotalAmount = totalTaxAmount + amountbeforeTax;
  return <div>{invoiceTotalAmount ? invoiceTotalAmount.toFixed(2) : "--"}</div>;
};

export default PIAmountCalculator;
