import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";

const GetLoggedInUser = async () => {
  const response = await axios.get("/api/v1/admin/getloggedinnuser", {
    headers: {
      authorization: `bearer ${GetTokenFunc()}`,
    },
  });
  return response.data;
};

export const useGetLoggedInUser = () => {
  return useQuery("LoggedInUser", GetLoggedInUser);
};
