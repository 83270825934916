import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import PurchaseOrder from "./Allinvoicedetail/Purchaseorder";
import BXICommission from "../AllRecords/Allinvoicedetail/BXICommission";
import TaxInvoice from "../AllRecords/Allinvoicedetail/TaxInvoice";
import MediaTaxInvoice from "../AllRecords/Allinvoicedetail/MediaTaxInvoice";
import VoucherTaxInvoice from "../AllRecords/Allinvoicedetail/VoucherTaxInvoice";
import ProformaInvoice from "../AllRecords/Allinvoicedetail/ProformaInvoice";
import MediaProformaInvoice from "../AllRecords/Allinvoicedetail/MediaProformaInvoice";
import MembershipFeesInvoice from "../AllRecords/Allinvoicedetail/MembershipFeesInvoice";
import "../Company/Company.Modules.css";
// import useGetOrderById from "./../AllRecords/Allinvoicedetail/useGetOrderById";
import BackArrowSmall from "../../assets/Vector (1).svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const InvoiceDetail = () => {
  let { id } = useParams();
  let location = useLocation();
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [OrderData, setOrderData] = useState();

  const GetOrderById = async () => {
    await axios
      .get(`order/get_order_by_id/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setOrderData(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    GetOrderById();
  }, []);

  const { companyId } = useParams();

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          onClick={() => {
            navigate(`/admindashboard/companylanding/${companyId}`, {
              state: {
                activeTab: 4,
                childactiveTab: location?.state?.activeTab,
              },
            });
          }}
          component="img"
          src={BackArrowSmall}
          sx={{
            position: "relative",
            top: "35px",
            cursor: "pointer",
          }}
        />
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              className="company-details-tabs"
              onChange={handleTabChange}
            >
              {/* <Tab
                sx={{
                  "&.Mui-selected": { color: "#445FD2 !important" },
                  textTransform: "capitalize",
                }}
                label="Membership Invoice"
                value="1"
              /> */}
              <Tab
                sx={{
                  "&.Mui-selected": { color: "#445FD2 !important" },
                  textTransform: "capitalize",
                }}
                label="Purchase Order"
                value="1"
              />
              <Tab
                sx={{
                  "&.Mui-selected": { color: "#445FD2 !important" },
                  textTransform: "capitalize",
                }}
                label="Proforma Invoice"
                value="2"
              />
              <Tab
                sx={{
                  "&.Mui-selected": { color: "#445FD2 !important" },
                  textTransform: "capitalize",
                }}
                label="Tax Invoice"
                value="3"
              />
              <Tab
                sx={{
                  "&.Mui-selected": { color: "#445FD2 !important" },
                  textTransform: "capitalize",
                }}
                label="BXI Commission"
                value="4"
              />
            </TabList>
          </Box>
          {/* <TabPanel value="1">
            <MembershipFeesInvoice OrderData={OrderData} />
          </TabPanel> */}
          <TabPanel value="1">
            {" "}
            {OrderData?.PurchaseOrderData && !OrderData?.IsVoucher ? (
              <PurchaseOrder />
            ) : (
              "No Purchase Order found"
            )}
          </TabPanel>
          <TabPanel value="2">
            {OrderData?.InvoiceData ? (
              OrderData?.IsMedia ? (
                <MediaProformaInvoice />
              ) : (
                <ProformaInvoice />
              )
            ) : (
              "No Proforma Invoice found"
            )}
          </TabPanel>
          <TabPanel value="3">
            {OrderData?.TaxInvoice ? (
              OrderData?.IsMedia ? (
                <MediaTaxInvoice />
              ) : OrderData?.IsVoucher ? (
                <VoucherTaxInvoice />
              ) : (
                <TaxInvoice />
              )
            ) : (
              "No Tax Invoice found"
            )}
          </TabPanel>
          <TabPanel value="4">
            {OrderData?.BXICommision || OrderData?.IsCommisionInvoiceCreated ? (
              <BXICommission OrderData={OrderData} />
            ) : (
              "No BXI Commission found"
            )}
          </TabPanel>
        </TabContext>
      </Paper>
    </div>
  );
};

export default InvoiceDetail;
