import { createSlice } from "@reduxjs/toolkit";

export const AllCompanys = createSlice({
    name: "companys",
    initialState: {
        AllCompany: []
    },
    reducers: {
        All_Company_Request: (state) => {
            state.loading = true;
            state.AllCompany = [];
        },
        All_Company_Success: (state, action) => {
            state.loading = false;
            state.AllCompany = action.payload;
        },
        All_Company_Failed: (state, action) => {
            state.loading = false;
            state.AllCompany = action.payload;
        },
    }
})

export const { All_Company_Request, All_Company_Success, All_Company_Failed } = AllCompanys.actions;

export default AllCompanys.reducer;