import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Slider from "react-slick";
import GotLeftImg from "../../../assets1/Images/CommonImages/GoLeftImg.png";
import GoRightImg from "../../../assets1/Images/CommonImages/GoRightImg.png";
import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Box
      component={"img"}
      className={className}
      onClick={onClick}
      sx={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        left: "-3%",
        height: "auto",
        width: "7%",
        zIndex: "1",
      }}
      src={GotLeftImg}
    ></Box>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <Box
      component={"img"}
      className={className}
      onClick={onClick}
      sx={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        right: "-3%",
        height: "auto",
        width: "7%",
        zIndex: "10",
      }}
      src={GoRightImg}
    ></Box>
  );
}

export default function CarouselForProductHorizontal(props) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openModal = (index) => {
    setIsModalOpen(true);
    setSelectedImageIndex(index);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const settings = {
    className: "center",
    // centerMode: true,
    beforeChange: (current, next) => setCurrentSlide(next),
    customPaging: function (i) {
      return (
        <a>
          <div>
            <li
              style={{
                border: "3px solid #6B7A99",
                backgroundColor: currentSlide === i ? "#6B7A99" : "white",
                borderRadius: "10px",
                width: "10px",
                height: "10px",
              }}
            ></li>
          </div>
        </a>
      );
    },
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 1,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    touchMove: true,
    dots: true,
    // draggable: true,
    // dotsClass: "slick-dots slick-thumb",
    // appendDots: (dots) => <ul>{dots}</ul>,
  };

  return (
    <Grid
      sx={{
        width: "100%",
        maxWidth: "450px",
        mx: "auto",
        position: "relative",
        borderRadius: "10px",
      }}
      gap={5}
    >
      <Slider {...settings}>
        {props?.ImageDataArray?.map((res, index) => {
          return (
            <Box
              sx={{
                width: "100%",
                height: "350px",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: "10px",
                // backgroundColor: "red",
                backgroundImage: `url(${res?.url}), url('fallback-image-url')`,
              }}
              onClick={() => openModal(index)}
            ></Box>
          );
        })}
      </Slider>

      <Dialog
        sx={{
          backgroundColor: "transparent",
        }}
        fullWidth
        open={isModalOpen}
        onClose={closeModal}
        maxWidth="lg"
      >
        <DialogContent
          sx={{
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              bgcolor: "transparent",
            }}
          >
            <CloseIcon
              onClick={closeModal}
              sx={{
                color: "#fff",
                fontSize: "20px",
                borderRadius: "15px",
                cursor: "pointer",
                bgcolor: "rgb(195, 202, 217)",
                my: 0.5,
              }}
            />
          </Box>
          <Grid
            sx={{
              width: "80%",
              height: "550px",
              mx: "auto",
              position: "relative",
              borderRadius: "10px",
            }}
          >
            <Slider {...settings}>
              {props?.ImageDataArray?.map((res, index) => {
                return (
                  <Box
                    sx={{
                      width: "90%",
                      height: "550px",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${res?.url}), url('fallback-image-url')`,
                    }}
                  ></Box>
                );
              })}
            </Slider>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}

const ImageStyle = {
  width: "98%",
  borderRadius: "10px",
  maxWidth: "450px",
  height: "auto",
  minHeight: "450px",
  maxHeight: "450px",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  textAlign: "center",
};
