import {
  Box,
  Grid,
  Paper,
  Divider,
  IconButton,
  Modal,
  Input,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useFetchAdminTrails } from "../../Hooks/AllRecords/useAllRecords";
import * as Styles from "../../common/Styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import filter from "../../assets/filter.png";
import CloseIcon from "../../assets/CloseIcon.svg";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import PageLoader from "../CommonActions/PageLoader";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIconDialog from "@mui/icons-material/Close";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
import Searchbar from "../../common/Searchbar";

function Invoices() {
  const navigate = useNavigate();

  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );
  const [open, setOpen] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [AdminTrailsbyid, setAdminTrailsbyid] = useState();

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  //   const [Type, setType] = useState("");
  const {
    data: fetchAdminTrails,
    isLoading: AdminTrailsLoading,
    refetch: AdminTrailsRefetch,
  } = useFetchAdminTrails(currentPage, searchData);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const GetAdminTrails = async (id) => {
    try {
      const response = await axios.get(`api/v1/admin/get-admin-trail/${id}`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
      setAdminTrailsbyid(response.data);
      // console.log("response", response.data);
    } catch (err) {
      console.error("Error fetching category data:", err);
    }
  };

  useEffect(() => {
    if (fetchAdminTrails && fetchAdminTrails?.totalPages !== undefined) {
      setInitialPaginationCount(fetchAdminTrails?.totalPages);
    }
  }, [fetchAdminTrails?.totalPages]);
  useEffect(() => {
    AdminTrailsRefetch();
  }, [currentPage, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const [openFilter, setOpenFilter] = React.useState(false);

  const handleFilterOpen = () => setOpenFilter(true);
  const handleFilterClose = () => setOpenFilter(false);

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "620px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <React.Fragment>
        <Paper elevation={0}>
          <Box
            className="page-heading-section"
            style={{
              justifyContent: "start",
              gap: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <KeyboardBackspaceIcon />
            </Box>
            <Box className="page-heading">
              <h5>Record of Admin Trails</h5>
              <Typography>Footprint Data of All work Happened</Typography>
            </Box>
            <Searchbar onSendData={handleChildData} />
          </Box>
          <Divider />
          {AdminTrailsLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                ml: "500px",
                mt: 20,
              }}
            >
              <PageLoader />
            </Box>
          ) : (
            <Box
              sx={{
                background: "rgba(250, 251, 252, 1)",
                p: 1,
                mt: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "97%",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box sx={buddge}>
                  <Typography sx={textBudge}>
                    {fetchAdminTrails instanceof Object ? (
                      <Typography sx={textBudge}>
                        Total Requests: {fetchAdminTrails?.totalCounts}
                      </Typography>
                    ) : (
                      <Typography sx={textBudge}>Total Requests: 0</Typography>
                    )}
                  </Typography>
                </Box>
                {/* <Box
                  className="marketplace-filters"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                  }}
                >
                  <span style={textBudge}>Type</span>
                  <Box>
                    <div class="input-container">
                      <select
                        value={Type}
                        onChange={(e) => setType(e.target.value)}
                      >
                        <option value="">All</option>
                        <option value="Proforma">Proforma</option>
                        <option value="TaxInvoice">Tax Invoice</option>
                        <option value="BxiFees">BxiFees</option>
                        <option value="PurchaseOrder">PurchaseOrder</option>
                        <option value="ShippingInvoice">ShippingInvoice</option>
                      </select>
                    </div>
                  </Box>
                </Box> */}
                {/* <Box sx={Styles.filtersDesign} onClick={handleFilterOpen}>
                  <img
                    src={filter}
                    alt=""
                    style={{ height: 'auto', width: '15px' }}
                  />
                  Filters
                </Box> */}
              </Box>
              <Box
                sx={{
                  background: AdminTrailsLoading
                    ? "rgba(250, 251, 252, 1)"
                    : "transparent",
                  p: 1,
                  mt: 0,
                  width: "100%",
                  borderRadius: 2,
                }}
              >
                <Grid
                  container
                  sx={{
                    p: 2,
                  }}
                >
                  <Grid
                    align="left"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    No.
                  </Grid>
                  <Grid
                    item
                    xl={2.5}
                    lg={2.5}
                    md={2.5}
                    sm={2.5}
                    xs={2.5}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Name
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Email
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={2.5}
                    lg={2.5}
                    md={2.5}
                    sm={2.5}
                    xs={2.5}
                    sx={Styles.textHead}
                  >
                    Events
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={2.5}
                    lg={2.5}
                    md={2.5}
                    sm={2.5}
                    xs={2.5}
                    sx={Styles.textHead}
                  >
                    Date
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Action
                  </Grid>
                </Grid>

                {fetchAdminTrails?.data?.map((data, idx) => {
                  //   console.log("Loading", data?._id);
                  const formattedDate = data ? formatDate(data?.createdAt) : "";
                  return (
                    <>
                      <Grid
                        container
                        sx={{
                          p: 2,
                        }}
                      >
                        <Grid
                          align="left"
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={{
                            ...subText,
                            display: "flex",
                            justifyContent: "flex-start",
                            textAlign: "center",
                          }}
                        >
                          {idx + 1}
                        </Grid>
                        <Grid
                          item
                          xl={2.5}
                          lg={2.5}
                          md={2.5}
                          sm={2.5}
                          xs={2.5}
                          sx={{
                            ...subText,
                            display: "flex",
                            justifyContent: "flex-start",
                            textAlign: "center",
                          }}
                        >
                          {data?.initiatedBy?.name}
                        </Grid>
                        <Grid
                          item
                          xl={2}
                          lg={2}
                          md={2}
                          sm={2}
                          xs={2}
                          sx={{
                            ...subText,
                            display: "flex",
                            justifyContent: "flex-start",
                            textAlign: "center",
                          }}
                        >
                          {data?.initiatedBy?.email}
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={2.5}
                          lg={2.5}
                          md={2.5}
                          sm={2.5}
                          xs={2.5}
                          sx={subText}
                        >
                          {data?.event}
                        </Grid>

                        <Grid
                          align="center"
                          item
                          xl={2.5}
                          lg={2.5}
                          md={2.5}
                          sm={2.5}
                          xs={2.5}
                          sx={subText}
                        >
                          {formattedDate}
                        </Grid>
                        <Grid
                          align="center"
                          item
                          xl={1}
                          lg={1}
                          md={1}
                          sm={1}
                          xs={1}
                          sx={subText}
                        >
                          <VisibilityIcon
                            sx={{ color: "#445FD2", cursor: "pointer" }}
                            onClick={() => {
                              handleClickOpen();
                              GetAdminTrails(data?._id);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Typography
                        sx={{
                          borderBottom: "1px solid rgba(245, 245, 245, 1)",
                        }}
                      ></Typography>
                    </>
                  );
                })}
                <Dialog
                  open={open}
                  onClose={handleClose}
                  fullWidth
                  maxWidth="sm"
                  sx={{
                    backdropFilter: "blur(2px)",
                  }}
                  PaperProps={{
                    sx: {
                      width: "60%",
                      maxHeight: 600,
                      borderRadius: "20px",
                    },
                  }}
                >
                  <DialogTitle id="responsive-dialog-title">
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "start",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          width: "80%",
                          mx: "auto",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "18px",
                            lineHeight: "28px",
                            color: "#6B7A99",
                            textAlign: "center",
                          }}
                        >
                          More information
                        </Typography>
                      </Box>
                      <Box>
                        <CloseIconDialog
                          sx={{
                            color: "#667085",
                            fontSize: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (open === false) {
                              setOpen(true);
                            } else {
                              setOpen(false);
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "scroll",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "auto",
                        // border: "1px solid #EDEFF2",
                        // borderRadius: "10px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        p: 2,
                      }}
                    >
                      {AdminTrailsbyid?.initiatedBy === undefined ||
                      AdminTrailsbyid?.initiatedBy === null ? null : (
                        <Box sx={infoboxStyle}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              Local Information
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "65%",
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                              }}
                            />
                          </Box>
                          <Typography sx={SubDataHeadText}>
                            Name :{" "}
                            <span style={SubDataText}>
                              {AdminTrailsbyid?.initiatedBy?.name}
                            </span>
                          </Typography>
                          <Typography sx={SubDataHeadText}>
                            Email :{" "}
                            <span style={SubDataText}>
                              {AdminTrailsbyid?.initiatedBy?.email}
                            </span>
                          </Typography>
                          <Typography sx={SubDataHeadText}>
                            Role :{" "}
                            <span style={SubDataText}>
                              {AdminTrailsbyid?.initiatedBy?.role}
                            </span>
                          </Typography>
                          <Typography sx={SubDataHeadText}>
                            Last Login Time :{" "}
                            <span style={SubDataText}>
                              {new Date(
                                AdminTrailsbyid?.initiatedBy?.lastLogin
                              )?.toLocaleString()}
                            </span>
                          </Typography>
                          <Typography sx={SubDataHeadText}>
                            Event :{" "}
                            <span style={SubDataText}>
                              {AdminTrailsbyid?.event}
                            </span>
                          </Typography>
                          <Typography sx={SubDataHeadText}>
                            Date :{" "}
                            <span style={SubDataText}>
                              {new Date(
                                AdminTrailsbyid?.createdAt
                              )?.toLocaleString()}
                            </span>
                          </Typography>
                          {AdminTrailsbyid?.initiatedBy?.acess?.length <=
                          0 ? null : (
                            <Typography sx={SubDataHeadText}>
                              Acesss :
                              {AdminTrailsbyid?.initiatedBy?.acess.map(
                                (x, idx) => {
                                  return (
                                    <span style={SubDataText}>
                                      {" "}
                                      {x + "."}
                                      <br />{" "}
                                    </span>
                                  );
                                }
                              )}
                            </Typography>
                          )}
                        </Box>
                      )}
                      {AdminTrailsbyid?.newData === undefined ||
                      AdminTrailsbyid?.newData === null ? null : (
                        <Box sx={infoboxStyle}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              New Data Information
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "65%",
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                              }}
                            />
                          </Box>
                          {AdminTrailsbyid?.newData?.role ? (
                            <Typography sx={SubDataHeadText}>
                              Role :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.newData?.role}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.newData?.ListingType ? (
                            <Typography sx={SubDataHeadText}>
                              ListingType :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.newData?.ListingType}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.newData?.ProductName ? (
                            <Typography sx={SubDataHeadText}>
                              ProductName :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.newData?.ProductName}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.newData?.Price ? (
                            <Typography sx={SubDataHeadText}>
                              Price :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.newData?.Price}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.newData?.Quantity ? (
                            <Typography sx={SubDataHeadText}>
                              Quantity :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.newData?.Quantity}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.newData?.new ? (
                            <Typography sx={SubDataHeadText}>
                              Is :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.newData?.new === "true"
                                  ? "true"
                                  : "false"}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.newData?.ProductUploadStatus ? (
                            <Typography sx={SubDataHeadText}>
                              ProductUploadStatus :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.newData?.ProductUploadStatus}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.newData?.companyName ? (
                            <Typography sx={SubDataHeadText}>
                              Company Name :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.newData?.companyName}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.newData?.email ? (
                            <Typography sx={SubDataHeadText}>
                              Email :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.newData?.email}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.newData?.acess ? (
                            <Typography sx={SubDataHeadText}>
                              Acesss :{" "}
                              {AdminTrailsbyid?.newData?.acess?.length > 0
                                ? AdminTrailsbyid?.newData?.acess.map(
                                    (x, idx) => {
                                      return (
                                        <span style={SubDataText}>
                                          {" "}
                                          {x + "."}
                                          <br />{" "}
                                        </span>
                                      );
                                    }
                                  )
                                : null}
                            </Typography>
                          ) : null}
                        </Box>
                      )}
                      {AdminTrailsbyid?.oldData === undefined ||
                      AdminTrailsbyid?.oldData === null ? null : (
                        <Box sx={infoboxStyle}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              Old Data Information
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "65%",
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                              }}
                            />
                          </Box>
                          {AdminTrailsbyid?.oldData?.name ? (
                            <Typography sx={SubDataHeadText}>
                              Name :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.name}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.ProductName ? (
                            <Typography sx={SubDataHeadText}>
                              ProductName :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.ProductName}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.PricePerUnit ? (
                            <Typography sx={SubDataHeadText}>
                              PricePerUnit :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.PricePerUnit}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.ProductGst ? (
                            <Typography sx={SubDataHeadText}>
                              ProductGst :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.ProductGst}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.ProductQuantity ? (
                            <Typography sx={SubDataHeadText}>
                              ProductQuantity :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.ProductQuantity}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.TotalGSTAmount ? (
                            <Typography sx={SubDataHeadText}>
                              TotalGSTAmount :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.TotalGSTAmount}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.TotalPrice ? (
                            <Typography sx={SubDataHeadText}>
                              TotalPrice :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.TotalPrice}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.ProductUploadStatus ? (
                            <Typography sx={SubDataHeadText}>
                              ProductUploadStatus :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.ProductUploadStatus}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.SellerCompanyName ? (
                            <Typography sx={SubDataHeadText}>
                              SellerCompanyName :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.SellerCompanyName}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.companyName ? (
                            <Typography sx={SubDataHeadText}>
                              Company Name :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.companyName}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.CompanyAddress
                            ?.AddressLine ? (
                            <Typography sx={SubDataHeadText}>
                              Company Address:{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.oldData?.CompanyAddress
                                    ?.AddressLine
                                }
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.companyOnboardingStatus ? (
                            <Typography sx={SubDataHeadText}>
                              Company Onboarding Status:{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.oldData
                                    ?.companyOnboardingStatus
                                }
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.OnboardingPaymentStatus ? (
                            <Typography sx={SubDataHeadText}>
                              Onboarding Payment Status:{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.oldData
                                    ?.OnboardingPaymentStatus
                                }
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.role ? (
                            <Typography sx={SubDataHeadText}>
                              Role :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.role}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.email ? (
                            <Typography sx={SubDataHeadText}>
                              Email :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.oldData?.email}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.oldData?.acess ? (
                            <Typography sx={SubDataHeadText}>
                              Acesss :{" "}
                              {AdminTrailsbyid?.oldData?.acess?.length > 0
                                ? AdminTrailsbyid?.oldData?.acess.map(
                                    (x, idx) => {
                                      return (
                                        <span style={SubDataText}>
                                          {" "}
                                          {x + "."}
                                          <br />{" "}
                                        </span>
                                      );
                                    }
                                  )
                                : null}
                            </Typography>
                          ) : null}
                        </Box>
                      )}
                      {AdminTrailsbyid?.companyId === undefined ||
                      AdminTrailsbyid?.companyId === null ? null : (
                        <Box sx={infoboxStyle}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              Company Information
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "65%",
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                              }}
                            />
                          </Box>
                          {AdminTrailsbyid?.companyId?.companyName ? (
                            <Typography sx={SubDataHeadText}>
                              Company Name :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.companyId?.companyName}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.companyId?.CompanyAddress
                            ?.AddressLine ? (
                            <Typography sx={SubDataHeadText}>
                              Company Address :{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.companyId?.CompanyAddress
                                    ?.AddressLine
                                }
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.companyId
                            ?.companyOnboardingStatus ? (
                            <Typography sx={SubDataHeadText}>
                              Company Onboarding Status :{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.companyId
                                    ?.companyOnboardingStatus
                                }
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.companyId
                            ?.OnboardingPaymentStatus ? (
                            <Typography sx={SubDataHeadText}>
                              Onboarding Payment Status :{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.companyId
                                    ?.OnboardingPaymentStatus
                                }
                              </span>
                            </Typography>
                          ) : null}{" "}
                          {AdminTrailsbyid?.companyId?.email ? (
                            <Typography sx={SubDataHeadText}>
                              Email :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.companyId?.email}
                              </span>
                            </Typography>
                          ) : null}
                        </Box>
                      )}
                      {AdminTrailsbyid?.Company === undefined ||
                      AdminTrailsbyid?.Company === null ? null : (
                        <Box sx={infoboxStyle}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              Company Information
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "65%",
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                              }}
                            />
                          </Box>
                          {AdminTrailsbyid?.Company?.companyName ? (
                            <Typography sx={SubDataHeadText}>
                              Company Name :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.Company?.companyName}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.Company?.CompanyAddress
                            ?.AddressLine ? (
                            <Typography sx={SubDataHeadText}>
                              Company Address :{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.Company?.CompanyAddress
                                    ?.AddressLine
                                }
                              </span>
                            </Typography>
                          ) : null}{" "}
                          {AdminTrailsbyid?.Company?.companyOnboardingStatus ? (
                            <Typography sx={SubDataHeadText}>
                              Company Onboarding Status :{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.Company
                                    ?.companyOnboardingStatus
                                }
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.Company?.OnboardingPaymentStatus ? (
                            <Typography sx={SubDataHeadText}>
                              Onboarding Payment Status :{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.Company
                                    ?.OnboardingPaymentStatus
                                }
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.Company?.email ? (
                            <Typography sx={SubDataHeadText}>
                              Email :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.Company?.email}
                              </span>
                            </Typography>
                          ) : null}
                        </Box>
                      )}
                      {AdminTrailsbyid?.productId === undefined ||
                      AdminTrailsbyid?.productId === null ? null : (
                        <Box sx={infoboxStyle}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              Product Information
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "65%",
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                              }}
                            />
                          </Box>
                          {AdminTrailsbyid?.productId?.ProductName ? (
                            <Typography sx={SubDataHeadText}>
                              Product Name :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.productId?.ProductName}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.productId?.ProductSubtittle ? (
                            <Typography sx={SubDataHeadText}>
                              Product Subtittle :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.productId?.ProductSubtittle}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.productId?.ListingType ? (
                            <Typography sx={SubDataHeadText}>
                              Product Type :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.productId?.ListingType}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.productId?.ProductCategoryName ? (
                            <Typography sx={SubDataHeadText}>
                              Product Category Name :{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.productId
                                    ?.ProductCategoryName
                                }
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.productId?.ProductsVariantions?.at(
                            0
                          )?.PricePerUnit ? (
                            <Typography sx={SubDataHeadText}>
                              Product Price :{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.productId?.ProductsVariantions?.at(
                                    0
                                  )?.PricePerUnit
                                }
                              </span>
                            </Typography>
                          ) : null}{" "}
                          {AdminTrailsbyid?.productId?.ProductsVariantions?.at(
                            0
                          )?.DiscountedPrice ? (
                            <Typography sx={SubDataHeadText}>
                              Product Discounted Price:{" "}
                              <span style={SubDataText}>
                                {
                                  AdminTrailsbyid?.productId?.ProductsVariantions?.at(
                                    0
                                  )?.DiscountedPrice
                                }
                              </span>
                            </Typography>
                          ) : null}
                        </Box>
                      )}
                      {AdminTrailsbyid?.newAmount ? (
                        <Typography>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              New Amount
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "75%",
                                position: "absolute",
                                top: "50%",
                                left: "25%",
                              }}
                            />
                          </Box>
                          <Typography sx={SubDataHeadText}>
                            Amount :{" "}
                            <span style={SubDataText}>
                              {AdminTrailsbyid?.newAmount}
                            </span>
                          </Typography>
                        </Typography>
                      ) : null}
                      {AdminTrailsbyid?.oldAmount ? (
                        <Typography>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              Old Amount
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "75%",
                                position: "absolute",
                                top: "50%",
                                left: "25%",
                              }}
                            />
                          </Box>
                          <Typography sx={SubDataHeadText}>
                            Amount :{" "}
                            <span style={SubDataText}>
                              {AdminTrailsbyid?.oldAmount}
                            </span>
                          </Typography>
                        </Typography>
                      ) : null}
                      {AdminTrailsbyid?.creditedAmount ? (
                        <Typography>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              Credited Amount
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "65%",
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                              }}
                            />
                          </Box>
                          <Typography sx={SubDataHeadText}>
                            Amount :{" "}
                            <span style={SubDataText}>
                              {AdminTrailsbyid?.creditedAmount}
                            </span>
                          </Typography>
                        </Typography>
                      ) : null}
                      {AdminTrailsbyid?.operationAdminUser === undefined ||
                      AdminTrailsbyid?.operationAdminUser === null ? null : (
                        <Box sx={infoboxStyle}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              Operation Admin User Information
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "65%",
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                              }}
                            />
                          </Box>
                          {AdminTrailsbyid?.operationAdminUser?.name ? (
                            <Typography sx={SubDataHeadText}>
                              Name :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.operationAdminUser?.name}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.operationAdminUser?.role ? (
                            <Typography sx={SubDataHeadText}>
                              Role :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.operationAdminUser?.role}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.operationAdminUser?.email ? (
                            <Typography sx={SubDataHeadText}>
                              Email :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.operationAdminUser?.email}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.operationAdminUser?.acess ? (
                            <Typography sx={SubDataHeadText}>
                              Acesss :
                              {AdminTrailsbyid?.operationAdminUser?.acess.map(
                                (x, idx) => {
                                  return (
                                    <span style={SubDataText}>
                                      {" "}
                                      {x + "."}
                                      <br />{" "}
                                    </span>
                                  );
                                }
                              )}
                            </Typography>
                          ) : null}
                        </Box>
                      )}
                      {AdminTrailsbyid?.fund === undefined ||
                      AdminTrailsbyid?.fund === null ? null : (
                        <Box sx={infoboxStyle}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              Fund Information
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "65%",
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                              }}
                            />
                          </Box>
                          {AdminTrailsbyid?.fund?.ProductType ? (
                            <Typography sx={SubDataHeadText}>
                              Type :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.fund?.ProductType}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.fund?.amount ? (
                            <Typography sx={SubDataHeadText}>
                              Amount :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.fund?.amount}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.fund?.status ? (
                            <Typography sx={SubDataHeadText}>
                              Status :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.fund?.status}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.fund?.totalPoints ? (
                            <Typography sx={SubDataHeadText}>
                              Total Points :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.fund?.totalPoints}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.fund?.points ? (
                            <Typography sx={SubDataHeadText}>
                              Points Steps :
                              {AdminTrailsbyid?.fund?.points.map((x, idx) => {
                                return (
                                  <span style={SubDataText}>
                                    <br />
                                    {x?.index + ".)"}
                                    {x?.name ? "Name : " + x?.name + ", " : ""}

                                    {x?.points
                                      ? "Points : " + x?.points + ", "
                                      : ""}

                                    {x?.message
                                      ? "Message : " + x?.message + ", "
                                      : ""}

                                    {x?.productType
                                      ? "Product Type : " +
                                        x?.productType +
                                        ", "
                                      : ""}

                                    {x?.bxiApprove
                                      ? "BXi Approvel : " + x?.bxiApprove + ", "
                                      : ""}
                                  </span>
                                );
                              })}
                            </Typography>
                          ) : null}
                        </Box>
                      )}
                      {AdminTrailsbyid?.fundId === undefined ||
                      AdminTrailsbyid?.fundId === null ? null : (
                        <Box sx={infoboxStyle}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              gap: "20px",
                              position: "relative",
                            }}
                          >
                            <Typography
                              sx={{ ...HeadText, bgcolor: "#fff", zIndex: "1" }}
                            >
                              Fund Information
                            </Typography>
                            <span
                              style={{
                                borderTop: "0.5px solid #6B7A99",
                                width: "65%",
                                position: "absolute",
                                top: "50%",
                                left: "35%",
                              }}
                            />
                          </Box>

                          {AdminTrailsbyid?.fundId?.ProductType ? (
                            <Typography sx={SubDataHeadText}>
                              Type :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.fundId?.ProductType}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.fundId?.amount ? (
                            <Typography sx={SubDataHeadText}>
                              Amount :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.fundId?.amount}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.fundId?.status ? (
                            <Typography sx={SubDataHeadText}>
                              Status :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.fundId?.status}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.fundId?.totalPoints ? (
                            <Typography sx={SubDataHeadText}>
                              Total Points :{" "}
                              <span style={SubDataText}>
                                {AdminTrailsbyid?.fundId?.totalPoints}
                              </span>
                            </Typography>
                          ) : null}
                          {AdminTrailsbyid?.fundId?.points ? (
                            <Typography sx={SubDataHeadText}>
                              Points Steps :
                              {AdminTrailsbyid?.fundId?.points.map((x, idx) => {
                                return (
                                  <span style={SubDataText}>
                                    <br />
                                    {x?.index + ".)"}
                                    {x?.name ? "Name : " + x?.name + ", " : ""}

                                    {x?.points
                                      ? "Points : " + x?.points + ", "
                                      : ""}

                                    {x?.message
                                      ? "Message : " + x?.message + ", "
                                      : ""}

                                    {x?.productType
                                      ? "Product Type : " +
                                        x?.productType +
                                        ", "
                                      : ""}

                                    {x?.bxiApprove
                                      ? "BXi Approvel : " + x?.bxiApprove + ", "
                                      : ""}
                                  </span>
                                );
                              })}
                            </Typography>
                          ) : null}
                        </Box>
                      )}
                    </Box>
                  </DialogContent>
                </Dialog>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    mx: "auto",
                  }}
                  mt={2}
                >
                  <Stack>
                    <StyledPagination
                      size="medium"
                      count={initialPaginationCount}
                      color="primary"
                      showFirstButton
                      showLastButton
                      page={currentPage}
                      onChange={handlePageChange}
                      strokeWidth={currentPage}
                    />
                  </Stack>
                </Box>
              </Box>
            </Box>
          )}
        </Paper>

        <Modal open={openFilter} onClose={handleFilterClose}>
          <Box sx={FilterStyle}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Filters
              </Typography>
              <Box
                component="img"
                src={CloseIcon}
                sx={{
                  cursor: "pointer",
                  height: "20px",
                  width: "20px",
                }}
                onClick={() => {
                  handleFilterClose();
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: 1,
                  mt: 1,
                }}
              >
                <FormControlLabel
                  style={RadioTextStyle}
                  value="Product"
                  control={<Radio />}
                  label="Product"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="Voucher"
                  control={<Radio />}
                  label="Voucher"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="All"
                  control={<Radio />}
                  label="All"
                />
              </RadioGroup>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                  mt: 2,
                  textTransform: "none",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleFilterClose();
                  }}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </React.Fragment>
    </Paper>
  );
}

export default Invoices;

const RadioTextStyle = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};

const FilterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  // lineHeight: 20,
};

const HeadText = {
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 500,
  color: "#191919CC",
  textAlign: "left",
  py: 1,
  width: "auto",
  MinWidth: "40%",
  pr: 1,
};

const SubDataHeadText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontWeight: 500,
  textAlign: "left",
};
const SubDataText = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 400,
  textAlign: "left",
};

const subText = {
  color: "#1B212D",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};

const infoboxStyle = {
  width: "100%",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "8px",
};
