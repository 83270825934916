import React, { useState, useEffect } from "react";
import {
  InputLabel,
  MenuItem,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
  Typography,
  Grid,
  Accordion,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Arrow from "../../assets/GenieImg/Arrow.png";
import ss from "../../assets/Recyclebin/ss.png";
import eye from "../../assets/GenieImg/eye.png";
import * as Styles from "../../common/Styles";
import axios from "axios";
import PageLoader from "../CommonActions/PageLoader";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate } from "react-router-dom";
import { GetTokenFunc } from "../../utils/GetToken";
const GenieCategory = () => {
  const [activeMessageBox, setActiveMessageBox] = useState();
  const [expanded, setExpanded] = React.useState(false);
  const [loader, setLoader] = useState(true);
  const [dataTypes, setDataTypes] = useState("All");
  const [category, setCategory] = useState();
  const navigate = useNavigate();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const genieneCategoryData = async () => {
    try {
      const response = await axios.get(
        `/api/v1/admin/AdminCompanyDetails/GenieAllCategoryOfCompanys?${dataTypes}=${dataTypes}`,
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      );
      setCategory(response.data);
      // console.log('response', response.data);
      setLoader(false);
    } catch (err) {
      console.error("Error fetching category data:", err);
    }
  };

  useEffect(() => {
    setLoader(true);
    genieneCategoryData();
  }, [dataTypes]);

  const renderBoth = () => {
    return (
      <div>
        {category &&
          Object.entries(category?.companies)?.map(([key, value], i) => {
            // Destructure the entry into key and value
            // console.log("data", key, value);
            return (
              <div
                key={i}
                style={{
                  width: "95%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Accordion
                  sx={{ mt: 2, width: "100%" }}
                  expanded={expanded === `panel${i + 1}`}
                  onChange={handleChange(`panel${i + 1}`)}
                  // sx={{ width: '100%', height: 52 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${i + 1}bh-content`}
                    id={`panel${i + 1}bh-header`}
                    onClick={() => setActiveMessageBox(key)}
                    sx={{
                      my: 2,
                      borderRadius: 1,
                      borderTop: "none", // Remove top border

                      "&:before": {
                        backgroundColor: "transparent",
                      },
                    }}
                    //   sx={{
                    //     bgcolor:
                    //       x.chatName === activeMessageBox?.x ? '#445FD2' : '#fff',
                    //   }}
                  >
                    <Grid container>
                      <Grid item xl={4} lg={4} md={4}>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Typography sx={numText}>{i + 1}</Typography>
                          {/* <img
                          src={x?.CompanyAvatar?.url}
                          alt=""
                          style={{ width: '35px', height: '35px' }}
                        /> */}
                          <Typography sx={accHeadText}>{key}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={5} lg={5} md={5}>
                        <Box sx={{ display: "flex", gap: "5px" }}>
                          <img
                            src={eye}
                            alt=""
                            style={{
                              height: "auto",
                              width: "15px",
                            }}
                          />
                          <Typography sx={accHeadText}>
                            {value?.totalViewCount}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sx={{ ml: "-30px" }}>
                        <Typography sx={accHeadText}>
                          {key === "Airlines Tickets" ||
                          key === "Hotel" ||
                          key === "Entertainment & Events"
                            ? "Voucher"
                            : key === "Media"
                            ? "Media"
                            : "Product"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      borderRadius: "2px",
                      border: "1.033px solid #E9EFF4",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "40px",
                      }}
                    >
                      <Typography sx={subTextAcc}>No.</Typography>
                      <Typography sx={subTextAcc}>Company Name</Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "0.5px solid #F0F0F0",
                        width: "100%",
                        mx: "auto",
                        mt: 1,
                      }}
                    ></Box>
                    {/* {value?.companies?.map((x, i) => {
                        return (
                          <>
                           
                          </>
                        );
                      })} */}
                    <Box mt={1}>
                      {value?.companies?.map((x, i) => {
                        // console.log('compsny', x);
                        return (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "40px",
                              }}
                            >
                              <Typography
                                sx={{ ...subTextAcc, fontSize: "12px" }}
                                key={i}
                              >
                                {i + 1}
                              </Typography>
                              <Typography
                                sx={{ ...subTextAcc, fontSize: "12px" }}
                              >
                                <Typography>{x?.CompanyName}</Typography>
                              </Typography>
                            </Box>
                          </>
                        );
                      })}
                      <Box>
                        <Typography sx={{ ...subTextAcc, textAlign: "right" }}>
                          View Details
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
      </div>
    );
  };

  const renderAllVouchers = () => {
    return (
      <div>
        {category &&
          Object.entries(category?.companies)?.map(([key, value], i) => {
            // Destructure the entry into key and value
            // console.log('data', key, value);
            return (
              <div
                key={i}
                style={{
                  width: "95%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Accordion
                  sx={{
                    mt: 2,
                    width: "100%",
                    my: 2,
                    borderRadius: 1,
                    borderTop: "none", // Remove top border

                    "&:before": {
                      backgroundColor: "transparent",
                    },
                  }}
                  expanded={expanded === `panel${i + 1}`}
                  onChange={handleChange(`panel${i + 1}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${i + 1}bh-content`}
                    id={`panel${i + 1}bh-header`}
                    onClick={() => setActiveMessageBox(key)}
                    //   sx={{
                    //     bgcolor:
                    //       x.chatName === activeMessageBox?.x ? '#445FD2' : '#fff',
                    //   }}
                  >
                    <Grid container>
                      <Grid item xl={4} lg={4} md={4}>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Typography sx={numText}>{i + 1}</Typography>
                          {/* <img
                          src={x?.CompanyAvatar?.url}
                          alt=""
                          style={{ width: '35px', height: '35px' }}
                        /> */}
                          <Typography sx={accHeadText}>{key}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={5} lg={5} md={5}>
                        <Box sx={{ display: "flex", gap: "5px" }}>
                          <img
                            src={eye}
                            alt=""
                            style={{
                              height: "auto",
                              width: "15px",
                            }}
                          />
                          <Typography sx={accHeadText}>
                            {value?.totalViewCount}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sx={{ ml: "-30px" }}>
                        <Typography sx={accHeadText}>Voucher</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      borderRadius: "2px",
                      border: "1.033px solid #E9EFF4",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "40px",
                      }}
                    >
                      <Typography sx={subTextAcc}>No.</Typography>
                      <Typography sx={subTextAcc}>Company Name</Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "0.5px solid #F0F0F0",
                        width: "100%",
                        mx: "auto",
                        mt: 1,
                      }}
                    ></Box>
                    {/* {value?.companies?.map((x, i) => {
                        return (
                          <>
                           
                          </>
                        );
                      })} */}
                    <Box mt={1}>
                      {value?.companies?.map((x, i) => {
                        // console.log('compsny', x);
                        return (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "40px",
                              }}
                            >
                              <Typography
                                sx={{ ...subTextAcc, fontSize: "12px" }}
                                key={i}
                              >
                                {i + 1}
                              </Typography>
                              <Typography
                                sx={{ ...subTextAcc, fontSize: "12px" }}
                              >
                                <Typography>{x?.CompanyName}</Typography>
                              </Typography>
                            </Box>
                          </>
                        );
                      })}
                      <Box>
                        <Typography sx={{ ...subTextAcc, textAlign: "right" }}>
                          View Details
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
      </div>
    );
  };
  const renderAllProducts = () => {
    return (
      <div>
        {category &&
          Object.entries(category?.companies)?.map(([key, value], i) => {
            // Destructure the entry into key and value
            return (
              <div
                key={i}
                style={{
                  width: "95%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Accordion
                  sx={{
                    mt: 2,
                    width: "100%",
                    my: 2,
                    borderRadius: 1,
                    borderTop: "none", // Remove top border

                    "&:before": {
                      backgroundColor: "transparent",
                    },
                  }}
                  expanded={expanded === `panel${i + 1}`}
                  onChange={handleChange(`panel${i + 1}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${i + 1}bh-content`}
                    id={`panel${i + 1}bh-header`}
                    onClick={() => setActiveMessageBox(key)}
                    //   sx={{
                    //     bgcolor:
                    //       x.chatName === activeMessageBox?.x ? '#445FD2' : '#fff',
                    //   }}
                  >
                    <Grid container>
                      <Grid item xl={4} lg={4} md={4}>
                        <Box sx={{ display: "flex", gap: "10px" }}>
                          <Typography sx={numText}>{i + 1}</Typography>
                          {/* <img
                          src={x?.CompanyAvatar?.url}
                          alt=""
                          style={{ width: '35px', height: '35px' }}
                        /> */}
                          <Typography sx={accHeadText}>{key}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={5} lg={5} md={5}>
                        <Box sx={{ display: "flex", gap: "5px" }}>
                          <img
                            src={eye}
                            alt=""
                            style={{
                              height: "auto",
                              width: "15px",
                            }}
                          />
                          <Typography sx={accHeadText}>
                            {value?.totalViewCount}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sx={{ ml: "-30px" }}>
                        <Typography sx={accHeadText}>Product</Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      borderRadius: "2px",
                      border: "1.033px solid #E9EFF4",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "40px",
                      }}
                    >
                      <Typography sx={subTextAcc}>No.</Typography>
                      <Typography sx={subTextAcc}>Company Name</Typography>
                    </Box>
                    <Box
                      sx={{
                        borderBottom: "0.5px solid #F0F0F0",
                        width: "100%",
                        mx: "auto",
                        mt: 1,
                      }}
                    ></Box>
                    {/* {value?.companies?.map((x, i) => {
                        return (
                          <>
                           
                          </>
                        );
                      })} */}
                    <Box mt={1}>
                      {value?.companies?.map((x, i) => {
                        // console.log('compsny', x);
                        return (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "40px",
                              }}
                            >
                              <Typography
                                sx={{ ...subTextAcc, fontSize: "12px" }}
                                key={i}
                              >
                                {i + 1}
                              </Typography>
                              <Typography
                                sx={{ ...subTextAcc, fontSize: "12px" }}
                              >
                                <Typography>{x?.CompanyName}</Typography>
                              </Typography>
                            </Box>
                          </>
                        );
                      })}
                      <Box>
                        <Typography sx={{ ...subTextAcc, textAlign: "right" }}>
                          View Details
                        </Typography>
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "620px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box
        className="page-heading-section"
        style={{
          justifyContent: "start",
          gap: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/admindashboard/genie");
          }}
        >
          <KeyboardBackspaceIcon />
        </Box>
        <Box className="page-heading">
          <h5>Category</h5>
          <Typography>View top Companies from all the Categories.</Typography>
        </Box>
      </Box>
      <Grid container>
        <Grid item xl={6} lg={6} md={6}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Box
              sx={{
                background: "#fff",
                textAlign: "center",
                p: 1.5,
                borderRadius: "20px",
              }}
            >
              {/* <Typography sx={textTotal}>Total Query : 100</Typography> */}
            </Box>
            {/* <Box sx={{ maxWidth: 100, display: 'flex', gap: '10px' }}>
              <label for="Date" style={headText}>
                Location
              </label>
              <select style={Styles.opt} name="filter" label="OrderStatus">
                <option value="Ready To Ship">All</option>
                <option value="Shipped">incompleted</option>
                <option value="In Transit">In Transit</option>
              </select>
            </Box> */}
            <div>
              {/* <div style={{ display: 'flex' }}>
                <label for="Date" style={headText}>
                  Date
                </label>
                <input
                  type="date"
                  id="Date"
                  name="Date"
                  style={Styles.dateStyle}
                />
              </div> */}
            </div>
          </Box>
        </Grid>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "15px",
            alignItems: "center",
          }}
        >
          <Box
            key="All"
            sx={{
              ...btn,
              color: dataTypes === "All" ? "#445FD2" : "rgba(181, 188, 204, 1)",
              border:
                dataTypes === "All" ? "1px solid #445FD2" : "1px solid #EDEFF2",
            }}
            onClick={() => setDataTypes("All")}
          >
            <Typography sx={categoryText}>All</Typography>
          </Box>
          <Box
            key="Voucher"
            onClick={() => {
              setDataTypes("Voucher");
            }}
            sx={{
              ...btn,
              width: "67px",
              height: "33px",
              color:
                dataTypes === "Voucher" ? "#445FD2" : "rgba(181, 188, 204, 1)",
              border:
                dataTypes === "Voucher"
                  ? "1px solid #445FD2"
                  : "1px solid #EDEFF2",
            }}
          >
            <Typography sx={categoryText}>Voucher</Typography>
          </Box>
          <Box
            key="Product"
            onClick={() => {
              setDataTypes("Product");
            }}
            sx={{
              ...btn,
              width: "67px",
              height: "33px",
              color:
                dataTypes === "Product" ? "#445FD2" : "rgba(181, 188, 204, 1)",
              border:
                dataTypes === "Product"
                  ? "1px solid #445FD2"
                  : "1px solid #EDEFF2",
            }}
          >
            <Typography sx={categoryText}>Product</Typography>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ background: "#FAFBFC", p: 0.2, mt: 2, pb: 2 }}>
        <Grid container mt={2}>
          <Grid item xl={2} lg={2} md={2}>
            <Typography sx={accMainText}>CATEGORY</Typography>
          </Grid>
          <Grid item xl={5} lg={5} md={5}>
            <Typography sx={accMainText}>VIEWS</Typography>
          </Grid>
          <Grid item xl={3} lg={3} md={3}>
            <Typography sx={accMainText}>TYPE</Typography>
          </Grid>
        </Grid>
        <Grid container>
          {loader ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: 5,
                width: "100%",
                // mr: '150%',
                ml: "500px",
              }}
            >
              <PageLoader />
            </Box>
          ) : (
            <>
              {dataTypes === "All" && (
                <div style={{ width: "100%" }}>{renderBoth()} </div>
              )}
              {dataTypes === "Voucher" && (
                <div style={{ width: "100%" }}>{renderAllVouchers()}</div>
              )}
              {dataTypes === "Product" && (
                <div style={{ width: "100%" }}>{renderAllProducts()} </div>
              )}
            </>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default GenieCategory;

const headText = {
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  color: "var(--grey-blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
};
const textTotal = {
  color: "var(--grey-blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
const btn = {
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
  border: "0.705px solid #E6E9EE",
  color: "#B5BCCC",
  fontFamily: "Poppins",
  fontSize: "10px",
  fontStyle: "Poppins",
  fontWeight: 500,
  height: "33px",
  width: "33px",
  p: 2,
  cursor: "pointer",
};
const categoryText = {
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
};
const accHeadText = {
  color: "var(--gray, #3A3A49)",
  fontFamily: "Poppins",
  fontSize: 13.433,
  fontStyle: "normal",
  fontWeight: 400,
  display: "flex",
  alignItems: "center",
};
const subTextAcc = {
  color: "var(--text-color-text-2, #929EAE)",
  fontFamily: "Poppins",
  fontSize: 10,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
};
const numText = {
  color: "rgba(68, 95, 210, 1)",
  fontFamily: "Poppins",
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 400,
  display: "flex",
  alignItems: "center",
};
const accMainText = {
  color: "var(--text-color-text-2, #929EAE)",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  textAlign: "center",
};
