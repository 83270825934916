import React, { useEffect, useState } from "react";
import {
  XYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeriesCanvas,
  VerticalBarSeries,
  XAxis,
  YAxis,
  Hint,
  DiscreteColorLegend,
} from "react-vis";
import { GetTokenFunc } from "../../utils/GetToken";
import axios from "axios";
import PageLoader from "../CommonActions/PageLoader";
import "/node_modules/react-vis/dist/style.css";
import { Box } from "@material-ui/core";

const CustomDiscreteColorLegend = ({ items }) => {
  return (
    <div
      style={{
        margin: "10px 0",
        display: "flex",
        width: "700",
        justifyContent: "space-around",
      }}
    >
      {items.map((item, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
        >
          <div
            style={{
              height: "12px",
              width: "12px",
              backgroundColor: item.color,
              marginRight: "5px",
              borderRadius: "50%",
            }}
          />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
};

const TotalTransections = () => {
  const BarSeries = VerticalBarSeries;

  const [hoveredBar, setHoveredBar] = useState(null);
  const [label, setLabel] = useState([]);
  const [Count, setCount] = useState([]);
  const [filter, setFilter] = useState("last-week");
  const [optionFilter, setOptionFilter] = useState("Completed");
  const [isLoading, setIsLoading] = useState(true);
  const [Data, setData] = useState([]);
  const [Legends, setLegends] = useState([]);

  const handleBarHover = (value) => {
    setHoveredBar(value);
  };

  useEffect(() => {
    let newLegends = [];

    if (optionFilter == "Completed")
      newLegends.push({
        title: "Total Completed (INR + Coins)",
        color: "#00CED1",
      });
    if (optionFilter == "GST")
      newLegends.push({ title: "Total GST (INR)", color: "#DA70D6" });
    if (optionFilter == "Commission")
      newLegends.push({
        title: "BXI Commission - excl GST",
        color: "#778899",
      });
    if (optionFilter == "Pending")
      newLegends.push({
        title: "Pending Transactions (INR + Coins)",
        color: "#3CB371",
      });
    if (optionFilter == "Tokens")
      newLegends.push({ title: "Total Completed (Coins)", color: "#CECECE" });
    if (optionFilter == "PendingPo")
      newLegends.push({ title: "Pending PO (Coins + INR)", color: "#dd233a" });
    if (optionFilter == "TDS")
      newLegends.push({ title: "Total TDS (INR)", color: "#008000" });
    if (optionFilter == "Logistic")
      newLegends.push({ title: "Logistics - Inc GST (INR)", color: "#E67451" });
    if (optionFilter == "Escrow")
      newLegends.push({ title: "Total Escrow (INR)", color: "#FF7F50" });
    if (optionFilter == "PendingEscrow")
      newLegends.push({ title: "Pending Escrow (INR)", color: "#f1d717" });
    if (optionFilter == "BXIGST")
      newLegends.push({ title: "BXI GST (INR)", color: "#6F4E37" });
    if (optionFilter == "BXITDS")
      newLegends.push({ title: "BXI TDS (INR)", color: "#A74AC7" });

    setLegends(newLegends);
  }, [optionFilter]);

  const fetchData = async () => {
    setIsLoading(true);
    let apiUrl = "";

    // Completed Transactions
    if (optionFilter == "Completed" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/CompletedTransactionsWeek";
    if (optionFilter == "Completed" && filter == "last-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/CompletedTransactionsMonth";
    if (optionFilter == "Completed" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/CompletedTransactionsCurrentMonth";
    if (optionFilter == "Completed" && filter == "last-three-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/CompletedTransactions3Months";
    if (optionFilter == "Completed" && filter == "last-six-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/CompletedTransactions6Months";
    if (optionFilter == "Completed" && filter == "last-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/CompletedTransactions12Months";
    if (optionFilter == "Completed" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/CompletedTransactionsCurrent12Months";
    if (optionFilter == "Completed" && filter == "last-five-years")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/CompletedTransactions5Years";

    // Pending Transactions
    if (optionFilter == "Pending" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingTransactionsWeek";
    if (optionFilter == "Pending" && filter == "last-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingTransactionsMonth";
    if (optionFilter == "Pending" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingTransactionsCurrentMonth";
    if (optionFilter == "Pending" && filter == "last-three-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingTransactions3Months";
    if (optionFilter == "Pending" && filter == "last-six-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingTransactions6Months";
    if (optionFilter == "Pending" && filter == "last-year")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingTransactions12Months";
    if (optionFilter == "Pending" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingTransactionsCurrent12Months";
    if (optionFilter == "Pending" && filter == "last-five-years")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingTransactions5Years";

    // Pending PO
    if (optionFilter == "PendingPo" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingPoTransactionsWeek";
    if (optionFilter == "PendingPo" && filter == "last-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingPoTransactionsMonth";
    if (optionFilter == "PendingPo" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingPoTransactionsCurrentMonth";
    if (optionFilter == "PendingPo" && filter == "last-three-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingPoTransactions3Months";
    if (optionFilter == "PendingPo" && filter == "last-six-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingPoTransactions6Months";
    if (optionFilter == "PendingPo" && filter == "last-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingPoTransactions12Months";
    if (optionFilter == "PendingPo" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingPoTransactionsCurrent12Months";
    if (optionFilter == "PendingPo" && filter == "last-five-years")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/PendingPoTransactions5Years";

    // Commission Transactions
    if (optionFilter == "Commission" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/CommissionTransactionsWeek";
    if (optionFilter == "Commission" && filter == "last-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/CommissionTransactionsMonth";
    if (optionFilter == "Commission" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/CommissionTransactionsCurrentMonth";
    if (optionFilter == "Commission" && filter == "last-three-months")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/CommissionTransactions3Months";
    if (optionFilter == "Commission" && filter == "last-six-months")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/CommissionTransactions6Months";
    if (optionFilter == "Commission" && filter == "last-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/CommissionTransactions12Months";
    if (optionFilter == "Commission" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/CommissionTransactionsCurrent12Months";
    if (optionFilter == "Commission" && filter == "last-five-years")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/CommissionTransactions5Years";

    // GST Transactions
    if (optionFilter == "GST" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/GSTTransactionsWeek";
    if (optionFilter == "GST" && filter == "last-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/GSTTransactionsMonth";
    if (optionFilter == "GST" && filter == "current-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/GSTTransactionsCurrentMonth";
    if (optionFilter == "GST" && filter == "last-three-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/GSTTransactions3Months";
    if (optionFilter == "GST" && filter == "last-six-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/GSTTransactions6Months";
    if (optionFilter == "GST" && filter == "last-year")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/GSTTransactions12Months";
    if (optionFilter == "GST" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/GSTTransactionsCurrent12Months";
    if (optionFilter == "GST" && filter == "last-five-years")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/GSTTransactions5Years";

    // Tokens Transactions
    if (optionFilter == "Tokens" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TokensTransactionsWeek";
    if (optionFilter == "Tokens" && filter == "last-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TokensTransactionsMonth";
    if (optionFilter == "Tokens" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/TokensTransactionsCurrentMonth";
    if (optionFilter == "Tokens" && filter == "last-three-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TokensTransactions3Months";
    if (optionFilter == "Tokens" && filter == "last-six-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TokensTransactions6Months";
    if (optionFilter == "Tokens" && filter == "last-year")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TokensTransactions12Months";
    if (optionFilter == "Tokens" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/TokensTransactionsCurrent12Months";
    if (optionFilter == "Tokens" && filter == "last-five-years")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TokensTransactions5Years";

    // TDS Transactions
    if (optionFilter == "TDS" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TDSTransactionsWeek";
    if (optionFilter == "TDS" && filter == "last-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TDSTransactionsMonth";
    if (optionFilter == "TDS" && filter == "current-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TDSTransactionsCurrentMonth";
    if (optionFilter == "TDS" && filter == "last-three-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TDSTransactions3Months";
    if (optionFilter == "TDS" && filter == "last-six-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TDSTransactions6Months";
    if (optionFilter == "TDS" && filter == "last-year")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TDSTransactions12Months";
    if (optionFilter == "TDS" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/TDSTransactionsCurrent12Months";
    if (optionFilter == "TDS" && filter == "last-five-years")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/TDSTransactions5Years";

    // Logistic Transactions
    if (optionFilter == "Logistic" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/LogisticTransactionsWeek";
    if (optionFilter == "Logistic" && filter == "last-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/LogisticTransactionsMonth";
    if (optionFilter == "Logistic" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/LogisticTransactionsCurrentMonth";
    if (optionFilter == "Logistic" && filter == "last-three-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/LogisticTransactions3Months";
    if (optionFilter == "Logistic" && filter == "last-six-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/LogisticTransactions6Months";
    if (optionFilter == "Logistic" && filter == "last-year")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/LogisticTransactions12Months";
    if (optionFilter == "Logistic" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/LogisticTransactionsCurrent12Months";
    if (optionFilter == "Logistic" && filter == "last-five-years")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/LogisticTransactions5Years";

    // Pending Escrow Transactions
    if (optionFilter == "PendingEscrow" && filter == "last-week")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingEscrowTransactionsWeek";
    if (optionFilter == "PendingEscrow" && filter == "last-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingEscrowTransactionsMonth";
    if (optionFilter == "PendingEscrow" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingEscrowTransactionsCurrentMonth";
    if (optionFilter == "PendingEscrow" && filter == "last-three-months")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingEscrowTransactions3Months";
    if (optionFilter == "PendingEscrow" && filter == "last-six-months")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingEscrowTransactions6Months";
    if (optionFilter == "PendingEscrow" && filter == "last-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingEscrowTransactions12Months";
    if (optionFilter == "PendingEscrow" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingEscrowTransactionsCurrent12Months";
    if (optionFilter == "PendingEscrow" && filter == "last-five-years")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/PendingEscrowTransactions5Years";

    // Escrow Transactions
    if (optionFilter == "Escrow" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/EscrowTransactionsWeek";
    if (optionFilter == "Escrow" && filter == "last-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/EscrowTransactionsMonth";
    if (optionFilter == "Escrow" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/EscrowTransactionsCurrentMonth";
    if (optionFilter == "Escrow" && filter == "last-three-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/EscrowTransactions3Months";
    if (optionFilter == "Escrow" && filter == "last-six-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/EscrowTransactions6Months";
    if (optionFilter == "Escrow" && filter == "last-year")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/EscrowTransactions12Months";
    if (optionFilter == "Escrow" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/EscrowTransactionsCurrent12Months";
    if (optionFilter == "Escrow" && filter == "last-five-years")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/EscrowTransactions5Years";

    //BXI GST Transactions
    if (optionFilter == "BXIGST" && filter == "last-week")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIGSTTransactionsWeek";
    if (optionFilter == "BXIGST" && filter == "last-month")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIGSTTransactionsMonth";
    if (optionFilter == "BXIGST" && filter == "current-month")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/BXIGSTTransactionsCurrentMonth";
    if (optionFilter == "BXIGST" && filter == "last-three-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIGSTTransactions3Months";
    if (optionFilter == "BXIGST" && filter == "last-six-months")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIGSTTransactions6Months";
    if (optionFilter == "BXIGST" && filter == "last-year")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIGSTTransactions12Months";
    if (optionFilter == "BXIGST" && filter == "current-year")
      apiUrl =
        "/api/v1/admin/AdminCompanyDetails/BXIGSTTransactionsCurrent12Months";
    if (optionFilter == "BXIGST" && filter == "last-five-years")
      apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIGSTTransactions5Years";

    // //BXITDS Transactions
    // if (optionFilter == "BXITDS" && filter == "last-week")
    //   apiUrl = "/api/v1/admin/AdminCompanyDetails/BXITDSTransactionsWeek";
    // if (optionFilter == "BXITDS" && filter == "last-month")
    //   apiUrl = "/api/v1/admin/AdminCompanyDetails/BXITDSTransactionsMonth";
    // if (optionFilter == "BXITDS" && filter == "last-three-months")
    //   apiUrl = "/api/v1/admin/AdminCompanyDetails/BXITDSTransactions3Months";
    // if (optionFilter == "BXITDS" && filter == "last-six-months")
    //   apiUrl = "/api/v1/admin/AdminCompanyDetails/BXITDSTransactions6Months";
    // if (optionFilter == "BXITDS" && filter == "last-year")
    //   apiUrl = "/api/v1/admin/AdminCompanyDetails/BXITDSTransactions12Months";
    // if (optionFilter == "BXITDS" && filter == "last-five-years")
    //   apiUrl = "/api/v1/admin/AdminCompanyDetails/BXITDSTransactions5Years";

    try {
      const res = await axios.get(apiUrl, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      });
      setCount(res.data.Count);
      setLabel(res.data.label);
      setData(res.data.Transactions);
    } catch (err) {
      console.log("Error fetching data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, optionFilter]);

  const DataArray = Data?.map((item, index) => ({
    x: index + 1,
    y: parseFloat(item),
    z: Count ? (Count.length > 0 ? Count[index] : Count) : null,
    color: Legends[0]?.color,
  }));

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <h3 className="graph-heading">Financial Graph</h3>

        <Box className="marketplace-filters">
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setOptionFilter(e.target.value);
              }}
              value={optionFilter}
            >
              <option value="Completed">Total Completed (INR + Coins)</option>
              <option value="Tokens">Total Completed (Coins)</option>
              <option value="Commission">BXI Commission</option>
              <option value="GST">Total GST</option>
              <option value="TDS">Total TDS</option>
              <option value="Logistic">Total Logistic</option>
              <option value="BXIGST">BXI GST</option>
              <option value="Escrow">Escrow (INR)</option>
              <option value="PendingEscrow">Escrow Pending (INR)</option>
              <option value="PendingPo">Pending PO</option>
              <option value="Pending">Pending Transactions</option>
            </select>
          </Box>
        </Box>
        <Box className="marketplace-filters">
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              value={filter}
            >
              <option value="last-week">last-week</option>
              <option value="last-month">last-month</option>
              <option value="current-month">current-month</option>
              <option value="last-three-months">last-three-months</option>
              <option value="last-six-months">last-six-months</option>
              <option value="last-year">last-year</option>
              <option value="current-year">current-year</option>
              <option value="last-five-years">last-five-years</option>
            </select>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 10,
            ml: 35,
          }}
        >
          <PageLoader />
        </Box>
      ) : (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <XYPlot
            height={350}
            width={700}
            stackBy="y"
            margin={{ left: 70, right: 10, top: 10, bottom: 30 }}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis
              tickValues={label?.map((item, index) => index + 1)}
              tickFormat={(v) => label[v - 1]}
            />

            <YAxis
              tickFormat={(v) => `${v}`}
              //  tickLabelAngle={-45}
            />

            <BarSeries
              barWidth={0.2}
              data={DataArray}
              color={Legends[0]?.color}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
            />

            {hoveredBar && (
              <Hint value={hoveredBar}>
                <div
                  className="grapharrow"
                  style={{
                    background: `${hoveredBar.color}`,
                    color: "white",
                    width: "130px",
                  }}
                >
                  <p>{hoveredBar.label}</p>
                  <p>{`${Legends[0]?.title}: ${hoveredBar.y} - ${hoveredBar.z}`}</p>
                </div>
              </Hint>
            )}
            <CustomDiscreteColorLegend items={Legends} />
          </XYPlot>
        </div>
      )}
    </div>
  );
};

export default TotalTransections;
