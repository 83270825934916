function NumberToWord({ number }) {
  const words = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  const convertToWords = (num) => {
    if (num === 0) return "";
    else if (num < 20) return words[num] + " ";
    else if (num < 100)
      return tens[Math.floor(num / 10)] + " " + convertToWords(num % 10);
    else
      return (
        words[Math.floor(num / 100)] + " Hundred " + convertToWords(num % 100)
      );
  };

  const formatNumber = (num) => num.toString().split(",").join("");

  const convertToIndianWords = (num) => {
    if (num === 0) return "Zero Rupees Only";
    const formattedNum = formatNumber(num);
    const crores = Math.floor(formattedNum / 10000000);
    const lakhs = Math.floor((formattedNum % 10000000) / 100000);
    const thousands = Math.floor((formattedNum % 100000) / 1000);
    const remainder = formattedNum % 1000;

    let words_string = "";
    if (crores) words_string += convertToWords(crores) + " Crores ";
    if (lakhs) words_string += convertToWords(lakhs) + " Lakhs ";
    if (thousands) words_string += convertToWords(thousands) + " Thousand ";
    if (remainder) words_string += convertToWords(remainder);

    return words_string + "Rupees Only";
  };

  return convertToIndianWords(Math.round(number));
}

export default NumberToWord;
