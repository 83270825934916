import { Typography } from "@material-ui/core";
import { Box, Button, Divider, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import invoice from "../../assets/AllRecords/invoice.png";
import approvals from "../../assets/AllRecords/approvals.png";
import escrow from "../../assets/EternalApiImg/escrow.png";
import signz from "../../assets/EternalApiImg/signz.png";
import pinelabs from "../../assets/EternalApiImg/pinelabs.png";
import picker from "../../assets/EternalApiImg/picker.png";
import juspay from "../../assets/EternalApiImg/juspay.png";
import products from "../../assets/AllRecords/products.png";
import creditline from "../../assets/AllRecords/creditline.png";
import wishlist from "../../assets/AllRecords/wishlist.png";
import wantlist from "../../assets/AllRecords/wantlist.png";
import chat from "../../assets/AllRecords/chat.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function AllExternalApi() {
  const [activePage, setActivePage] = useState("Invoice");
  const navigate = useNavigate();

  const DataArray = [
    {
      id: 1,
      name: "SignZy",
      Img: signz,
      page: "invoices",
      apiText: "Advanced online Brand identity verification using Signzy",
      link: "/admindashboard/signzydata",
    },
    {
      id: 2,
      name: "JusPay",
      Img: juspay,
      page: "approvals",
      apiText:
        "View all payment details through Juspay payment gateway dashboard.",
      link: "/admindashboard/pinelabsdata",
    },
    {
      id: 3,
      name: "Escrow",
      page: "orders",
      Img: escrow,
      apiText: "Escrowpay Payment Gateway to view all  payment details.",
      link: "/admindashboard/escrowdata",
    },
  ];
  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "580px",
        width: "100%",
        overflowY: "hidden",
      }}
    >
      <Box
        className="page-heading-section"
        style={{
          justifyContent: "start",
          gap: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/admindashboard/allexternalapi');
          }}
        >
          <KeyboardBackspaceIcon
            onClick={() => {
              navigate(-1);
            }}
          />
        </Box> */}
        <Box className="page-heading">
          <h5>Third Party All Partner API Page</h5>
          <Typography>
            Click on Specific Partner to Check the Information
          </Typography>
        </Box>
      </Box>
      {/*    <Divider /> */}

      <Box
        sx={{
          background: "rgba(250, 251, 252, 1)",
          p: 5,
          mt: 4,
          height: "530px",
          width: "99%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            alignContent: "center",
            flexWrap: "wrap",
            gap: "50px",
          }}
        >
          {DataArray?.map((data, idx) => {
            return (
              <Box sx={boxDesign}>
                <Box
                  sx={{
                    ...boxStyle,
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      height: "30px",
                      width: "30px",
                    }}
                  >
                    <img
                      src={data.Img}
                      style={{ width: "30px", height: "auto" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      ...boxStyle,
                      height: "80px",
                      width: "80%",
                    }}
                  >
                    <Typography sx={text}>{data.name}</Typography>
                    <span
                      style={{
                        ...subText,
                        height: "30px",
                        bgcolor: "green",
                      }}
                    >
                      {data.apiText}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      width: "178px",
                      height: "28px",
                    }}
                  >
                    <Button
                      sx={btnDesign}
                      key={data}
                      onClick={() => {
                        setActivePage(data.page);
                        navigate(data.link);
                      }}
                    >
                      View Details
                    </Button>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Paper>
  );
}

export default AllExternalApi;

const boxDesign = {
  width: "244px",
  height: "176px",
  borderRadius: 2.455,
  border: "0.5px solid #848484",
  background: "#FFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "nowrap",
};

const text = {
  color: "#212121",
  textAlign: "center",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const subText = {
  color: "#727272",
  textAlign: "center",
  fontFamily: "Poppins",
  fontSize: "9px",
  fontStyle: "normal",
  fontWeight: 400,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "70%",
};

const btnDesign = {
  width: "100%",
  height: "100%",
  background: "rgba(68, 95, 210, 1)",
  color: "#FFF",
  fontFamily: "Poppins",
  fontSize: "10px",
  textTransform: "none",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  "&:hover": {
    background: "rgba(68, 95, 210, 1)",
    color: "#FFF",
  },
};

const boxStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
};
