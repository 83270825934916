import { createSlice } from '@reduxjs/toolkit';

export const EditCompanyDetailsSlice = createSlice({
  name: 'EditCompany',
  initialState: {
    EditCompany: {},
  },
  reducers: {
    Edit_Company_Request: (state) => {
      state.loading = true;
      state.EditCompany = [];
    },
    Edit_Company_Success: (state, action) => {
      state.loading = false;
      state.EditCompany = action.payload;
    },
    Edit_Company_Fail: (state, action) => {
      state.loading = false;
      state.EditCompany = action.payload;
    },
  },
});

export const { Edit_Company_Request, Edit_Company_Success, Edit_Company_Fail } =
  EditCompanyDetailsSlice.actions;

export default EditCompanyDetailsSlice.reducer;
