import axios from "axios";
import {
  product_Request,
  product_Success,
  product_Fail,
} from "../../reduser/MarketPlace/Updatedata";
import { GetTokenFunc } from "../../../utils/GetToken";

export const MarketplaceEditReducer =
  (id, formData, manualPoint, variationStatus, calenderStatus, calenderData) =>
  async (dispatch) => {
    // console.log("manualPoint", manualPoint);
    try {
      dispatch({ type: product_Request.toString() });

      const {
        productName,
        ProductsVariantions,
        view,
        productType,
        location,
        listperiod,
        status,
        rejectionReason,
        reviewReason,
        reviewReasonNavigation,
        // manualPoint,
      } = formData;

      const respose = await axios.put(
        `/api/v1/admin/AdminUpdateProduct/${id}`,
        {
          productName,
          ProductsVariantions,
          view,
          productType,
          location,
          listperiod,
          status,
          rejectionReason,
          reviewReason,
          reviewReasonNavigation,
          manualPoint,
          variationStatus,
          calenderStatus,
          calenderData,
        },
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      );
      dispatch({
        type: product_Success.toString(),
        payload: respose.data,
      });
    } catch (error) {
      dispatch({
        type: product_Fail,
        payload: error.response.data.message,
      });
    }
  };
