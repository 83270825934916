import axios from "axios";
import React, { useEffect, useState } from "react";

const CheckStatusComp = (props) => {
  const [StatusData, setStatusData] = useState();
  const GetStatus = async (props) => {
    try {
      const response = await axios.post(
        `/escrow/check-order-escrow-status`,
        {
          txn_id: props?.escrowId,
        },
        {
          withCredentials: true,
        }
      );
      setStatusData(response?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (props?.escrowId) {
      GetStatus(props);
    }
  }, [props?.escrowId]);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  return props?.date ? (
    <div>
      {props?.escrowId
        ? (StatusData?.created_at
            ? formatDate(StatusData?.created_at)
            : StatusData?.created_at) || "N/A"
        : "N/A"}
    </div>
  ) : (
    <div>{props?.escrowId ? StatusData?.status_msg || "N/A" : "N/A"}</div>
  );
};

export default CheckStatusComp;
