import { createSlice } from '@reduxjs/toolkit';

export const GetAllCompanyDetailesSlice = createSlice({
    name: 'AllCompanyDetailes',
    initialState: {
        CompanyDetailes: {}
    },
    reducers: {
        ALL_COMPANYDETAILS_REQUEST: (state) => {
            state.loading = true;
            state.CompanyDetailes = {};
        },
        ALL_COMPANYDETAILS_SUCCESS: (state, action) => {
            state.loading = false;
            state.CompanyDetailes = action.payload;
        },
        ALL_COMPANYDETAILS_FAIL: (state, action) => {
            state.loading = false;
            state.CompanyDetailes = action.payload;
        }
    }
});

export const { ALL_COMPANYDETAILS_REQUEST, ALL_COMPANYDETAILS_SUCCESS, ALL_COMPANYDETAILS_FAIL } = GetAllCompanyDetailesSlice.actions;

export default GetAllCompanyDetailesSlice.reducer;


