import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../Company/Company.Modules.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import { Delete, Edit, Visibility } from "@mui/icons-material";
import {
  Divider,
  IconButton,
  Modal,
  Input,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
  Button,
} from "@mui/material";
import FigmaIcon from "../../assets/figma-icon.png";
import CategoryDropdown from "../Layouts/CategoryDropdown";
import BxiCoinIcon from "../../assets/BXI-coin-icon.svg";
import filter from "../../assets/filter.png";
import * as Styles from "../../common/Styles";
import { useParams } from "react-router-dom";
import CloseIcon from "../../assets/CloseIcon.svg";
import CommonDelete from "../CommonActions/CommonDelete";
import { GetTokenFunc } from "../../utils/GetToken";
import axios from "axios";
import Searchbar from "../../common/Searchbar";

const Wantlist = (props) => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },
    // "& .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon": {
    //   color: "#1976d2",
    //   display: "none",
    // },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [activeTab, setActiveTab] = useState(0);
  const [openView, setOpenView] = React.useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );
  const [searchData, setSearchData] = useState("");

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleClose = () => {
    setIsDeleteModalOpen(false);
  };

  const [openFilter, setOpenFilter] = React.useState(false);
  const handleViewOpen = () => setOpenView(true);
  const handleViewClose = () => setOpenView(false);
  const [wantlistData, setwantlistData] = useState([]);
  const [typeFilter, setTypeFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const { id } = useParams();
  const [ids, setIds] = useState();
  const handleFilterOpen = () => setOpenFilter(true);
  const handleFilterClose = () => setOpenFilter(false);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const profileData = async () => {
    await axios
      .get(
        `api/v1/admin/wantlist/get-wantlist/${id}?page=${currentPage}&typeFilter=${typeFilter}&categoryFilter=${categoryFilter}&search=${searchData}`,
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        setwantlistData(res.data);
        setInitialPaginationCount(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteWantList = async () => {
    await axios
      .delete(`api/v1/admin/wantlist/delete-wantlist-item/${id}?id=${ids}`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        // console.log(res);
        profileData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = () => {
    DeleteWantList();
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    profileData();
  }, [currentPage, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  const renderPurchaseItems = () => {
    return (
      <div>
        <table className="common-table">
          <thead>
            <tr>
              <td width={"15%"} align="left">
                Product/Service Name
              </td>
              <td width={"10%"}>Category </td>
              <td width={"8%"}>Sub Category</td>
              <td width={"8%"}>Type</td>
              <td width={"10%"}>Nature</td>
              <td width={"5%"}>Quantity</td>
              <td width={"10%"}> Location</td>
              <td width={"10%"}>Time</td>
              <td width={"10%"}>Remarks</td>
              <td width={"14%"}>Action</td>
            </tr>
          </thead>
          <tbody>
            {wantlistData?.WantlistData?.map((x, i) => {
              return (
                <tr>
                  <td align="left" className="product-title-colum">
                    <Box className="product-title-box">
                      <label>{x?.productName}</label>
                    </Box>
                  </td>
                  <td className="font-12-400">{x?.category} </td>
                  <td className="font-12-400">{x?.subCategory} </td>
                  <td className="font-12-400">{x?.type} </td>
                  <td className="font-12-400">{x?.natureofOreder} </td>
                  <td className="font-12-400">{x?.qty} </td>
                  <td className="font-12-400">{x?.location} </td>
                  <td className="font-12-400">
                    {new Date(x?.createdAt).toLocaleDateString("en-GB", {
                      timeZone: "UTC",
                    })}{" "}
                  </td>
                  <td className="font-12-400">{x?.remarks} </td>
                  <td>
                    <IconButton aria-label="delete" style={iconStyle}>
                      <Delete
                        onClick={() => {
                          setIsDeleteModalOpen(true);
                          setIds(x?._id);
                        }}
                        onDelete={handleDelete}
                      />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const handleCategoryChange = (event) => {
    setCategoryFilter(event.target.value);
  };

  const handleTypeChange = (event) => {
    setTypeFilter(event.target.value);
  };

  return (
    <div>
      <Box
        className="page-heading-section"
        style={{ justifyContent: "start", gap: "20px" }}
      >
        <Box className="page-heading">
          <h5>Wantlist</h5>
          <Typography>
            Here is a list of all the products that the user want.
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
        <Box
          className="products-filters-row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={TotalProductStyle}>
            <Typography sx={textBudge}>
              {/* Total Products : {products?.totalProductCount} */}
              Total Products : {wantlistData?.count}
            </Typography>
          </Box>{" "}
          <Searchbar width={"50%"} onSendData={handleChildData} />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "10px",
            }}
          >
            <Box sx={Styles.filtersDesign} onClick={handleFilterOpen}>
              <img
                src={filter}
                alt=""
                style={{ height: "auto", width: "15px" }}
              />
              Filters
            </Box>{" "}
          </Box>
        </Box>
        {activeTab === 0 && <div>{renderPurchaseItems()}</div>}
        {activeTab === 1 && <div>Sales items</div>}
      </Box>
      <Modal open={openView} onClose={handleViewClose}>
        <Box sx={ViewStyle}>
          <Typography sx={ModalHeaderStyle}>Edit : Product Details</Typography>
          <Box
            sx={{
              //   background: 'red',
              width: "95%",
              mx: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 1,
                my: 1,
              }}
            >
              <Typography>Product / Service Name</Typography>
              <Input
                disableUnderline
                sx={{ ...InputStyle, width: "100%" }}
                placeholder="Sell Your Products/ Services"
                inputProps={{
                  style: {
                    padding: "15px 10px 10px 15px",
                    color: "#6B7A99",
                    fontFamily: "Poppins",
                    fontSize: 10,
                    fontStyle: "normal",
                    fontWeight: 400,
                  },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "row",
                gap: 7,
                my: 1,
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography>Sub category</Typography>
                <Input
                  disableUnderline
                  sx={{ ...InputStyle, width: "100%" }}
                  placeholder="T-shirt"
                  inputProps={{
                    style: {
                      padding: "15px 10px 10px 15px",
                      color: "#6B7A99",
                      fontFamily: "Poppins",
                      fontSize: 10,
                      fontStyle: "normal",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "50%",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography>Amount / Unit</Typography>
                <Input
                  disableUnderline
                  sx={{ ...InputStyle, width: "100%" }}
                  placeholder="₹ 244"
                  inputProps={{
                    style: {
                      padding: "15px 10px 10px 15px",
                      color: "#6B7A99",
                      fontFamily: "Poppins",
                      fontSize: 10,
                      fontStyle: "normal",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "row",
                gap: 7,
                my: 1,
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography>Quanitity</Typography>
                <Input
                  disableUnderline
                  sx={{ ...InputStyle, width: "100%" }}
                  placeholder="122"
                  inputProps={{
                    style: {
                      padding: "15px 10px 10px 15px",
                      color: "#6B7A99",
                      fontFamily: "Poppins",
                      fontSize: 10,
                      fontStyle: "normal",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "50%",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography>Total Value</Typography>
                <Input
                  disableUnderline
                  sx={{ ...InputStyle, width: "100%" }}
                  placeholder="₹ 1234"
                  inputProps={{
                    style: {
                      padding: "15px 10px 10px 15px",
                      color: "#6B7A99",
                      fontFamily: "Poppins",
                      fontSize: 10,
                      fontStyle: "normal",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "row",
                gap: 3.5,
                my: 1,
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography>GST</Typography>
                <Input
                  disableUnderline
                  sx={{ ...InputStyle, width: "100%" }}
                  placeholder="5 %"
                  inputProps={{
                    style: {
                      padding: "15px 10px 10px 15px",
                      color: "#6B7A99",
                      fontFamily: "Poppins",
                      fontSize: 10,
                      fontStyle: "normal",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "50%",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography>Posted Date </Typography>
                <Input
                  disableUnderline
                  sx={{ ...InputStyle, width: "100%" }}
                  placeholder="02 Apr 2022"
                  inputProps={{
                    style: {
                      padding: "15px 10px 10px 15px",
                      color: "#6B7A99",
                      fontFamily: "Poppins",
                      fontSize: 10,
                      fontStyle: "normal",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "50%",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography>Closed deal In</Typography>
                <Input
                  disableUnderline
                  sx={{ ...InputStyle, width: "100%" }}
                  placeholder="30 Days"
                  inputProps={{
                    style: {
                      padding: "15px 10px 10px 15px",
                      color: "#6B7A99",
                      fontFamily: "Poppins",
                      fontSize: 10,
                      fontStyle: "normal",
                      fontWeight: 400,
                    },
                  }}
                />
              </Box>
            </Box>
            <Box sx={ButtonBoxStyle}>
              <Button
                onClick={handleViewClose}
                variant="contained"
                sx={{
                  ...ButtonStyle,
                  color: "#000",
                  "&:hover": {
                    background: "#fff",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Adding the box shadow on hover
                    color: "#000",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  ...ButtonStyle,
                  // background: 'rgba(68, 95, 210, 1)',
                  color: "#fff",
                  "&:hover": {
                    background: "rgba(68, 95, 210, 1)",
                    color: "#fff",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.5)", // Adding the box shadow on hover
                  },
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <CommonDelete
        open={isDeleteModalOpen}
        onClose={handleClose}
        onDelete={handleDelete}
        text="Are you sure you want to delete this item?"
        ButtonLabel="Delete"
        cancelButtonLabel="Cancel"
      />

      <Modal
        open={openFilter}
        // onClose={handleFilterClose}
      >
        <Box sx={FilterStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Filters
            </Typography>
            <Box
              component="img"
              src={CloseIcon}
              sx={{
                cursor: "pointer",
                height: "20px",
                width: "20px",
              }}
              onClick={() => {
                handleFilterClose();
              }}
            />
          </Box>

          <Box
            sx={{
              mt: 2,
            }}
          >
            <Typography
              sx={{
                textAlign: "Left",
              }}
            >
              Category
            </Typography>
            <Select
              value={categoryFilter}
              onChange={handleCategoryChange}
              style={{
                height: "40px",
                width: "100%",
                border: "none",
                borderRadius: 10,
                padding: "0px 10px 0px 10px",
                color: "#6B7A99",
                background: "#F3F6F9",
              }}
              inputProps={{
                style: {
                  border: "none",
                  outline: "none",
                },
              }}
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="other">Others</MenuItem>
              <MenuItem value="lifestyle">Lifestyle</MenuItem>
              <MenuItem value="fmcg">FMCG</MenuItem>
              <MenuItem value="mediaonline">Media Online</MenuItem>
              <MenuItem value="mediaoffline">Media Offline</MenuItem>
              <MenuItem value="hotel">Hotel</MenuItem>
              <MenuItem value="officesupply">Office Supply</MenuItem>
              <MenuItem value="mobility">Mobility</MenuItem>
              <MenuItem value="electronics">Electronics</MenuItem>
              <MenuItem value="texttile">Textile</MenuItem>
            </Select>
          </Box>

          {/* <Box>
            <Typography
              sx={{
                textAlign: "Left",
              }}
            >
              Location
            </Typography>

            <Select
              sx={{
                width: "100%",
                border: "none",
                borderRadius: 10,
                padding: "0px 10px 0px 10px",
                color: "#6B7A99",
                height: 40,
                background: "#F3F6F9",
                "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
              }}
              inputProps={{
                style: {
                  border: "none",
                  outline: "none",
                },
              }}
            >
              {citiesInIndia?.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </Box> */}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RadioGroup
              row
              value={typeFilter}
              onChange={handleTypeChange}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: 1,
                mt: 1,
              }}
            >
              <FormControlLabel
                style={RadioTextStyle}
                value="product"
                control={<Radio />}
                label="Product"
              />
              <FormControlLabel
                style={RadioTextStyle}
                value="voucher"
                control={<Radio />}
                label="Voucher"
              />
              <FormControlLabel
                style={RadioTextStyle}
                value=""
                control={<Radio />}
                label="All"
              />
            </RadioGroup>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1,
                mt: 2,
                textTransform: "none",
              }}
            >
              <Button
                onClick={() => {
                  profileData();
                  handleFilterClose();
                }}
                variant="contained"
                color="primary"
                sx={{
                  textTransform: "none",
                }}
              >
                Apply
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  handleFilterClose();
                }}
                sx={{
                  textTransform: "none",
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: {
            xl: "100%",
            lg: "100%",
            md: "100%",
            sm: "100%",
            xs: "100%",
          },
          mx: "auto",
        }}
        mt={2}
      >
        <Stack>
          <StyledPagination
            size="medium"
            count={initialPaginationCount}
            color="primary"
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            strokeWidth={currentPage}
          />
        </Stack>
      </Box>
    </div>
  );
};

export default Wantlist;
const iconStyle = {
  color: "rgba(68, 95, 210, 1)",
};

const ViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  // textAlign: 'center',
};
const DeleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const ModalHeaderStyle = {
  color: "#6B7A99",
  fontFamily: "Inter",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  my: 2,
};

const InputStyle = {
  width: "auto",
  border: "2px solid rgba(230, 233, 238, 1)",
  borderRadius: 1.5,
};

const ButtonBoxStyle = {
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "flex-end",
  gap: 1,
  width: "100%",
  bottom: 0,
  height: "100%",
  mt: 2,
};
const ButtonStyle = {
  fontFamily: "poppins",
  fontSize: 10,
  fontWeight: 500,
  border: "0.5px solid rgba(208, 213, 221, 1)",
  width: "140px",
  textTransform: "none",
  borderRadius: 1.5,
};

const RadioTextStyle = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};

const FilterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const CategoryMenuItemStyle = {
  color: "#6B7A99",
};

const TotalProductStyle = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
