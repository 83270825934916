import React, { useState } from "react";
import {
  useFetchAllCommissionInvoices,
  useFetchAllTaxInvoice,
} from "../../../Hooks/AllRecords/useAllRecords";
import {
  Box,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import * as Styles from "../../../common/Styles";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import PageLoader from "../../CommonActions/PageLoader";
import CommissionAmountCalculator from "../CommissionAmountCalculator";

const CommissionInvoiceList = (props) => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const GetProductCategory = async (type, DataOfInvoice) => {
    navigate(`invoicedetail/${DataOfInvoice?._id}`, {
      state: {
        Type: type,
        DataOfInvoice: DataOfInvoice,
        tabvalue: "5",
      },
    });
  };

  return (
    <Paper>
      <Grid
        container
        sx={{
          p: 2,
        }}
      >
        <Grid
          item
          xl={0.5}
          lg={0.5}
          md={0.5}
          sm={0.5}
          xs={0.5}
          sx={{
            ...Styles.textHead,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          Sr No
        </Grid>
        <Grid
          item
          xl={2.5}
          lg={2.5}
          md={2.5}
          sm={2.5}
          xs={2.5}
          sx={{
            ...Styles.textHead,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          Buyer Name
        </Grid>
        <Grid
          item
          xl={2.5}
          lg={2.5}
          md={2.5}
          sm={2.5}
          xs={2.5}
          sx={{
            ...Styles.textHead,
            display: "flex",
            justifyContent: "center",
          }}
        >
          Seller Name
        </Grid>

        <Grid
          align="center"
          item
          xl={1}
          lg={1}
          md={1}
          sm={1}
          xs={1}
          sx={Styles.textHead}
        >
          Category
        </Grid>

        <Grid
          align="center"
          item
          xl={1}
          lg={1}
          md={1}
          sm={1}
          xs={1}
          sx={Styles.textHead}
        >
          Amount
        </Grid>
        <Grid
          align="center"
          item
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          sx={Styles.textHead}
        >
          Date
        </Grid>

        <Grid
          align="center"
          item
          xl={1}
          lg={1}
          md={1}
          sm={1}
          xs={1}
          sx={Styles.textHead}
        >
          Invoice Id
        </Grid>
        <Grid
          align="center"
          item
          xl={1}
          lg={1}
          md={1}
          sm={1}
          xs={1}
          sx={Styles.textHead}
        >
          Action
        </Grid>

        {props?.data?.map((data, idx) => {
          const formattedDate = data?.PoDate ? formatDate(data?.PoDate) : "--";

          const createdAt = data?.createdAt
            ? formatDate(data?.createdAt)
            : data?.updatedAt
            ? formatDate(data?.updatedAt)
            : "--";

          let TotalAdditionalCostWtihoutGSTInBXI = 0;
          let TotalPriceWithoutGST = 0;

          data?.OrderData?.PurchaseOrderData?.ProductData?.map((el, idx) => {
            if (data?.OrderData?.IsVoucher) {
              TotalAdditionalCostWtihoutGSTInBXI +=
                el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
            } else {
              TotalAdditionalCostWtihoutGSTInBXI +=
                el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
            }
            TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
          });

          return (
            <>
              <Grid
                container
                sx={{
                  p: 2,
                }}
              >
                <Grid
                  item
                  xl={0.5}
                  lg={0.5}
                  md={0.5}
                  sm={0.5}
                  xs={0.5}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {idx + 1}
                </Grid>
                <Grid
                  item
                  xl={2.5}
                  lg={2.5}
                  md={2.5}
                  sm={2.5}
                  xs={2.5}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {/*  */}
                  <Box sx={HeadText}>
                    <label>
                      {data?.BuyerDetails?.BuyerCompanyName
                        ? data?.BuyerDetails?.BuyerCompanyName
                        : "--"}
                    </label>
                    <br />
                    <span
                      style={{
                        color: "var(--text-color-text-3, #78778B)",
                        fontFamily: "Poppins",
                        fontSize: 10,
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                      }}
                    >
                      OrderId : {data?.OrderData?.OrderId}
                    </span>
                  </Box>
                </Grid>
                <Grid
                  item
                  xl={2.5}
                  lg={2.5}
                  md={2.5}
                  sm={2.5}
                  xs={2.5}
                  sx={{
                    ...subText,
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  {data?.SellerDetails?.SellerCompanyName
                    ? data?.SellerDetails?.SellerCompanyName
                    : data?.OrderData?.PurchaseOrderData?.SellerDetails
                        ?.SellerCompanyName
                    ? data?.OrderData?.PurchaseOrderData?.SellerDetails
                        ?.SellerCompanyName
                    : null}
                </Grid>

                <Grid
                  align="center"
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={subText}
                >
                  {data?.ProductData?.at(0)?.ProductTypeName
                    ? data?.ProductData?.at(0)?.ProductTypeName
                    : data?.OrderData?.PurchaseOrderData?.ProductData?.at(0)
                        ?.ProductTypeName
                    ? data?.OrderData?.PurchaseOrderData?.ProductData?.at(0)
                        ?.ProductTypeName
                    : "--"}
                </Grid>

                <Grid
                  align="center"
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={subText}
                >
                  <CommissionAmountCalculator OrderData={data?.OrderData} />
                </Grid>

                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={subText}
                >
                  {createdAt ? createdAt : formattedDate}
                </Grid>

                <Grid
                  align="center"
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={subText}
                >
                  {data?.CommitionId}
                </Grid>
                <Grid
                  align="right"
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={subText}
                >
                  <IconButton
                    aria-label="view"
                    style={{ color: "rgba(68, 95, 210, 1)" }}
                    onClick={() => {
                      GetProductCategory(data?.InvoiceType, data);
                    }}
                  >
                    <VisibilityIcon sx={{ color: "#445FD2" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
    </Paper>
  );
};

export default CommissionInvoiceList;

const HeadText = {
  color: "var(--text-color-text-1, #1B212D)",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
};

const subText = {
  color: "#1B212D",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
};
