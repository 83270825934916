import axios from "axios";
import { useMutation } from "react-query";
import { GetTokenFunc } from "../../utils/GetToken";
// export const useRemoveRolesPermission = () => {
//   const { data, isLoading, error, mutate } = useMutation(
//     (id) =>
//       axios.post(
//         `/api/v1/admin/roles-user/delete_iamuser`,
//         {
//           userId: id,
//         },
//         {
//           withCredentials: true,
//           headers: {
//             authorization: `bearer ${GetTokenFunc()}`,
//           },
//         }
//       ),
//     {
//       onSuccess: (data) => {
//         return 'Deleted';
//       },
//     },
//     {
//       onError: (error) => {
//         return 'Error';
//       },
//     }
//   );
//   return { data, isLoading, error, mutate };
// };
const removeUser = async (id) => {
  // console.log('hello', id);
  return await axios.post(
    `/api/v1/admin/roles-user/delete_iamuser`,
    {
      userId: id,
    },
    {
      withCredentials: true,
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
};
export const useRemoveRolesPermission = () => {
  return useMutation(removeUser);
};
