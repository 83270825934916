import React, { useEffect, useState, useRef } from "react";
import {
  Paper,
  Box,
  Typography,
  Button,
  Dialog,
  IconButton,
} from "@mui/material";

import GalleryIcon from "../../assets1/Images/Message/gallerynew.png";
import SendIcon from "../../assets1/Images/Message/sendicons.png";
import { Delete } from "@mui/icons-material";
import CommonDelete from "../CommonActions/CommonDelete";
import axios from "axios";
import socketIO from "socket.io-client";
import InputEmoji from "react-input-emoji";
import { searchChats } from "../../redux/action/Chat/Search-Chat";
import { getMassages } from "../../redux/action/Chat/Get-Massages";
import { sendMassage } from "../../redux/action/Chat/Send-Massages";
import { sendFile } from "../../redux/action/Chat/SendFile";
import { useSelector, useDispatch } from "react-redux";
import * as Styles from "../../common/Styles";
import BlueTail from "../../assets1/Message/BlueTail.png";
import GrayTail from "../../assets1/Message/GrayTail.png";
import barterLogo from "../../assets1/BXI_LOGO.png";
import SouthIcon from "@mui/icons-material/South";
import { StyledPagination } from "../../common/StyledPagination";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { toast, ToastContainer } from "react-toastify";
import "../Marketplace/Marketplace.Module.css";
import { GetTokenFunc } from "../../utils/GetToken";

const ENDPOINT =
  process.env?.BACKEND_URL ?? process.env.NODE_ENV === "production"
    ? "https://api.production-bxi.unada.in/"
    : "http://localhost:7000/";

export let socket = socketIO(ENDPOINT, { transports: ["websocket"] }),
  selectedChatCompare;
let RecevieMessages_Dot = [];

const Message = () => {
  const backend_Chats = [];

  const [RecevieMessagesDot, setRecevieMessagesDot] = useState([]);
  const [RecevieMessagesDotNew, setRecevieMessagesDotNew] = useState(false);
  const [massages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [imgurl, setImgurl] = useState("");
  const [onlineUser, setOnlineUser] = useState([]);
  const [sendMassages, setsendMassages] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null);
  const [sendMessageUser, setSendMessageUser] = useState(null);
  const [sendMessageUser1, setSendMessageUser1] = useState(null);
  const [lastSeen, setLastSeen] = useState([]);
  const [activeLastSeen, setactiveLastSeen] = useState("");
  const [chatIdSet, setChatIdSet] = useState(null);
  const [search, setSearch] = useState("");
  const [image, setImage] = useState("");
  const [fileSet, setFileSet] = useState(false);
  const [massage_Type, setMassage_Type] = useState("");
  const [sendChat, setSendChat] = useState(null);
  const [recevieChat, setRecevieChat] = useState([]);
  const [RecevieMessgaeSolve, setRecevieMessgaeSolve] = useState(true);
  const [textInputMessages, setTextInputMessages] = useState("");
  const [open, setOpen] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(true);
  const [date, setDate] = useState("");
  const [DateRevers, setDateRevers] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [ChatId, setChatId] = useState();
  const [showButton, setShowButton] = useState(true);

  const chatBoxRef = useRef(null);

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      // Hide the button after scrolling to the bottom
      setShowButton(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (chatBoxRef.current) {
        // Adjust this value to control when the button appears
        const scrollTriggerPoint = 10; // Adjust to your preference

        if (chatBoxRef.current.scrollTop > scrollTriggerPoint) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }

        // Check if the user is already at the bottom
        if (
          chatBoxRef.current.scrollTop + chatBoxRef.current.clientHeight >=
          chatBoxRef.current.scrollHeight
        ) {
          setShowButton(false);
        }
      }
    };

    // Attach scroll event listener to the chat box
    chatBoxRef?.current?.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      chatBoxRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCloseDelete = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDelete = () => {
    DeleteChat();
    setIsDeleteModalOpen(false);
  };

  const AdminID = "650ac9e0b29820230639b197";
  const handleClickOpen = () => {
    setOpen(true);
    setIsOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  // const { data: loggedInUserData } = useGetLoggedInUser();

  const { searchChat, loading } = useSelector((state) => state.searchChat);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeMessageBox, setActiveMessageBox] = useState();

  const scrollToBottom2 = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      setShowButton(false);
    }
  };
  useEffect(() => {
    scrollToBottom2();
  }, [massages]);

  useEffect(() => {
    if (searchChat?.chats?.length > 0) {
      setActiveMessageBox(searchChat.chats.at(0));
      all_Massages(searchChat.chats.at(0)?._id);
    }
  }, [searchChat]);

  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  useEffect(() => {
    if (searchChat && searchChat?.totalPages !== undefined) {
      setInitialPaginationCount(searchChat?.totalPages);
    }
  }, [searchChat?.totalPages]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const { aLLMassages } = useSelector((state) => state.massages);

  const [imgUrlforview, setImgUrlforview] = useState(null);

  let login_User = "650ac9c7b29820230639ab99";
  let companyName = "BXI World Pvt Ltd";

  const all_Massages = (item) => {
    dispatch(getMassages(item));
  };

  let massagesRe = "";
  searchChat?.chats?.map((chat) => {
    if (chat?._id == activeMessageBox?._id) {
      chat?.users.map((item) => {
        if (item.companyId !== login_User) {
          massagesRe = item.companyId;
        }
      });
    }
  });

  if (Array.isArray(aLLMassages)) {
    aLLMassages.map((item) => {
      backend_Chats.push({
        item: item.content,
        type: item.type,
        loginUser: login_User,
        recevieMassUserId: massagesRe,
        sender: item.sender,
        senderName: item.senderName,
        time: item.createdAt,
      });
    });
  } else {
    // console.log("Massages is not an array");
  }

  useEffect(() => {
    setMessages([...backend_Chats]);
  }, [activeMessageBox, aLLMassages]);

  let receiverId = "";
  let receiverId2 = "";
  let number = /\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}/.test(newMessage);
  let email = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.test(newMessage);

  const send_Massages = async () => {
    const now = new Date();
    const timestamp = now.toISOString();
    setImgurl("");

    if (number) {
      setTextInputMessages("Sending numbers is not allowed");
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    } else if (email) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
      setTextInputMessages("Sending email is not allowed");
    }

    if (!number && !email) {
      let massageType = "";
      setRecevieMessgaeSolve(true);
      setImgurl("");

      if (fileSet !== true) {
        massageType = "text";
        dispatch(sendMassage(newMessage, activeMessageBox, "text"));
      } else {
        massageType = "url";
        dispatch(sendMassage(newMessage, activeMessageBox, "url"));
        setFileSet(false);
      }

      if (receivedMessage == null) {
        setMessages([
          ...massages,
          {
            item: newMessage,
            type: massageType,
            loginUser: login_User,
            recevieMassUserId: massagesRe,
            senderName: companyName,
            sender: login_User,
            time: timestamp,
          },
        ]);
      } else if (receivedMessage != null) {
        setMessages([
          ...massages,
          {
            item: newMessage,
            type: massageType,
            loginUser: login_User,
            recevieMassUserId: massagesRe,
            senderName: companyName,
            sender: login_User,
            time: timestamp,
          },
        ]);
      }

      setNewMessage("");
      searchChat?.chats?.map((chat) => {
        if (chat?._id == activeMessageBox?._id) {
          chat?.users.map((item) => {
            if (item.companyId !== login_User) {
              receiverId = item.companyId;
            }
          });
        }
      });

      searchChat?.chats?.map((chat) => {
        if (chat?._id == activeMessageBox?._id) {
          chat?.users.map((item) => {
            if (
              item.companyId !== login_User &&
              item.companyId !== receiverId
            ) {
              receiverId2 = item.companyId;
            }
          });
        }
      });

      let chatId = activeMessageBox?._id;
      setsendMassages({
        newMessage,
        receiverId,
        receiverId2,
        chatId,
        massageType,
        loginUser: login_User,
        recevieMassUserId: massagesRe,
        sender: login_User,
        senderName: companyName,
        time: timestamp,
      });
    }
  };

  let notificationSenderId = "";
  let notificationSenderId1 = "";

  searchChat?.chats?.map((chat) => {
    if (chat?._id == activeMessageBox?._id) {
      chat?.users.map((item, index) => {
        // Added index parameter
        if (item.companyId !== login_User) {
          if (notificationSenderId === "") {
            notificationSenderId = item.companyId;
          } else if (notificationSenderId1 === "") {
            notificationSenderId1 = item.companyId;
          }
        }
      });
    }
  });

  // Chat User Name
  let chat_Name = "";

  useEffect(() => {
    dispatch(searchChats(search, date, DateRevers, currentPage));
  }, [dispatch, search, date, DateRevers, currentPage]);

  useEffect(() => {
    selectedChatCompare = activeMessageBox?._id;
    dispatch(getMassages(activeMessageBox?._id));
  }, []);

  useEffect(() => {
    socket.emit("new-user-add", login_User);
    socket.on("get-users", (users) => {
      setOnlineUser(users);
    });

    socket.on("get-lastseen", (lastseen) => {
      setLastSeen(lastseen);
    });
  }, [activeMessageBox]);

  useEffect(() => {
    const handleReceiveMessageDot = (data) => {
      RecevieMessages_Dot.push(data);
      setRecevieMessagesDotNew(data);
    };
    socket.on("recieve-message-dot", handleReceiveMessageDot);
    return () => {
      socket.off("recieve-message-dot", handleReceiveMessageDot);
    };
  }, []);

  const formatDate = (dateString) => {
    const options = { hour: "numeric", minute: "numeric", hour12: true };
    const formattedTime = new Date(dateString).toLocaleTimeString(
      undefined,
      options
    );
    const formattedDate = new Date(dateString).toLocaleDateString("en-GB", {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
    return `${formattedTime.toLowerCase()} On ${formattedDate}`;
  };

  const TimeAgo = ({ date }) => {
    const getTimeAgo = (date) => {
      const currentDate = new Date();
      const messageDate = new Date(date);
      const diffTime = Math.abs(currentDate - messageDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays === 0) return "Today";
      if (diffDays === 1) return "1 day ago";
      if (diffDays < 7) return `${diffDays} days ago`;
      if (diffDays < 14) return "1 week ago";
      if (diffDays < 30) return `${Math.floor(diffDays / 7)} weeks ago`;
      if (diffDays < 60) return "1 month ago";
      if (diffDays < 365) return `${Math.floor(diffDays / 30)} months ago`;
      return `${Math.floor(diffDays / 365)} years ago`;
    };

    return <span>{getTimeAgo(date)}</span>;
  };

  // Send Massages to Socket
  useEffect(() => {
    if (sendMassages !== null) {
      socket.emit("send-message", sendMassages);
    }
  }, [sendMassages]);

  // Send chats to Socket
  useEffect(() => {
    if (sendChat !== null) {
      socket.emit("send-chat", sendChat);
    }
  }, [sendChat]);

  useEffect(() => {
    socket.on("recevie-chat", (data) => {
      data.map((item) => {
        if (item.id === login_User) {
          setRecevieChat(item.chat);
        }
      });
    });
  }, [sendChat]);

  // Receive Massages to Socket
  socket.on(
    "recieve-message",
    (data) => {
      setSendMessageUser(data.receiverId);
      setSendMessageUser1(data.receiverId2);
      setReceivedMessage({
        item: data.newMessage,
        type: data.massageType,
        loginUser: data.login_User,
        recevieMassUserId: data.recevieMassUserId,
        recevieMassUserId2: data.receiverId2,
        sender: data.sender,
        senderName: data.senderName,
        time: data.time,
      });
      setChatIdSet(data.chatId);
      setMassage_Type(data.massageType);
      setRecevieMessgaeSolve(true);
    },
    []
  );

  useEffect(() => {
    if (
      receivedMessage !== null &&
      (sendMessageUser === login_User || sendMessageUser1 === login_User) &&
      sendMessageUser !== sendMessageUser1 &&
      chatIdSet === activeMessageBox?._id &&
      RecevieMessgaeSolve == true
    ) {
      setMessages([...massages, receivedMessage]);
      setRecevieMessgaeSolve(false);
    }
  }, [receivedMessage]);

  useEffect(() => {
    if (
      receivedMessage !== null &&
      sendMessageUser == login_User &&
      chatIdSet === activeMessageBox?._id
    ) {
      // dispatch(searchChats(""));
    }
  }, [receivedMessage]);

  searchChat?.chats?.map((item) => {
    if (
      receivedMessage !== null &&
      sendMessageUser == login_User &&
      item._id === chatIdSet
    ) {
      if (!RecevieMessagesDot.some((chatIdSet) => chatIdSet === item._id)) {
        setRecevieMessagesDot([...RecevieMessagesDot, chatIdSet]);
        RecevieMessages_Dot.push(chatIdSet);
      }
    }
  });

  if (activeMessageBox) {
    RecevieMessages_Dot = RecevieMessages_Dot.filter(
      (chat) => chat.chatId !== activeMessageBox._id
    );
  }

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImage(reader.result);
      const myForm = new FormData();

      // console.log("myForm", myForm);
      myForm.append("senderUserId", "642269ef97b700a5ecd3a540");
      myForm.append("recevieUserId", "64219e37d0dd5c3b49c38ba2");
      myForm.append("recevieChatId", activeMessageBox?._id);
      myForm.append("file", file);
      changeImageHandler(myForm);
    };
    if (file && file.type.includes("video")) {
      return toast.error("Video files are not allowed.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // return;
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImage(reader.result);
        const myForm = new FormData();
        myForm.append("senderUserId", "642269ef97b700a5ecd3a540");
        myForm.append("recevieUserId", "64219e37d0dd5c3b49c38ba2");
        myForm.append("recevieChatId", activeMessageBox?._id);
        myForm.append("file", file);
        changeImageHandler(myForm);
      };
    }
  };

  const changeImageHandler = async (myForm) => {
    const { data } = await axios.post("support/files", myForm, {
      headers: { "Content-type": "multipart/form-data" },
      withCredentials: true,
    });
    setNewMessage(data?.file?.fileUrl);
    setImgurl(data?.file?.fileUrl);
    setFileSet(true);
    dispatch(sendFile(myForm));
  };

  const updateActiveLastSeen = () => {
    if (lastSeen && activeMessageBox?.users) {
      lastSeen.forEach((item) => {
        activeMessageBox.users.forEach((chat) => {
          if (chat?.companyId === item.id) {
            setactiveLastSeen(item.lastSeen);
          }
        });
      });
    }
  };

  useEffect(() => {
    updateActiveLastSeen();
  }, [activeLastSeen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: chatData } = await axios.get(
          `AdminCompanyDetails/GetAllChatsForAdmin?search=${search}`,
          {
            withCredentials: true,
          }
        );
        dispatch(getMassages(chatData[0]?._id));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  function isWithinLast24Hours(date) {
    const commingDate = new Date(date);
    const options = { timeZone: "Asia/Kolkata" };
    const formattedDate = commingDate.toLocaleString("en-IN", options);

    const now = Date.now();
    const diff = now - commingDate.getTime();
    const millisecondsInDay = 24 * 60 * 60 * 1000;

    return diff < millisecondsInDay;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingMessage(false);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  const DeleteChat = async () => {
    await axios
      .put(
        `/api/v1/admin/AdminCompanyDetails/DeleteChat?ChatID=${activeMessageBox?._id}`,
        {},
        {
          withCredentials: true,
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "620px",
        // height: '100%',
        width: "100%",
        overflowY: "scroll",
        backgroundColor: "transparent",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "98%",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <Typography sx={Styles.chatText}>
          Chat & Query <br />
          <span style={Styles.chatTextSub}>
            Access member chat and queries.
          </span>
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "15px",
          }}
        >
          <Box
            className="marketplace-filters"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <span style={textBudge}>Days</span>
            <Box>
              <div class="input-container">
                <select value={date} onChange={(e) => setDate(e.target.value)}>
                  <option value="">All</option>
                  <option value="1">1 Days</option>
                  <option value="3">3 Days</option>
                  <option value="5">5 Days</option>
                  <option value="7">7 Days</option>
                  <option value="15">15 Days</option>
                  <option value="30">1 Month</option>
                  <option value="90">3 Month</option>
                  <option value="180">6 Month</option>
                  <option value="365">1 Year</option>
                </select>
              </div>
            </Box>
          </Box>
          <Box
            className="marketplace-filters"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <span style={textBudge}>Sort</span>
            <Box>
              <div class="input-container">
                <select
                  value={DateRevers}
                  onChange={(e) => setDateRevers(e.target.value)}
                >
                  <option value="">All</option>
                  <option value="ChatShortHightToLow">Old Chats</option>
                  <option value="ChatShortLowToHight">New Chats</option>
                </select>
              </div>
            </Box>
          </Box>
        </Box>
      </Box>

      <Paper
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          bgcolor: "transparent",
          border: "none",
          boxShadow: "none",
          overflowY: "hidden",
          overflowX: "hidden",
          mt: 1,
        }}
      >
        <Box
          sx={{
            ...MessageUserViewStyle,
            height: "400px", // Set the desired height for your chat list
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "20px",
              position: "sticky",
              top: 0,
              background: "#fff",
              width: "400px",
            }}
          >
            <Typography sx={MessageTitle}>All Queries</Typography>
            <input
              value={search}
              name="search"
              type={"text"}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              style={UserMessageSearchBox}
              placeholder="Search"
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            {searchChat?.chats &&
              searchChat?.chats?.length > 0 &&
              searchChat?.chats?.map((item, idx) => {
                return (
                  <>
                    {loadingMessage ? (
                      <Box
                        sx={{
                          width: "95%",
                          display: "flex",
                          gap: "10px",
                          flexWrap: "nowrap",
                          mx: "auto",
                          mt: 2,
                          alignItems: "center",
                        }}
                      >
                        <Skeleton
                          variant="circular"
                          sx={{ width: "40px", height: "40px" }}
                        />
                        <Skeleton
                          variant="rectangular"
                          height={40}
                          sx={{ width: "100%" }}
                        />
                      </Box>
                    ) : (
                      <Box
                        onClick={() => {
                          setActiveMessageBox(item);
                          all_Massages(item?._id);
                          setNewMessage("");
                          // sendEvents("Click on search");
                        }}
                        sx={{
                          ...UserNameBox,
                          bgcolor:
                            item._id === activeMessageBox?._id
                              ? "#445FD2"
                              : "#fff",
                          cursor: "pointer",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "20px",
                            // bgcolor: "red",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {searchChat.chats?.map((chat) => {
                              return RecevieMessages_Dot.map((chats) => {
                                if (
                                  chat._id === chats.chatId &&
                                  item._id === chats.chatId
                                ) {
                                  return (
                                    <Box
                                      sx={{
                                        width: "11px",
                                        height: "11px",
                                        borderRadius: "50%",
                                        ml: 1,
                                        mr: 1,
                                        background: "blue",
                                        overflow: "scroll",
                                      }}
                                    ></Box>
                                  );
                                }
                              });
                            })}

                            {item?.users?.map((users) => {
                              if (
                                login_User !== users?.companyId &&
                                users?.companyId !== "650ac9c7b29820230639ab99"
                              ) {
                                chat_Name = users.name;
                              }
                            })}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              {/*  <AvatarGenerator
                                companyname={chat_Name}
                              //  AvatarUrl={userData?.data?.CompanyAvatar?.url}
                            /> */}
                              <Box>
                                <Typography
                                  sx={{
                                    ...UserNameText,
                                    color:
                                      item._id === activeMessageBox?._id
                                        ? "#ffffff"
                                        : "#6B7A99",
                                    // make text hide when it is too long
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    maxWidth: "180px",
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: "5px",
                                    alignItems: "center",
                                    fontSize: "12px",
                                  }}
                                >
                                  {chat_Name}
                                </Typography>

                                {item?.latestMessage?.content.includes(
                                  "https://bxi-development.s3.amazonaws.com/"
                                ) ? (
                                  <Typography
                                    sx={{
                                      ...UserNameText,
                                      fontSize: "10px",
                                      lineHeight: "20px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "180px",
                                      bgcolor: "transparent",
                                      color:
                                        item._id === activeMessageBox?._id
                                          ? "rgba(247, 247, 255, 0.6);"
                                          : "rgba(60, 60, 67, 0.6)",
                                    }}
                                  >
                                    Image Attachment sent by {chat_Name}
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      ...UserNameText,
                                      fontSize: "10px",
                                      lineHeight: "20px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "180px",
                                      // bgcolor: "green",
                                      color:
                                        item._id === activeMessageBox?._id
                                          ? "rgba(247, 247, 255, 0.6);"
                                          : "rgba(60, 60, 67, 0.6)",
                                    }}
                                  >
                                    {item?.latestMessage?.content}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          </Box>
                          <Typography
                            sx={{
                              ...UserNameText,
                              fontSize: "10px",
                              lineHeight: "20px",
                              width: "100%",
                              textAlign: "end",
                              color:
                                item._id === activeMessageBox?._id
                                  ? "rgba(247, 247, 255, 0.6);"
                                  : "rgba(60, 60, 67, 0.6)",
                            }}
                          >
                            <TimeAgo date={item?.latestMessage?.updatedAt} />
                          </Typography>
                          {item._id === activeMessageBox?._id ? (
                            <IconButton
                              aria-label=""
                              style={{
                                color:
                                  item._id === activeMessageBox?._id
                                    ? "#ffffff"
                                    : "#445FD2",
                              }}
                            >
                              <Delete
                                onClick={() => {
                                  setIsDeleteModalOpen(true);
                                  setChatId(activeMessageBox?._id);
                                }}
                              />
                            </IconButton>
                          ) : null}
                        </Box>
                      </Box>
                    )}
                  </>
                );
              })}
            <Stack>
              <StyledPagination
                size="medium"
                count={initialPaginationCount}
                color="primary"
                showFirstButton
                showLastButton
                page={currentPage}
                onChange={handlePageChange}
                strokeWidth={currentPage}
              />
            </Stack>
          </Box>
        </Box>

        <Box sx={MessageViewStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid #E5E5E5",
                width: "90%",
                py: "15px",
              }}
            >
              {searchChat?.chats?.length === 0 ? null : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  {(activeMessageBox?.users?.at(0)?.companyId !==
                    "650ac9c7b29820230639ab" &&
                    login_User !== activeMessageBox?.users?.at(0)?.companyId) ||
                  (activeMessageBox?.users?.at(1)?.companyId !==
                    "650ac9c7b29820230639ab" &&
                    login_User !==
                      activeMessageBox?.users?.at(1)?.companyId) ? (
                    <>
                      <CompanyNameAvatar
                        id={activeMessageBox?.users?.at(0)?.companyId}
                      />

                      <Typography
                        sx={{
                          ...UserNameText,
                          color: "#000000",
                        }}
                      >
                        {activeMessageBox?.myName}
                      </Typography>
                    </>
                  ) : null}

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <CompanyNameAvatar
                      id={null}
                      CompanyName={activeMessageBox?.sendName}
                    />
                    <Typography
                      sx={{
                        ...UserNameText,
                        color: "#000000",
                      }}
                    >
                      {activeMessageBox?.sendName}
                    </Typography>
                    {/* <Typography
                      sx={{
                        ...UserNameText,
                        fontSize: "10px",
                        lineHeight: "20px",
                        color: "rgba(60, 60, 67, 0.6)",
                      }}
                    >
                      {activeMessageBox &&
                        activeMessageBox?.users?.map((chat) => {
                          onlineUser?.map((user) => {
                            if (
                              user?.username !== login_User &&
                              user?.username === chat?.companyId
                            ) {
                              login_Status = "Online";
                            }
                          });
                        })}

                      {login_Status === 'Offline'
                      ? isWithinLast24Hours(yesterdayLastSeen)
                        ? `Last seen at ${activeLastSeen}`
                        : `Last seen at ${activeLastSeen}`
                      : login_Status}
                    </Typography> */}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            ref={chatBoxRef}
            sx={{
              height: "100%",
              minHeight: "66vh",
              maxHeight: "66vh",
              px: "40px",
              py: "1px",
              mb: 0,
              overflowY: "auto",
            }}
          >
            {searchChat?.chats?.length === 0 ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    color: "#8A8A8A",
                    lineHeight: 25,
                  }}
                >
                  No Data Found
                </Typography>
              </Box>
            ) : (
              <>
                {massages &&
                  massages.length > 0 &&
                  massages?.map((item, idx) => {
                    return (
                      // do message skeleton code here+
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems:
                            login_User === item.sender
                              ? "flex-end"
                              : "flex-start",
                          marginBottom: "10px",
                        }}
                      >
                        {item.type === "text" ? (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "17px",
                              flexDirection:
                                login_User === item.sender
                                  ? "row-reverse"
                                  : "row",
                              justifyContent: "flex-start",
                              alignItems: "flex-end",
                            }}
                          >
                            {item.senderName === "BXI World Pvt Ltd" ? (
                              <img
                                src={barterLogo}
                                style={{
                                  height: "45px",
                                  width: "55px",
                                  borderRadius: "12px",
                                }}
                              />
                            ) : (
                              <CompanyNameAvatar id={item?.sender} />
                            )}

                            <span
                              style={{
                                display: "inline-block",
                                maxWidth: "60%",
                                borderRadius: "20px",
                                padding: "10px",
                                backgroundColor:
                                  login_User === item.sender
                                    ? "#445FD2"
                                    : item.senderName === "BXI Admin"
                                    ? "#ccd0fe"
                                    : "#F3F6F9",
                                color:
                                  login_User === item.sender
                                    ? "#ffffff"
                                    : "black",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "400",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  wordWrap: "break-word",
                                  overflowWrap: "break-word",
                                }}
                              >
                                {item.item}
                              </div>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  fontFamily: "poppins",
                                  fontWeight: 400,
                                  mt: "5px",
                                }}
                              >
                                {formatDate(item.time)}
                                {/* {timeFormatset(item.time)} */}
                              </Typography>

                              {login_User === item.sender ? (
                                <span
                                  style={{
                                    position: "absolute",
                                    bottom: "-13px",
                                    right: "-14px",
                                    zIndex: "1",
                                    transform: "translate(-10px, -10px)",
                                  }}
                                >
                                  <img
                                    src={BlueTail}
                                    width={"18px"}
                                    height={"auto "}
                                    alt=""
                                  />
                                </span>
                              ) : (
                                <span
                                  style={{
                                    position: "absolute",
                                    bottom: "-16px",
                                    left: "06px",
                                    zIndex: "1",
                                    transform: "translate(-10px, -10px)",
                                  }}
                                >
                                  <img
                                    src={GrayTail}
                                    width={"18px"}
                                    height={"auto"}
                                    alt=""
                                  />
                                </span>
                              )}
                            </span>
                          </Box>
                        ) : item.type === "url" ? (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              flexDirection:
                                login_User === item.sender
                                  ? "row-reverse"
                                  : "row",
                            }}
                          >
                            {item.senderName === "BXI World Pvt Ltd" ? (
                              <img
                                src={barterLogo}
                                style={{
                                  height: "45px",
                                  width: "55px",
                                  borderRadius: "12px",
                                }}
                              />
                            ) : (
                              <CompanyNameAvatar id={item?.sender} />
                            )}

                            <img
                              onClick={() => {
                                setImgUrlforview(item.item);
                                setIsOpen(true);
                              }}
                              style={{
                                borderRadius: "20px",
                                maxWidth: "70%",
                                textAlign:
                                  login_User === item.sender ? "right" : "left",
                                backgroundColor:
                                  login_User === item.sender
                                    ? "#445FD2"
                                    : " #f1f0f0",
                                height: "220px",
                                width: "220px",
                              }}
                              src={item.item}
                              alt={item.alt}
                            />
                          </Box>
                        ) : item.type === "video" ? (
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              flexDirection:
                                login_User === item.sender
                                  ? "row-reverse"
                                  : "row",
                            }}
                          >
                            {item?.senderName === "BXI World Pvt Ltd" ? (
                              <img
                                src={barterLogo}
                                style={{
                                  height: "45px",
                                  width: "55px",
                                  borderRadius: "12px",
                                }}
                              />
                            ) : (
                              <CompanyNameAvatar id={item?.sender} />
                            )}
                            <video
                              controls
                              style={{
                                borderRadius: "20px",
                                maxWidth: "70%",
                                textAlign:
                                  login_User === item.sender ? "right" : "left",
                                backgroundColor:
                                  login_User === item.sender
                                    ? "#445FD2"
                                    : " #f1f0f0",
                                height: "220px",
                                width: "220px",
                              }}
                              src={item.item}
                              alt={item.alt}
                            />
                          </Box>
                        ) : null}
                      </div>
                    );
                  })}
              </>
            )}
          </Box>

          <Dialog
            maxWidth="sm"
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <img src={imgUrlforview} alt="item" onClick={handleClose} />
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                // sendEvents("Click on close");
              }}
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                fontSize: "10px",
              }}
            >
              Close
            </Button>
          </Dialog>

          <Dialog
            maxWidth="xs"
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            sx={{
              "& .MuiDialog-paper": {
                minHeight: "90px",
                minWidth: "200px",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                mt: 1,
                ml: "auto",
                mr: "auto",
              }}
            >
              {textInputMessages}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                handleClose();
                // sendEvents("Click on close");
              }}
              sx={{
                textTransform: "none",
                fontFamily: "Poppins",
                fontSize: "10px",
                ml: "auto",
                mr: "auto",
                width: "50px",
                mt: 1,
              }}
            >
              Close
            </Button>
          </Dialog>

          <Box
            sx={{
              display: "flex",
              ml: 87,
            }}
          >
            {showButton && (
              <button
                onClick={scrollToBottom}
                style={{
                  display: showButton ? "block" : "none",
                  background: "#ffffff",
                  color: "black",
                  padding: "3px 7px",
                  border: "2px solid #E6E9EE",
                  cursor: "pointer",
                  borderRadius: "14px",
                }}
              >
                <SouthIcon />
              </button>
            )}
          </Box>

          <Box
            sx={{
              width: "95%",
              maxWidth: "700px",
              border: "1px solid #E6E9EE",
              height: "auto",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: "10px",
              mx: "auto",
              mt: 2,
              background: "#fff",
            }}
          >
            {imgurl === "" ? null : (
              <Box
                component="img"
                src={imgurl}
                alt="image"
                sx={{
                  height: "40px",
                  width: "40px",
                }}
              />
            )}
            <Box
              sx={{
                width: "90%",
                position: "relative",
              }}
            >
              {newMessage.includes("https") ? (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "50px",
                    left: "0px",
                    height: "100px",
                    width: "100px",
                    backgroundImage: `url(${
                      newMessage.includes("https") ? newMessage : null
                    })`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              ) : null}

              <InputEmoji
                value={newMessage}
                onChange={setNewMessage}
                onEnter={newMessage == "" ? null : send_Massages}
                placeholder="Type your Message here.."
                borderRadius={10}
                fontSize={newMessage?.includes("https") ? 0 : 15}
                borderColor="#fff"
                style={{ marginRight: "10px" }}
                buttonElement={true}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "10%",
                gap: "9px",
              }}
            >
              <button
                style={{
                  padding: "0px",
                  margin: 0,
                  display: "flex",
                  border: "none",
                  background: "transparent",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <input
                  id="file-input"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  multiple
                />
                <label htmlFor="file-input">
                  <img
                    src={GalleryIcon}
                    width={"22px"}
                    alt="gallery"
                    style={{ cursor: "pointer" }}
                  />
                </label>
              </button>

              <button
                onClick={newMessage == "" ? null : send_Massages}
                style={{
                  padding: "5px",
                  margin: 0,
                  display: "flex",
                  border: "none",
                  background: "transparent",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <img src={SendIcon} width={"24px"} />
              </button>
            </Box>
          </Box>
        </Box>
        <CommonDelete
          open={isDeleteModalOpen}
          onClose={handleCloseDelete}
          onDelete={handleDelete}
          text="Are you sure you want to delete this Chat?"
          ButtonLabel="Delete"
          cancelButtonLabel="Cancel"
        />
        {/* </Box> */}
      </Paper>
    </Paper>
  );
};

export default Message;

const CompanyNameAvatar = ({ id, CompanyName }) => {
  const [companyImage, setCompanyImage] = useState("");

  useEffect(() => {
    const getCompanyByName = async () => {
      try {
        let response;
        if (id) {
          response = await axios.get(`company/get_company/${id}`);
          setCompanyImage(response?.data?.CompanyAvatar?.url);
        } else if (CompanyName) {
          response = await axios.get(
            `company/get_company_byName/${CompanyName}`
          );
          setCompanyImage(response?.data);
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    };

    getCompanyByName();
  }, [id, CompanyName]);

  return (
    <img
      src={companyImage}
      alt={`${CompanyName || "Company"} logo`}
      style={{ width: "50px", height: "50px", borderRadius: "50%" }}
    />
  );
};

const MessageUserViewStyle = {
  width: "100%",
  maxWidth: "400px",
  height: "95%",
  minHeight: "90vh",
  maxHeight: "90vh",
  background: "#fff",
  borderRadius: "10px",
  overflowY: "scroll",
  overflowY: "hidden",
  overflowX: "hidden",
};

const MessageViewStyle = {
  width: "100%",
  maxWidth: "800px",
  height: "100%",
  minHeight: "90vh",
  background: "#fff",
  borderRadius: "10px",
};

const UserMessageSearchBox = {
  width: "120px",
  height: "36px",
  border: "1px solid #E6E9EE",
  borderRadius: "20px",
  textAlign: "center",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "21px",
  color: "#929EAE",
};

const MessageTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "12px",
  color: "#15223C",
};

const UserNameBox = {
  height: "76px",
  width: "400px",
  borderRadius: "0px",
  background: "#445FD2",
};

const UserNameText = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  marginLeft: "10px",
  color: "#FFFFFF",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  // lineHeight: 20,
};
