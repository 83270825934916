import React, { useState } from 'react';
import {
  Paper,
  Box,
  Typography,
  Grid,
  MenuItem,
  FormControl,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from '@mui/material';

import DropCategory from '../Layouts/DropCategory';
import { Delete, Edit } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { IconButton } from '@mui/material';
import filter from '../../assets/filter.png';
import * as Styles from '../../common/Styles';
import Popup from '../Layouts/Popup/Popup';
const GenieNeedList = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState('');
  const [category, setCategory] = useState();
  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  const [activeTab, setActiveTab] = useState(2);
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const [prodBtn, setProdBtn] = useState(true);
  const [voucBtn, setVoucBtn] = useState(false);
  const [allBtn, setAllBtn] = useState(false);
  const handleEditButtonClick = () => {
    let dummy = (
      <Paper elevation={0} sx={{ width: '70%' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Typography sx={Styles.selectText}>Category</Typography>
          <FormControl fullWidth sx={Styles.selectDesign}>
            <Select
              className="selectDropdownStyle"
              value={category}
              onChange={handleChange}
            >
              <MenuItem value={10}>Textile</MenuItem>
              <MenuItem value={20}>FMCG</MenuItem>
              <MenuItem value={30}>Hotels</MenuItem>
            </Select>
          </FormControl>
          <Typography sx={Styles.selectText}>Location</Typography>
          <FormControl fullWidth sx={Styles.selectDesign}>
            <Select
              className="selectDropdownStyle"
              value={category}
              onChange={handleChange}
            >
              <MenuItem value={10}>Mumbai</MenuItem>
              <MenuItem value={20}>Pune</MenuItem>
              <MenuItem value={30}>Nagpur</MenuItem>
            </Select>
          </FormControl>
          <FormLabel>Type</FormLabel>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="Product"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="Product"
                control={<Radio />}
                label="Product"
                onClick={() => {
                  setProdBtn(true);
                  setVoucBtn(false);
                  setAllBtn(false);
                }}
                sx={{ color: prodBtn ? '#445FD2' : '#E6E9EE' }}
              />
              <FormControlLabel
                value="Voucher"
                control={<Radio />}
                label="Voucher"
                onClick={() => {
                  setProdBtn(false);
                  setVoucBtn(true);
                  setAllBtn(false);
                }}
                sx={{ color: voucBtn ? '#445FD2' : '#E6E9EE' }}
              />
              <FormControlLabel
                value="All"
                control={<Radio />}
                label="All"
                onClick={() => {
                  setProdBtn(false);
                  setVoucBtn(false);
                  setAllBtn(true);
                }}
                sx={{ color: allBtn ? '#445FD2' : '#E6E9EE' }}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box className="button-container">
          <button className="save-button">Apply</button>
          <button
            className="cancel-button"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancel
          </button>
        </Box>
      </Paper>
    );
    setContent(dummy);
    setIsOpen(true);
  };
  return (
    <Paper elevation={0}>
      <Box sx={{ mt: 0 }}>
        <DropCategory />
      </Box>
      <Box mt={2} mb={2}>
        <Typography sx={Styles.chatText}>Need List </Typography>
        <Typography sx={Styles.chatTextSub}>
          Lorem ipsum dolor sit amet consectetur.
        </Typography>
      </Box>
      <Box sx={{ background: '#FAFBFC', p: 0.2 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 1,
            width: '100%',
          }}
        >
          <Box
            sx={{
              background: '#fff',
              textAlign: 'center',
              p: 1.5,
              borderRadius: '20px',
            }}
          >
            <Typography sx={textTotal}>Total Query : 100</Typography>
          </Box>
          {/* <Box sx={{ maxWidth: 100, display: 'flex', gap: '10px' }}>
            <label for="Date" style={headText}>
              Location
            </label>
            <select style={Styles.opt} name="filter" label="OrderStatus">
              <option value="Ready To Ship">All</option>
              <option value="Shipped">incompleted</option>
              <option value="In Transit">In Transit</option>
            </select>
          </Box>
          <div>
            <div style={{ display: 'flex' }}>
              <label for="Date" style={headText}>
                Date
              </label>
              <input
                type="date"
                id="Date"
                name="Date"
                style={Styles.dateStyle}
              />
            </div>
          </div> */}
          <Popup
            openPopup={isOpen}
            popupTitle="Filters"
            popupContent={content}
            handleClose={() => {
              setIsOpen(false);
            }}
          />
          <Box
            sx={Styles.filtersDesign}
            onClick={() => {
              handleEditButtonClick();
            }}
          >
            <img
              src={filter}
              alt=""
              style={{ height: 'auto', width: '15px' }}
            />
            Filters
          </Box>
        </Box>
        <Box mt={2}>
          <Grid
            container
            sx={{
              p: 1,
              textAlign: 'center',
            }}
          >
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2} sx={{ textHead }}>
              Product/Service Name
            </Grid>
            <Grid
              item
              xl={1.5}
              lg={1.5}
              md={1.5}
              sm={1.5}
              xs={1.5}
              sx={textHead}
            >
              Category
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={textHead}>
              Sub Category
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={textHead}>
              Type
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={textHead}>
              Nature
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={textHead}>
              Quantity
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={textHead}>
              Location
            </Grid>
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={textHead}>
              Time
            </Grid>{' '}
            <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={textHead}>
              Remarks
            </Grid>
            <Grid
              item
              xl={1.5}
              lg={1.5}
              md={1.5}
              sm={1.5}
              xs={1.5}
              sx={textHead}
            >
              Action
            </Grid>
          </Grid>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((x, i) => {
            return (
              <Grid
                container
                sx={{
                  mt: 1,
                  background: '#fff',
                  height: '50px',
                  borderRadius: '4px',
                  border: '1px solid #E9EFF4',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  // align="left"
                  className="product-title-colum"
                >
                  {/* <img src={FigmaIcon} /> */}
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '15px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Typography sx={numTextColor}>1</Typography>
                    <Box sx={Styles.headProductText}>Figma Subscription</Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  sx={subTextData}
                >
                  Textile
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={subTextData}>
                  Lorem
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={subTextData}>
                  Product
                </Grid>{' '}
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={subTextData}>
                  Product
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={subTextData}>
                  One Time
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={subTextData}>
                  100
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={subTextData}>
                  Mumbai
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={subTextData}>
                  lorem
                </Grid>
                <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                  <IconButton
                    aria-label="edit"
                    style={{ color: 'rgba(68, 95, 210, 1)' }}
                  >
                    <RemoveRedEyeIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    style={{ color: 'rgba(68, 95, 210, 1)' }}
                  >
                    <Delete />
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    style={{ color: 'rgba(68, 95, 210, 1)' }}
                  >
                    <Edit />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Box>
    </Paper>
  );
};

export default GenieNeedList;

const textTotal = {
  color: 'var(--grey-blue-60, #6B7A99)',
  fontFeatureSettings: 'clig off, liga off',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 600,
};
const headText = {
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  color: 'var(--grey-blue-60, #6B7A99)',
  fontFeatureSettings: 'clig off, liga off',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
};
const textHead = {
  color: 'var(--text-color-text-2, #929EAE)',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};
const subTextData = {
  color: 'var(--text-color-text-3, #78778B)',
  fontFamily: 'Poppins',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};
const numText = {
  color: 'var(--text-color-text-3, #1B212D)',
  fontFamily: 'Poppins',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};
const numTextColor = {
  color: 'rgba(68, 95, 210, 1)',
  fontFamily: 'Poppins',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 400,
  ml: 1,
};
