import React from "react";
import { Box, Paper, Typography, Grid } from "@mui/material";
import DropCategory from "../Layouts/DropCategory";
import bigGenie from "../../assets/GenieImg/bigGenie.png";
import ribbon from "../../assets/GenieImg/deal.png";
import list from "../../assets/ChatImages/list.png";
import categoryimg from "../../assets/GenieImg/categoryimg.png";
import userimg from "../../assets/GenieImg/userimg.png";
import * as Styles from "../../common/Styles";
import { Link } from "react-router-dom";
const GenieAdmin = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "420px",
        width: "100%",
        // overflowY: 'scroll',
      }}
    >
      {/* <Box sx={{ mt: 0 }}>
        <DropCategory />
      </Box> */}
      {/* <Box
        sx={{
          borderBottom: '0.5px solid #F0F0F0',
          width: '100%',
          mx: 'auto',
          mt: 2,
        }}
      ></Box> */}
      <Box mt={1} mb={2}>
        <Typography sx={Styles.chatText}>Genie </Typography>
        <Typography sx={Styles.chatTextSub}>
          Explore Genie to resolve member queries and concern.
        </Typography>
      </Box>
      <Box sx={{ background: "#FAFBFC", p: 0.2 }}>
        <Grid container>
          <Grid item xl={6} lg={6} md={6}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
            >
              {/* <Box
                sx={{
                  background: '#fff',
                  textAlign: 'center',
                  p: 1.5,
                  borderRadius: '20px',
                }}
              >
                <Typography sx={textTotal}>Total Query : 100</Typography>
              </Box> */}
              {/* <Box sx={{ maxWidth: 100, display: 'flex', gap: '10px' }}>
                <label for="Date" style={headText}>
                  Location
                </label>
                <select style={Styles.opt} name="filter" label="OrderStatus">
                  <option value="Ready To Ship">All</option>
                  <option value="Shipped">incompleted</option>
                  <option value="In Transit">In Transit</option>
                </select>
              </Box> */}
              {/* <div>
                <div style={{ display: 'flex' }}>
                  <label for="Date" style={headText}>
                    Date
                  </label>
                  <input
                    type="date"
                    id="Date"
                    name="Date"
                    style={Styles.dateStyle}
                  />
                </div>
              </div> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                height: "475px",
                flexDirection: "column",
              }}
            >
              <img
                src={bigGenie}
                alt=""
                style={{ height: "auto", width: "200px" }}
              />
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: 20,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  mt: 2,
                }}
              >
                Just Ask
              </Typography>
              <Typography
                sx={{
                  color: "#7D8FB3",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: 12,
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  width: "50%",
                  mx: "auto",
                }}
              >
                Our Genie is an AI designed to provide information and assist
                members with any queries related to their Barter.
              </Typography>
            </Box>
          </Grid>
          <Grid item xl={6} lg={6} md={6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                height: "500px",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Link
                to="/admindashboard/genienebingodeals"
                style={Styles.LinkStyles}
              >
                <Box sx={bingoDeals}>
                  <img src={ribbon} alt="" style={smallimg} />
                  Bingo Deals
                </Box>
              </Link>
              <Link
                to="/admindashboard/geniecategory"
                style={Styles.LinkStyles}
              >
                <Box sx={{ ...bingoDeals, width: "318px" }}>
                  <img src={categoryimg} alt="" style={smallimg} />
                  Category
                </Box>
              </Link>
              {/* <Link
                to="/admindashboard/genieneedlist"
                style={Styles.LinkStyles}
              >
                <Box sx={{ ...bingoDeals, width: '395px' }}>
                  <img src={list} alt="" style={smallimg} />
                  Need List
                </Box>
              </Link> */}
              <Link
                to="/admindashboard/genieneactiveinactive"
                style={Styles.LinkStyles}
              >
                <Box sx={{ ...bingoDeals, width: "314px" }}>
                  <img src={userimg} alt="" style={smallimg} />
                  Member Status
                </Box>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default GenieAdmin;

const textTotal = {
  color: "var(--grey-blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
const headText = {
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  color: "var(--grey-blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
};
const bingoDeals = {
  width: 259,
  height: 55,
  borderRadius: 34,
  background: "#FFF",
  boxShadow: "0px 10px 20px 0px rgba(68, 95, 210, 0.06)",
  color: "#000",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  cursor: "pointer",
  transform: "scale(1)",
  transition: "0.5s ease-in-out",
  "&:hover": {
    transform: "scale(1.05)",
  },
};
const smallimg = { height: "auto", width: "25px" };
