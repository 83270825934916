import React, { useState } from "react";
import CompanyProfile from "./CompanyProfile";
import CompanyOnboarding from "./CompanyOnboarding";
// import ProductDetails from "./ProductDetails";
import Wallet from "./Wallet";
import Transactions from "./Transactions";
import Invoice from "./Invoice";
import RolesAndPermissions from "./RolesAndPermissions";
import Approvals from "./Approvals";
import Wallettransaction from "./Wallettransaction";
import { Tabs, Tab, Box, Paper } from "@mui/material";
import "../Company/Company.Modules.css";
import BackArrowSmall from "../../assets/Vector (1).svg";
import { Typography } from "@material-ui/core";
import Wishlist from "./Wishlist";
import Wantlist from "./Wantlist";
import { useLocation, useNavigate, useParams } from "react-router-dom";
const CompanyLanding = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location?.state?.activeTab ? location?.state?.activeTab : 0
  );
  const navigate = useNavigate();
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          onClick={() => {
            navigate(
              location?.state?.Signzy
                ? "/admindashboard/signzydata"
                : "/admindashboard/companies"
            );
          }}
          component="img"
          src={BackArrowSmall}
          sx={{
            position: "relative",
            top: "35px",
            cursor: "pointer",
          }}
        />
        <Tabs
          className="company-details-tabs"
          value={activeTab}
          onChange={handleTabChange}
        >
          <Tab
            sx={{
              "&.Mui-selected": { color: "#445FD2 !important" },
              textTransform: "capitalize",
            }}
            className="tab-label"
            label="Profile Details"
          />
          <Tab
            sx={{
              "&.Mui-selected": { color: "#445FD2 !important" },
              textTransform: "capitalize",
            }}
            className="tab-label"
            label="Onboarding"
          />
          <Tab
            sx={{
              "&.Mui-selected": { color: "#445FD2 !important" },
              textTransform: "capitalize",
            }}
            className="tab-label"
            label="Wallet-Transaction"
          />
          <Tab
            sx={{
              "&.Mui-selected": { color: "#445FD2 !important" },
              textTransform: "capitalize",
            }}
            className="tab-label"
            label="Transaction"
          />
          <Tab
            sx={{
              "&.Mui-selected": { color: "#445FD2 !important" },
              textTransform: "capitalize",
            }}
            className="tab-label"
            label="Invoice"
          />
          <Tab
            sx={{
              "&.Mui-selected": { color: "#445FD2 !important" },
              textTransform: "capitalize",
            }}
            className="tab-label"
            label="Roles & Permission"
          />
          <Tab
            sx={{
              "&.Mui-selected": { color: "#445FD2 !important" },
              textTransform: "capitalize",
            }}
            className="tab-label"
            label="Wishlist"
          />
          <Tab
            sx={{
              "&.Mui-selected": { color: "#445FD2 !important" },
              textTransform: "capitalize",
            }}
            className="tab-label"
            label="Want List"
          />
          {/* <Tab
            sx={{
              "&.Mui-selected": { color: "#445FD2 !important" },
              textTransform: "capitalize",
            }}
            className="tab-label"
            label="Approvals"
          /> */}
          {/* <Tab
                sx={{
                  "&.Mui-selected": { color: "#445FD2 !important" },
                  textTransform: "capitalize",
                }}
                className="tab-label"
                label="Product Details"
              /> */}
          {/* <Tab
            sx={{
              "&.Mui-selected": { color: "#445FD2 !important" },
              textTransform: "capitalize",
            }}
            className="tab-label"
            label="Wallet-Transaction"
          /> */}
        </Tabs>
        <hr
          style={{ backgroundColor: "#F0F0F0", height: "2px", border: "none" }}
        />
        <Box>
          {activeTab === 0 && <CompanyProfile />}
          {activeTab === 1 && <CompanyOnboarding />}
          {activeTab === 2 && <Wallet />}
          {activeTab === 3 && <Transactions />}
          {activeTab === 4 && <Invoice />}
          {activeTab === 5 && <RolesAndPermissions />}
          {activeTab === 6 && <Wishlist />}
          {activeTab === 7 && <Wantlist />}
          {/* {activeTab === 8 && <Wallettransaction />} */}
          {/* {activeTab === 2 && <ProductDetails />} */}
          {/* {activeTab === 9 && <Approvals />} */}
        </Box>
      </Paper>
    </div>
  );
};

export default CompanyLanding;
