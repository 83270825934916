import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Typography,
  Grid,
  Modal,
  Divider,
  Button,
  Stack,
  styled,
} from "@mui/material";
import * as Styles from "../../common/Styles";
import CloseIcon from "../../assets/CloseIcon.svg";
import { useFetchCompaniesRequests, useUpdateApproval } from "./Hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import EmptyData from "../../assets/Recyclebin/recyclebin.png";
import Pagination from "@mui/material/Pagination";
import BackArrowSmall from "../../assets/Vector (1).svg";
import CommaSeprator from "../../common/CommaSeparator";
import useGetCompanyTypeData from "../../Hooks/CompanyData/useGetCompanyTypeData";
import BXICOIN from "../../assets/GenieImg/BXICOIN.png";
import Searchbar from "../../common/Searchbar";

function AllProductApprovals() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },
    // "& .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon": {
    //   color: "#1976d2",
    //   display: "none",
    // },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });
  const handleViewRequestOpen = () => setViewRequest(true);
  const handleViewRequestClose = () => {
    setViewRequest(false);
    setVisiblePoints(false);
    setTotalPoints({});
    setSumOfPoints(0);
    setBXIRateForCredit(0);
    setQuantityForCredit(0);
    setInputValues({
      CreditValue: "",
    });
  };
  const handleViewProfileRequestClose = () => setViewRequest(false);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [queryType, setQueryType] = useState(
    location?.state?.Index
      ? location?.state?.Index
      : location?.state?.queryType == "CreditApproved"
      ? "CreditApproved"
      : "Product"
  );

  const [viewRequest, setViewRequest] = useState(false);
  const [VisiblePoints, setVisiblePoints] = useState(false);
  const { id } = useParams();
  const [paramId, setparamId] = useState(id);
  const [storeId, setStoreId] = useState();
  const [CreditData, setCreditData] = useState();
  const [ProductCreditData, setProductCreditData] = useState();
  const [CompanyData, setCompanyData] = useState();
  const [searchData, setSearchData] = useState("");
  const [currentPagesec, setCurrentPagesec] = useState(1);
  const [initialPaginationCountsec, setInitialPaginationCountsec] = useState(
    currentPagesec || 1
  );

  console.log("storeId", storeId);

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const handlePageChangesec = (event, page) => {
    setCurrentPagesec(page);
  };

  const navigate = useNavigate();
  // console.log("navigate", storeId);
  const {
    data: requestData,
    isLoading: ApprovalDataLoading,
    isError: ApprovalDataError,
    refetch: ApprovalDataRefetch,
  } = useFetchCompaniesRequests(id, queryType, searchData, currentPagesec);

  useEffect(() => {
    if (requestData && requestData?.totalPages !== undefined) {
      setInitialPaginationCountsec(requestData?.totalPages);
    }
  }, [requestData]);

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
  };

  const GetCompanyByID = async () => {
    try {
      const response = await axios.get(`/company/get_company/${id}`, {
        withCredentials: true,
      });
      setCompanyData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    data: CompanyTypeData,
    isLoading: CompanyTypeDataLoading,
    error: CompanyTypeDataError,
    refetch: CompanyTypeDataRefetch,
  } = useGetCompanyTypeData(CompanyData?.companyType);

  const [inputValues, setInputValues] = useState({
    CreditValue: "",
  });
  const [PriceForCredit, setPriceForCredit] = useState(0);
  const [QuantityForCredit, setQuantityForCredit] = useState(0);
  const [BXIRateForCredit, setBXIRateForCredit] = useState(0);

  const handleInputChange = (field, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const ProductPoints = [
    {
      name: "If product is from BXI Star Category ",
      index: 2,
      bxiApprove: true,
      Points: {
        No: 2,
        Yes: 10,
      },
    },
    {
      name: "Products brand from the top category",
      index: 3,
      bxiApprove: true,
      Points: {
        "Not from the top 100 Brands": 0,
        "Its Brand Ranking is between 50 to 100": 4,
        "Brand Ranking is from >25 & < 50": 6,
        "Brand Ranking is from >5 & < 25": 8,
        "Yes, Top 5": 10,
      },
    },
    {
      name: "Product Price",
      index: 4,
      bxiApprove: true,
      Points: {
        "Higher Than Market Rate": 0,
        "As Per Market Operating Rates": 2,
        "Discounted to Market Rates but Higher then Bulk Rates": 6,
        "Institutional Bulk Purchase Discounted Rates": 10,
      },
    },
    {
      name: "Products Expiry Date / Shelf Life",
      index: 6,
      bxiApprove: true,
      Points: {
        "<= 25% life of the Product remaining": 0,
        "< 50% but >= 25 % Life of the Product Remainaing": 2,
        "< 75% but >= 50% Life of the Product Remainaing": 6,
        "< 100% but >= 75% Life of the Product Remainaing": 8,
        "100% Life of Product / Fresh Stock": 10,
      },
    },
    {
      name: "Product features and price with regards to its competitors",
      index: 7,
      bxiApprove: true,
      message: "Start Working After Analytics",
      Points: {
        "If product features are below their competitors": 0,
        "If product features are at par with their competitors": 4,
        "If product features are more compare to their competitors": 8,
        "If product has unique features & comapre to Competitors": 10,
      },
    },
    {
      name: "Product / SKU Demand in Marketplace",
      index: 8,
      bxiApprove: true,
      message: "Approve Why Product Is In Demand",
      Points: {
        "No Demand - Dead Stock": 0,
        "Low Demand": 2,
        "Medium Demand": 6,
        "High Demand": 10,
      },
    },
    {
      name: "Product Brand Standing",
      index: 11,
      bxiApprove: true,
      message: "Start Working After Analytics",
      Points: {
        "Single Retail Outlet in One City ( Eg: One outlet in Mumbai)": 0,
        "Multiple Retail Outlets in One City (Eg: Two or more outlets in Mumbai)": 2,
        "Multiple Retail Outlets in the One state (across couple of Cities) - Eg: Outlets in Mumbai | Pune | Nasik": 4,
        "Multiple Retail outlets in One Region (across multiple states in same region) Eg: West Region - Outlets in Maharashtra | Gujarat | Goa": 6,
        "Multiple Retail outlets in minimum Two Regions (across multiple states) Eg: East (Kolkata | Sikkim & West ( Maharashtra & Gujarat)": 8,
        "National (Multiples Retail Outles across all 4 Regions)": 10,
      },
    },
  ];

  const VoucherPoints = [
    {
      name: "Vouchers from BXI Star Category",
      index: 2,
      bxiApprove: true,
      Points: {
        No: 2,
        Yes: 10,
      },
    },
    {
      name: "Reedemable Criteria",
      index: 4,
      bxiApprove: true,
      Points: {
        "Specific on single product/Service": 0,
        "Specific on Multiple Products/Services.": 2,
        "Specific on more than one Brand & its Products/Services.": 6,
        "Fully Redeemable": 10,
      },
    },
    {
      name: "Clubbable Criteria",
      index: 5,
      bxiApprove: true,
      Points: {
        "Non Clubbable": 6,
        "Fully Clubbable": 10,
      },
    },
    {
      name: "Voucher Acceptability (Online and Offline)",
      index: 6,
      bxiApprove: true,
      Points: {
        "Single Store/ Locations": 2,
        "Selected Stores| Locations/All products": 6,
        "All products/Services All store & Locations": 10,
      },
    },
    {
      name: "Voucher category demand in marketplace",
      index: 7,
      bxiApprove: true,
      Points: {
        "No Demand": 0,
        "Low Demand": 2,
        "Medium Demand": 6,
        "High Demand": 10,
      },
    },
    {
      name: "Voucher Validity",
      index: 9,
      bxiApprove: true,
      Points: {
        "Less than 3 months": 0,
        "> 3 Months & < = 6 months": 2,
        "> 6 months & <= 12 Months": 6,
        "> 12 Months": 10,
      },
    },
    {
      name: "Voucher Product Brand Standing",
      index: 10,
      bxiApprove: true,
      Points: {
        "Single Retail Outlet in One City ( Eg: One outlet in Mumbai)": 0,
        "Multiple Retail Outlets in One City (Eg: Two or more outlets in Mumbai)": 2,
        "Multiple Retail Outlets in the One state (across couple of Cities) - Eg: Outlets in Mumbai | Pune | Nasik": 4,
        "Multiple Retail outlets in One Region (across multiple states in same region) Eg: West Region - Outlets in Maharashtra | Gujarat | Goa": 6,
        "Multiple Retail outlets in minimum Two Regions (across multiple states) Eg: East (Kolkata | Sikkim & West ( Maharashtra & Gujarat)": 8,
        "National (Multiples Retail Outles across all 4 Regions)": 10,
      },
    },
    {
      name: "Voucher Product & Company Demand Check",
      index: 11,
      bxiApprove: true,
      Points: {
        "Both Not in Demand": 0,
        "Company in Demand / Product Not in Demand": 4,
        "Product in Demand / Company Not in Demand": 8,
        "Both Product & Company in Demand": 10,
      },
    },
  ];

  const [selectedOptions, setSelectedOptions] = useState({});
  const [totalPoints, setTotalPoints] = useState({});
  const [sumOfPoints, setSumOfPoints] = useState(0);
  const [highestValue, setHighestValue] = useState(0);
  const [highestqty, setHighestQty] = useState(0);
  const [highestDiscountedPrice, setHighestDiscountedPrice] = useState(0);

  const ValueOfFalse = storeId?.points
    ?.filter((points) => points?.bxiApprove === false)
    .map((el, idx) => el?.points);
  const SumOfFalse = ValueOfFalse?.reduce((acc, current) => acc + current, 0);

  const lastamounttodisplay = SumOfFalse + storeId?.productId?.ManualPoint;

  const credittoken = (Number(lastamounttodisplay) / 120) * 100;

  const handleInputChangeInput = (name, value) => {
    setSelectedOptions((prevOptions) => ({ ...prevOptions, [name]: value }));
  };

  const GetProductCategory = async (
    name,
    id,
    type,
    listingType,
    VoucherType,
    ProductSubCategory
  ) => {
    if (listingType === "Voucher") {
      if (
        VoucherType === "Value Voucher / Gift Cards " ||
        ProductSubCategory === "Value Voucher" ||
        ProductSubCategory === "Gift Cards"
      ) {
        navigate(`/admindashboard/marketplace/valueandgiftvoucher/${id}`);
      } else {
        navigate(`/admindashboard/marketplace/valueandgiftvoucher/${id}`);
      }
    } else if (listingType === "Media") {
      navigate(`/admindashboard/marketplace/mediaviewproduct/${id}`, {
        state: { type: "Media" },
      });
    } else if (listingType === "Product") {
      navigate(`/admindashboard/marketplace/viewproduct/${id}`);
    } else if (ProductSubCategory === "Gift Cards") {
      navigate(`/admindashboard/marketplace/valueandgiftvoucher/${id}`);
    } else {
      navigate(`/admindashboard/marketplace/viewproduct/${id}`);
    }
  };

  const updateStatusOnClick = async (AdminAcceptence) => {
    if (storeId?.productId?.ListingType === "Media") {
      if (
        (!inputValues.CreditValue && AdminAcceptence) ||
        (inputValues.CreditValue <= 0 && AdminAcceptence)
      ) {
        return toast.error("Please Filled Proper Information");
      } else {
        try {
          // All validation passed, make the API call
          const response = await axios.post(
            `/api/v1/admin/approvals/update-request/${storeId?._id}`,
            {
              AdminAcceptence,
              holdAmount: inputValues.CreditValue || 0,
              Points: sumOfPoints,
              RequestId: storeId?._id,
              PriceForCredit: 0,
              QuantityForCredit: 0,
              BXIRateForCredit: 0,
            },
            {
              headers: {
                authorization: `bearer ${GetTokenFunc()}`,
              },
            }
          );

          handleViewRequestClose();
          ApprovalDataRefetch();
          setInputValues({
            CreditValue: "",
          });

          // Uncomment the line below if you want to log the response
          // console.log("res", response);
        } catch (error) {
          setInputValues({
            CreditValue: "",
          });
        }
      }
    } else {
      if (
        sumOfPoints <= 0 &&
        queryType === "Product" &&
        storeId?.requestStatus === "pending" &&
        AdminAcceptence
      ) {
        return toast.error("Please Fill Manual Points");
      } else if (
        queryType === "CreditRequest" &&
        AdminAcceptence &&
        (!inputValues.CreditValue ||
          !QuantityForCredit ||
          inputValues.CreditValue <= 0 ||
          QuantityForCredit <= 0)
      ) {
        return toast.error("Please Fill Proper Information");
      } else if (
        queryType === "CreditRequest" &&
        AdminAcceptence &&
        inputValues.CreditValue > highestValue
      ) {
        return toast.error(
          "You cannot enter a value greater than the total product value"
        );
      } else {
        try {
          // All validation passed, make the API call
          const response = await axios.post(
            `/api/v1/admin/approvals/update-request/${storeId?._id}`,
            {
              AdminAcceptence,
              holdAmount: inputValues.CreditValue || 0,
              Points: sumOfPoints,
              RequestId: storeId?._id,
              PriceForCredit,
              QuantityForCredit,
              BXIRateForCredit,
            },
            {
              headers: {
                authorization: `bearer ${GetTokenFunc()}`,
              },
            }
          );

          handleViewRequestClose();
          ApprovalDataRefetch();
          setInputValues({
            CreditValue: "",
          });
          setQuantityForCredit(0);
          setBXIRateForCredit(0);
          setPriceForCredit(0);

          // Uncomment the line below if you want to log the response
          // console.log("res", response);
        } catch (error) {
          setInputValues({
            CreditValue: "",
          });
          setQuantityForCredit(0);
          setBXIRateForCredit(0);
          setPriceForCredit(0);
        }
        // alert("hello world!");
      }
    }
  };

  const updateProfileStatusOnClick = async (AdminAcceptence) => {
    // console.log(AdminAcceptence, 'AdminAcceptence', storeId);
    await axios
      .post(
        `/api/v1/admin/approvals/update-profile-edit-request`,
        {
          id: storeId,
          AdminAcceptance: AdminAcceptence,
        },
        {
          headers: {
            authorization: `bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        handleViewProfileRequestClose();
        ApprovalDataRefetch();
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const RequestTypeAndRequests = [
    {
      requestType: "Profile",
      request: "the user has requested to modify the profile",
    },
    {
      requestType: "Product",
      request: "the user has requested approval for the product",
    },
    {
      requestType: "CreditRequest",
      request: "the user has requested credit",
    },
  ];

  const fetchtransaction = async () => {
    try {
      await axios.get(`wallet/get_all_credit_line_transaction`).then((res) => {
        setCreditData(res?.data?.body);
        // console.log("transactions", res);
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchCreditData = async () => {
    try {
      const response = await axios.get(
        `wallet_funds/get-companies-credit-requests-by-id/${id}?search=${searchData}`,
        {
          withCredentials: true,
        }
      );
      setProductCreditData(response?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalItems = ProductCreditData?.filter(
    (product) => product?.requestType === "CreditRequest"
  ).length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPaginatedData = () => {
    const reversedData = ProductCreditData?.filter(
      (product) => product?.requestType === "CreditRequest"
    )
      .slice()
      .reverse();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return reversedData?.slice(startIndex, endIndex);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleViewButtonClick = (productData, item) => {
    navigate("/admindashboard/creditterms", {
      state: {
        CreditedAmount: productData?.at(0)?._balance,
        TransactionsId: productData?.at(0)?._transaction_id,
        ProductData: item,
        Itemdata: productData,
      },
    });
  };

  useEffect(() => {
    // Calculate the total points based on the selected options
    const points = {};
    let sum = 0; // Initialize the sum

    for (const pointName in selectedOptions) {
      const selectedOption = selectedOptions[pointName];
      const point =
        storeId?.productId?.ListingType === "Voucher"
          ? VoucherPoints.find((el) => el.name === pointName)
          : storeId?.productId?.ListingType === "Product"
          ? ProductPoints.find((el) => el.name === pointName)
          : ProductPoints.find((el) => el.name === pointName);

      if (point && point.Points[selectedOption] !== undefined) {
        points[pointName] = point.Points[selectedOption];
        sum += point.Points[selectedOption]; // Add the point value to the sum
      }
    }

    setTotalPoints(points);
    setSumOfPoints(sum); // Set the sum
  }, [selectedOptions]);

  useEffect(() => {
    // console.log("log", storeId);
    // const variationWithHighestTotal =
    //   storeId?.productId?.ProductsVariantions?.reduce(
    //     (maxVariation, currentVariation) => {
    //       const maxTotal =
    //         maxVariation.MaxOrderQuantity * maxVariation.DiscountedPrice;
    //       const currentTotal =
    //         currentVariation.MaxOrderQuantity *
    //         currentVariation.DiscountedPrice;

    //       return currentTotal > maxTotal ? currentVariation : maxVariation;
    //     }
    //   );

    const calculateSums = (productVariations) => {
      let totalValueSum = 0;
      let maxOrderQtySum = 0;
      let highestPriceVariation = null;

      productVariations?.forEach((item) => {
        const currentValue = item?.MaxOrderQuantity * item?.DiscountedPrice;

        totalValueSum += currentValue;
        maxOrderQtySum += item?.MaxOrderQuantity;

        // Check if the current item has a higher DiscountedPrice than the current highestPriceVariation
        if (
          !highestPriceVariation ||
          item?.DiscountedPrice > highestPriceVariation?.DiscountedPrice
        ) {
          highestPriceVariation = item;
        }
      });

      // console.log(
      //   "Total value",
      //   totalValueSum,
      //   maxOrderQtySum,
      //   highestPriceVariation?.DiscountedPrice
      // );
      return {
        totalValueSum,
        maxOrderQtySum,
        highestPriceVariation,
      };
    };

    const sums = calculateSums(storeId?.productId?.ProductsVariantions);
    const GSTAmount =
      sums?.highestPriceVariation?.DiscountedPrice -
      sums?.highestPriceVariation?.DiscountedPrice *
        (100 / (100 + Number(sums?.highestPriceVariation?.GST)));

    if (sums) {
      setHighestValue(sums?.totalValueSum);
      setHighestQty(sums?.maxOrderQtySum);
      setHighestDiscountedPrice(
        (sums?.highestPriceVariation?.DiscountedPrice - GSTAmount).toFixed(2)
      );
    }
  }, [storeId]);

  useEffect(() => {
    setQuantityForCredit(
      (inputValues?.CreditValue / highestDiscountedPrice).toFixed()
    );
    setBXIRateForCredit(highestDiscountedPrice);
  }, [inputValues]);

  useEffect(() => {
    ApprovalDataRefetch();
  }, [queryType, searchData, currentPagesec]);

  useEffect(() => {
    setCurrentPagesec(1);
    ApprovalDataRefetch();
  }, [queryType, searchData]);

  useEffect(() => {
    fetchCreditData();
    fetchtransaction();

    GetCompanyByID();
    if (queryType === "CreditRequest" || queryType === "CreditApproved") {
      setparamId(id);
    }
  }, [searchData]);

  const renderProductData = () => {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            p: 2,
          }}
        >
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            sx={{ ...Styles.textHead, textAlign: "center" }}
          >
            No.
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            sx={{
              ...Styles.textHead,
              display: "flex",
              justifyContent: "center",
            }}
          >
            Request
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Request Type
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Request Status
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Date
          </Grid>

          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Action
          </Grid>
        </Grid>
        {requestData?.message === "No Requests Found For This Company" ? (
          <Box
            sx={{
              width: "100%",
              mx: "auto",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              component="img"
              src={EmptyData}
              sx={{
                height: "auto",
                width: "200px",
                mx: "auto",
                mt: 5,
              }}
            />
            <Typography sx={{ ...DataHeader, textAlign: "center", mt: 2 }}>
              {queryType === "Product"
                ? "No Product Approval Requests Found For This Company"
                : queryType === "CreditRequest"
                ? "No Credit Requests Found For This Company"
                : queryType === "Profile"
                ? "No Profile Edit Requests Found For This Company"
                : null}
            </Typography>
          </Box>
        ) : (
          requestData?.data?.map((x, i) => {
            const correspondingRequest = RequestTypeAndRequests.find(
              (item) => item.requestType === x.requestType
            );

            return (
              <Grid
                container
                sx={{
                  ...Styles.containerStyles,
                  p: 2,
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={Styles.textHead}
                >
                  {i + 1}
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {correspondingRequest?.request}
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  {x.requestType}
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  {x.requestStatus}
                </Grid>

                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  {formatDate(x?.createdAt)}
                </Grid>

                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  {/* {x?.requestStatus === 'rejected' ||
                  x?.requestStatus === 'accepted' ? (
                    <Box
                      component="button"
                      sx={{
                        display: 'flex',
                        padding: 0.5,
                        alignItems: 'flex-start',
                        borderRadius: 2,
                        background: '#F0F1F5',
                        color: '#000',
                        border: 'none',
                        lineHeight: '25px',
                        width: '70%',
                        justifyContent: 'center',
                        cursor: 'not-allowed',
                      }}
                      disabled
                      onClick={() => {
                        setViewRequest(true);
                        setStoreId(x);
                      }}
                    >
                      View Request
                    </Box>
                  ) : (
                    <Box
                      component="button"
                      sx={{
                        display: 'flex',
                        padding: 0.5,
                        alignItems: 'flex-start',
                        borderRadius: 2,
                        background: '#445FD2',
                        color: '#fff',
                        border: 'none',
                        lineHeight: '25px',
                        width: '70%',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setViewRequest(true);
                        setStoreId(x);
                      }}
                    >
                      View Request
                    </Box>
                  )} */}
                  <Box
                    component="button"
                    sx={{
                      display: "flex",
                      padding: 0.5,
                      alignItems: "flex-start",
                      borderRadius: 2,
                      background: "#445FD2",
                      color: "#fff",
                      border: "none",
                      lineHeight: "25px",
                      width: "70%",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setViewRequest(true);
                      setStoreId(x);
                    }}
                  >
                    View Request
                  </Box>
                </Grid>
              </Grid>
            );
          })
        )}
      </React.Fragment>
    );
  };

  const renderProfileData = () => {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            p: 2,
          }}
        >
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={Styles.textHead}>
            No.
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            sx={{
              ...Styles.textHead,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            Request
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Request Type
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Request Status
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Date
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Action
          </Grid>
        </Grid>
        {requestData?.message === "No Requests Found For This Company" ? (
          <Box
            sx={{
              width: "100%",
              mx: "auto",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              component="img"
              src={EmptyData}
              sx={{
                height: "auto",
                width: "200px",
                mx: "auto",
                mt: 5,
              }}
            />
            <Typography sx={{ ...DataHeader, textAlign: "center", mt: 2 }}>
              {queryType === "Product"
                ? "No Product Approval Requests Found For This Company"
                : queryType === "CreditRequest"
                ? "No Credit Requests Found For This Company"
                : queryType === "Profile"
                ? "No Profile Edit Requests Found For This Company"
                : null}
            </Typography>
          </Box>
        ) : (
          requestData?.data?.map((x, i) => {
            const correspondingRequest = RequestTypeAndRequests.find(
              (item) => item.requestType === x.requestType
            );
            return (
              <Grid
                container
                sx={{
                  p: 2,
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={Styles.textHead}
                >
                  {i + 1}
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  {correspondingRequest?.request}
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  {x.requestType}
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  {x.requestStatus}
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  {formatDate(x?.createdAt)}
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  <Box
                    component="button"
                    sx={{
                      display: "flex",
                      padding: 0.5,
                      alignItems: "flex-start",
                      borderRadius: 2,
                      background: "#445FD2",
                      color: "#fff",
                      border: "none",
                      lineHeight: "25px",
                      width: "70%",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setViewRequest(true);
                      setStoreId(x);
                    }}
                  >
                    View Request
                  </Box>
                </Grid>
              </Grid>
            );
          })
        )}
      </React.Fragment>
    );
  };

  const renderCreditData = () => {
    return (
      <React.Fragment>
        <Grid
          container
          sx={{
            p: 2,
          }}
        >
          <Grid item xl={1} lg={1} md={1} sm={1} xs={1} sx={Styles.textHead}>
            No.
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            sx={{
              ...Styles.textHead,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            Product Name
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Request Type
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Request Status
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Date
          </Grid>
          <Grid
            align="center"
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={Styles.textHead}
          >
            Action
          </Grid>
        </Grid>
        {requestData?.message === "No Requests Found For This Company" ? (
          <Box
            sx={{
              width: "100%",
              mx: "auto",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              component="img"
              src={EmptyData}
              sx={{
                height: "auto",
                width: "200px",
                mx: "auto",
                mt: 5,
              }}
            />
            <Typography sx={{ ...DataHeader, textAlign: "center", mt: 2 }}>
              {queryType === "Product"
                ? "No Product Approval Requests Found For This Company"
                : queryType === "CreditRequest"
                ? "No Credit Requests Found For This Company"
                : queryType === "Profile"
                ? "No Profile Edit Requests Found For This Company"
                : null}
            </Typography>
          </Box>
        ) : (
          requestData?.data?.map((x, i) => {
            return (
              <Grid
                container
                sx={{
                  p: 2,
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xl={1}
                  lg={1}
                  md={1}
                  sm={1}
                  xs={1}
                  sx={Styles.textHead}
                >
                  {i + 1}
                </Grid>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  sx={{
                    ...Styles.textHead,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Box
                    sx={{
                      width: "auto",
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "10px",
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    {" "}
                    {x?.productId?.ProductImages?.at(0)?.url ? (
                      <>
                        <img
                          src={x?.productId?.ProductImages?.at(0)?.url}
                          alt="company-logo"
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "30px",
                          }}
                        />{" "}
                        <Typography
                          sx={{
                            fontFamily: "Kumbh Sans",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "#929EAE",
                          }}
                        >
                          {x?.productId?.ProductName
                            ? x?.productId?.ProductName
                            : "--"}
                          <br />

                          {x?.clnumber ? (
                            <span
                              style={{
                                fontSize: "9px",
                              }}
                            >
                              Credit_Id: {x?.clnumber}
                            </span>
                          ) : (
                            "--"
                          )}
                        </Typography>
                      </>
                    ) : x?.productId?.VoucherImages?.at(0)?.url ? (
                      <>
                        <img
                          src={x?.productId?.VoucherImages?.at(0)?.url}
                          alt="company-logo"
                          style={{
                            height: "30px",
                            width: "30px",
                            borderRadius: "30px",
                          }}
                        />{" "}
                        <Typography
                          sx={{
                            fontFamily: "Kumbh Sans",
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "#929EAE",
                          }}
                        >
                          {x?.productId?.ProductName
                            ? x?.productId?.ProductName
                            : "--"}
                          <br />

                          {x?.clnumber ? (
                            <span
                              style={{
                                fontSize: "9px",
                              }}
                            >
                              Credit_Id: {x?.clnumber}
                            </span>
                          ) : (
                            "--"
                          )}
                        </Typography>
                      </>
                    ) : (
                      "--"
                    )}
                    <br />
                  </Box>
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  {x.requestType}
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  {x.requestStatus === "pending"
                    ? "Request Raised"
                    : x.requestStatus === "accepted"
                    ? "BXI Approved"
                    : x.requestStatus === "rejected"
                    ? "BXI Rejected"
                    : ""}
                </Grid>

                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  {formatDate(x?.createdAt)}
                </Grid>
                <Grid
                  align="center"
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  sx={{ ...Styles.textHead }}
                >
                  <Box
                    component="button"
                    sx={{
                      display: "flex",
                      padding: 0.5,
                      alignItems: "flex-start",
                      borderRadius: 2,
                      background: "#445FD2",
                      color: "#fff",
                      border: "none",
                      lineHeight: "25px",
                      width: "70%",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setViewRequest(true);
                      setStoreId(x);
                    }}
                  >
                    View Request
                  </Box>
                </Grid>
              </Grid>
            );
          })
        )}
      </React.Fragment>
    );
  };

  const renderCreditUserApproved = () => {
    return (
      <React.Fragment>
        <Box>
          <Grid container>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                p: 2,
                borderBottom: "1px solid #F5F5F5",
              }}
            >
              <Grid
                item
                xl={0.3}
                lg={0.3}
                md={0.3}
                sm={0.3}
                xs={0.3}
                align="left"
                sx={{ ...tablehead, textAlign: "left" }}
              >
                No.
              </Grid>
              <Grid
                item
                xl={2.2}
                lg={2.2}
                md={2.2}
                sm={2.2}
                xs={2.2}
                sx={{ ...tablehead, textAlign: "left", width: "20px" }}
              >
                Product Name
              </Grid>
              <Grid
                item
                xl={0.7}
                lg={0.7}
                md={0.7}
                sm={0.7}
                xs={0.7}
                align="center"
                sx={tablehead}
              >
                Type
              </Grid>

              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                align="center"
                sx={{ ...tablehead, textAlign: "center" }}
              >
                Requested Amount
              </Grid>

              {CompanyTypeData?.data?.CompanyTypeName === "Media" ? null : (
                <Grid
                  item
                  xl={1.5}
                  lg={1.5}
                  md={1.5}
                  sm={1.5}
                  xs={1.5}
                  align="center"
                  sx={{ ...tablehead, textAlign: "left" }}
                >
                  Credit Eligibility Amount
                </Grid>
              )}

              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                align="center"
                sx={{ ...tablehead, textAlign: "center" }}
              >
                Credited Amount
              </Grid>

              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                align="center"
                sx={{ ...tablehead, textAlign: "left" }}
              >
                Requested Date
              </Grid>

              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                align="center"
                sx={{ ...tablehead, textAlign: "center" }}
              >
                Issued Date
              </Grid>

              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                align="center"
                sx={tablehead}
              >
                Admin Status
              </Grid>
              <Grid
                item
                xl={1.5}
                lg={1.5}
                md={1.5}
                sm={1.5}
                xs={1.5}
                align="center"
                sx={tablehead}
              >
                User Status
              </Grid>
              <Grid
                item
                xl={0.8}
                lg={0.8}
                md={0.8}
                sm={0.8}
                xs={0.8}
                align="center"
                sx={tablehead}
              >
                Agreement
              </Grid>
            </Grid>

            {ProductCreditData?.filter(
              (product) => product?.requestType === "CreditRequest"
            ).length <= 0 ? (
              <Box
                sx={{
                  width: "100%",
                  mx: "auto",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box
                  component="img"
                  src={EmptyData}
                  sx={{
                    height: "auto",
                    width: "200px",
                    mx: "auto",
                    mt: 5,
                  }}
                />
                <Typography sx={{ ...DataHeader, textAlign: "center", mt: 2 }}>
                  No Credit Requests Found For This Company
                </Typography>
              </Box>
            ) : (
              getPaginatedData()?.map((item, idx) => {
                const continuousIdx =
                  (currentPage - 1) * itemsPerPage + idx + 1;

                const CreditDataFilter = CreditData?.filter(
                  (item) => item?._user_id === paramId
                );

                const productData = CreditDataFilter?.filter(
                  (product) => product?._request_id?.toString() === item?._id
                );

                const credittoken =
                  (Number(item?.totalPoints + item?.productId?.ManualPoint) /
                    120) *
                  100;

                const calculateSums = (productVariations) => {
                  let totalValueSum = 0;
                  let maxOrderQtySum = 0;

                  productVariations?.forEach((item) => {
                    const currentValue =
                      item?.MaxOrderQuantity * item?.DiscountedPrice;

                    totalValueSum += currentValue;
                    maxOrderQtySum += item?.MaxOrderQuantity;
                  });

                  return {
                    totalValueSum,
                    maxOrderQtySum,
                  };
                };

                const sums = calculateSums(
                  item?.productId?.ProductsVariantions
                );

                const highestValue = sums?.totalValueSum;

                const totalValue = (
                  (highestValue * credittoken) /
                  100
                ).toFixed();

                return (
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      p: 2,
                      flexDirection: "row",
                      borderBottom: "1px solid #F5F5F5",
                      mt: 2,
                    }}
                  >
                    <Grid
                      item
                      xl={0.3}
                      lg={0.3}
                      md={0.3}
                      sm={0.3}
                      xs={0.3}
                      align="left"
                    >
                      <Box
                        sx={{ display: "flex", flexDirection: "row" }}
                        gap={2}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "Kumbh Sans",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "#1B212D",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            {continuousIdx}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      xl={2.2}
                      lg={2.2}
                      md={2.2}
                      sm={2.2}
                      xs={2.2}
                      align="left"
                    >
                      <Box
                        sx={{
                          width: "auto",
                          display: "flex",
                          justifyContent: "flex-start",
                          gap: "10px",
                          alignItems: "center",
                          alignContent: "center",
                        }}
                      >
                        {" "}
                        {item?.productId?.ProductImages?.at(0)?.url ? (
                          <>
                            <img
                              src={item?.productId?.ProductImages?.at(0)?.url}
                              alt="company-logo"
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "30px",
                              }}
                            />{" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#929EAE",
                              }}
                            >
                              {item?.productId?.ProductName
                                ? item?.productId?.ProductName
                                : "--"}
                              <br />

                              {item?.clnumber ? (
                                <span
                                  style={{
                                    fontSize: "9px",
                                  }}
                                >
                                  Credit_Id: {item?.clnumber}
                                </span>
                              ) : (
                                "--"
                              )}
                            </Typography>
                          </>
                        ) : item?.productId?.VoucherImages?.at(0)?.url ? (
                          <>
                            <img
                              src={item?.productId?.VoucherImages?.at(0)?.url}
                              alt="company-logo"
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "30px",
                              }}
                            />{" "}
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#929EAE",
                              }}
                            >
                              {item?.productId?.ProductName
                                ? item?.productId?.ProductName
                                : "--"}
                              <br />

                              {item?.clnumber ? (
                                <span
                                  style={{
                                    fontSize: "9px",
                                  }}
                                >
                                  Credit_Id: {item?.clnumber}
                                </span>
                              ) : (
                                "--"
                              )}
                            </Typography>
                          </>
                        ) : (
                          "--"
                        )}
                        <br />
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xl={0.7}
                      lg={0.7}
                      md={0.7}
                      sm={0.7}
                      xs={0.7}
                      align="center"
                    >
                      <Typography
                        sx={{
                          fontFamily: "Kumbh Sans",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "12px",
                          color: "#929EAE",
                        }}
                      >
                        <span>
                          {item?.productId?.ListingType
                            ? item?.productId?.ListingType
                            : "--"}
                        </span>
                      </Typography>
                    </Grid>

                    <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                      <Typography
                        sx={{
                          fontFamily: "Kumbh Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "12px",
                          color: "#929EAE",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          gap: "5px",
                        }}
                      >
                        {item?.requestedUserAmount ? (
                          <>
                            <Box
                              component="img"
                              src={BXICOIN}
                              alt="currency"
                              style={{ width: "15px", height: "15px" }}
                            />
                            <CommaSeprator Price={item?.requestedUserAmount} />
                          </>
                        ) : (
                          "--"
                        )}
                      </Typography>
                    </Grid>
                    {CompanyTypeData?.data?.CompanyTypeName ===
                    "Media" ? null : (
                      <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                          gap={2}
                        >
                          <Box>
                            <Typography
                              sx={{
                                fontFamily: "Kumbh Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize: "12px",
                                color: "#929EAE",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "flex",
                                gap: "5px",
                              }}
                            >
                              <Box
                                component="img"
                                src={BXICOIN}
                                alt="currency"
                                style={{ width: "15px", height: "15px" }}
                              />
                              <CommaSeprator Price={totalValue} />
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    <Grid item xl={1.5} lg={1.5} md={1.5} sm={1.5} xs={1.5}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                        gap={2}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "Kumbh Sans",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "#929EAE",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              gap: "5px",
                            }}
                          >
                            {item?.approvedAmount ? (
                              <>
                                <Box
                                  component="img"
                                  src={BXICOIN}
                                  alt="currency"
                                  style={{ width: "15px", height: "15px" }}
                                />
                                <CommaSeprator Price={item?.approvedAmount} />
                              </>
                            ) : (
                              "--"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                        gap={2}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "Kumbh Sans",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "#929EAE",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            {formatDate(item?.createdAt)}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                        gap={2}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontFamily: "Kumbh Sans",
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "#929EAE",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              gap: "10px",
                            }}
                          >
                            {productData.length > 0
                              ? productData?.map((item, idx) => {
                                  return item?._document_type === "onhold" ? (
                                    <Box>
                                      {item?._status === true ? (
                                        <span>
                                          {item?.createdAt === item?.updatedAt
                                            ? "--"
                                            : formatDate(item?.updatedAt)}
                                        </span>
                                      ) : (
                                        "--"
                                      )}
                                    </Box>
                                  ) : (
                                    ""
                                  );
                                })
                              : "--"}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xl={1.5}
                      lg={1.5}
                      md={1.5}
                      sm={1.5}
                      xs={1.5}
                      align="center"
                    >
                      <Typography
                        sx={{
                          fontFamily: "Kumbh Sans",
                          fontStyle: "normal",
                          fontWeight: 400,
                          fontSize: "11px",
                          color: "#445fd2",
                          background: "#445fd221",
                          width: "100px",
                          height: "30px",
                          borderRadius: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {item?.requestStatus === "accepted"
                            ? "BXI Approved"
                            : item?.requestStatus === "pending"
                            ? "Request Raised"
                            : item?.requestStatus === "rejected"
                            ? "BXI Rejected"
                            : null}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xl={1.5}
                      lg={1.5}
                      md={1.5}
                      sm={1.5}
                      xs={1.5}
                      align="center"
                      sx={{
                        ...tablehead,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {productData.length > 0
                        ? productData?.map((el, idx) => {
                            // console.log("element", el);
                            return el?._document_type === "onhold" ? (
                              <Box>
                                {el?._acceptorreject === "reject" ? (
                                  <Typography
                                    sx={{
                                      fontFamily: "Kumbh Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "11px",
                                      color: "#445fd2",
                                      background: "#445fd221",
                                      width: "110px",
                                      height: "30px",
                                      borderRadius: 1,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>Member Declined</span>
                                  </Typography>
                                ) : el?._acceptorreject === "accept" ? (
                                  <Typography
                                    sx={{
                                      fontFamily: "Kumbh Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "11px",
                                      color: "#445fd2",
                                      background: "#445fd221",
                                      width: "110px",
                                      height: "30px",
                                      borderRadius: 1,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>Credit Issued</span>
                                  </Typography>
                                ) : (
                                  <Typography
                                    sx={{
                                      fontFamily: "Kumbh Sans",
                                      fontStyle: "normal",
                                      fontWeight: 400,
                                      fontSize: "11px",
                                      color: "#445fd2",
                                      background: "#445fd221",
                                      width: "100px",
                                      height: "30px",
                                      borderRadius: 1,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>Member Pending</span>
                                  </Typography>
                                )}
                              </Box>
                            ) : (
                              ""
                            );
                          })
                        : "--"}
                    </Grid>
                    <Grid
                      item
                      xl={0.8}
                      lg={0.8}
                      md={0.8}
                      sm={0.8}
                      xs={0.8}
                      align="center"
                      sx={{
                        ...tablehead,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {productData?.length > 0
                        ? productData?.map((el, idx) => {
                            // console.log("element", el);
                            return el?._document_type === "onhold" ? (
                              <Box>
                                {el?._acceptorreject === "reject" ? (
                                  "--"
                                ) : el?._acceptorreject === "accept" ? (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      boxShadow: "none",
                                      background: "#445fd2",
                                      textTransform: "none",
                                    }}
                                    onClick={() =>
                                      handleViewButtonClick(productData, item)
                                    }
                                  >
                                    View
                                  </Button>
                                ) : (
                                  "--"
                                )}
                              </Box>
                            ) : (
                              ""
                            );
                          })
                        : "--"}
                    </Grid>
                  </Grid>
                );
              })
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
              mt={2}
            >
              <StyledPagination
                size="medium"
                count={totalPages}
                color="primary"
                page={currentPage}
                onChange={handlePageChange}
                strokeWidth={currentPage}
                showFirstButton
                showLastButton
              />
            </Box>
          </Grid>
        </Box>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Paper elevation={0}>
        <Box
          className="page-heading-section"
          style={{ justifyContent: "start", gap: "20px" }}
        >
          <Box className="page-heading">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <Box
                onClick={() => {
                  navigate(
                    location?.state?.Navigation
                      ? "/admindashboard/creditline"
                      : "/admindashboard/approvallisting"
                  );
                }}
                component="img"
                src={BackArrowSmall}
                sx={{
                  cursor: "pointer",
                }}
              />
              <h5>Approvals</h5>
            </Box>
            <Typography>View all the approval request from users.</Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
          <Box
            className="products-filters-row"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              mt: 2,
            }}
          >
            <Box sx={TotalReqStyle}>
              <Typography sx={textBudge}>
                {queryType === "Profile" ? (
                  requestData?.totalCount ? (
                    <>Total Requests: {requestData?.totalCount}</>
                  ) : (
                    <>Total Requests: 0</>
                  )
                ) : queryType === "Product" ? (
                  requestData?.totalCount ? (
                    <>Total Products: {requestData?.totalCount}</>
                  ) : (
                    <>Total Products: 0</>
                  )
                ) : queryType === "CreditRequest" ? (
                  requestData?.totalCount ? (
                    <>Total Credit Requests: {requestData?.totalCount}</>
                  ) : (
                    <>Total Credit Requests: 0</>
                  )
                ) : null}
              </Typography>
            </Box>

            <Box
              className="total-products-badge custom-tab"
              style={{
                width: "auto",
                minwidth: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "20px",
                background: "#fff",
              }}
            >
              <span
                onClick={() => {
                  setQueryType("Product");
                }}
                style={{
                  color: queryType == "Product" && "#445FD2",
                }}
              >
                Product
              </span>
              <span
                onClick={() => {
                  setQueryType("Profile");
                }}
                style={{ color: queryType == "Profile" && "#445FD2" }}
              >
                Profile
              </span>
              <span
                onClick={() => {
                  setQueryType("CreditRequest");
                }}
                style={{ color: queryType == "CreditRequest" && "#445FD2" }}
              >
                Credit Request
              </span>
              <span
                onClick={() => {
                  setQueryType("CreditApproved");
                }}
                style={{ color: queryType == "CreditApproved" && "#445FD2" }}
              >
                User Credit Approved
              </span>
            </Box>
          </Box>
          {queryType == "CreditRequest" || queryType == "CreditApproved" ? (
            <Searchbar width={"100%"} onSendData={handleChildData} />
          ) : null}
          {/* <React.Fragment>{approvalRefetchFunction()}</React.Fragment> */}

          {queryType == "Product" && <div>{renderProductData()}</div>}
          {queryType == "Profile" && <div>{renderProfileData()}</div>}
          {queryType == "CreditRequest" && <div>{renderCreditData()}</div>}
          {queryType == "CreditApproved" && (
            <div>{renderCreditUserApproved()}</div>
          )}
        </Box>

        <Modal open={viewRequest} onClose={handleViewRequestClose}>
          <Box sx={RequestViewStyle}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "#6B7A99",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                }}
              >
                {queryType === "CreditRequest"
                  ? "CREDIT LINE REQUEST"
                  : queryType === "Profile"
                  ? "Profile Request"
                  : queryType === "Product"
                  ? "Product Request"
                  : "Change Request"}
              </Typography>

              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "10px",
                  fontStyle: "normal",
                  fontWeight: 400,
                }}
              >
                {queryType === "Profile" ? (
                  <React.Fragment>{/* {storeId?.} */}</React.Fragment>
                ) : (
                  <React.Fragment>
                    {queryType === "Product"
                      ? "The user has requested approval for the product"
                      : queryType === "CreditRequest" &&
                        storeId?.productId?.ListingType === "Media"
                      ? "Requirement and Media information below"
                      : queryType === "CreditRequest"
                      ? "Requirement and products information below"
                      : null}
                  </React.Fragment>
                )}
              </Typography>

              <Box
                component="img"
                src={CloseIcon}
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  right: "3%",
                  top: "5%",
                  height: "20px",
                  width: "auto",
                }}
                onClick={() => {
                  handleViewRequestClose();
                }}
              />
            </Box>

            {queryType === "Profile" ? (
              <Box
                sx={{
                  my: 2.5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  width: "90%",
                  mx: "auto",
                }}
              >
                <Typography sx={DataHeader}>Old Data:</Typography>
                <Box sx={DataContentBox}>
                  <Typography sx={SubData}>
                    {storeId?.currentData?.phone ? (
                      <>Phone no: {storeId?.currentData?.phone}</>
                    ) : null}
                  </Typography>

                  <Typography sx={SubData}>
                    {storeId?.currentData?.CompanyAddress?.City ? (
                      <>City: {storeId?.currentData?.CompanyAddress?.City} </>
                    ) : null}{" "}
                  </Typography>

                  <Typography sx={SubData}>
                    {storeId?.currentData?.CompanyAddress?.Pincode ? (
                      <>
                        Pincode: {storeId?.currentData?.CompanyAddress?.Pincode}
                      </>
                    ) : null}
                  </Typography>

                  <Typography sx={SubData}>
                    {storeId?.currentData?.RegisteredAddress ? (
                      <>Address :{storeId?.currentData?.RegisteredAddress}</>
                    ) : null}{" "}
                  </Typography>

                  <Typography sx={SubData}>
                    {storeId?.currentData?.email ? (
                      <>Email :{storeId?.currentData?.email}</>
                    ) : null}{" "}
                  </Typography>

                  <Typography sx={SubData}>
                    {storeId?.currentData?.website ? (
                      <>Website :{storeId?.currentData?.website}</>
                    ) : null}{" "}
                  </Typography>
                </Box>

                <Typography sx={DataHeader}>Requested Data:</Typography>
                <Box sx={DataContentBox}>
                  <Typography sx={SubData}>
                    {storeId?.profileChanges?.bankdetails?.bankAccountNo ? (
                      <>
                        bankAccountNo:{" "}
                        {storeId?.profileChanges?.bankdetails?.bankAccountNo}
                      </>
                    ) : null}
                  </Typography>
                  <Typography sx={SubData}>
                    {storeId?.profileChanges?.bankdetails?.branchName ? (
                      <>
                        branchName:{" "}
                        {storeId?.profileChanges?.bankdetails?.branchName}
                      </>
                    ) : null}
                  </Typography>
                  <Typography sx={SubData}>
                    {storeId?.profileChanges?.bankdetails?.ifsc ? (
                      <>ifsc: {storeId?.profileChanges?.bankdetails?.ifsc}</>
                    ) : null}
                  </Typography>
                  <Typography sx={SubData}>
                    {storeId?.profileChanges?.phone ? (
                      <>Phone no: {storeId?.profileChanges?.phone}</>
                    ) : null}
                  </Typography>

                  <Typography sx={SubData}>
                    {storeId?.profileChanges?.CompanyAddress?.City ? (
                      <>
                        City: {storeId?.profileChanges?.CompanyAddress?.City}{" "}
                      </>
                    ) : null}{" "}
                  </Typography>

                  <Typography sx={SubData}>
                    {storeId?.profileChanges?.CompanyAddress?.Pincode ? (
                      <>
                        Pincode:{" "}
                        {storeId?.profileChanges?.CompanyAddress?.Pincode}
                      </>
                    ) : null}
                  </Typography>

                  <Typography sx={SubData}>
                    {storeId?.profileChanges?.RegisteredAddress ? (
                      <>Address :{storeId?.profileChanges?.RegisteredAddress}</>
                    ) : null}{" "}
                  </Typography>

                  <Typography sx={SubData}>
                    {storeId?.profileChanges?.email ? (
                      <>Email :{storeId?.profileChanges?.email}</>
                    ) : null}{" "}
                  </Typography>
                  <Typography sx={SubData}>
                    {storeId?.profileChanges?.website ? (
                      <>Website :{storeId?.profileChanges?.website}</>
                    ) : null}{" "}
                  </Typography>
                </Box>
              </Box>
            ) : queryType === "Product" ? (
              <Box
                sx={{
                  my: 2.5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  width: "90%",
                  mx: "auto",
                }}
              >
                <Box sx={DataContentBox}>
                  <Typography sx={SubData}>
                    '{storeId?.companyId?.companyName}' has requested approval
                    for the product '{storeId?.productId?.ProductName}'{" "}
                  </Typography>
                  <Button
                    sx={{
                      ...ButtonStyle,
                      textTransform: "none",
                      color: "#445FD2",
                      background: "#fff",
                      border: "1px solid #445FD2",
                      width: "45%",
                      height: "30px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      GetProductCategory(
                        storeId?.productId?.ProductCategoryName,
                        storeId?.productId?._id,
                        storeId?.productId?.ProductType,
                        storeId?.productId?.ListingType,
                        storeId?.productId?.VoucherType,
                        storeId?.productId?.ProductSubCategory
                      );
                    }}
                  >
                    {storeId?.productId?.ListingType === "Media"
                      ? "View Media"
                      : "View Product"}
                  </Button>
                </Box>
              </Box>
            ) : queryType === "CreditRequest" ? (
              <Box
                sx={{
                  my: 2.5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "90%",
                  mx: "auto",
                }}
              >
                <Box sx={DataContentBox}>
                  <Typography
                    sx={{ ...SubData, color: "black", textAlign: "center" }}
                  >
                    Company '{storeId?.companyId?.companyName}'
                  </Typography>
                  <Typography
                    sx={{ ...SubData, color: "black", textAlign: "center" }}
                  >
                    Product Name: '{storeId?.productId?.ProductName}'{" "}
                  </Typography>
                  <Typography
                    sx={{ ...SubData, color: "black", textAlign: "center" }}
                  >
                    Requested Barter Coins :{" "}
                    <b>
                      <CommaSeprator Price={storeId?.requestedUserAmount} />
                    </b>
                  </Typography>
                  {storeId?.productId?.ListingType === "Media" ||
                  storeId?.productId?.ListingType === null ||
                  storeId?.productId?.ListingType === undefined ? null : (
                    <>
                      <Typography
                        sx={{ ...SubData, color: "black", textAlign: "center" }}
                      >
                        Calculater Eligibity :{" "}
                        <b>
                          {
                            <CommaSeprator
                              Price={(
                                (highestValue * credittoken) /
                                100
                              ).toFixed()}
                            />
                          }
                        </b>{" "}
                        amount
                      </Typography>
                      <Typography
                        sx={{ ...SubData, color: "black", textAlign: "center" }}
                      >
                        Total Product Value :{" "}
                        <b>{<CommaSeprator Price={highestValue} />}</b>
                      </Typography>
                    </>
                  )}
                  <Button
                    sx={{
                      ...ButtonStyle,
                      textTransform: "none",
                      color: "#445FD2",
                      background: "#fff",
                      border: "1px solid #445FD2",
                      width: "auto",
                      height: "30px",
                    }}
                    variant="outlined"
                    onClick={() => {
                      GetProductCategory(
                        storeId?.productId?.ProductCategoryName,
                        storeId?.productId?._id,
                        storeId?.productId?.ProductType,
                        storeId?.productId?.ListingType,
                        storeId?.productId?.VoucherType,
                        storeId?.productId?.ProductSubCategory
                      );
                    }}
                  >
                    {storeId?.productId?.ListingType === "Media"
                      ? "View Media"
                      : "View Product"}
                  </Button>
                </Box>
              </Box>
            ) : null}
            {queryType === "Product" &&
              storeId?.requestStatus === "pending" && (
                <Box
                  sx={{
                    my: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {storeId?.productId?.ListingType === "Media" ||
                  storeId?.productId?.ListingType === null ||
                  storeId?.productId?.ListingType === undefined ? null : (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        height: "auto",
                        maxHeight: "300px",
                        justifyContent: "center",
                        overflow: "scroll",
                      }}
                    >
                      {VisiblePoints ? (
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          {storeId?.productId?.ListingType === "Voucher"
                            ? VoucherPoints.map((el, idx) => {
                                return (
                                  <Box
                                    key={idx}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      fontSize: "10px",
                                      width: "100%",
                                      mb: "5px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        ...SubData,
                                        fontSize: "10px",
                                        textAlign: "left",
                                        color: "black",
                                      }}
                                    >
                                      {el?.name}
                                    </span>
                                    <select
                                      className="input-field"
                                      style={{
                                        ...InputStyle,
                                        width: "30%",
                                        color: "black",
                                      }}
                                      variant="contained"
                                      defaultValue=""
                                      onChange={(e) =>
                                        handleInputChangeInput(
                                          el?.name,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option disabled="true" value="">
                                        -- Select --
                                      </option>
                                      {Object.keys(el?.Points).map(
                                        (pointName, pointIdx) => (
                                          <option
                                            key={pointIdx}
                                            value={pointName}
                                          >
                                            {pointName}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </Box>
                                );
                              })
                            : storeId?.productId?.ListingType === "Product"
                            ? ProductPoints.map((el, idx) => {
                                return (
                                  <Box
                                    key={idx}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      fontSize: "10px",
                                      width: "100%",
                                      mb: "5px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        ...SubData,
                                        fontSize: "10px",
                                        textAlign: "left",
                                        color: "black",
                                      }}
                                    >
                                      {el?.name}
                                    </span>
                                    <select
                                      className="input-field"
                                      style={{
                                        ...InputStyle,
                                        width: "30%",
                                        color: "black",
                                      }}
                                      variant="contained"
                                      defaultValue=""
                                      onChange={(e) =>
                                        handleInputChangeInput(
                                          el?.name,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option disabled="true" value="">
                                        -- Select --
                                      </option>
                                      {Object.keys(el?.Points).map(
                                        (pointName, pointIdx) => (
                                          <option
                                            key={pointIdx}
                                            value={pointName}
                                          >
                                            {pointName}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </Box>
                                );
                              })
                            : null}
                          <Box>
                            <Typography sx={SubData}>
                              <b>Total Question</b>
                            </Typography>
                            {Object.keys(totalPoints).map(
                              (pointName, pointIdx) => (
                                <Box
                                  key={pointIdx}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontSize: "10px",
                                    width: "100%",
                                    mb: "5px",
                                  }}
                                >
                                  <span
                                    style={{ ...SubData, textAlign: "left" }}
                                  >
                                    {pointIdx + 1} : {pointName}:{" "}
                                  </span>{" "}
                                  <span
                                    style={{ ...SubData, textAlign: "left" }}
                                  >
                                    <b>{totalPoints[pointName]}</b>
                                  </span>
                                </Box>
                              )
                            )}
                          </Box>
                          <Typography sx={SubData}>
                            <b>Total Selected Points Calculated :</b>{" "}
                            {sumOfPoints}
                          </Typography>
                          {/* <Typography sx={SubData}>
                            <b>Total Points Calculated :</b>{" "}
                            {lastamounttodisplay}
                          </Typography>
                          <Typography sx={SubData}>
                            <b>Total Percentage :</b> {credittoken.toFixed(2)}%
                          </Typography>
                          <Typography sx={SubData}>
                            <b> Total Amount For Credit :</b>{" "}
                            {credittoken <= 49
                              ? "0"
                              : credittoken > 49 && credittoken <= 74
                              ? "2,50,000"
                              : credittoken > 74 && credittoken <= 100
                              ? "5,00,000"
                              : null}
                          </Typography> */}
                        </Box>
                      ) : null}
                    </Box>
                  )}
                  {storeId?.productId?.ListingType === "Media" ||
                  storeId?.productId?.ListingType === null ||
                  storeId?.productId?.ListingType === undefined ? null : (
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        sx={{
                          ...ButtonStyle,
                          textTransform: "none",
                          color: "#445FD2",
                          background: "#fff",
                          border: "1px solid #445FD2",
                          width: "auto",
                          height: "30px",
                        }}
                        onClick={() => {
                          if (!VisiblePoints) {
                            setVisiblePoints(true);
                          } else {
                            setVisiblePoints(false);
                            setTotalPoints({});
                            setSumOfPoints(0);
                          }
                        }}
                      >
                        {!VisiblePoints
                          ? "View More Points..."
                          : "View Less..."}
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            {queryType === "CreditRequest" &&
              storeId?.requestStatus === "pending" && (
                <Box
                  sx={{
                    my: 3,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Typography sx={{ ...SubData, color: "black" }}>
                    <b>Important Note</b>: Below Information would be documented
                    in Credit Line Agreement between
                    <br />
                    BXI & Member
                  </Typography>
                  {/* {!VisiblePoints ? ( */}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "10px",
                      width: "100%",
                      mb: "5px",
                    }}
                  >
                    <span
                      style={{
                        ...SubData,
                        fontSize: "10px",
                        textAlign: "left",
                        color: "black",
                      }}
                    >
                      Credit Line Approved :
                    </span>
                    <input
                      className="input-field"
                      type="number"
                      min={0}
                      style={{
                        ...InputStyle,
                        color: "black",
                      }}
                      placeholder="Eg. 1000"
                      variant="contained"
                      disableUnderline
                      onWheel={numberInputOnWheelPreventChange}
                      onChange={(e) =>
                        handleInputChange("CreditValue", e.target.value)
                      }
                    />
                  </Box>
                  {storeId?.productId?.ListingType === "Media" ||
                  storeId?.productId?.ListingType === null ||
                  storeId?.productId?.ListingType === undefined ? null : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "10px",
                          width: "100%",
                          mb: "5px",
                        }}
                      >
                        <span
                          style={{
                            ...SubData,
                            fontSize: "10px",
                            textAlign: "left",
                            color: "black",
                          }}
                        >
                          Listed Price on Marketplace :
                        </span>
                        <input
                          className="input-field"
                          type="number"
                          style={{
                            ...InputStyle,
                            width: "50%",
                            color: "black",
                          }}
                          min={0}
                          disabled
                          placeholder={
                            // storeId?.productId?.ProductsVariantions?.at(0)
                            //   ?.DiscountedPrice
                            highestDiscountedPrice
                          }
                          onChange={(e) => setPriceForCredit(e.target.value)}
                          variant="contained"
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "10px",
                          width: "100%",
                          mb: "5px",
                        }}
                      >
                        <span
                          style={{
                            ...SubData,
                            fontSize: "10px",
                            textAlign: "left",
                            color: "black",
                          }}
                        >
                          Listed Quantity on Marketplace :
                        </span>
                        <input
                          className="input-field"
                          type="number"
                          style={{
                            ...InputStyle,
                            width: "50%",
                            color: "black",
                          }}
                          min={0}
                          disabled
                          variant="contained"
                          placeholder={
                            inputValues?.CreditValue
                              ? "Qty " +
                                (
                                  inputValues?.CreditValue /
                                  highestDiscountedPrice
                                ).toFixed()
                              : "0"
                          }
                          // onChange={(e) => setQuantityForCredit(e.target.value)}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "10px",
                          width: "100%",
                          mb: "5px",
                        }}
                      >
                        <span
                          style={{
                            ...SubData,
                            fontSize: "10px",
                            textAlign: "left",
                            color: "black",
                          }}
                        >
                          BXI Purchase Price :
                        </span>
                        <input
                          className="input-field"
                          type="number"
                          style={{
                            ...InputStyle,
                            width: "50%",
                            color: "black",
                          }}
                          min={0}
                          disabled
                          variant="contained"
                          placeholder={highestDiscountedPrice}
                          // onChange={(e) => setBXIRateForCredit(e.target.value)}
                        />
                      </Box>
                    </Box>
                  )}
                  {storeId?.productId?.ListingType === "Media" ||
                  storeId?.productId?.ListingType === null ||
                  storeId?.productId?.ListingType === undefined ? null : (
                    <>
                      <Typography sx={SubData}>
                        <b>Total Points Calculated :</b> {lastamounttodisplay}
                      </Typography>
                      <Typography sx={SubData}>
                        <b>Total Percentage :</b> {credittoken.toFixed(2)}%
                      </Typography>
                    </>
                  )}
                </Box>
              )}

            {storeId?.requestStatus === "pending" ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 1,
                    textTransform: "none",
                  }}
                >
                  <Box
                    component="button"
                    variant="contained"
                    onClick={() => {
                      {
                        queryType === "Profile"
                          ? updateProfileStatusOnClick(false)
                          : updateStatusOnClick(false);
                      }
                    }}
                    sx={{
                      ...ButtonStyle,
                      textTransform: "none",
                      color: "#F15B5B",
                      background: "#fff",
                      border: "1px solid #F15B5B",
                    }}
                  >
                    Reject
                  </Box>
                  <Box
                    component="button"
                    variant="contained"
                    onClick={() => {
                      {
                        queryType === "Profile"
                          ? updateProfileStatusOnClick(true)
                          : updateStatusOnClick(true);
                      }
                    }}
                    sx={{
                      ...ButtonStyle,
                      textTransform: "none",
                      color: "#fff",
                      background: "#42C157",
                    }}
                  >
                    Approve
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    width: "auto",
                    borderRadius: 4,
                    p: 1.2,
                    cursor: "pointer",
                  }}
                >
                  {" "}
                  Note: 'This request is already {storeId?.requestStatus}.'{" "}
                </Typography>
              </Box>
            )}
          </Box>
        </Modal>
        {queryType == "CreditApproved" ? null : requestData?.totalCount ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              mx: "auto",
            }}
            mt={2}
          >
            <Stack>
              <StyledPagination
                size="medium"
                count={initialPaginationCountsec}
                color="primary"
                showFirstButton
                showLastButton
                page={currentPagesec}
                onChange={handlePageChangesec}
                strokeWidth={currentPagesec}
              />
            </Stack>
          </Box>
        ) : null}
      </Paper>
    </React.Fragment>
  );
}

export default AllProductApprovals;

const RequestViewStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const ButtonStyle = {
  width: "100%",
  height: "40px",
  border: "none",
  borderRadius: 1,
  "&:hover": {
    cursor: "pointer",
    boxShadow: "1px 1px 5px 1px #ccc",
  },
  borderRadius: 2,
  fontFamily: "poppins",
  fontWeight: 400,
};

const DataHeader = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  textAlign: "left",
};

const DataContentBox = {
  my: 1,
  textAlign: "left",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
};

const SubData = {
  color: "#868686",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
};

const TotalReqStyle = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
  position: "absolute",
  left: 0,
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  // lineHeight: 20,
};

const InputStyle = {
  color: "#818181",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 400,
};

const tablehead = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "11px",
  textAlign: "center",
  color: "#929EAE",
  textAlign: "center",
};
