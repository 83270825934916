import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import Message from "./MessagesBox";

const Chat = () => {
  return (
    <Paper elevation={0}>
      <Box sx={{ background: "#FAFBFC", p: 0.2 }}>
        <Box>
          <Message />
        </Box>
      </Box>
    </Paper>
  );
};

export default Chat;
