import axios from "axios";
import { useMutation } from "react-query";
import { GetTokenFunc } from "../../utils/GetToken";

const UpdateWallet = async (data) => {
  // console.log('apidata', data);
  await axios.post(
    `/api/v1/admin/wallet/update-funds-reuqests`,
    { fundId: data.id, amount: data.amount },
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
};
export const useUpdateWallet = () => {
  return useMutation(UpdateWallet);
};
