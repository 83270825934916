import { useQuery } from "react-query";
import axios from "axios";

const fetchPromotionalEmails = async () => {
  try {
    const response = await axios.get("promotional-emails/get");

    if (response.status !== 200) {
      throw new Error("Failed to fetch promotional emails");
    }

    return response.data;
  } catch (error) {
    throw new Error(`An error occurred while fetching promotional emails: ${error.message}`);
  }
};

const useGetPromotionalEmails = () => {
  const { data, isLoading, isError, error } = useQuery("promotionalEmails", fetchPromotionalEmails);

  return { data, isLoading, isError, error };
};

export default useGetPromotionalEmails;
