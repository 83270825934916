import React, { useState } from 'react';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Box,
  Paper,
  Typography,
} from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';
import List from '../../assets/ChatImages/list.png';
const DropCategory = () => {
  const [category, setCategory] = useState();
  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: '40px' }}>
        <Box sx={{ display: 'flex', gap: '10px', mt: 0 }}>
          <Typography sx={selectText}>Select Category</Typography>
          <FormControl fullWidth sx={selectDesign}>
            <Select
              className="selectDropdownStyle"
              value={category}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <img src={List} style={{ height: '18px', width: 'auto' }} />
                </InputAdornment>
              }
            >
              <MenuItem value={10}>Textile</MenuItem>
              <MenuItem value={20}>FMCG</MenuItem>
              <MenuItem value={30}>Hotels</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', gap: '10px', mt: 0 }}>
          <Typography sx={selectText}>Select Company</Typography>
          <FormControl fullWidth sx={selectDesign}>
            <Select
              className="selectDropdownStyle"
              value={category}
              onChange={handleChange}
              startAdornment={
                <InputAdornment position="start">
                  <img src={List} style={{ height: '18px', width: 'auto' }} />
                </InputAdornment>
              }
            >
              <MenuItem value={10}>Textile</MenuItem>
              <MenuItem value={20}>FMCG</MenuItem>
              <MenuItem value={30}>Hotels</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </div>
  );
};

export default DropCategory;
const selectText = {
  color: 'var(--grey-blue-60, #6B7A99)',
  fontFeatureSettings: 'clig off, liga off',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 500,
  mt: 1.6,
};

const selectDesign = {
  display: 'flex',
  flexDirection: 'row',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  minWidth: 20,
  width: '220px',
  height: '40px',
};
