import axios from 'axios';
import { useQuery, useMutation } from 'react-query';
import { GetTokenFunc } from '../../utils/GetToken';
const useOnBoardingData = async (data) => {
  return await axios.post(
    '/api/v1/admin/update-company-onboardingstatus',
    { ...data },
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
        // companyId: '64748ac636043f9feac4fdab',
        // OnboardingStatus: 'UNDER_REVIEW',
      },
    }
  );
};
export const useOnBoarding = () => {
  return useMutation(useOnBoardingData);
};
