import { Box } from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase, alpha, styled } from "@mui/material";
import React, { useState } from "react";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "20px",
  // borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
  background: "#FAFBFC",
  border: "1px solid #445FD2",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "70ch",
    },
  },
}));

function Searchbar(props) {
  const [globalsearch, setGlobalSearch] = useState("");

  return (
    <Box
      sx={{
        width: props.width ? props.width : "60%",
        mx: "auto",
      }}
    >
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="search"
          inputProps={{ "aria-label": "refresh" }}
          value={globalsearch}
          style={{
            width: "100%",
            borderRadius: "20px",
          }}
          onChange={(e) => {
            const trimmedValue = e.target.value.replace(/^\s/, "");
            setGlobalSearch(trimmedValue);
            props.onSendData(trimmedValue);
          }}
        />
      </Search>
    </Box>
  );
}

export default Searchbar;
