import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";

export const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "#1976d2",
  },
  // "& .css-g2z002-MuiSvgIcon-root-MuiPaginationItem-icon": {
  //   color: "#1976d2",
  //   display: "none",
  // },
  "& .MuiPaginationItem-root.Mui-selected": {
    color: "#fff",
  },
  "& .MuiPagination-ul li:last-child": {
    marginLeft: "16px",
  },
  "& .css-1v2lvtn-MuiPaginationItem-root": {
    marginRight: "16px",
    border: "none !important",
  },
  "& .MuiPagination-ul li:last-child": {
    marginLeft: "16px",
  },
  "& .MuiPagination-ul li:last-child button::before": {
    content: "'Last'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "11px",
    lineHeight: "14px",
    color: "#445FD2",
    marginRight: "8px",
  },
  "& .MuiPagination-ul li:first-child": {
    marginRight: "16px",
  },
  "& .MuiPagination-ul li:last-child button::before": {
    content: "'Last'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "11px",
    lineHeight: "14px",
    color: "#445FD2",
    marginRight: "8px",
  },
  "& .MuiPagination-ul li:first-child": {
    marginRight: "16px",
  },
  "& .MuiPagination-ul li:first-child button::after": {
    content: "'First'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "11px",
    lineHeight: "14px",
    color: "#445FD2",
    marginLeft: "8px",
  },
  "& .MuiPaginationItem-icon": {
    color: "#445FD2",
    fontWeight: 800,
  },
});
