import React from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import PageLoader from "../components/CommonActions/PageLoader";
import { toast } from "react-toastify";
import Searchbar from "../common/Searchbar";

const MembershipList = () => {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  const [buttonLoading, setButtonLoading] = useState(false);

  // State to hold data received from child component
  const [searchData, setSearchData] = useState("");

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const fetchAllMemberships = async () => {
    try {
      const response = await axios.get(
        `CompanyMembership_route/fetchAllCompanyMemberships?page=${currentPage}&search=${searchData}`
      );
      setData(response?.data?.data);
      setInitialPaginationCount(response.data?.totalPages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setLoading(false);
    }
  };

  const generateEInvoice = async (data) => {
    try {
      setButtonLoading(true);

      await axios.post(`eInvoice/generate_irn`, { data: data }).then((res) => {
        if (res?.data?.SignedInvoice === null) {
          toast.error(res?.data?.ErrorResponse?.at(0)?.ErrorInfo, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        fetchAllMemberships();
        setButtonLoading(false);
      });
    } catch (error) {
      fetchAllMemberships();
      setButtonLoading(false);
    }
  };

  const navigate = useNavigate();
  const storeDataToLocalStorage = async ({ type, IRNdata, id }) => {
    try {
      let IRNObject = {
        IRNData: IRNdata?.SignedQRCode,
        IRNType: type,
        IRNId: IRNdata?.IRN,
        IRNAckNo: IRNdata?.AckNo,
        IRNAckDate: IRNdata?.AckDate,
      };
      localStorage.setItem("MembershipIRNData", JSON.stringify(IRNObject));
      navigate(`/admindashboard/einvoiceView/${id}/${type}`);
    } catch (error) {
      console.error("Error storing data to local storage:", error);
    }
  };

  useEffect(() => {
    fetchAllMemberships();
  }, [currentPage, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  if (buttonLoading) {
    return <PageLoader />;
  } else {
    return (
      <Box>
        <Searchbar onSendData={handleChildData} />

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Invoice Number</TableCell>
                <TableCell>Membership Type</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>E Invoice Generation Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((response, idx) => {
                let startDate = new Date(
                  response.MembershipStartDate
                ).toLocaleDateString("en-IN");

                let endDate = new Date(
                  response.MembershipExpiryDate
                ).toLocaleDateString("en-IN");

                const continuousIdx = (currentPage - 1) * 10 + idx + 1;
                return (
                  <TableRow>
                    <TableCell>{continuousIdx}</TableCell>
                    <TableCell>{response?.companieData?.companyName}</TableCell>
                    <TableCell>{response?.MembershipId}</TableCell>
                    <TableCell>
                      {response?.MembershipType === "basic" ||
                      response?.MembershipType === "BASIC"
                        ? "Standard"
                        : response?.MembershipType === "advance"
                        ? "Advance"
                        : response?.MembershipType}
                    </TableCell>
                    <TableCell>{startDate}</TableCell>
                    <TableCell>{endDate}</TableCell>
                    <TableCell>
                      {response?.IRN && response?.IRN?.SignedQRCode !== null ? (
                        <Button variant="outlined">
                          IRN already Generated
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          onClick={() =>
                            generateEInvoice({
                              id: response?._id,
                              companyId: response?.CompanyId,
                            })
                          }
                        >
                          Generate eInvoice
                        </Button>
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        onClick={() => {
                          storeDataToLocalStorage({
                            type: "mem",
                            IRNdata: response?.IRN,
                            id: response?.CompanyId,
                          });
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
              xs: "100%",
            },
            mx: "auto",
          }}
          mt={2}
        >
          <Stack>
            <StyledPagination
              size="medium"
              count={initialPaginationCount}
              color="primary"
              showFirstButton
              showLastButton
              page={currentPage}
              onChange={handlePageChange}
              strokeWidth={currentPage}
            />
          </Stack>
        </Box>
      </Box>
    );
  }
};

export default MembershipList;
