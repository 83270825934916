import { createSlice } from "@reduxjs/toolkit";

export const GetTotalCompanys = createSlice({
    name: "Companys",
    initialState: {
        CompanyCounts: []
    },
    reducers: {
        company_count_Request: (state) => {
            state.loading = true;
            state.CompanyCounts = [];
        },
        company_count_Success: (state, action) => {
            state.loading = false;
            state.CompanyCounts = action.payload;
        },
        company_count_Fail: (state, action) => {
            state.loading = false;
            state.CompanyCounts = action.payload;
        }
    }
})

export const { company_count_Fail, company_count_Request, company_count_Success } = GetTotalCompanys.actions

export default GetTotalCompanys.reducer