
import BXILOGO from '../../assets/BXI_LOGO.png'
import Loginimg from '../../assets/loginimg.svg'
import "../../App.css";
import React, { useState } from 'react';
import {
    TextField,
    Button,
    IconButton,
    InputAdornment,
    Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useNavigate } from "react-router-dom";
import { loginAdminUser,handleError } from '../../common/ApiUtility';

const Login = () => {

    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleShowPasswordClick = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Validation
            if (!username || !password) {
                setError('Please enter both username and password.');
            } else {
                const res = await loginAdminUser(username, password);
                navigate("/admindashboard/userdashboard");
            }
        }catch(err){
            handleError('Please enter valid credentials');
        }
    };

    return (
        <>
            <div className='login-container'>
                <div className='login-left'>
                    <div className='bxi-logo-img'><img src={BXILOGO} width="55px" height="50px" /></div>

                    <div className='login-box'>
                        <span className='login-title'>Admin LogIn</span>

                        <form onSubmit={handleSubmit}>
                            <TextField
                                label="Email Address"
                                type="text"
                                value={username}
                                onChange={handleUsernameChange}
                                fullWidth
                                InputProps={{
                                    className: "login-text-field"
                                }}
                                variant="outlined"
                                size="small"
                                margin="normal"
                                color="#6B7A99"
                            />
                            <TextField
                                label="Password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={handlePasswordChange}
                                fullWidth
                                error={!!error}
                                helperText={error}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPasswordClick}>
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    className: "login-text-field"
                                }}
                                variant="outlined"
                                size="small"
                                margin="normal"
                                color="#6B7A99"
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                className='btn-login'
                                style={{ marginTop: '20px' }}
                            >
                                Login
                            </Button>
                        </form>
                    </div>
                </div>
                <div className='login-right'>
                    <img src={Loginimg} />
                </div>
            </div>
        </>
    )
}
export default Login;