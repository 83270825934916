import {
  Box,
  Grid,
  Paper,
  Divider,
  IconButton,
  Modal,
  Input,
  Radio,
  RadioGroup,
  FormControlLabel,
  MenuItem,
  Select,
  Typography,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useFetchDelivery } from "../../Hooks/AllRecords/useAllRecords";
import * as Styles from "../../common/Styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import filter from "../../assets/filter.png";
import CloseIcon from "../../assets/CloseIcon.svg";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import PageLoader from "../CommonActions/PageLoader";
import recyclebin from "../../assets/Recyclebin/recyclebin.png";
import Searchbar from "../../common/Searchbar";

function Delivery() {
  const navigate = useNavigate();

  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [searchData, setSearchData] = useState("");
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const {
    data: fetchDelivery,
    isLoading: DeliveryLoading,
    refetch: DeliveryRefetch,
  } = useFetchDelivery(currentPage, status, searchData);

  // console.log(fetchDelivery, 'fetchDelivery');

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (fetchDelivery && fetchDelivery?.totalPages !== undefined) {
      setInitialPaginationCount(fetchDelivery?.totalPages);
    }
  }, [fetchDelivery?.totalPages]);

  useEffect(() => {
    DeliveryRefetch();
  }, [currentPage, status, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [status, searchData]);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  const [openFilter, setOpenFilter] = React.useState(false);

  const handleFilterOpen = () => setOpenFilter(true);
  const handleFilterClose = () => setOpenFilter(false);

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "620px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <React.Fragment>
        <Paper elevation={0}>
          <Box
            className="page-heading-section"
            style={{
              justifyContent: "start",
              gap: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <KeyboardBackspaceIcon />
            </Box>
            <Box className="page-heading">
              <h5>All Delivery</h5>
              <Typography>Explore all the data by applying filters.</Typography>
            </Box>
          </Box>
          <Divider />
          {DeliveryLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                ml: "500px",
                mt: 20,
              }}
            >
              <PageLoader />
            </Box>
          ) : (
            <Box
              sx={{
                background: "rgba(250, 251, 252, 1)",
                p: 2,
                mt: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "97%",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box>
                  {/* <Box className="total-products-badge">
                    Total Delivery: {fetchDelivery?.totalCount}
                  </Box> */}

                  <Box sx={buddge}>
                    <Typography sx={textBudge}>
                      {fetchDelivery instanceof Object ? (
                        <Typography sx={textBudge}>
                          Total Delivery(s): {fetchDelivery?.totalCount || 0}
                        </Typography>
                      ) : (
                        <Typography sx={textBudge}>
                          Total Delivery(s): 0
                        </Typography>
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Searchbar onSendData={handleChildData} />
                <Box sx={Styles.filtersDesign} onClick={handleFilterOpen}>
                  <img
                    src={filter}
                    alt=""
                    style={{ height: "auto", width: "15px" }}
                  />
                  Filters
                </Box>
              </Box>
              <Box
                sx={{
                  background: DeliveryLoading
                    ? "rgba(250, 251, 252, 1)"
                    : "#fff",
                  p: 2,
                  mt: 2,
                  width: "100%",
                  borderRadius: 2,
                }}
              >
                <Grid
                  container
                  sx={{
                    p: 2,
                  }}
                >
                  <Grid
                    item
                    xl={2.5}
                    lg={2.5}
                    md={2.5}
                    sm={2.5}
                    xs={2.5}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Product Name
                  </Grid>
                  <Grid
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Type
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.textHead}
                  >
                    Buyer Company & Location
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={Styles.textHead}
                  >
                    Seller Company & Location
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={Styles.textHead}
                  >
                    Order Id
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    sx={Styles.textHead}
                  >
                    Quantity
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Coin Amount
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Order Date
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Status
                  </Grid>
                </Grid>

                <Divider />
                {fetchDelivery?.allDelivery?.length <= 0 ? (
                  <Box sx={setBox}>
                    <img
                      src={recyclebin}
                      alt="recycle"
                      style={{ height: "auto", width: "23%" }}
                    />
                    <Typography sx={empty}>Data is empty</Typography>
                  </Box>
                ) : (
                  <React.Fragment>
                    {fetchDelivery?.allDelivery?.map((data) => {
                      let TotalAdditionalCostWtihoutGSTInBXI = 0;
                      let TotalPriceWithoutGST = 0;

                      data?.PurchaseOrderData?.ProductData?.map((el, idx) => {
                        if (data?.IsVoucher) {
                          TotalAdditionalCostWtihoutGSTInBXI +=
                            el?.AdditionalCost
                              ?.TotalAdditionalCostWtihoutGSTInBXI;
                        } else {
                          TotalAdditionalCostWtihoutGSTInBXI +=
                            el?.AdditionCost
                              ?.TotalAdditionalCostWtihoutGSTInBXI;
                        }
                        TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
                      });
                      return (
                        <Grid
                          container
                          sx={{
                            p: 2,
                            borderBottom: "1px solid rgba(245, 245, 245, 1)",
                          }}
                        >
                          <Grid
                            item
                            xl={2.5}
                            lg={2.5}
                            md={2.5}
                            sm={2.5}
                            xs={2.5}
                            sx={{
                              ...Styles.textHead,
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            {
                              data?.PurchaseOrderData?.ProductData?.at(0)
                                ?.ProductName
                            }
                          </Grid>
                          <Grid
                            item
                            xl={0.5}
                            lg={0.5}
                            md={0.5}
                            sm={0.5}
                            xs={0.5}
                            sx={{
                              ...Styles.textHead,
                              display: "flex",
                              justifyContent: "flex-start",
                              textAlign: "center",
                            }}
                          >
                            {
                              data?.BuyerChoosedTransportation
                                ?.TransportationType
                            }
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            sx={Styles.textHead}
                          >
                            {
                              data?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                            }
                            <br />
                            {
                              data?.PurchaseOrderData?.BuyerDetails?.Address
                                ?.State
                            }
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={2}
                            lg={2}
                            md={2}
                            sm={2}
                            xs={2}
                            sx={Styles.textHead}
                          >
                            {
                              data?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                            }
                            <br />
                            {
                              data?.PurchaseOrderData?.SellerDetails?.Address
                                ?.State
                            }
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={1.5}
                            lg={1.5}
                            md={1.5}
                            sm={1.5}
                            xs={1.5}
                            sx={{ ...Styles.textHead, overflow: "scroll" }}
                          >
                            {data?.OrderId}
                          </Grid>

                          <Grid
                            align="center"
                            item
                            xl={0.5}
                            lg={0.5}
                            md={0.5}
                            sm={0.5}
                            xs={0.5}
                            sx={Styles.textHead}
                          >
                            {
                              data?.PurchaseOrderData?.ProductData?.at(0)
                                ?.ProductQuantity
                            }
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={Styles.textHead}
                          >
                            {(
                              (TotalAdditionalCostWtihoutGSTInBXI || 0) +
                              (TotalPriceWithoutGST || 0)
                            ).toFixed(2)}
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={Styles.textHead}
                          >
                            {formatDate(data?.createdAt)}
                          </Grid>
                          <Grid
                            align="center"
                            item
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            sx={Styles.textHead}
                          >
                            {data?.OrderTracking?.at(
                              data?.OrderTracking?.length - 1
                            )?.Status
                              ? data?.OrderTracking?.at(
                                  data?.OrderTracking?.length - 1
                                )?.Status
                              : "Progress"}
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: {
                          xl: "100%",
                          lg: "100%",
                          md: "100%",
                          sm: "100%",
                          xs: "100%",
                        },
                        mx: "auto",
                      }}
                      mt={2}
                    >
                      <Stack>
                        <StyledPagination
                          size="medium"
                          count={initialPaginationCount}
                          color="primary"
                          showFirstButton
                          showLastButton
                          page={currentPage}
                          onChange={handlePageChange}
                          strokeWidth={currentPage}
                        />
                      </Stack>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Box>
          )}
        </Paper>
        <Modal open={openFilter} onClose={handleFilterClose}>
          <Box sx={FilterStyle}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Filters
              </Typography>
              <Box
                component="img"
                src={CloseIcon}
                sx={{
                  cursor: "pointer",
                  height: "20px",
                  width: "20px",
                }}
                onClick={() => {
                  handleFilterClose();
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                defaultValue={""}
                value={status}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: 1,
                  mt: 1,
                }}
                onChange={(e) => {
                  // console.log("change", e.target.value);
                  setStatus(e.target.value);
                  handleFilterClose();
                }}
              >
                <FormControlLabel
                  style={RadioTextStyle}
                  value=""
                  control={<Radio />}
                  label="All"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="Progress"
                  control={<Radio />}
                  label="Progress"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="Ready To Ship"
                  control={<Radio />}
                  label="Ready To Ship"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="Shipped"
                  control={<Radio />}
                  label="Shipped"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="In Transit"
                  control={<Radio />}
                  label="In Transit"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="Reached Nearest hub"
                  control={<Radio />}
                  label="Reached Nearest hub"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="Out For delivery"
                  control={<Radio />}
                  label="Out For delivery"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="Delivered"
                  control={<Radio />}
                  label="Delivered"
                />
              </RadioGroup>

              {/* <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                  mt: 2,
                  textTransform: "none",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleFilterClose();
                  }}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
              </Box> */}
            </Box>
          </Box>
        </Modal>
      </React.Fragment>
    </Paper>
  );
}

export default Delivery;

const RadioTextStyle = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};

const FilterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const empty = {
  color: "#8B8B8B",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 16,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "24px",
  mt: 3,
};
const setBox = {
  background: "#FAFBFC",
  mt: 2,
  height: "auto",
  width: "auto",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  alignItems: "center",
  flexDirection: "column",
  minHeight: "500px",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};
