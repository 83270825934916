import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import React, { useEffect, useRef, useState } from "react";
import StateData from "../../utils/StateCityArray.json";
import BxiCoin from "../../assets/BXIToken.svg";
import NumberToWord from "../../common/NumberToWord";
import CommaSeprator from "../../common/CommaSeparator";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import print from "../../../src/assets1/Images/CommonImages/PrintInvoice.svg";
import download from "../../../src/assets1/Images/CommonImages/downloadinvoice.svg";

import { useReactToPrint } from "react-to-print";

import axios from "axios";

const PurchaseOrderDetails = (props) => {
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [stateArray, setStateArray] = useState();
  const [Address, setAddress] = useState("");
  const [Area, setArea] = useState("");
  const [state, setState] = useState("");
  const [BuyerShippingAddress, setBuyerShippingAddress] = useState();
  const [CityArray, setCityArray] = useState();

  const [AddressToMap, setAddressToMap] = useState();

  useEffect(() => {
    setBuyerShippingAddress({
      PinCode: pincode,
      City: city,
      State: state,
      Address: Address,
    });
  }, [pincode, city, state, Address, Area]);

  // const { data: OrderSummarydata, loading: InvoiceLoading } =
  //   useGetPurchaseOrderByOrderSummary(id);

  const OrderSummarydata = props.dataFromParent;

  const GetTimeline = (timeline, boughtDates) => {
    let result = [];
    if (timeline === "Month") {
      const months = Math.ceil(boughtDates.length / 30);
      for (let i = 0; i < months; i++) {
        const startIndex = i * 30;
        const endIndex = Math.min((i + 1) * 30, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Week") {
      const weeks = Math.ceil(boughtDates.length / 7);
      for (let i = 0; i < weeks; i++) {
        const startIndex = i * 7;
        const endIndex = Math.min((i + 1) * 7, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    } else if (timeline === "Day") {
      result = boughtDates.map((date) => ({
        startDate: date,
        endDate: date,
      }));
    } else if (timeline === "Years") {
      const years = Math.ceil(boughtDates.length / 365);
      for (let i = 0; i < years; i++) {
        const startIndex = i * 365;
        const endIndex = Math.min((i + 1) * 365, boughtDates.length);
        result.push({
          startDate: boughtDates[startIndex],
          endDate: boughtDates[endIndex - 1],
        });
      }
    }

    return result;
  };

  const BuyerMediaViewContent = ({ productMedia, productUrl, productName }) => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Typography
          sx={{
            ...TableBottomtext,
            fontSize: "14px",
            width: "300px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          Media Name : {productName}
        </Typography>
        <a
          href={productUrl}
          target="_blank"
          download={`Product_${productMedia?.productId}_Content`}
          style={{
            textDecoration: "none",
          }}
        >
          <Button
            onClick={() => {
              // Optionally add logic for opening the content in a new tab
              // window.open(productUrl, "_blank");
            }}
            sx={ButtonStyleForView}
          >
            View Content
          </Button>
        </a>
      </Box>
    );
  };

  async function GetDeliveryData() {
    await axios.get(`api/v1/signzy-data/gst-from-pan`).then((res) => {
      setAddressToMap(res.data);
    });
  }

  let storeDataIds = [];
  let TotalQuantity = 0;
  let totalAmount = 0;
  let totalPricePerUnit = 0;
  let totatlTaxableAmount = 0;
  let totalGST = 0;
  let totalAmountWithGST = 0;
  let totalAmountWithTax = 0;
  let totaltaxvalue = 0;
  let totalCGSTAmount = 0;
  let totalIGSTPercentage = 0;
  let totalSGSTAmount = 0;
  let totalSGSTPercentage = 0;
  let totalCSTPerCentage = 0;
  let TotalGSTAmount = 0;
  let TotalTokanAmount = 0;

  let totaladditionalcostinrupee = 0;
  let totaladditionalcostinbxi = 0;
  OrderSummarydata?.ProductData?.map((item) => {
    storeDataIds.push(item);
    TotalQuantity += item.ProductQuantity;
    totalAmount +=
      OrderSummarydata?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item.PricePerUnitWithoutGST * item.ProductQuantity;
    totalPricePerUnit += item.PricePerUnitWithoutGST;
    TotalTokanAmount +=
      OrderSummarydata?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item?.PriceWithoutGST;

    totatlTaxableAmount +=
      OrderSummarydata?.ProductTypeName === "Media"
        ? item.DiscountedPrice *
          item?.TimelineToBought *
          item.ProductQuantity *
          item?.BoughtSeconds
        : item.PricePerUnitWithoutGST * item.ProductQuantity;
    totalGST += item.GST;
    totalAmountWithGST += (
      item?.DiscountedPrice *
      item?.ProductQuantity *
      (item?.GST / 100)
    )?.toFixed(2);
    totalAmountWithTax +=
      OrderSummarydata?.ProductTypeName === "Media"
        ? item?.DiscountedPrice *
            item?.ProductQuantity *
            item?.TimelineToBought *
            item?.BoughtSeconds *
            (item?.GST / 100) +
          item?.DiscountedPrice *
            item?.ProductQuantity *
            item?.TimelineToBought *
            item?.BoughtSeconds
        : item?.TotalPriceWithGSTInRupee;

    totalCGSTAmount +=
      (item?.DiscountedPrice * item?.ProductQuantity * item?.GST) / 2 / 100;
    totalSGSTAmount +=
      (item?.DiscountedPrice * item?.ProductQuantity * item?.GST) / 2 / 100;
    totalSGSTPercentage += item.GST / 2;
    totalCSTPerCentage += item.GST / 2;
    totalIGSTPercentage += item.GST;
    TotalGSTAmount += item?.TotalGSTInBXI;
    totaltaxvalue += item?.TotalGSTInBXI;
  });

  const printRef = useRef();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleDownload = () => {
    setTimeout(() => {
      html2canvas(componentRef.current)
        .then((canvas) => {
          const pdf = new jsPDF();
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

          pdf.addImage(
            canvas.toDataURL("image/png"),
            "PNG",
            0,
            0,
            pdfWidth,
            pdfHeight
          );
          pdf.save("print.pdf");
        })
        .catch((error) => {
          console.error("Error generating PDF:", error);
        });
    }, 1000);
  };

  const downloadRef = useRef(null);

  useEffect(() => {
    if (stateArray) {
      let stateData = StateData?.filter((item) => item?.name === stateArray);
      setCityArray(stateData[0]?.data);
    }
  }, [stateArray]);

  let AdCostInrTotal = 0;
  let AdCostBxiTotal = 0;

  OrderSummarydata?.ProductData?.map((item) => {
    if (item?.AdditionCostArray?.length > 0) {
      item?.AdditionCostArray.forEach((item) => {
        if (item?.currencyType === "₹") {
          AdCostInrTotal += item?.GstPrice;
          totaladditionalcostinrupee += item?.TotalWithGst;
        } else if (item?.currencyType === "BXITokens") {
          AdCostBxiTotal += item?.GstPrice;
          totaladditionalcostinbxi += item?.TotalWithGst;
        }
      });
    }
  });

  useEffect(() => {
    GetDeliveryData();
  }, []);

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [
      pad(d.getDate()), // Day
      pad(d.getMonth() + 1), // Month (adding 1 to convert to 1-indexed)
      d.getFullYear(), // Year
    ].join("/");
  }

  return (
    <Box
      sx={{
        bgcolor: "transparent",
        width: "100%",
        height: "100%",
      }}
      elevation={0}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: "white",
          mx: "auto",
          borderRadius: "17px",
          pb: "40px",
        }}
        elevation={1}
      >
        <Box ref={componentRef}>
          <Box
            sx={{
              width: "95%",
              mx: "auto",
              borderLeft: "1px solid #cdcdcd",
              borderRight: "1px solid #cdcdcd",
              borderBottom: "1px solid #cdcdcd",
              borderTop: "1px solid #cdcdcd",
              px: "0px",
            }}
            // ref={printRef}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "auto",
                mx: "auto",
              }}
              ref={downloadRef}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                }}
              >
                <img
                  src={OrderSummarydata?.SellerDetails?.SellerCompanyLogo}
                  style={{ width: "100px", height: "100px" }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft: "1px solid #cdcdcd",
                  height: "130px",
                  width: "40%",
                }}
              >
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    color: "rgba(107, 122, 153, 1)",
                    opacity: 1,
                    fontWeight: 600,
                  }}
                >
                  {OrderSummarydata?.BuyerDetails?.BuyerCompanyName}
                </Typography>
                <Typography
                  sx={{
                    ...CommongTextStyle,
                    textAlign: "center",
                    fontSize: "11px",
                    color: "rgba(107, 122, 153, 1)",
                    opacity: 1,
                    fontWeight: 600,
                  }}
                >
                  {OrderSummarydata?.BuyerDetails?.BuyerCompanyAddress}
                </Typography>
                <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                  Tel:{OrderSummarydata?.BuyerDetails?.BuyerCompanyContact}
                </Typography>
                <Typography sx={{ color: "rgba(80, 80, 80, 1)", opacity: 1 }}>
                  GSTIN:{OrderSummarydata?.BuyerDetails?.GSTIN}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "130px",
                  width: "30%",
                  borderLeft: "1px solid #cdcdcd",
                }}
              >
                <img
                  src={OrderSummarydata?.BuyerDetails?.BuyerCompanyLogo}
                  style={{ width: "100px", height: "100px" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "42px",
                mx: "auto",
                background: "#2261A2",
                borderRadius: "10px",
              }}
            >
              <Typography
                sx={{
                  ...CommongTextStyle,
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: "100%",
                }}
              >
                {props?.PageName || "Purchase order"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                mx: "auto",
                height: "40px",
                borderRight: "1px solid #cdcdcd",
                borderLeft: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  borderRight: "1px solid #F3F2F3",
                  pl: 1,
                }}
              >
                {props?.ProformaInvoice ? (
                  <Typography sx={CommongTextStyle}>
                    PI Number: {OrderSummarydata?.PINumber}
                  </Typography>
                ) : (
                  <Typography sx={CommongTextStyle}>
                    PO Number: {OrderSummarydata?.PoNumber}
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  width: "50%",
                  pl: 1,
                }}
              >
                {props?.ProformaInvoice ? (
                  <Typography sx={CommongTextStyle}>
                    PI Date:{" "}
                    {new Date(OrderSummarydata?.createdAt).toLocaleDateString()}
                  </Typography>
                ) : (
                  <Typography sx={CommongTextStyle}>
                    PO Date: {convertDate(new Date(OrderSummarydata?.PoDate))}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                border: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "21px",
                  background: "#2261A2",
                  borderRadius: "3px 0px 0px 3",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                    borderRight: "1px solid #F3F2F3",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      fontSize: "12px",
                      color: "white",
                    }}
                  >
                    Seller Details
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...CommongTextStyle,
                      color: "white",
                      fontSize: "12px",
                    }}
                  >
                    Buyer Details
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",

                  alignItems: "flex-start",

                  width: "100%",
                  py: 0,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    mx: "auto",
                    borderRight: "1px solid #CDCDCD",
                    p: 2,
                  }}
                >
                  <Table sx={{ p: 0 }}>
                    <TableBody sx={{ p: 0 }}>
                      <TableRow sx={{ p: 0 }}>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            width: "90px",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Seller Name
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.SellerDetails?.SellerCompanyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Address:{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {
                              OrderSummarydata?.SellerDetails?.Address
                                ?.AddressLine
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> GSTIN: </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.SellerDetails?.GSTIN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> State: </Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            position: "relative",
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.SellerDetails?.Address?.State}
                          </Typography>
                          <Typography
                            sx={{
                              ...TextStyleTwo,
                              textAlign: "right",
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            Code: {OrderSummarydata?.SellerDetails?.StateCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> CUID: </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.Seller_CUID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
                <Box sx={{ width: "100%", mx: "auto", p: 2 }}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            width: "90px",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Buyer Name
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {" "}
                            {OrderSummarydata?.BuyerDetails?.BuyerCompanyName}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}>
                            {" "}
                            Address:{" "}
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {
                              OrderSummarydata?.BuyerDetails?.Address
                                ?.AddressLine
                            }
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> GSTIN: </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.BuyerDetails?.GSTIN}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> State:</Typography>{" "}
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                            position: "relative",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {" "}
                            {OrderSummarydata?.BuyerDetails?.Address?.State}
                          </Typography>{" "}
                          <Typography
                            sx={{
                              ...TextStyleTwo,
                              textAlign: "right",
                              position: "absolute",
                              right: "0px",
                              top: "0px",
                            }}
                          >
                            Code: {OrderSummarydata?.BuyerDetails?.StateCode}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTitle}> CUID: </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            p: 0.5,
                            borderBottom: "none",
                          }}
                        >
                          <Typography sx={TextStyleTwo}>
                            {OrderSummarydata?.Buyer_CUID}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
            {OrderSummarydata?.BuyerRequestedAddress ? (
              <Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "21px",
                    background: "#2261A2",
                    borderRadius: "3px 0px 0px 3",
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      alignContent: "center",
                      height: "100%",
                      borderRight: "1px solid #F3F2F3",
                    }}
                  >
                    <Typography
                      sx={{
                        ...CommongTextStyle,
                        fontSize: "10px",
                        color: "white",
                      }}
                    >
                      Buyer Ship To / Delivery Location Details Below
                    </Typography>
                  </Box>
                </Box>

                <Table
                  sx={{
                    p: 0,
                    border: "1px solid #cdcdcd",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "none",
                    borderRadius: "0px 0px 10px 10px",
                    width: "100%",
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={AddressTextStyleTitle}>
                          {" "}
                          State:{" "}
                          {OrderSummarydata?.BuyerRequestedAddress?.State}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={AddressTextStyleTitle}>
                          {" "}
                          City: {OrderSummarydata?.BuyerRequestedAddress?.City}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          p: 0.5,
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={AddressTextStyleTitle}>
                          {" "}
                          Pincode:{" "}
                          {OrderSummarydata?.BuyerRequestedAddress?.PinCode}
                        </Typography>
                      </TableCell>
                      <TableCell
                        sx={{
                          width: "auto",
                          maxWidth: "300px",
                          borderBottom: "none",
                        }}
                      >
                        <Typography sx={AddressTextStyleTitle}>
                          {" "}
                          Address:{" "}
                          {OrderSummarydata?.BuyerRequestedAddress?.Address}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            ) : null}
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: "100%",
                mx: "auto",
              }}
            >
              <Table sx={{ minWidth: "700px" }} aria-label="customized table">
                <TableHead>
                  <TableRow sx={{ height: "42px", bgcolor: "#2261A2" }}>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}> Sr. No.</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={2}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>
                        Product / Service Description
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>HSN Code</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>QTY</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>Rate</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>Amount</Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography sx={TableTextStyle}>Taxable Value</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "150px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                            }}
                          >
                            <Typography sx={TableTextStyle}>IGST</Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "150px",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                            }}
                          >
                            <Typography sx={TableTextStyle}>CGST</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "150px",
                              p: 0,
                              height: "35px",
                            }}
                          >
                            <Typography sx={TableTextStyle}>SGST</Typography>
                          </TableCell>
                        </TableRow>
                      )}

                      {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                              width: "25%",
                            }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>%</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                            }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>Rs.</Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            sx={{
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                              width: "25%",
                            }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>%</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "35px",
                            }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>Rs.</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "25%",
                              borderRight: "1px solid #CDCDCD",
                              p: 0,
                              height: "100%",
                            }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>%</Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ p: 0, height: "100%", width: "25%" }}
                          >
                            {" "}
                            <Typography sx={TableTextStyle}>Rs.</Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        bgcolor: "#2261A2",
                        width: "170px",
                        borderLeft: "1px solid #CDCDCD",
                      }}
                    >
                      <Typography sx={TableTextStyle}>Total</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {OrderSummarydata?.ProductData?.map((row, idx) => {
                    let TotalSec = row?.TotalSec;

                    return (
                      <React.Fragment key={idx}>
                        <TableRow
                          sx={{
                            height: "42px",
                            backgroundColor: "#F7F7F7",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {idx + 1}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="left"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                fontSize: "14px",
                                width: "300px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {row?.ProductName}
                            </Typography>

                            {row?.TotalSec && (
                              <Typography>
                                {row?.ProductTypeId === "MediaOffline" ||
                                row?.ProductTypeId === "News Papers / Magazines"
                                  ? `(${row?.TimelineToBought} ${row?.Timeline})`
                                  : `(${TotalSec * 10} sec) (${
                                      row?.TimelineToBought
                                    } ${row?.Timeline})`}
                              </Typography>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {row?.HSN}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator Price={row?.ProductQuantity} />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator
                                Price={
                                  row?.ProductTypeName === "Media"
                                    ? row?.DiscountedPrice
                                    : row?.PricePerUnitWithoutGST
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator
                                Price={row?.TotalPriceWithoutGST}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              {" "}
                              <CommaSeprator
                                Price={row?.TotalPriceWithoutGST}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              p: 0,
                              maxWidth: "200px",
                              mx: "auto",
                            }}
                            align="center"
                          >
                            {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {row?.GST}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={row?.TotalGSTAmount}
                                    />
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {row?.GST / 2}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={row?.TotalGSTAmount / 2}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    {row?.GST / 2}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  align="center"
                                  colSpan={2}
                                  sx={{
                                    width: "150px",
                                    borderRight: "1px solid #CDCDCD",
                                  }}
                                >
                                  <Typography sx={TableBottomtext}>
                                    <CommaSeprator
                                      Price={row?.TotalGSTAmount / 2}
                                    />
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={1}
                            rowSpan={1}
                            sx={{ borderRight: "1px solid #CDCDCD" }}
                          >
                            <Typography sx={TableBottomtext}>
                              <CommaSeprator
                                Price={row?.TotalPriceWithGSTInBXI}
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {row?.AdditionCost?.AdditionCostArray?.map(
                          (res, index) => {
                            let GstOfAdCost = Number(res?.AdCostGST);
                            return (
                              <TableRow
                                sx={{
                                  height: "25px",
                                  padding: 0,
                                  margin: 0,
                                }}
                                key={index}
                              >
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                ></TableCell>
                                <TableCell
                                  align="left"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                    px: 1,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      width: "300px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    {" "}
                                    {res?.ReasonOfCost} {"  "}({" "}
                                    {res?.AdCostApplicableOn === "PerUnit"
                                      ? "Per Unit"
                                      : " All"}{" "}
                                    ){" "}
                                    {res?.currencyType === "₹" ? (
                                      "₹"
                                    ) : (
                                      <img
                                        src={BxiCoin}
                                        style={{
                                          width: "20px",
                                          height: "auto",
                                        }}
                                      />
                                    )}{" "}
                                  </Typography>

                                  {row?.TotalSec && (
                                    <Typography>
                                      {row?.ProductTypeId === "MediaOffline" ||
                                      row?.ProductTypeId ===
                                        "News Papers / Magazines"
                                        ? `(${row?.TimelineToBought} ${row?.Timeline})`
                                        : `(${TotalSec * 10} sec) (${
                                            row?.TimelineToBought
                                          } ${row?.Timeline})`}
                                    </Typography>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography>{res?.AdCostHSN}</Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography>-</Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography>
                                    <CommaSeprator
                                      Price={res?.PriceWithoutGST}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography>
                                    <CommaSeprator
                                      Price={res?.TotalTaxableAmount}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography>
                                    <CommaSeprator
                                      Price={res?.TotalTaxableAmount}
                                    />
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    maxWidth: "200px",
                                    mx: "auto",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                  align="center"
                                >
                                  {OrderSummarydata &&
                                  OrderSummarydata?.IsIGST ? (
                                    <TableRow>
                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography>{GstOfAdCost}</Typography>
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography>
                                          {res?.AdCostApplicableOn === "PerUnit"
                                            ? Math.round(
                                                res?.PriceWithoutGST *
                                                  row?.ProductQuantity *
                                                  (GstOfAdCost / 100)
                                              )
                                            : Math.round(
                                                res?.PriceWithoutGST *
                                                  (GstOfAdCost / 100)
                                              )}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    <TableRow>
                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography>
                                          {GstOfAdCost / 2}
                                        </Typography>
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography>
                                          {res?.AdCostApplicableOn === "PerUnit"
                                            ? Math.round(
                                                (res?.PriceWithoutGST *
                                                  row?.ProductQuantity *
                                                  (GstOfAdCost / 100)) /
                                                  2
                                              )
                                            : Math.round(
                                                (res?.PriceWithoutGST *
                                                  (GstOfAdCost / 100)) /
                                                  2
                                              )}
                                        </Typography>
                                      </TableCell>
                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography>
                                          {GstOfAdCost / 2}
                                        </Typography>
                                      </TableCell>

                                      <TableCell
                                        align="center"
                                        colSpan={2}
                                        sx={{
                                          padding: 0,
                                          width: "150px",
                                          borderRight: "1px solid #CDCDCD",
                                          margin: 0,
                                        }}
                                      >
                                        <Typography>
                                          {res?.AdCostApplicableOn === "PerUnit"
                                            ? Math.round(
                                                (res?.PriceWithoutGST *
                                                  row?.ProductQuantity *
                                                  (GstOfAdCost / 100)) /
                                                  2
                                              )
                                            : Math.round(
                                                (res?.PriceWithoutGST *
                                                  (GstOfAdCost / 100)) /
                                                  2
                                              )}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  colSpan={1}
                                  rowSpan={1}
                                  sx={{
                                    borderRight: "1px solid #CDCDCD",
                                    padding: 0,
                                    margin: 0,
                                    height: "10px",
                                  }}
                                >
                                  <Typography>
                                    <CommaSeprator
                                      Price={
                                        row?.ProductTypeName === "Media"
                                          ? TotalTokanAmount
                                          : res?.TotalWithGst
                                      }
                                    />
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      </React.Fragment>
                    );
                  })}
                  <TableRow
                    sx={{
                      height: "42px",
                      backgroundColor: "#F7F7F7",
                    }}
                  >
                    <TableCell
                      align="center"
                      colSpan={3}
                      rowSpan={3}
                      sx={{
                        borderRight: "1px solid #CDCDCD",
                        background: "#156DB6",
                      }}
                    >
                      <Typography sx={{ ...TableBottomtext, color: "white" }}>
                        Total
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator
                          Price={OrderSummarydata?.POTotals?.TotalQuantity}
                        />
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        {/* <CommaSeprator
                          Price={
                            OrderSummarydata?.POTotals
                              ?.TotalPricePerUnitWithoutGST +
                            OrderSummarydata?.POTotals
                              ?.TotalTaxableAdditionalCost
                          }
                        /> */}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator
                          Price={
                            OrderSummarydata?.POTotals?.TotalProductCoins +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInRupee +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInBXI
                          }
                        />
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator
                          Price={
                            OrderSummarydata?.POTotals?.TotalProductCoins +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInRupee +
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInBXI
                          }
                        />
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        p: 0,
                        maxWidth: "200px",
                        mx: "auto",
                      }}
                      align="center"
                    >
                      {OrderSummarydata && OrderSummarydata?.IsIGST ? (
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              {/* <CommaSeprator Price={totalIGSTPercentage} /> */}
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              <CommaSeprator
                                Price={
                                  OrderSummarydata?.POTotals?.TotalGstAmount
                                }
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              {/* <CommaSeprator Price={totalCSTPerCentage} /> */}
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              <CommaSeprator
                                Price={
                                  OrderSummarydata?.POTotals?.TotalGstAmount / 2
                                }
                              />
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              {/* <CommaSeprator Price={totalSGSTPercentage} /> */}
                            </Typography>
                          </TableCell>

                          <TableCell
                            align="center"
                            colSpan={2}
                            sx={{
                              width: "100px",
                              borderRight: "1px solid #CDCDCD",
                            }}
                          >
                            <Typography
                              sx={{
                                ...TableBottomtext,
                                color: "rgba(5, 5, 5, 1)",
                                opacity: 1,
                              }}
                            >
                              <CommaSeprator
                                Price={
                                  OrderSummarydata?.POTotals?.TotalGstAmount / 2
                                }
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={1}
                      rowSpan={1}
                      sx={{ borderRight: "1px solid #CDCDCD" }}
                    >
                      <Typography
                        sx={{
                          ...TableBottomtext,
                          color: "rgba(5, 5, 5, 1)",
                          opacity: 1,
                        }}
                      >
                        <CommaSeprator
                          Price={OrderSummarydata?.POTotals?.GrandTotal}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                mx: "auto",
                border: "1px solid #cdcdcd",
              }}
            >
              <Box
                sx={{
                  width: "60%",
                  height: "100%",
                  borderRight: "1px solid #CDCDCD",
                }}
              >
                <Box
                  sx={{
                    background: "#2261A2",
                    borderRadius: "3px 0px 0px 3",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={TableTextStyle}>
                    Total amount in words
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    minHeight: "150px",
                  }}
                >
                  <Typography
                    sx={{
                      ...TableTotaltextStyle,
                      fontWeight: 600,
                      fontSize: "13px",
                    }}
                  >
                    {OrderSummarydata?.POTotals && (
                      <NumberToWord
                        number={OrderSummarydata?.POTotals?.GrandTotal}
                      />
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "40%",
                }}
              >
                <Table
                  sx={{
                    height: "150px",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <TableRow sx={{ width: "100%", display: "flex" }}>
                    <TableCell
                      sx={{
                        pl: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                      colSpan={1}
                    >
                      <Typography sx={TableTotaltextStyle}>
                        Total Amount to be paid in BXI Coins
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                      align="right"
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator
                          Price={
                            OrderSummarydata?.POTotals
                              ?.TotalCoinsWithAdditionalCost
                          }
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ width: "100%", display: "flex" }}>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                    >
                      <Typography sx={TableTotaltextStyle}>
                        Total GST to be paid in INR
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                      align="right"
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator
                          Price={
                            OrderSummarydata?.POTotals?.TotalGstAmount +
                            OrderSummarydata?.POTotals?.TotalAdCostGstAmount
                          }
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ width: "100%", display: "flex" }}>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                    >
                      <Typography sx={TableTotaltextStyle}>
                        Additional cost to be paid in INR
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                      align="right"
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator
                          Price={Number(
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInRupee
                          )}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ width: "100%", display: "flex" }}>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                    >
                      <Typography sx={TableTotaltextStyle}>
                        Additional cost to be paid in BXI Coins
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderBottom: "none",
                        width: "50%",
                      }}
                      align="right"
                    >
                      <Typography sx={TableBottomtext}>
                        <CommaSeprator
                          Price={Number(
                            OrderSummarydata?.POTotals
                              ?.TotalAdditionalCostWtihoutGSTInBXI
                          )}
                        />
                      </Typography>
                    </TableCell>
                  </TableRow>
                </Table>
                <Box
                  sx={{
                    background: "#2261A2",
                    borderRadius: "3px 0px 0px 3",
                    display: "flex",
                    justifyContent: "space-between",
                    px: 1,
                  }}
                >
                  <Typography sx={TableTextStyle}>
                    Total amount after Tax :
                  </Typography>
                  <Typography sx={TableTextStyle}>
                    <CommaSeprator
                      Price={OrderSummarydata?.POTotals?.GrandTotal}
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                borderTop: "1px solid #CDCDCD",
                height: "22px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  ...TextLastStyle,
                  fontSize: "12px",
                  textAlign: "center",
                  fontWeight: 400,
                }}
              >
                This is a System Generated Purchase Order
              </Typography>{" "}
            </Box>
          </Box>
          <Box
            sx={{
              justifyContent: "space-around",
              border: "1px solid #cdcdcd",
              alignItems: "center",
              flexDirection: "row",
              minHeight: "150px",
              display: "flex",
              height: "auto",
              width: "95%",
              mx: "auto",
              mt: 2,
              py: 1,
            }}
          >
            {OrderSummarydata?.ProductData &&
              OrderSummarydata?.ProductData?.map((item, idx) => {
                return (
                  <Box
                    sx={{
                      minHeight: "150px",
                      height: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        ...TableBottomtext,
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        fontSize: "14px",
                        color: "black",
                        width: "100%",
                      }}
                    >
                      Bought Dates for : {item?.ProductName}
                    </Typography>
                    <Typography
                      sx={{
                        ...TableBottomtext,
                        fontSize: "14px",
                      }}
                    >
                      {GetTimeline(item?.Timeline, item?.BoughtDates).map(
                        (el, idx) => {
                          if (item.Timeline === "Day") {
                            return (
                              <Typography
                                sx={{
                                  ...TableBottomtext,
                                  textAlign: "center",
                                }}
                              >
                                {idx + 1}).{" "}
                                {new Date(el.startDate).toDateString()}
                              </Typography>
                            );
                          } else {
                            return (
                              <>
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    textAlign: "center",
                                  }}
                                >
                                  {idx + 1}). Start Date :{" "}
                                  {new Date(el.startDate).toDateString()} -- End
                                  Date : {new Date(el.endDate).toDateString()}
                                </Typography>
                                <Typography
                                  sx={{
                                    ...TableBottomtext,
                                    textAlign: "center",
                                  }}
                                ></Typography>
                              </>
                            );
                          }
                        }
                      )}
                    </Typography>
                  </Box>
                );
              })}
          </Box>
          {OrderSummarydata?.ProductData?.map((item, idx) => {
            const productMedia = OrderSummarydata?.MediaContentUrls?.find(
              (media) => media.productId === item?.ProductId
            );

            return (
              productMedia && (
                <BuyerMediaViewContent
                  productMedia={productMedia}
                  productUrl={productMedia?.url}
                  productName={item?.ProductName}
                />
              )
            );
          })}
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: 3,
            pb: 3,
          }}
        >
          <Box
            sx={{
              maxWidth: "360px",
              mx: "auto",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              height: "100%",
            }}
          >
            <Button variant="outlined" sx={btnsx} onClick={handlePrint}>
              <Box component={"img"} src={print} sx={ButtonIconStyle} />{" "}
              &nbsp;Print
            </Button>

            <Button variant="outlined" sx={btnsx} onClick={handlePrint}>
              <Box component={"img"} src={download} sx={ButtonIconStyle} />{" "}
              &nbsp;Download
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PurchaseOrderDetails;

const TextLastStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  // lineHeight: "20px",
  color: "#000000",
  opacity: 1,
};

const btnsx = {
  width: "100%",
  height: "51px",
  maxWidth: "168px",
  borderRadius: "10px",
  borderColor: "#156DB6",
  color: "#156DB6",
  textTransform: "none",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  cursor: "pointer",
};

const ButtonIconStyle = {
  width: {
    xl: "24px",
    lg: "24px",
    md: "20px",
    sm: "20px",
    xs: "18px",
  },
  height: "auto",
};

const CommongTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const TextStyleTwo = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "rgba(80, 80, 80, 1)",
  opacity: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
};

const TextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "rgba(80, 80, 80, 1)",
  opacity: 1,
  width: "120px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const AddressTextStyleTitle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "20px",
  textAlign: "left",
  color: "#505050",
  opacity: 1,

  width: "300px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

const TableTextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#FFFFFF",
};

const TableBottomtext = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  opacity: 1,
  color: "rgba(80, 80, 80, 1)",
};

const TableTotaltextStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "20px",
  color: "#050505",
  opacity: 1,
};

const ButtonStyleForView = {
  width: "100%",
  height: "30px",
  width: "auto",
  borderRadius: "6px",
  bgcolor: "#2261A2",
  textTransform: "none",
  color: "#fff",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  color: "#FFFFFF",
  textAlign: "center",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  curso0r: "pointer",
  my: 1,
  border: "1px solid #fff",
  "&:hover": {
    color: "#2261A2",
    border: "1px solid #2261A2",
  },
};
