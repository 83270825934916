import axios from "axios";
import {
  All_Company_Request,
  All_Company_Success,
  All_Company_Failed,
} from "../../../redux/reduser/Company&User/AllCompanys";
import { search } from "../../../components/Layouts/AdminHeader";
import { GetTokenFunc } from "../../../utils/GetToken";

import {
  Category,
  CompanyStatus,
  Membership,
  Page,
  SelectedCompany,
  DateFilter,
} from "../../../components/Company/CompanyListing";

export const AllCompanysDetails =
  (category, status, membership) => async (dispatch) => {
    try {
      dispatch({ type: All_Company_Request.toString() });

      const { data } = await axios.get(
        `/api/v1/admin/GetAllCompanys?CompanyCategoty=${Category}&CompanyStatus=${CompanyStatus}&Membership=${Membership}&SelectedCompany=${SelectedCompany}&DateFilter=${DateFilter}&page=${Page}&Search=${search}`,
        {
          withCredentials: true,
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      );

      dispatch({
        type: All_Company_Success.toString(),
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: All_Company_Failed.toString(),
        payload: error?.response?.data?.message,
      });
    }
  };
