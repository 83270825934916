import { createSlice } from "@reduxjs/toolkit";

export const GetAllTotalProductCount = createSlice({
    name: "Products",
    initialState: {
        ProductCounts: [],
    },
    reducers: {
        product_count_Request: (state) => {
            state.loading = true;
            state.ProductCounts = [];
        },
        product_count_Success: (state, action) => {
            state.loading = false;
            state.ProductCounts = action.payload;
        },
        product_count_Fail: (state, action) => {
            state.loading = false;
            state.ProductCounts = action.payload;
        }
    }
})

export const { product_count_Request, product_count_Success, product_count_Fail } = GetAllTotalProductCount.actions

export default GetAllTotalProductCount.reducer;