import axios from "axios";
import { useQuery, useMutation } from "react-query";
import { GetTokenFunc } from "../../utils/GetToken";

const UpdateInvoice = async (data) => {
  await axios.put(
    `/api/v1/admin/wallet/update-funds-reuqests/${data.id}`,
    { ...data },
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
  // console.log('data', data);
};
export const useUpdateInvoice = () => {
  return useMutation(UpdateInvoice);
};
