import React, { useState } from "react";
import "./Landing.Module.css";
import { Paper } from "@mui/material";

import TopProductsIcon from "../../assets/top-products.svg";
import FinacialgraphIcon from "../../assets/Finacialgraph.svg";
import GenieIcon from "../../assets/genie.svg";
import MembershipIcon from "../../assets/membership.svg";
import CreditLineIssueIcon from "../../assets/credit-line-issue.svg";
import TotalSignupsIcon from "../../assets/total-signups.svg";
import ConversionrateIcon from "../../assets/conversion-rate.svg";
import TotalvisitorsIcon from "../../assets/total-visitors.png";
import OrdersfulfilledIcon from "../../assets/order-fulfilled.svg";
import TotalTransectionsIcon from "../../assets/total-transections.svg";

import TotalTransections from "./TotalTransections";
import Transections from "./Transections";
import TotalSignups from "./TotalSignups";
import HeatmapGraph from "./HeatmapGraph";
import Conversionrates from "./Conversionrates";
import Ordersfullfilled from "./Ordersfullfilled";
import Memberships from "./Memberships";
import TopProduct from "./TopProduct";
import CreditLineIssue from "./CreditLineIssue";
import BXIGenie from "./BXIGenie";

const Landing = () => {
  const graphsToShow = {
    TotalTransectionsGraph: <TotalTransections />,
    Transections: <Transections />,
    TotalSignupsGraph: <TotalSignups />,
    TotalVisitors: <HeatmapGraph />,
    ConversionsRateGraph: <Conversionrates />,
    OrdersfullfilledGraph: <Ordersfullfilled />,
    MembershipsGraph: <Memberships />,
    TopProductsGraph: <TopProduct />,
    BXIGenieGraph: <BXIGenie />,
    CreditLineIssue: <CreditLineIssue />,
  };

  const [currentChart, setCurrentChart] = useState("TotalTransectionsGraph");

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "620px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <div className="custom-container">
        <div className="custom-rightbar">
          <div className="inner-left">
            <div className="inner-left-top">{graphsToShow[currentChart]}</div>
            <div className="inner-left-bottom">
              {/* <a className="disabled-link">
                <img src={TopProductsIcon} />
              </a> */}
              {currentChart != "TopProductsGraph" && (
                <a onClick={() => setCurrentChart("TopProductsGraph")}>
                  <img src={TopProductsIcon} />
                </a>
              )}
              {currentChart != "BXIGenieGraph" && (
                <a onClick={() => setCurrentChart("BXIGenieGraph")}>
                  <img src={GenieIcon} />
                </a>
              )}
              {/* <a className="disabled-link">
                <img src={GenieIcon} />
              </a> */}
            </div>
          </div>

          <div className="inner-right">
            {currentChart != "TotalTransectionsGraph" && (
              <a onClick={() => setCurrentChart("TotalTransectionsGraph")}>
                <img src={FinacialgraphIcon} />
              </a>
            )}

            {currentChart != "Transections" && (
              <a onClick={() => setCurrentChart("Transections")}>
                <img src={TotalTransectionsIcon} />
              </a>
            )}

            {currentChart != "TotalSignupsGraph" && (
              <a onClick={() => setCurrentChart("TotalSignupsGraph")}>
                <img src={TotalSignupsIcon} />
              </a>
            )}
            {currentChart != "ConversionsRateGraph" && (
              <a onClick={() => setCurrentChart("ConversionsRateGraph")}>
                <img src={ConversionrateIcon} />
              </a>
            )}
            {currentChart != "TotalVisitors" && (
              <a onClick={() => setCurrentChart("TotalVisitors")}>
                <img src={TotalvisitorsIcon} />
              </a>
            )}
            {currentChart != "OrdersfullfilledGraph" && (
              <a onClick={() => setCurrentChart("OrdersfullfilledGraph")}>
                <img src={OrdersfulfilledIcon} />
              </a>
            )}
            {currentChart != "MembershipsGraph" && (
              <a onClick={() => setCurrentChart("MembershipsGraph")}>
                <img src={MembershipIcon} />
              </a>
            )}
            {currentChart != "CreditLineIssue" && (
              <a onClick={() => setCurrentChart("CreditLineIssue")}>
                <img src={CreditLineIssueIcon} />
              </a>
            )}
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default Landing;
