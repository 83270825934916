import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import * as Styles from "../../common/Styles";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useGetEscrowData } from "../../Hooks/ExternalApiData/useGetExternalApiData";
import { useNavigate } from "react-router-dom";
import PageLoader from "../CommonActions/PageLoader";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CheckStatusComp from "./CheckEscrowStatus";
import Searchbar from "../../common/Searchbar";
import EmptyData from "../../assets/Recyclebin/recyclebin.png";

function PineLabsData() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  // State to hold data received from child component
  const [searchData, setSearchData] = useState("");

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const navigate = useNavigate();
  const {
    data: getEscrowData,
    isError: getEscrowDataError,
    isLoading: getEscrowDataLoading,
    refetch: getEscrowDataRefetch,
  } = useGetEscrowData(currentPage, searchData);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  useEffect(() => {
    getEscrowDataRefetch();
  }, [currentPage, searchData]);

  const escrowFeeStatement = getEscrowData?.escrowFeeStatement;

  useEffect(() => {
    if (getEscrowData && getEscrowData?.totalPages !== undefined) {
      setInitialPaginationCount(getEscrowData?.totalPages);
    }
  }, [getEscrowData?.totalPages]);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          className="page-heading-section"
          style={{
            justifyContent: "start",
            gap: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/admindashboard/allexternalapi");
            }}
          >
            <KeyboardBackspaceIcon
              onClick={() => {
                navigate(-1);
              }}
            />
          </Box>
          <Box className="page-heading">
            <h5>All Escrow(s) Data</h5>
            <Typography>View individual payment details and status.</Typography>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "97%",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box sx={buddge}>
            <Typography sx={textBudge}>
              Total :{getEscrowData?.totalCount || 0}
            </Typography>
          </Box>
          <Searchbar onSendData={handleChildData} />
        </Box>
        <Box
          sx={{
            background: "rgba(250, 251, 252, 1)",
            p: 0,
            mt: 0,
            width: "100%",
          }}
        >
          {getEscrowDataLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                ml: "500px",
                mt: 20,
              }}
            >
              <PageLoader />
            </Box>
          ) : getEscrowData?.data?.length <= 0 ? (
            <Box
              sx={{
                width: "100%",
                mx: "auto",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Box
                component="img"
                src={EmptyData}
                sx={{
                  height: "auto",
                  width: "200px",
                  mx: "auto",
                  mt: 5,
                }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                maxWidth: "80vw",
                minWidth: "80vw",
                height: "auto",
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  width: "100%",
                }}
              >
                <TableContainer sx={TableContainerStyle}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>No.</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>
                            Buyer Payment Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>
                            Order Date
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>
                            {" "}
                            Buyer Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>
                            {" "}
                            Seller Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>
                            INR Collected
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>
                            TDS Amount
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>
                            Total Coins
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>
                            Escrow Fee Amount
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>Deal Id</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>Doc. Id</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>Order Id</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>Status</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>
                            Escrow Trusty Status
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={TableTitleStyle}>
                            Escrow Date
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getEscrowData?.data?.map((x, i) => {
                        let TotalAdditionalCostWtihoutGSTInBXI = 0;
                        let TotalPriceWithoutGST = 0;

                        x?.OrderData?.PurchaseOrderData?.ProductData?.map(
                          (el, idx) => {
                            if (x?.OrderData?.IsVoucher) {
                              TotalAdditionalCostWtihoutGSTInBXI +=
                                el?.AdditionalCost
                                  ?.TotalAdditionalCostWtihoutGSTInBXI;
                            } else {
                              TotalAdditionalCostWtihoutGSTInBXI +=
                                el?.AdditionCost
                                  ?.TotalAdditionalCostWtihoutGSTInBXI;
                            }
                            TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
                          }
                        );

                        let TotalTaxAmount =
                          (x?.OrderData?.IsMedia || x?.OrderData?.IsVoucher
                            ? 0
                            : x?.OrderData?.INRDetails
                                ?.totalTransportationCostGST
                            ? x?.OrderData?.INRDetails
                                ?.totalTransportationCostGST
                            : 0) +
                          (x?.OrderData?.IsMedia
                            ? x?.OrderData?.INRDetails?.TotalGstInrOfAdCost
                            : x?.OrderData?.INRDetails?.totalGstInrOfAdCost) +
                          (x?.OrderData?.IsMedia || x?.OrderData?.IsVoucher
                            ? 0
                            : x?.OrderData?.INRDetails?.packagingCostGSTValue
                            ? x?.OrderData?.INRDetails?.packagingCostGSTValue
                            : 0) +
                          (x?.OrderData?.IsMedia
                            ? x?.OrderData?.INRDetails?.BxiCommisionGST
                            : x?.OrderData?.INRDetails?.bxiCommisionGST) +
                          (x?.OrderData?.IsMedia || x?.OrderData?.IsVoucher
                            ? 0
                            : x?.OrderData?.INRDetails?.InsuranceCostGstValue
                            ? x?.OrderData?.INRDetails?.InsuranceCostGstValue
                            : 0) +
                          (x?.OrderData?.IsMedia
                            ? x?.OrderData?.INRDetails?.TotalGstAmount
                            : x?.OrderData?.INRDetails?.totalGstAmount);

                        return (
                          <TableRow>
                            <TypographyComponent>{i + 1}</TypographyComponent>
                            <TypographyComponent width={150}>
                              {x?.BuyerPaymentDate
                                ? formatDate(x?.BuyerPaymentDate)
                                : "--"}
                            </TypographyComponent>
                            <TypographyComponent width={100}>
                              {x?.Date ? formatDate(x?.Date) : "--"}
                            </TypographyComponent>
                            <TypographyComponent width={250}>
                              {x?.OrderData?.PurchaseOrderData?.BuyerDetails
                                ?.BuyerCompanyName
                                ? x?.OrderData?.PurchaseOrderData?.BuyerDetails
                                    ?.BuyerCompanyName
                                : "--"}
                            </TypographyComponent>
                            <TypographyComponent width={250}>
                              {x?.OrderData?.PurchaseOrderData?.SellerDetails
                                ?.SellerCompanyName
                                ? x?.OrderData?.PurchaseOrderData?.SellerDetails
                                    ?.SellerCompanyName
                                : "--"}
                            </TypographyComponent>
                            <TypographyComponent width={100}>
                              {x?.OrderData?.INRDetails?.TotalInrToPay
                                ? x?.OrderData?.INRDetails?.TotalInrToPay.toFixed(
                                    2
                                  )
                                : "--"}
                            </TypographyComponent>
                            <TypographyComponent width={100}>
                              {/* {x?.OrderData?.INRDetails
                                ? TotalTaxAmount?.toFixed(2)
                                : "--"} */}
                              {x?.OrderData?.INRDetails
                                ? x?.OrderData?.INRDetails?.TDS?.toFixed(2)
                                : "--"}
                            </TypographyComponent>
                            <TypographyComponent width={100}>
                              {(
                                (TotalAdditionalCostWtihoutGSTInBXI || 0) +
                                (TotalPriceWithoutGST || 0) -
                                x?.OrderData?.INRDetails?.TDS
                              ).toFixed(2)}
                            </TypographyComponent>
                            <TypographyComponent width={100}>
                              {escrowFeeStatement
                                ?.filter(
                                  (item) =>
                                    item?.txn_id ===
                                    x?.OrderData?.escrow_withDraw_toBank?.txnid
                                )
                                .reduce((idx, item) => {
                                  return <span>{item?.amount}</span>;
                                }, "--")}
                            </TypographyComponent>
                            <TypographyComponent width={100}>
                              {x?.EscroId ? x?.EscroId : "--"}
                            </TypographyComponent>
                            <TypographyComponent width={100}>
                              {x?.escrow_doc_id ? x?.escrow_doc_id : "--"}
                            </TypographyComponent>
                            <TypographyComponent width={150}>
                              {x?.OrderId ? x?.OrderId : "--"}
                            </TypographyComponent>
                            <TypographyComponent>
                              <Box
                                sx={{
                                  background:
                                    x?.Status === "Release Called"
                                      ? "#D9FFE9"
                                      : x?.Status === "Payment Done"
                                      ? "#FFEFEF"
                                      : "#FFEFEF",
                                  borderRadius: 1.5,
                                  width: "120px",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color:
                                      x?.Status === "Release Called"
                                        ? "#27AE60"
                                        : x?.Status === "Payment Done"
                                        ? "#F2994A"
                                        : x?.Status === "N/A"
                                        ? "var(--Red, #EB5757)"
                                        : "var(--Red, #EB5757)",
                                    fontFamily: "poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    lineHeight: "30px",
                                  }}
                                >
                                  {x?.Status}
                                </Typography>
                              </Box>
                            </TypographyComponent>
                            <TypographyComponent width={150}>
                              <Box
                                sx={{
                                  background: "#e3e7f6",
                                  borderRadius: 1.5,
                                  minWidth: "120px",
                                  width: "auto",
                                }}
                              >
                                <Typography
                                  align="center"
                                  sx={{
                                    color: "#445fd2",
                                    fontFamily: "poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    lineHeight: "30px",
                                    width: "100%",
                                  }}
                                >
                                  <CheckStatusComp
                                    escrowId={
                                      x?.OrderData?.escrow_withdraw_toBank
                                        ?.txnid ||
                                      x?.OrderData?.escrow_withDraw_toBank
                                        ?.txnid
                                    }
                                  />
                                </Typography>
                              </Box>
                            </TypographyComponent>
                            <TypographyComponent>
                              <Box
                                sx={{
                                  borderRadius: 1.5,
                                  minWidth: "120px",
                                  width: "auto",
                                }}
                              >
                                <Typography
                                  align="center"
                                  sx={{
                                    fontFamily: "poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    fontStyle: "normal",
                                    width: "100%",
                                  }}
                                >
                                  <CheckStatusComp
                                    escrowId={
                                      x?.OrderData?.escrow_withdraw_toBank
                                        ?.txnid ||
                                      x?.OrderData?.escrow_withDraw_toBank
                                        ?.txnid
                                    }
                                    date={true}
                                  />
                                </Typography>
                              </Box>
                            </TypographyComponent>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    mx: "auto",
                  }}
                  mt={2}
                >
                  <Stack>
                    <StyledPagination
                      size="medium"
                      count={initialPaginationCount}
                      color="primary"
                      showFirstButton
                      showLastButton
                      page={currentPage}
                      onChange={handlePageChange}
                      strokeWidth={currentPage}
                    />
                  </Stack>
                </Box>
              </Paper>
            </Box>
          )}
        </Box>
      </Paper>
    </React.Fragment>
  );
}

export default PineLabsData;
const buddge = {
  display: "inline-flex",
  height: 40,
  padding: "16px 20px 16px 15px",
  justifyContent: "center",
  alignItems: "center",
  gap: 0,
  borderRadius: 10,
  border: "1px solid #E6E9EE",
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};

const TableContainerStyle = {
  minHeight: "500px",
  maxHeight: "500px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#6B7A99",
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#000",
};

const TypographyComponent = ({ children, ...props }) => {
  return (
    <TableCell>
      <Typography sx={{ ...TextStyleCommon, width: props.width }}>
        {children}
      </Typography>
    </TableCell>
  );
};
