import axios from "axios";
import React, { useEffect, useState } from "react";

const GetBalanceComp = (props) => {
  const [WalletData, setWalletData] = useState();
  const GetStatus = async (props) => {
    try {
      const response = await axios.get(
        `/wallet/get_wallet_by_id/${props?.id}`,
        {
          withCredentials: true,
        }
      );
      setWalletData(response?.data?.body);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (props?.id) {
      GetStatus(props);
    }
  }, [props?.id]);

  return props?.Sell ? (
    <div>
      {WalletData?.total_sold_balance
        ? WalletData?.total_sold_balance.toFixed(2)
        : "N/A"}
    </div>
  ) : props?.Purchase ? (
    <div>
      {WalletData?.total_purchased_balance
        ? WalletData?.total_purchased_balance.toFixed(2)
        : "N/A"}
    </div>
  ) : (
    "N/A"
  );
};

export default GetBalanceComp;
