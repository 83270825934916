import { createSlice } from "@reduxjs/toolkit";

export const GetTotalAmount = createSlice({
    name: "Amount",
    initialState: {
        TotalAmount: []
    },
    reducers: {
        TotalAmount_Request: (state) => {
            state.loading = true;
            state.TotalAmount = [];
        },
        TotalAmount_Success: (state, action) => {
            state.loading = false;
            state.TotalAmount = action.payload;
        },
        TotalAmount_Fail: (state, action) => {
            state.loading = false;
            state.TotalAmount = action.payload;
        }
    }
})

export const { TotalAmount_Fail, TotalAmount_Request, TotalAmount_Success } = GetTotalAmount.actions

export default GetTotalAmount.reducer
