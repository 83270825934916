import React, { useState, useEffect } from 'react';
import { Box, Modal, Typography, Button, Input } from '@mui/material';
import { useUpdateWallet } from '../../Hooks/Invoice/useUpdateWallet';

function WalletEdit(props) {
  const {
    open,
    onClose,
    onSave,
    text,
    ButtonLabel,
    cancelButtonLabel,
    fieldOne,
    productName,
    initialProductData,
    refetch,
  } = props;

  const {
    mutate: updateWalletData,
    error: updateWalletError,
    isLoading: updateWalletIsLoading,
    isSuccess: updateWalletIsSuccess,
    reset: updateWalletReset,
  } = useUpdateWallet();

  const [inputValues, setInputValues] = useState({
    fieldOne: initialProductData?.amount,
  });

  const handleInputChange = (field, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
  };

  const handleSaveClick = async () => {
    onSave({
      ...initialProductData,
      amount: Number(inputValues.fieldOne),
    });

    const updatedWalletData = {
      id: initialProductData._id,
      amount: Number(inputValues.fieldOne),
    };

    await updateWalletData(updatedWalletData, {
      onSuccess: () => {
        refetch();
        updateWalletReset();
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            maxWidth: '80%',
            textAlign: 'center',
            borderRadius: 2,
          }}
        >
          <Typography>{text}</Typography>

          <Typography sx={InputHeader}> {productName} </Typography>

          <Box sx={MainFieldBox}>
            <Box sx={SubFieldBox}>
              <Typography sx={InputHeader}> {fieldOne} </Typography>

              <Input
                type='number'
                sx={InputStyle}
                variant='contained'
                disableUnderline
                onWheel={numberInputOnWheelPreventChange}
                defaultValue={initialProductData?.amount}
                value={inputValues.fieldOne}
                onChange={(e) => handleInputChange('fieldOne', e.target.value)}
              />
            </Box>
            {/* <Box sx={SubFieldBox}>
              <Typography sx={InputHeader}> {fieldTwo} </Typography>
              <Input
                type="number"
                sx={InputStyle}
                variant="contained"
                disableUnderline
                onWheel={numberInputOnWheelPreventChange}
                defaultValue={initialProductData?.amount}
                value={inputValues.fieldTwo}
                onChange={(e) => handleInputChange('fieldTwo', e.target.value)}
              />
            </Box> */}
          </Box>
          <Box sx={ButtonBox}>
            <Button
              onClick={handleSaveClick}
              variant='contained'
              sx={{ mt: 2, mr: 2 }}
            >
              {ButtonLabel}
            </Button>
            <Button onClick={onClose} variant='outlined' sx={{ mt: 2 }}>
              {cancelButtonLabel}
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default WalletEdit;

const MainFieldBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  justifyContent: 'space-between',
  mt: 2,
};

const SubFieldBox = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  mt: 1,
};

const ButtonBox = {
  display: 'flex',
  gap: 1,
  justifyContent: 'space-between',
};

const InputStyle = {
  borderRadius: 4,
  width: '100%',
  background: '#F3F6F9 ',
  padding: '5px 10px',
  color: '#000',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontWeight: 500,
  fontStyle: 'normal',
};

const InputHeader = {
  fontFamily: 'Poppins',
  fontSize: 12,
  fontWeight: 500,
  fontStyle: 'normal',
  color: '#000',
};
