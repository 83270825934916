import axios from "axios";
import { GetTokenFunc } from '../../../utils/GetToken';

import { company_count_Request, company_count_Success, company_count_Fail } from '../../reduser/AdminDashboard/TotalCompanys'

export const CompanyCountOnPlateform = () => async (dispatch) => {
    try {
        dispatch({ type: company_count_Request.toString() })
        const response = await axios.get(
            `/api/v1/admin/TotalCompanyOnPlatform`, {
            headers: {
                authorization: `Bearer ${GetTokenFunc()}`
            }
        }
        );
        dispatch({
            type: company_count_Success.toString(),
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: company_count_Fail,
            payload: error.response.data.message,
        });
    }
}