import axios from "axios";
import {
  Category,
  Page,
  TypeOfSelection,
  PriceRevers,
  ProductAnalysis,
  State,
  PriceRange,
  SelectedCompany,
  DateFilter,
  ProductStatus,
} from "../../../components/Marketplace/Marketplace";
import { search } from "../../../components/Layouts/AdminHeader";
import {
  product_Fail,
  product_Request,
  product_Success,
} from "../../reduser/MarketPlace/Product-Filter";

// Get All Products
export const GetAllProduct = () => async (dispatch) => {
  try {
    dispatch({ type: product_Request.toString() });

    let Type = "";
    if (TypeOfSelection === 0) {
      Type = "Product";
    } else if (TypeOfSelection === 1) {
      Type = "Voucher";
    } else if (TypeOfSelection === 2) {
      Type = "Media";
    }

    const { data } = await axios.post(
      `AdminCompanyDetails/AllProducts?ProductCategory=${Category}&ProductType=${Type}&PriceRevers=${PriceRevers}&ProductAnalysis=${ProductAnalysis}&SelectedCompany=${SelectedCompany}&DateFilter=${DateFilter}&ProductStatus=${ProductStatus}&ProductLocationState=${State}&PriceRange=${PriceRange}&page=${Page}&Search=${search}`,
      {
        withCredentials: true,
      }
    );

    dispatch({
      type: product_Success.toString(),
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: product_Fail.toString(),
      payload: error.response.data.message,
    });
  }
};
