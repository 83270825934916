import { Typography } from "@material-ui/core";
import { Box, Button, Grid, Divider, Paper } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import invoice from "../../assets/AllRecords/invoice.png";
import truck from "../../assets/AllRecords/truck.svg";
import approvals from "../../assets/AllRecords/approvals.png";
import orders from "../../assets/AllRecords/orders.png";
import products from "../../assets/AllRecords/products.png";
import creditline from "../../assets/AllRecords/creditline.png";
import wishlist from "../../assets/AllRecords/wishlist.png";
import wantlist from "../../assets/AllRecords/wantlist.png";
import chat from "../../assets/AllRecords/chat.png";
import * as Styles from "../../common/Styles";
function AllRecords() {
  const [activePage, setActivePage] = useState("Invoice");
  const navigate = useNavigate();

  const DataArray = [
    {
      id: 1,
      name: "All Invoices",
      Img: invoice,
      page: "invoices",
      textRecords: "View & access all your Invoices here.",
      link: "/admindashboard/invoices",
    },
    {
      id: 2,
      name: "All Approvals",
      Img: approvals,
      page: "approvals",
      textRecords: "View & grant approvals for all requests.",
      link: "/admindashboard/approvals",
    },
    {
      id: 3,
      name: "All Orders",
      page: "orders",
      Img: orders,
      textRecords: "View & manage all the orders.",
      link: "/admindashboard/orders",
    },
    {
      id: 4,
      name: "Delivery",
      page: "Delivery",
      Img: truck,
      textRecords: "Check delivery details for any order.",
      link: "/admindashboard/delivery",
    },
    {
      id: 5,
      name: "Credit line",
      page: "Credit line",
      Img: creditline,
      textRecords: "View and Grant Credit line requests.",
      link: "/admindashboard/creditline",
    },
    {
      id: 6,
      name: "Wishlist",
      page: "Wishlist",
      Img: wishlist,
      textRecords: "Check the Wishlist products added by members.",
      link: "/admindashboard/wishlist",
    },
    {
      id: 7,
      name: "All Chats",
      page: "Chats",
      Img: chat,
      textRecords: "View & manage all Members chats.",
      link: "/admindashboard/messages",
    },
  ];
  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "590px",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <Box
        className="page-heading-section"
        style={{
          justifyContent: "start",
          gap: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            navigate('/admindashboard/allexternalapi');
          }}
        >
          <KeyboardBackspaceIcon
            onClick={() => {
              navigate(-1);
            }}
          />
        </Box> */}
        <Box className="page-heading">
          <h5>Important Records Page</h5>
          <Typography>
            Click on the View Details for Specific Information
          </Typography>
        </Box>
      </Box>
      {/* <Divider /> */}
      <Box
        sx={{
          width: "100%",
          height: "auto",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          alignContent: "center",
          flexWrap: "wrap",
          gap: "50px",
          mt: 4,
          background: "rgba(250, 251, 252, 1)",
          p: 5,
        }}
      >
        {DataArray?.map((data, idx) => {
          return (
            <Box sx={boxDesign}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <img src={data.Img} style={{ width: "25px", height: "auto" }} />
                <Typography sx={text}>{data.name}</Typography>
                <span
                  style={{
                    ...subText,
                  }}
                >
                  {data.textRecords}
                </span>
                <Button
                  // disabled={!data.link}
                  sx={{
                    ...btnDesign,
                    cursor: data.link ? "pointer" : "not-allowed",
                    color: data.link ? "#fff" : "#000",
                    background: data.link ? "#445FD2" : "#f5f5f5",
                    "&:hover": {
                      background: data.link ? "#445FD2" : "#f5f5f5",
                      color: data.link ? "#fff" : "#000",
                    },
                  }}
                  key={data}
                  onClick={() => {
                    setActivePage(data.page);
                    navigate(data.link);
                  }}
                >
                  View Details
                </Button>
              </Box>
            </Box>
          );
        })}
      </Box>
      {/* <Box>
        {activePage === 'invoices' ? <div> Invoice data here</div> : null}
        {activePage === 'orders' ? <div> Order data here</div> : null}
        {activePage === 'approvals' ? <div> Approval data here</div> : null}
        {activePage === 'interests' ? <div> Interest data here</div> : null}
      </Box> */}
    </Paper>
  );
}

export default AllRecords;

const MainRecordStyle = {
  width: "250px",
  background: "#fff",
  height: "120px",
  border: "1px solid #445FD2",
  borderRadius: 4,
  mb: 2,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  color: "#445FD2",
  textTransform: "none",
  "&:hover": {
    background: "#445FD2",
    color: "#fff",
  },
};
const boxDesign = {
  width: 246,
  height: 176,
  borderRadius: 2.455,
  border: "0.5px solid #848484",
  background: "#FFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const text = {
  color: "#212121",
  textAlign: "center",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const subText = {
  color: "#727272",
  textAlign: "center",
  fontFeatureSettings: "clig off, liga off",
  mr: "auto",
  ml: "auto",
  fontFamily: "Poppins",
  fontSize: 9,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "70%",
  mx: "auto",
  marginTop: 3,
};

const btnDesign = {
  width: 178,
  height: 28,
  flexShrink: 0,
  background: "rgba(68, 95, 210, 1)",
  color: "#FFF",
  fontFamily: "Poppins",
  textTransform: "none",
  fontSize: 10,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  "&:hover": {
    background: "rgba(68, 95, 210, 1)",
    color: "#FFF",
  },
};
