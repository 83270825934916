import React, { useState, useEffect } from "react";
import { Box, Modal, Typography, Button, Input } from "@mui/material";
import { useUpdateInvoice } from "../../Hooks/Invoice/useUpdateInvoice";

function CommonEdit(props) {
  const {
    open,
    onClose,
    onSave,
    text,
    ButtonLabel,
    cancelButtonLabel,
    fieldOne,
    fieldTwo,
    fieldThree,
    fieldFour,
    productName,
    initialProductData,
    refetch,
  } = props;

  const {
    mutate: updateInvoiceData,
    error: updateInvoiceError,
    isLoading: updateInvoiceIsLoading,
    isSuccess: updateInvoiceIsSuccess,
    refetch: updateInvoiceRefetch,
  } = useUpdateInvoice();

  // console.log('updateInvoiceIsSuccess', updateInvoiceIsSuccess);

  const [inputValues, setInputValues] = useState({
    fieldOne:
      initialProductData?.PurchaseOrderData?.ProductData?.at(0)
        ?.TotalPriceWithGSTInBXI,
    fieldTwo:
      initialProductData?.PurchaseOrderData?.ProductData?.at(0)
        ?.ProductQuantity,
    fieldThree: initialProductData?.PurchaseOrderData?.createdAt,
    fieldFour: initialProductData?.InvoiceData?.InvoiceType
      ? initialProductData?.InvoiceData?.InvoiceType
      : "--",
  });

  // console.log('inputValues', inputValues);

  const handleInputChange = (field, value) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
  };

  const handleSaveClick = async () => {
    onSave({
      ...initialProductData,
      PurchaseOrderData: {
        ...initialProductData.PurchaseOrderData,
        ProductData: [
          {
            ...initialProductData.PurchaseOrderData.ProductData?.at(0),
            TotalPriceWithGSTInBXI: inputValues.fieldOne,
            ProductQuantity: inputValues.fieldTwo,
          },
          ...initialProductData.PurchaseOrderData.ProductData?.slice(1),
        ],
      },
      InvoiceData: {
        ...initialProductData.InvoiceData,
        InvoiceType: inputValues.fieldFour,
      },
    });
    const updatedInvoiceData = {
      id: initialProductData?._id,
      InvoiceType: inputValues.fieldFour,
      "PurchaseOrderData.ProductData.0.TotalPriceWithGSTInBXI": Number(
        inputValues.fieldOne
      ),
      "PurchaseOrderData.ProductData.0.ProductQuantity": Number(
        inputValues.fieldTwo
      ),
      PoDate: inputValues.fieldThree,
    };

    await updateInvoiceData(updatedInvoiceData, {
      onSuccess: () => {
        refetch();
      },
      onError: () => {
        console.log("error");
      },
    });
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            maxWidth: "80%",
            textAlign: "center",
            borderRadius: 2,
          }}
        >
          <Typography>{text}</Typography>

          <Typography sx={InputHeader}> {productName} </Typography>

          <Box sx={MainFieldBox}>
            <Box sx={SubFieldBox}>
              <Typography sx={InputHeader}> {fieldOne} </Typography>

              <Input
                type="number"
                sx={InputStyle}
                variant="contained"
                disableUnderline
                onWheel={numberInputOnWheelPreventChange}
                defaultValue={
                  initialProductData?.PurchaseOrderData?.ProductData?.at(0)
                    ?.TotalPriceWithGSTInBXI
                }
                value={inputValues.fieldOne}
                onChange={(e) => handleInputChange("fieldOne", e.target.value)}
              />
            </Box>
            <Box sx={SubFieldBox}>
              <Typography sx={InputHeader}> {fieldTwo} </Typography>
              <Input
                type="number"
                sx={InputStyle}
                variant="contained"
                disableUnderline
                onWheel={numberInputOnWheelPreventChange}
                defaultValue={
                  initialProductData?.PurchaseOrderData?.ProductData?.at(0)
                    ?.ProductQuantity
                }
                value={inputValues.fieldTwo}
                onChange={(e) => handleInputChange("fieldTwo", e.target.value)}
              />
            </Box>
            <Box sx={SubFieldBox}>
              <Typography sx={InputHeader}> {fieldThree} </Typography>
              {/* <Input
                type="date"
                sx={InputStyle}
                variant="contained"
                disableUnderline
                defaultValue={
                  initialProductData?.PurchaseOrderData?.createdAt
                    ? new Date(initialProductData.PurchaseOrderData.createdAt)
                        .toISOString()
                        .substr(0, 10)
                    : ''
                }
                // value={inputValues.fieldThree}
                onChange={(e) =>
                  handleInputChange('fieldThree', e.target.value)
                }
              /> */}
              <Input
                type="date"
                sx={InputStyle}
                variant="contained"
                disableUnderline
                value={inputValues.fieldThree}
                onChange={(e) =>
                  handleInputChange("fieldThree", e.target.value)
                }
              />
            </Box>
            <Box sx={SubFieldBox}>
              <Typography sx={InputHeader}> {fieldFour} </Typography>
              <Input
                type="text"
                sx={InputStyle}
                variant="contained"
                disableUnderline
                disabled
                defaultValue={
                  initialProductData?.InvoiceData?.InvoiceType
                    ? initialProductData?.InvoiceData?.InvoiceType
                    : "--"
                }
                // value={inputValues.fieldFour ? inputValues.fieldFour : '--'}
                onChange={(e) => handleInputChange("fieldFour", e.target.value)}
              />
            </Box>
          </Box>
          <Box sx={ButtonBox}>
            <Button
              onClick={handleSaveClick}
              variant="contained"
              sx={{ mt: 2, mr: 2 }}
            >
              {ButtonLabel}
            </Button>
            <Button onClick={onClose} variant="outlined" sx={{ mt: 2 }}>
              {cancelButtonLabel}
            </Button>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

export default CommonEdit;

const MainFieldBox = {
  display: "flex",
  flexDirection: "column",
  gap: 1,
  justifyContent: "space-between",
  mt: 2,
};

const SubFieldBox = {
  display: "flex",
  flexDirection: "column",
  gap: 1,
  justifyContent: "flex-start",
  alignItems: "flex-start",
  mt: 1,
};

const ButtonBox = {
  display: "flex",
  gap: 1,
  justifyContent: "space-between",
};

const InputStyle = {
  borderRadius: 4,
  width: "100%",
  background: "#F3F6F9 ",
  padding: "5px 10px",
  color: "#000",
  fontFamily: "Poppins",
  fontSize: 12,
  fontWeight: 500,
  fontStyle: "normal",
};

const InputHeader = {
  fontFamily: "Poppins",
  fontSize: 12,
  fontWeight: 500,
  fontStyle: "normal",
  color: "#000",
};
