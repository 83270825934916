import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Divider, Paper } from "@mui/material";
import * as Styles from "../../common/Styles";
import { useGetPickrrData } from "../../Hooks/ExternalApiData/useGetExternalApiData";
import { useNavigate } from "react-router-dom";
import PageLoader from "../CommonActions/PageLoader";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import { Visibility } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

function PickrrData() {
  const navigate = useNavigate();
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const {
    data: getPickrrData,
    isError: getPickrrDataError,
    isLoading: getPickrrDataLoading,
    refetch: getPickrrDataRefetch,
  } = useGetPickrrData(currentPage);
  // console.log('getPickrrData', getPickrrData?.allPikerrsDetails?.length);

  useEffect(() => {
    if (getPickrrData && getPickrrData?.totalPages !== undefined) {
      setInitialPaginationCount(getPickrrData?.totalPages);
    }
  }, [getPickrrData?.totalPages]);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          className="page-heading-section"
          style={{
            justifyContent: "start",
            gap: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/admindashboard/allexternalapi");
            }}
          >
            <KeyboardBackspaceIcon
              onClick={() => {
                navigate(-1);
              }}
            />
          </Box>
          <Box className="page-heading">
            <h5>All Pickrr(s) Data</h5>
            <Typography>
              View all logistics details shipped by Pickrr.
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box sx={buddge}>
          <Typography sx={textBudge}>
            Total Company : {getPickrrData?.totalCount || 0}
          </Typography>
        </Box>
        <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 0, mt: 2 }}>
          <Box
            className="products-filters-row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: getPickrrDataLoading
                ? "rgba(250, 251, 252, 1)"
                : "fff",
            }}
          >
            {getPickrrDataLoading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  ml: "500px",
                  mt: 20,
                }}
              >
                <PageLoader />
              </Box>
            ) : (
              <Box
                className="total-products-badge"
                sx={{
                  width: "100%",
                }}
              >
                {/* <Box>
                Total Company :{' '}
                {activePage === 'Signzy' ? getPickrrData?.length : null}
                {activePage === 'Escrow'
                    ? getEscrowData?.length
                    : activePage === 'Signzy'
                    ? getSignzyData?.length
                    : activePage === 'PineLabs'
                    ? getPineLabsData?.length
                    : activePage === 'Pickrr'
                    ? getPickrrData?.length
                    : null}
              </Box> */}
                <Grid
                  container
                  sx={{
                    p: 1,
                  }}
                >
                  <Grid
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    sx={Styles.textHead}
                  >
                    No.
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Buyer Name
                  </Grid>
                  <Grid xl={2} lg={2} md={2} sm={2} xs={2} sx={Styles.textHead}>
                    Seller Name
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Location
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Logistic Cost
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Deal Amount
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Tax Amount
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={Styles.textHead}
                  >
                    Date
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Delivery by
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Status
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    sx={Styles.textHead}
                  >
                    View
                  </Grid>
                </Grid>
                {/* <Divider /> */}
                {getPickrrData?.allPikerrsDetails?.map((x, i) => {
                  return (
                    <Grid
                      container
                      sx={{
                        p: 1,
                        // mt: 1,
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid rgba(245, 245, 245, 1)",
                      }}
                      key={x}
                    >
                      <Grid
                        item
                        xl={0.5}
                        lg={0.5}
                        md={0.5}
                        sm={0.5}
                        xs={0.5}
                        sx={Styles.tableSubText}
                      >
                        {i + 1}
                      </Grid>
                      <Grid
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1.5}
                        sm={1.5}
                        xs={1.5}
                        sx={{
                          ...Styles.tableSubText,
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {x?.BuyerName ? x?.BuyerName : "--"}
                      </Grid>
                      <Grid
                        item
                        xl={2}
                        lg={2}
                        md={2}
                        sm={2}
                        xs={2}
                        sx={{
                          ...Styles.tableSubText,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {x?.SellerName ? x?.SellerName : "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{ ...Styles.tableSubText }}
                      >
                        {x?.Location ? x?.Location : "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{ ...Styles.tableSubText }}
                      >
                        {x?.transportationfee ? x?.transportationfee : "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{ ...Styles.tableSubText }}
                      >
                        {x?.DeliveryAmount?.toFixed(2)
                          ? x?.DeliveryAmount?.toFixed(2)
                          : "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{ ...Styles.tableSubText }}
                      >
                        {x?.TaxAmount ? x?.TaxAmount : "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1.5}
                        sm={1.5}
                        xs={1.5}
                        sx={{ ...Styles.tableSubText }}
                      >
                        {formatDate(x?.Date ? x?.Date : "--")}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{ ...Styles.tableSubText }}
                      >
                        {x?.DeliveryBy ? x?.DeliveryBy : "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{ ...Styles.textHead }}
                      >
                        <Box
                          sx={{
                            background:
                              x?.Status === "Delivered"
                                ? "#D9FFE9"
                                : x?.Status === "Progress"
                                ? "#FFF1E5"
                                : x?.Status === "N/A"
                                ? "#FFEFEF"
                                : "#FFEFEF",
                            borderRadius: 1.5,
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                x?.Status === "Delivered"
                                  ? "var(--Green-2, #27AE60)"
                                  : x?.Status === "Progress"
                                  ? "#F2994A"
                                  : x?.Status === "N/A"
                                  ? "var(--Red, #EB5757)"
                                  : "var(--Red, #EB5757)",
                              fontFamily: "poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              fontStyle: "normal",
                              lineHeight: "30px",
                            }}
                          >
                            {x?.Status === "Delivered"
                              ? "Payment Done"
                              : x?.Status === "Progress"
                              ? "Pending"
                              : x?.Status === "N/A"
                              ? "N/A"
                              : x?.Status}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={0.5}
                        lg={0.5}
                        md={0.5}
                        sm={0.5}
                        xs={0.5}
                        sx={Styles.textHead}
                      >
                        <Visibility
                          onClick={() => {}}
                          sx={{
                            cursor: "pointer",
                            color: "#445FD2",
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    mx: "auto",
                  }}
                  mt={2}
                >
                  <Stack>
                    <StyledPagination
                      size="medium"
                      count={initialPaginationCount}
                      color="primary"
                      showFirstButton
                      showLastButton
                      page={currentPage}
                      onChange={handlePageChange}
                      strokeWidth={currentPage}
                    />
                  </Stack>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}

export default PickrrData;
const buddge = {
  display: "inline-flex",
  height: 40,
  padding: "16px 20px 16px 15px",
  justifyContent: "center",
  alignItems: "center",
  gap: 0,
  borderRadius: 10,
  border: "1px solid #E6E9EE",
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
