import {
  Box,
  Grid,
  Paper,
  Divider,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Button,
} from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CloseIcon from "../../assets/CloseIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import PageLoader from "../CommonActions/PageLoader";
import axios from "axios";
import ProformaInvoiceList from "./InvoicePages/ProformaInvoiceList";
import PurchaseOrderInvoice from "./InvoicePages/PurchaseOrderInvoice";
import TaxInvoiceList from "./InvoicePages/TaxInvoiceList";
import CommissionInvoiceList from "./InvoicePages/CommissionInvoiceList";
import { TabContext } from "@mui/lab";
import { GetTokenFunc } from "../../utils/GetToken";
import MembershipInvoices from "./InvoicePages/MembershipInvoices";
import AllOrder from "./InvoicePages/AllOrder";
import Searchbar from "../../common/Searchbar";
// import EmptyData from "../../assets/Recyclebin/recyclebin.png";

const StyledPagination = styled(Pagination)({
  "& .MuiPaginationItem-root": {
    color: "#1976d2",
  },

  "& .MuiPaginationItem-root.Mui-selected": {
    color: "#fff",
  },
  "& .css-1v2lvtn-MuiPaginationItem-root": {
    marginRight: "16px",
    border: "none !important",
  },
  "& .MuiPagination-ul li:last-child": {
    marginLeft: "16px",
  },
  "& .MuiPagination-ul li:last-child button::before": {
    content: "'Last'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "11px",
    lineHeight: "14px",
    color: "#445FD2",
    marginRight: "8px",
  },
  "& .MuiPagination-ul li:first-child": {
    marginRight: "16px",
  },
  "& .MuiPagination-ul li:first-child button::after": {
    content: "'First'",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "11px",
    lineHeight: "14px",
    color: "#445FD2",
    marginLeft: "8px",
  },
  "& .MuiPaginationItem-icon": {
    color: "#445FD2",
    fontWeight: 800,
  },
});

function Invoices() {
  const navigate = useNavigate();
  let location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );
  const [dataLoading, setDataLoading] = useState(false);
  const [storeData, setStoreData] = useState();
  const [searchData, setSearchData] = useState("");

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const [openFilter, setOpenFilter] = React.useState(false);

  const handleFilterClose = () => setOpenFilter(false);

  const [value, setValue] = React.useState(
    location?.state?.tabvalue ? location?.state?.tabvalue : "1"
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchAllProformaInvoice = async (currentPage) => {
    try {
      setDataLoading(true);
      await axios
        .get(
          `/api/v1/admin/AdminCompanyDetails/AllProformaInvoices?page=${currentPage}&search=${searchData}`,
          {
            headers: {
              authorization: `bearer ${GetTokenFunc()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data) {
            setDataLoading(false);
          }
          setStoreData(response?.data);
          setInitialPaginationCount(response?.data?.finalCount);
        });
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const fetchAllPurchaseOrder = async (currentPage) => {
    try {
      setDataLoading(true);

      await axios
        .get(
          `/api/v1/admin/AdminCompanyDetails/AllPurchaseOrderInvoices?page=${currentPage}&search=${searchData}`,
          {
            headers: {
              authorization: `bearer ${GetTokenFunc()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data) {
            setDataLoading(false);
          }
          setStoreData(response?.data);
          setInitialPaginationCount(response?.data?.finalCount);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllTaxInvoice = async (currentPage) => {
    try {
      setDataLoading(true);

      await axios
        .get(
          `/api/v1/admin/AdminCompanyDetails/AllTaxInvoices?page=${currentPage}&search=${searchData}`,
          {
            headers: {
              authorization: `bearer ${GetTokenFunc()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data) {
            setDataLoading(false);
          }
          setStoreData(response?.data);
          setInitialPaginationCount(response?.data?.finalCount);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllCommissionInvoices = async (currentPage) => {
    try {
      setDataLoading(true);

      await axios
        .get(
          `/api/v1/admin/AdminCompanyDetails/AllCommissionInvoices?page=${currentPage}&search=${searchData}`,
          {
            headers: {
              authorization: `bearer ${GetTokenFunc()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data) {
            setDataLoading(false);
          }
          setStoreData(response?.data);
          setInitialPaginationCount(response?.data?.finalCount);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllMembershipInvoices = async (currentPage) => {
    try {
      setDataLoading(true);

      await axios
        .get(
          `/api/v1/admin/AdminCompanyDetails/AllMembershipOrderInvoices?page=${currentPage}&search=${searchData}`,
          {
            headers: {
              authorization: `bearer ${GetTokenFunc()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data) {
            setDataLoading(false);
          }
          setStoreData(response?.data);
          setInitialPaginationCount(response?.data?.finalCount);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllOrder = async (currentPage) => {
    try {
      setDataLoading(true);

      await axios
        .get(
          `/api/v1/admin/AdminCompanyDetails/AllOrder?page=${currentPage}&search=${searchData}`,
          {
            headers: {
              authorization: `bearer ${GetTokenFunc()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data) {
            setDataLoading(false);
          }
          setStoreData(response?.data);
          setInitialPaginationCount(response?.data?.finalCount);
        });
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (value === "1") {
      fetchAllOrder(currentPage);
    } else if (value === "2") {
      fetchAllPurchaseOrder(currentPage);
    } else if (value === "3") {
      fetchAllProformaInvoice(currentPage);
    } else if (value === "4") {
      fetchAllTaxInvoice(currentPage);
    } else if (value === "5") {
      fetchAllCommissionInvoices(currentPage);
    } else if (value === "6") {
      fetchAllMembershipInvoices(currentPage);
    }
  }, [value, currentPage, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [value, searchData]);

  return (
    <Paper
      elevation={0}
      sx={{
        justifyContent: "flex-end",
        height: "100%",
        width: "100%",
        overflowY: "scroll",
      }}
    >
      <React.Fragment>
        <Paper elevation={0}>
          <Box
            className="page-heading-section"
            style={{
              justifyContent: "start",
              gap: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/admindashboard/allrecords");
              }}
            >
              <KeyboardBackspaceIcon />
            </Box>
            <Box className="page-heading">
              <h5>All Invoice</h5>
              <Typography>Explore all the data by applying filters.</Typography>
            </Box>
          </Box>
          <Divider />

          <Box
            sx={{
              background: "rgba(250, 251, 252, 1)",
              p: 1,
              mt: 0,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "97%",
                alignItems: "center",
                alignContent: "center",
              }}
            >
              <Box sx={buddge}>
                <Typography sx={textBudge}>
                  <Typography sx={textBudge}>
                    Total Requests: {storeData?.totalCount}
                  </Typography>
                </Typography>
              </Box>
              <Searchbar onSendData={handleChildData} />
            </Box>
            {dataLoading ? (
              <PageLoader />
            ) : (
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Orders" value="1" />
                      <Tab label="Purchase Orders" value="2" />
                      <Tab label="Proforma Invoices" value="3" />
                      <Tab label="Tax Invoices" value="4" />
                      <Tab label="BXI Commissions" value="5" />
                      <Tab label="Memberships" value="6" />
                    </TabList>
                  </Box>
                  <TabPanel value="1">
                    <AllOrder data={storeData?.data} />
                  </TabPanel>
                  <TabPanel value="2">
                    <PurchaseOrderInvoice data={storeData?.data} />
                  </TabPanel>
                  <TabPanel value="3">
                    <ProformaInvoiceList data={storeData?.data} />
                  </TabPanel>
                  <TabPanel value="4">
                    <TaxInvoiceList data={storeData?.data} />
                  </TabPanel>
                  <TabPanel value="5">
                    <CommissionInvoiceList data={storeData?.data} />
                  </TabPanel>
                  <TabPanel value="6">
                    <MembershipInvoices data={storeData?.data} />
                  </TabPanel>
                </TabContext>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: {
                xl: "100%",
                lg: "100%",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              mx: "auto",
            }}
            mt={2}
          >
            <StyledPagination
              size="medium"
              count={initialPaginationCount}
              color="primary"
              showFirstButton
              showLastButton
              page={currentPage}
              onChange={handlePageChange}
              strokeWidth={currentPage}
            />
          </Box>
        </Paper>

        <Modal open={openFilter} onClose={handleFilterClose}>
          <Box sx={FilterStyle}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Filters
              </Typography>
              <Box
                component="img"
                src={CloseIcon}
                sx={{
                  cursor: "pointer",
                  height: "20px",
                  width: "20px",
                }}
                onClick={() => {
                  handleFilterClose();
                }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: 1,
                  mt: 1,
                }}
              >
                <FormControlLabel
                  style={RadioTextStyle}
                  value="Product"
                  control={<Radio />}
                  label="Product"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="Voucher"
                  control={<Radio />}
                  label="Voucher"
                />
                <FormControlLabel
                  style={RadioTextStyle}
                  value="All"
                  control={<Radio />}
                  label="All"
                />
              </RadioGroup>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                  mt: 2,
                  textTransform: "none",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Apply
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleFilterClose();
                  }}
                  sx={{
                    textTransform: "none",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </React.Fragment>
    </Paper>
  );
}

export default Invoices;

const RadioTextStyle = {
  color: "#6B7A99",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "12px",
};

const FilterStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
  // lineHeight: 20,
};
