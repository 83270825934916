import { useQuery } from "react-query";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";

export const useFetchMemberShipPlan = () => {
  return useQuery(["membershipplan"], async () => {
    try {
      const response = await axios.get(`membership/getallmembership`, {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  });
};
