import MainRouter from "./components/Routers/Router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

// axios.defaults.baseURL = "https://development-stage.bxi.unada.in/";
// axios.defaults.baseURL = 'https://api.production-bxi.unada.in/';
// axios.defaults.baseURL = "http://localhost:7000/";

// axios.defaults.baseURL = "https://bxiback-gp3.unada.in/";
// axios.defaults.baseURL = "https://bxiuat-test.unada.in/";

axios.defaults.baseURL = "https://production-api.unada.in/";
axios.defaults.headers.bxiapikey = "Bearer K8sY2jF4pL3rQ1hA9gZ6bX7wC5vU0t";

function App() {
  return (
    <>
      <ToastContainer />
      <MainRouter />
    </>
  );
}

export default App;
