import { createSlice } from '@reduxjs/toolkit';

export const AllProductsDetailsByCompanySlice = createSlice({
    name: 'AllProductsDetailsByCompany',
    initialState: {
        AllProductsDetailsByCompany: [],
    },
    reducers: {
        AllProducts_Company_Request: (state) => {
            state.loading = true;
            state.AllProductsDetailsByCompany = [];
        },
        AllProducts_Company_Success: (state, action) => {
            state.loading = false;
            state.AllProductsDetailsByCompany = action.payload;
        },
        AllProducts_Company_Fail: (state, action) => {
            state.loading = false;
            state.AllProductsDetailsByCompany = action.payload;
        },
    },
});

export const { AllProducts_Company_Request, AllProducts_Company_Success, AllProducts_Company_Fail } =
    AllProductsDetailsByCompanySlice.actions;

export default AllProductsDetailsByCompanySlice.reducer;
