export const ADMIN_ROLES = [
  {
    name: "Super Admin",
    value: "SUPER_ADMIN",
  },
  {
    name: "Finance Manager",
    value: "FINANCE_MANAGER",
  },
  {
    name: "Risk Analyst",
    value: "RISK_ANALYST",
  },
  {
    name: "Legal Officer",
    value: "LEGAL_OFFICER",
  },
  {
    name: "Marketplace Manager",
    value: "MARKETPLACE_MANAGER",
  },
  {
    name: "Sales Manager",
    value: "SALES_MANAGER",
  },
  {
    name: "Creative Manager",
    value: "CREATIVE_MANAGER",
  },
  {
    name: "Operations Manager",
    value: "OPERATIONS_MANAGER",
  },
];

export const ADMIN_ACCESS = {
  ANALYTICS: "ANALYTICS",
  PRIVATEDEAL: "PRIVATEDEAL",
  USERANDCOMPANY: "USERANDCOMPANY",
  MARKETPLACE: "MARKETPLACE",
  APPROVALS: "APPROVALS",
  MEMBERDIRECTORY: "MEMBERDIRECTORY",
  CHATANDQUERY: "CHATANDQUERY",
  GENIE: "GENIE",
  MEMBERSHIPLIST: "MEMBERSHIPLIST",
  EXTERNALAPIREPORTS: "EXTERNALAPIREPORTS",
  RECYLEBIN: "RECYLEBIN",
  ADMINDASHBOARD: "ADMINDASHBOARD",
  RELEASEFUNDS: "RELEASEFUNDS",
  ALLRECORDS: "ALLRECORDS",
  ADMINHANDLES: "ADMINHANDLES",
  ADMINTRAILS: "ADMINTRAILS",
  COMMUNICATIONS: "COMMUNICATIONS",
  COMMISSIONLIST: "COMMISSIONLIST",
  // LOGINANDSIGNUP: "LOGINANDSIGNUP",
  // ADDCATEGORY: "ADDCATEGORY",
};
