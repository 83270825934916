import axios from "axios";
import cookie from "js-cookie";
import { redirect } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const BASE_URL = 'https://api.production-bxi.unada.in/api/v1/admin/'; // development
const BASE_URL = "https://production-api.unada.in/api/v1/admin/"; // production
// const BASE_URL = "https://bxiback-gp3.unada.in/api/v1/admin/";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    bxiapikey: "Bearer K8sY2jF4pL3rQ1hA9gZ6bX7wC5vU0t",
  },
});

const setToken = (token) => {
  cookie.set("BearerToken", token);
};

export const getToken = () => {
  return cookie.get("BearerToken");
};

export const removeToken = () => {
  cookie.remove("BearerToken");
};

export const checkAuthToken = () => {
  const token = getToken();
  if (!token) {
    return redirect("/Login");
  }
  return true;
};

axiosInstance.interceptors.request.use((config) => {
  const token = cookie.get("BearerToken");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

const makeApiCall = async (endpoint, method = "Get", data = null) => {
  try {
    const response = await axiosInstance({ url: endpoint, method, data });
    return response;
  } catch (error) {
    throw error;
  }
};

// Hendle errors

export const handleError = (errorMessage) => {
  toast.error(errorMessage, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 5000,
  });
};

export const loginAdminUser = async (userName, password) => {
  try {
    const endpoint = `auth/login`;
    let postData = {
      email: userName,
      password: password,
    };
    const resData = await makeApiCall(endpoint, "Post", postData);
    if (resData && resData.data && resData.data.token) {
      setToken(resData.data.token);
    }
    return resData;
  } catch (error) {
    throw error;
  }
};

export const getTotalSignUpsChartData = async (startDate, endDate) => {
  try {
    const endpoint = `analytics/get-signups?startDate=${startDate}&endDate=${endDate}`;
    const data = await makeApiCall(endpoint);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getTotalTransactionChartData = async (startDate, endDate) => {
  try {
    const endpoint = `analytics/total-transaction?startDate=${startDate}&endDate=${endDate}`;
    const data = await makeApiCall(endpoint);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getMembershipChartData = async (startDate, endDate) => {
  try {
    const endpoint = `analytics/get-membership?startDate=${startDate}&endDate=${endDate}`;
    const data = await makeApiCall(endpoint);
    return data;
  } catch (error) {
    throw error;
  }
};
