import { configureStore } from "@reduxjs/toolkit";
import productReducer from "./reduser/MarketPlace/Product-Filter";
import AllCompanyDetailesReducer from "./reduser/Member/MemberDirectory";
import EditCompanyDetailsReducer from "./reduser/Company&User/EditCompanyDetails";
import ProductsDetailsReducer from "./reduser/Company&User/ProductsDetails";
import AllCompanysReducer from "./reduser/Company&User/AllCompanys";
import EditProductDetailsReducer from "./reduser/Company&User/EditProductDetails";
import searchChatReducer from "./reduser/Chat/SearchChat";
import GetLastSeenReducer from "./reduser/Chat/GetLastSeen";

import getMassagesReducer from "./reduser/Chat/Get-Massages";
import MarketplaceEditReducer from "./reduser/MarketPlace/Updatedata";

import getNotificationReducer from "./reduser/Notification/getNotications";
import notificationReducer from "./reduser/Notification/notification";
import OrderSummarySlice from "./reduser/OrderSummary";

import GetAllTotalProductCount from "./reduser/AdminDashboard/TotalProducts"

import GetTotalCompanys from './reduser/AdminDashboard/TotalCompanys'

import GetTotalMemberShip from './reduser/AdminDashboard/TotalMemberShip'

import GetTotalCreditIssue from './reduser/AdminDashboard/TotalCreditIssue'

import GetTotalAmount from './reduser/AdminDashboard/TotalAmount'

const store = configureStore({
  reducer: {
    AllProducts: productReducer,
    CompanyDetailes: AllCompanyDetailesReducer,
    EditCompanyDetail: EditCompanyDetailsReducer,
    ProductsDetails: ProductsDetailsReducer,
    AllCompany: AllCompanysReducer,
    EditProductDetails: EditProductDetailsReducer,
    MarketplaceEdit: MarketplaceEditReducer,

    searchChat: searchChatReducer,
    massages: getMassagesReducer,
    getlastSeen: GetLastSeenReducer,

    notification: notificationReducer,
    allNotifiactions: getNotificationReducer,
    OrderSummaryD: OrderSummarySlice,

    AllProductCount: GetAllTotalProductCount,
    AllCompanyCount: GetTotalCompanys,
    AllMemberShipCount: GetTotalMemberShip,
    AllCreditIssueCount: GetTotalCreditIssue,
    AllAmount: GetTotalAmount
  },
});

export default store;
