import Track from '../Stepper/Track';

const CompanyOnboarding = () => {
  return (
    <>
      <Track />
    </>
  );
};

export default CompanyOnboarding;
