import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Divider, Paper } from "@mui/material";
import * as Styles from "../../common/Styles";
import { useGetPinelabsData } from "../../Hooks/ExternalApiData/useGetExternalApiData";
import { useNavigate } from "react-router-dom";
import PageLoader from "../CommonActions/PageLoader";
import { styled } from "@mui/system";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useSelector, useDispatch } from "react-redux";
import { AllCompanysDetails } from "../../redux/action/Company&User/AllCompanys";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
import CompanyTypeComp from "../AllRecords/CompanyTypeComp";
import Searchbar from "../../common/Searchbar";

function PineLabsData() {
  const StyledPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
      color: "#1976d2",
    },

    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .css-1v2lvtn-MuiPaginationItem-root": {
      marginRight: "16px",
      border: "none !important",
    },
    "& .MuiPagination-ul li:last-child": {
      marginLeft: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:last-child button::before": {
      content: "'Last'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginRight: "8px",
    },
    "& .MuiPagination-ul li:first-child": {
      marginRight: "16px",
    },
    "& .MuiPagination-ul li:first-child button::after": {
      content: "'First'",
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 800,
      fontSize: "11px",
      lineHeight: "14px",
      color: "#445FD2",
      marginLeft: "8px",
    },
    "& .MuiPaginationItem-icon": {
      color: "#445FD2",
      fontWeight: 800,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [AllCompanys, setAllCompanys] = useState();
  const [searchData, setSearchData] = useState("");
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: getPineLabsData,
    isError: getPineLabsDataError,
    isLoading: getPineLabsDataLoading,
    refetch: getPineLabsDataRefetch,
  } = useGetPinelabsData(currentPage, searchData);

  function formatDate(originalDate) {
    const date = new Date(originalDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString();

    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    if (getPineLabsData && getPineLabsData?.totalPages !== undefined) {
      setInitialPaginationCount(getPineLabsData?.totalPages);
    }
  }, [getPineLabsData?.totalPages]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  return (
    <React.Fragment>
      <Paper
        elevation={0}
        sx={{
          justifyContent: "flex-end",
          height: "620px",
          width: "100%",
          overflowY: "scroll",
        }}
      >
        <Box
          className="page-heading-section"
          style={{
            justifyContent: "start",
            gap: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/admindashboard/allexternalapi");
            }}
          >
            <KeyboardBackspaceIcon
              onClick={() => {
                navigate(-1);
              }}
            />
          </Box>
          <Box className="page-heading">
            <h5>All JusPay(s) Data</h5>
            <Typography>View all payment details from members.</Typography>
          </Box>
        </Box>
        <Divider />{" "}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "97%",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <Box sx={buddge}>
            <Typography sx={textBudge}>
              Total Company :{getPineLabsData?.totalCount || 0}
            </Typography>
          </Box>
          <Searchbar onSendData={handleChildData} />
        </Box>
        <Box
          sx={{
            background: "rgba(250, 251, 252, 1)",
            p: 2,
            mt: 0,
          }}
        >
          <Box
            className="products-filters-row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {getPineLabsDataLoading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  ml: "500px",
                  mt: 20,
                }}
              >
                <PageLoader />
              </Box>
            ) : (
              <Box
                className="total-products-badge"
                sx={{
                  width: "100%",
                }}
              >
                <Grid
                  container
                  sx={{
                    p: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    MembershipId
                  </Grid>
                  <Grid
                    item
                    xl={2.5}
                    lg={2.5}
                    md={2.5}
                    sm={2.5}
                    xs={2.5}
                    sx={{
                      ...Styles.textHead,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Company Name
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Category
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={2.5}
                    lg={2.5}
                    md={2.5}
                    sm={2.5}
                    xs={2.5}
                    sx={Styles.textHead}
                  >
                    Email
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Membership Amount
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={Styles.textHead}
                  >
                    Membership Type
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Date
                  </Grid>
                  <Grid
                    align="center"
                    item
                    xl={1}
                    lg={1}
                    md={1}
                    sm={1}
                    xs={1}
                    sx={Styles.textHead}
                  >
                    Status
                  </Grid>
                </Grid>
                {/* <Divider /> */}

                {getPineLabsData?.AllJustPayData?.map((x, i) => {
                  return (
                    <Grid
                      container
                      sx={{
                        p: 2,
                        mt: 0,
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid rgba(245, 245, 245, 1)",
                      }}
                      key={x}
                    >
                      <Grid
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1.5}
                        sm={1.5}
                        xs={1.5}
                        sx={{
                          ...headText,
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        {x?.MembershipId ? x?.MembershipId : "--"}
                      </Grid>
                      <Grid
                        item
                        xl={2.5}
                        lg={2.5}
                        md={2.5}
                        sm={2.5}
                        xs={2.5}
                        sx={{
                          ...headText,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {x?.CompanyId?.companyName || "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{
                          ...Styles.textHead,
                          display: "flex",
                          overflow: "scroll",
                          justifyContent: "center",
                        }}
                      >
                        {x?.CompanyId?.companyType ? (
                          <CompanyTypeComp
                            companyTypeId={x?.CompanyId?.companyType}
                          />
                        ) : (
                          "--"
                        )}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={2.5}
                        lg={2.5}
                        md={2.5}
                        sm={2.5}
                        xs={2.5}
                        sx={{ ...Styles.textHead }}
                      >
                        {x?.CompanyId?.email || "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{ ...Styles.textHead }}
                      >
                        {x?.CompanyId?.memberships?.at(
                          x?.CompanyId?.memberships?.length - 1
                        )?.MembershipAmount
                          ? x?.CompanyId?.memberships?.at(
                              x?.CompanyId?.memberships?.length - 1
                            )?.MembershipAmount
                          : "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1.5}
                        lg={1.5}
                        md={1.5}
                        sm={1.5}
                        xs={1.5}
                        sx={{ ...Styles.textHead }}
                      >
                        {x?.MembershipPlan?.PlanName || "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{ ...Styles.textHead }}
                      >
                        {x?.createdAt ? formatDate(x?.createdAt) : "--"}
                      </Grid>
                      <Grid
                        align="center"
                        item
                        xl={1}
                        lg={1}
                        md={1}
                        sm={1}
                        xs={1}
                        sx={{ ...Styles.textHead }}
                      >
                        <Box
                          sx={{
                            color: "#445fd2",
                            background: "#445fd221",
                            borderRadius: 1.5,
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#445fd2",
                              fontFamily: "poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              fontStyle: "normal",
                              lineHeight: "30px",
                            }}
                          >
                            {x?.Status === "Transaction Successful"
                              ? "Payment Done"
                              : x?.Status === "PENDING"
                              ? "Pending"
                              : x?.Status === "N/A"
                              ? "N/A"
                              : "Done "}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: {
                      xl: "100%",
                      lg: "100%",
                      md: "100%",
                      sm: "100%",
                      xs: "100%",
                    },
                    mx: "auto",
                  }}
                  mt={2}
                >
                  <Stack>
                    <StyledPagination
                      size="medium"
                      count={initialPaginationCount}
                      color="primary"
                      showFirstButton
                      showLastButton
                      page={currentPage}
                      onChange={handlePageChange}
                      strokeWidth={currentPage}
                    />
                  </Stack>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
}

export default PineLabsData;
const headText = {
  color: "var(--text-color-text-1, #1B212D)",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  alignItems: "left",
};
const buddge = {
  display: "inline-flex",
  height: 40,
  padding: "16px 20px 16px 15px",
  justifyContent: "center",
  alignItems: "center",
  gap: 0,
  borderRadius: 10,
  border: "1px solid #E6E9EE",
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
