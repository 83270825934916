import axios from "axios";
import { useQuery } from "react-query";
import { GetTokenFunc } from "../../utils/GetToken";
// import { search } from "../../components/Layouts/AdminHeader";

const useGetApiData = (apiEndpoint) => {
  const { data, isLoading, isError, error, refetch } = useQuery(
    apiEndpoint,
    async () => {
      const response = await axios.get(apiEndpoint, {
        headers: {
          authorization: `bearer ${GetTokenFunc()}`,
        },
      });
      return response.data;
    }
  );
  return { data, isLoading, isError, error, refetch };
};

export const useGetSignzyData = (currentPage, search) => {
  return useGetApiData(
    `/api/v1/admin/AdminCompanyDetails/GetSignzyDetails?page=${currentPage}&search=${search}`,
    {
      headers: {
        authorization: `Bearer${GetTokenFunc()}`,
      },
    }
  );
};

export const useGetPinelabsData = (currentPage, search) => {
  return useGetApiData(
    `/api/v1/admin/AdminCompanyDetails/GetJustPayDetails?page=${currentPage}&search=${search}`,
    {
      headers: {
        authorization: `Bearer${GetTokenFunc()}`,
      },
    }
  );
};

export const useGetEscrowData = (currentPage, search) => {
  return useGetApiData(
    `/api/v1/admin/AdminCompanyDetails/GetEscrowDetails?page=${currentPage}&search=${search}`,
    {
      headers: {
        authorization: `Bearer${GetTokenFunc()}`,
      },
    }
  );
};

export const useGetPickrrData = (currentPage) => {
  return useGetApiData(
    `/api/v1/admin/AdminCompanyDetails/GetPickrrDetails?page=${currentPage}`,
    {
      headers: {
        authorization: `Bearer${GetTokenFunc()}`,
      },
    }
  );
};
