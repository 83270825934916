import { createSlice } from '@reduxjs/toolkit';

export const EditProductDetailsSlice = createSlice({
    name: "EditProduct",
    initialState: {
        EditProduct: {}
    },
    reducers: {
        Edit_Product_Request: (state) => {
            state.loading = true;
            state.EditProduct = [];
        },
        Edit_Product_Success: (state, action) => {
            state.loading = false;
            state.EditProduct = action.payload;
        },
        Edit_Product_Fail: (state, action) => {
            state.loading = false;
            state.EditProduct = action.payload;
        }
    }
})

export const { Edit_Product_Request, Edit_Product_Success, Edit_Product_Fail } = EditProductDetailsSlice.actions;

export default EditProductDetailsSlice.reducer;