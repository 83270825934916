import React, { useEffect, useState } from "react";
import { XYPlot, VerticalBarSeries, XAxis, YAxis, Hint } from "react-vis";
import "react-vis/dist/style.css";
import axios from "axios";
import { GetTokenFunc } from "../../utils/GetToken";
import { Box } from "@mui/material";
import PageLoader from "../CommonActions/PageLoader";

const Conversionrates = () => {
  const barColor = "rgba(68, 95, 210, 1)";
  const [hoveredBar, setHoveredBar] = useState(null);
  const [datas, setDatas] = useState([]);
  const [filter, setFilter] = useState("last-week");
  const [isLoading, setIsLoading] = useState(true);
  const [days, setDays] = useState([]);

  const handleBarHover = (value) => {
    setHoveredBar(value);
  };

  const fetchData = async (selectedFilter) => {
    setIsLoading(true);
    let apiUrl = "";

    switch (selectedFilter) {
      case "last-week":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIConversionRate";
        break;
      case "last-month":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIConversionRateMonth";
        break;
      case "current-month":
        apiUrl =
          "/api/v1/admin/AdminCompanyDetails/BXIConversionRateCurrentMonth";
        break;
      case "last-three-months":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIConversionRate3Month";
        break;
      case "last-six-months":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIConversionRate6Month";
        break;
      case "last-year":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIConversionRate12Month";
        break;
      case "current-year":
        apiUrl =
          "/api/v1/admin/AdminCompanyDetails/BXIConversionRateCurrentFiscalYear";
        break;
      case "last-five-years":
        apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIConversionRate5Years";
        break;
      default:
        apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIConversionRate";
    }

    try {
      const res = await axios.get(apiUrl, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      });

      // if ((apiUrl = "/api/v1/admin/AdminCompanyDetails/BXIConversionRate")) {
      //   let data = res.data.label;

      //   data.sort(function (a, b) {
      //     const dateA = new Date(a.split("/").reverse().join("/"));
      //     const dateB = new Date(b.split("/").reverse().join("/"));

      //     return dateA - dateB;
      //   });
      //   setDays(data);
      // } else {
      //   setDays(res.data.label);
      // }

      setDays(res.data.label);
      setDatas(res.data.conversionRates);
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(filter);
  }, [filter]);

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          px: 2,
        }}
      >
        <h3 className="graph-heading">
          Conversion Rate <small> - Signups / Visitors</small>
        </h3>
        <Box className="marketplace-filters">
          <Box>
            <select
              className="marketplace-filters"
              onChange={(e) => {
                setFilter(e.target.value);
              }}
              value={filter}
            >
              <option value="last-week">last-week</option>
              <option value="last-month">last-month</option>
              <option value="current-month">current-month</option>
              <option value="last-three-months">last-three-months</option>
              <option value="last-six-months">last-six-months</option>
              <option value="last-year">last-year</option>
              <option value="current-year">current-year</option>
              <option value="last-five-years">last-five-years</option>
            </select>
          </Box>
        </Box>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 10,
            ml: 35,
          }}
        >
          <PageLoader />
        </Box>
      ) : (
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <XYPlot
            height={350}
            width={700}
            stackBy="y"
            margin={{ left: 70, right: 10, top: 10, bottom: 30 }}
          >
            <XAxis
              tickValues={days?.map((day, index) => index + 1)}
              tickFormat={(v) => days[v - 1]}
            />
            <YAxis tickFormat={(v) => `${v}%`} />
            <VerticalBarSeries
              barWidth={0.2}
              color={barColor}
              data={datas?.map((value, index) => ({
                x: index + 1,
                y: value,
                color: "rgba(68, 95, 210, 1)",
              }))}
              onValueMouseOver={handleBarHover}
              onValueMouseOut={() => setHoveredBar(null)}
            />

            {hoveredBar && (
              <Hint value={hoveredBar}>
                <div
                  className="grapharrow"
                  style={{
                    background: "rgba(255, 255, 255, 1)",
                    color: "rgba(60, 185, 40, 1)",
                    border: "1px solid rgba(60, 185, 40, 1)",
                    boxShadow: "10",
                  }}
                >
                  <p>{hoveredBar?.label}</p>
                  <p>{`${hoveredBar?.y}%`}</p>
                </div>
              </Hint>
            )}
          </XYPlot>
        </div>
      )}{" "}
    </div>
  );
};

export default Conversionrates;
