import axios from "axios";
import { GetTokenFunc } from '../../../utils/GetToken';

import { TotalAmount_Request, TotalAmount_Success, TotalAmount_Fail } from '../../reduser/AdminDashboard/TotalAmount'

export const AllAmountOnPlateform = () => async (dispatch) => {
    try {
        dispatch({ type: TotalAmount_Request.toString() })
        const response = await axios.get(
            `/api/v1/admin/TotalProductAmountOnPlatform`, {
            headers: {
                authorization: `Bearer ${GetTokenFunc()}`
            }
        }
        );
        dispatch({
            type: TotalAmount_Success.toString(),
            payload: response.data
        })
    } catch (error) {
        dispatch({
            type: TotalAmount_Fail,
            payload: error.response.data.message,
        });
    }
}