import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "../Company/Company.Modules.css";
import * as Styles from "../../common/Styles";
import { Delete, Edit } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Divider,
  IconButton,
  Modal,
  Button,
  Typography,
  Input,
  Stack,
} from "@mui/material";
import CommonDelete from "../CommonActions/CommonDelete";
import FigmaIcon from "../../assets/figma-icon.png";
import CategoryDropdown from "../Layouts/CategoryDropdown";
import BxiCoinIcon from "../../assets/BXI-coin-icon.svg";
import EmptyData from "../../assets/Recyclebin/recyclebin.png";
import Loader from "../../common/Loader";
import {
  useFetchInvoiceList,
  useFetchSaleInvoice,
  useFetchInvoiceListList,
  useFetchSaleInvoiceList,
} from "../../Hooks/Invoice/useGetInvoice";
import { useRemoveinvoice } from "../../Hooks/Invoice/useRemoveinvoice";
import { useParams } from "react-router-dom";
import CommonEdit from "../CommonActions/CommonEdit";
import { StyledPagination } from "../../common/StyledPagination";
import PageLoader from "../CommonActions/PageLoader";
import Searchbar from "../../common/Searchbar";

const Invoice = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(
    location?.state?.childactiveTab ? location?.state?.childactiveTab : 0
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const [storeIdForEdit, setStoreIdForEdit] = useState(null);
  const [productEditData, setProductEditData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sellCurrentPage, setSellCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );
  const [initialSellPaginationCount, setInitialSellPaginationCount] = useState(
    sellCurrentPage || 1
  );
  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };
  const handleEditSave = (inputValues) => {
    //Edit logic here
    setProductEditData(inputValues);
    setIsEditModalOpen(false);
    // setStoreIdForEdit(null);
  };

  const handleEditClose = () => {
    setIsEditModalOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };
  const {
    data: invoiceData,
    isError: invoiceError,
    isLoading: invoiceLoading,
    refetch: invoiceRefetch,
  } = useFetchInvoiceList(id, currentPage, searchData);
  const getStatus = (data) => {
    const {
      escrowSellerReleaseResponse,
      escrowRefundResponse,
      walletRefund,
      escrowPayment,
      InvoiceData,
      PurchaseOrderData,
      BuyerOrderStatus,
      IsMedia,
      IsVoucher,
      OrderTracking,
      MediaStatus,
      VoucherStatus,
    } = data;

    if (IsVoucher) {
      if (escrowRefundResponse?.success) return "INR Refunded";
      if (walletRefund === "success") return "Wallet Refunded";
      if (escrowSellerReleaseResponse?.success) return "Payment Released";
      if (VoucherStatus) return VoucherStatus;
      if (escrowPayment?.success === "true") return "Payment Done";
      if (!escrowPayment?.success && !escrowSellerReleaseResponse?.success)
        return "Payment Pending";
    }
    if (escrowRefundResponse?.success) return "INR Refunded";
    if (walletRefund === "success") return "wallet Refunded";
    if (escrowSellerReleaseResponse?.success) return "Released Called";
    if (!IsVoucher && !IsMedia && OrderTracking)
      return OrderTracking?.at(OrderTracking?.length - 1)?.Status;
    if (!IsVoucher && IsMedia && MediaStatus) return MediaStatus;
    if (BuyerOrderStatus === "success" && !IsMedia && !IsVoucher)
      return "Buyer Confirmed";
    // if (SellerOrderStatus === "success" && !IsMedia && !IsVoucher)
    //   return "Delivered";
    if (escrowPayment?.success === "true") return "Payment Done";
    if (InvoiceData) {
      if (InvoiceData?.SellerInvoiceAcceptanceStatus === "Accepted") {
        if (InvoiceData?.BuyerInvoiceAcceptanceStatus === "Pending")
          return "PI Issued";
        if (InvoiceData?.BuyerInvoiceAcceptanceStatus === "Accepted")
          return "PI Accepted";
        if (InvoiceData?.BuyerInvoiceAcceptanceStatus === "Rejected")
          return "PI Rejected";
      }
    }
    if (PurchaseOrderData) {
      if (
        PurchaseOrderData?.BuyerOrderStatus === "Pending" &&
        PurchaseOrderData?.SellerOrderStatus === "Pending"
      )
        return "PO Pending";
      if (
        PurchaseOrderData?.BuyerOrderStatus === "Accepted" &&
        PurchaseOrderData?.SellerOrderStatus === "Pending"
      )
        return "PO Issued";
      if (PurchaseOrderData?.BuyerOrderStatus === "Rejected")
        return "PO Cancelled";
      if (
        PurchaseOrderData?.BuyerOrderStatus === "Accepted" &&
        PurchaseOrderData?.SellerOrderStatus === "Rejected"
      )
        return "PO Rejected";
    }
    return "";
  };

  const { data: mutateRemoveInvoice, mutate: removefrominvoice } =
    useRemoveinvoice(id);

  const {
    data: saleData,
    isError: saleError,
    isLoading: saleLoading,
    refetch: invoiceSaleRefetch,
  } = useFetchSaleInvoiceList(id, sellCurrentPage, searchData);

  const GetProductCategory = async (
    name,
    id,
    type,
    listingType,
    VoucherType,
    ProductSubCategory
  ) => {
    if (listingType === "Voucher") {
      if (
        VoucherType === "Value Voucher / Gift Cards " ||
        ProductSubCategory === "Value Voucher" ||
        ProductSubCategory === "Gift Cards"
      ) {
        navigate(`companyinvoicedetail/${id}`, {
          state: { activeTab: activeTab },
        });
      } else {
        navigate(`companyinvoicedetail/${id}`, {
          state: { activeTab: activeTab },
        });
      }
    } else if (listingType === "Media") {
      navigate(`companyinvoicedetail/${id}`, {
        state: { type: "Media", activeTab: activeTab },
      });
    } else if (listingType === "Product") {
      navigate(`companyinvoicedetail/${id}`, {
        state: { activeTab: activeTab },
      });
    } else if (ProductSubCategory === "Gift Cards") {
      navigate(`companyinvoicedetail/${id}`, {
        state: { activeTab: activeTab },
      });
    } else {
      navigate(`companyinvoicedetail/${id}`, {
        state: { activeTab: activeTab },
      });
    }
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState(null);
  const handleDelete = (id) => {
    // console.log('idHere', id);
    setIsLoading(true);
    removefrominvoice(id, {
      onSuccess: (res) => {
        // console.log(res);
        invoiceRefetch();
        setIsDeleteModalOpen(false);
        setDeleteProductId(null);
        invoiceSaleRefetch();
        setIsLoading(false);
      },
      onError: (err) => {
        // console.log("error", err);
        setIsLoading(false);
      },
    });
    setIsDeleteModalOpen(false);
    // invoiceRefetch();
  };
  useEffect(() => {
    // console.log('Pagination', invoiceData);
    if (invoiceData && invoiceData?.TotalPages !== undefined) {
      setInitialPaginationCount(invoiceData?.TotalPages);
    }
  }, [invoiceData?.TotalPages]);
  useEffect(() => {
    if (saleData && saleData?.TotalPages !== undefined) {
      setInitialSellPaginationCount(saleData?.TotalPages);
    }
  }, [saleData?.TotalPages]);

  useEffect(() => {
    invoiceSaleRefetch();
  }, [sellCurrentPage]);

  useEffect(() => {
    invoiceRefetch();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    invoiceRefetch();
    setSellCurrentPage(1);
    invoiceSaleRefetch();
  }, [searchData]);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const handleSellPageChange = (event, page) => {
    setSellCurrentPage(page);
  };

  const handleClose = () => {
    setIsDeleteModalOpen(false);
  };
  const renderPurchaseItems = () => {
    const bgColors = [
      "rgba(217, 255, 233, 1)",
      "rgba(217, 255, 233, 1)",
      "rgba(255, 241, 229, 1)",
      "rgba(255, 241, 229, 1)",
      "rgba(217, 255, 233, 1)",
      "rgba(255, 241, 229, 1)",
      "rgba(217, 255, 233, 1)",
    ];
    const colors = [
      "rgba(39, 174, 96, 1)",
      "rgba(39, 174, 96, 1)",
      "rgba(242, 153, 74, 1)",
      "rgba(242, 153, 74, 1)",
      "rgba(39, 174, 96, 1)",
      "rgba(242, 153, 74, 1)",
      "rgba(39, 174, 96, 1)",
    ];

    return (
      <div>
        <table className="common-table">
          {invoiceLoading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                ml: "500px",
                mt: 20,
              }}
            >
              <PageLoader />
            </Box>
          ) : (
            <>
              <thead>
                <tr>
                  <td width={"20%"} align="left">
                    Product Name
                  </td>
                  <td width={"10%"}>Seller Name</td>
                  <td width={"10%"}>Category</td>
                  <td width={"10%"}>Coins Amount</td>
                  <td width={"10%"}>Quantity</td>
                  {/* <td width={"10%"}>GST</td> */}
                  <td width={"10%"}>Order ID</td>
                  <td width={"10%"}>Date</td>
                  <td width={"10%"}>Type</td>
                  <td width={"10%"}>Action</td>
                </tr>
              </thead>
              <tbody>
                {invoiceData === "No Data Found" ? (
                  <Box
                    sx={{
                      width: "100%",
                      mx: "auto",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      component="img"
                      src={EmptyData}
                      sx={{
                        height: "auto",
                        width: "200px",
                        mx: "auto",
                        mt: 5,
                      }}
                    />
                  </Box>
                ) : (
                  invoiceData?.data?.map((x, i) => {
                    let TotalAdditionalCostWtihoutGSTInBXI = 0;
                    let TotalPriceWithoutGST = 0;

                    x?.PurchaseOrderData?.ProductData?.map((el, idx) => {
                      if (x?.IsVoucher) {
                        TotalAdditionalCostWtihoutGSTInBXI +=
                          el?.AdditionalCost
                            ?.TotalAdditionalCostWtihoutGSTInBXI;
                      } else {
                        TotalAdditionalCostWtihoutGSTInBXI +=
                          el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
                      }
                      TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
                    });

                    const status = getStatus(x);
                    return (
                      <tr>
                        <td align="left" className="product-title-colum">
                          <img
                            src={
                              x?.PurchaseOrderData?.ProductData?.at(0)
                                ?.ProductImage
                            }
                            alt="img"
                            style={{ width: "35px", height: "35px" }}
                          />
                          <Box className="product-title-box">
                            <label>
                              {
                                x?.PurchaseOrderData?.ProductData?.at(0)
                                  ?.ProductName
                              }
                            </label>
                            <span>Order ID : {x?.OrderId}</span>
                          </Box>
                        </td>
                        <td className="font-12-500">
                          {
                            x?.PurchaseOrderData?.SellerDetails
                              ?.SellerCompanyName
                          }
                        </td>
                        <td className="font-12-500">
                          {
                            x?.PurchaseOrderData?.ProductData.at(0)
                              ?.ProductTypeName
                          }
                        </td>

                        <td className="black-text font-14-600-Kumbh">
                          <img
                            src={BxiCoinIcon}
                            alt=""
                            style={{ height: "15px", width: "15px" }}
                          />
                          {(
                            (TotalAdditionalCostWtihoutGSTInBXI || 0) +
                            (TotalPriceWithoutGST || 0)
                          ).toFixed(2)}
                          {/* - (x?.INRDetails?.TDS || 0) */}
                        </td>
                        <td className="black-text font-14-600-Kumbh">
                          {
                            x?.PurchaseOrderData?.ProductData.at(0)
                              ?.ProductQuantity
                          }
                        </td>
                        {/* <td className="black-text font-14-600-Kumbh">
                          {x?.PurchaseOrderData?.ProductData.at(0)?.GST}%
                        </td> */}
                        <td className="font-12-500">{x?.OrderId || "--"}</td>
                        <td className="font-12-500">
                          {x?.createdAt
                            ? new Date(x?.createdAt).toLocaleDateString(
                                "en-GB",
                                { timeZone: "UTC" }
                              )
                            : "--"}
                        </td>
                        <td>
                          <Box
                            className="status-box"
                            style={{ background: bgColors[x - 1] }}
                          >
                            <p style={{ color: colors[x - 1] }}>{status}</p>
                          </Box>
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <IconButton
                            aria-label="view"
                            style={iconStyle}
                            onClick={() => {
                              GetProductCategory(
                                x?.ProductCategoryName,
                                x?._id,
                                x?.ProductType,
                                x?.ListingType,
                                x?.VoucherType,
                                x?.ProductSubCategory
                              );
                            }}
                          >
                            <VisibilityIcon sx={{ color: "#445FD2" }} />
                          </IconButton>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </>
          )}
        </table>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
              xs: "100%",
            },
            mx: "auto",
          }}
          mt={2}
        >
          <Stack>
            <StyledPagination
              size="medium"
              count={initialPaginationCount}
              color="primary"
              showFirstButton
              showLastButton
              page={currentPage}
              onChange={handlePageChange}
              strokeWidth={currentPage}
            />
          </Stack>
        </Box>
      </div>
    );
  };

  const renderSaleItems = () => {
    const bgColors = [
      "rgba(217, 255, 233, 1)",
      "rgba(217, 255, 233, 1)",
      "rgba(255, 241, 229, 1)",
      "rgba(255, 241, 229, 1)",
      "rgba(217, 255, 233, 1)",
      "rgba(255, 241, 229, 1)",
      "rgba(217, 255, 233, 1)",
    ];
    const colors = [
      "rgba(39, 174, 96, 1)",
      "rgba(39, 174, 96, 1)",
      "rgba(242, 153, 74, 1)",
      "rgba(242, 153, 74, 1)",
      "rgba(39, 174, 96, 1)",
      "rgba(242, 153, 74, 1)",
      "rgba(39, 174, 96, 1)",
    ];

    return (
      <div>
        <table className="common-table">
          <thead>
            <tr>
              <td width={"20%"} align="left">
                Product Name
              </td>
              <td width={"10%"}>Buyer Name</td>
              <td width={"10%"}>Category</td>
              <td width={"10%"}>Coins Amount</td>
              <td width={"10%"}>Quantity</td>
              {/* <td width={"10%"}>GST</td> */}
              <td width={"10%"}>Order ID</td>
              <td width={"10%"}>Date</td>
              <td width={"10%"}>Type</td>
              <td width={"10%"}>Action</td>
            </tr>
          </thead>
          <tbody>
            {saleData === "No Data Found" ? (
              <Typography
                sx={{
                  textAlign: "center",
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  width: "500%",
                  mt: 5,
                }}
              >
                No Data Found
              </Typography>
            ) : (
              saleData?.data?.map((x, i) => {
                let TotalAdditionalCostWtihoutGSTInBXI = 0;
                let TotalPriceWithoutGST = 0;

                x?.PurchaseOrderData?.ProductData?.map((el, idx) => {
                  if (x?.IsVoucher) {
                    TotalAdditionalCostWtihoutGSTInBXI +=
                      el?.AdditionalCost?.TotalAdditionalCostWtihoutGSTInBXI;
                  } else {
                    TotalAdditionalCostWtihoutGSTInBXI +=
                      el?.AdditionCost?.TotalAdditionalCostWtihoutGSTInBXI;
                  }
                  TotalPriceWithoutGST += el?.TotalPriceWithoutGST;
                });

                const status = getStatus(x);
                return (
                  <tr>
                    <td align="left" className="product-title-colum">
                      <img
                        src={
                          x?.PurchaseOrderData?.ProductData.at(0)?.ProductImage
                        }
                        alt=""
                        style={{ height: "35px", width: "35px" }}
                      />
                      <Box className="product-title-box">
                        <label>
                          {x?.PurchaseOrderData?.ProductData.at(0)?.ProductName}
                        </label>
                        <span>Order ID : {x?.OrderId}</span>
                      </Box>
                    </td>
                    <td className="font-12-500">
                      {x?.PurchaseOrderData?.BuyerDetails?.BuyerCompanyName}
                    </td>
                    <td className="font-12-500">
                      {x?.PurchaseOrderData?.ProductData.at(0)?.ProductTypeName}
                    </td>

                    <td className="black-text font-14-600-Kumbh">
                      <img
                        src={BxiCoinIcon}
                        alt=""
                        style={{ height: "15px", width: "15px" }}
                      />
                      {(
                        (TotalAdditionalCostWtihoutGSTInBXI || 0) +
                        (TotalPriceWithoutGST || 0)
                      ).toFixed(2)}
                    </td>
                    <td className="black-text font-14-600-Kumbh">
                      {x?.PurchaseOrderData?.ProductData.at(0)?.ProductQuantity}
                    </td>
                    {/* <td className="black-text font-14-600-Kumbh">
                      {x?.PurchaseOrderData?.ProductData.at(0)?.GST}%
                    </td> */}
                    <td className="font-12-500">{x?.OrderId || "--"} </td>
                    <td className="font-12-500">
                      {x?.createdAt
                        ? new Date(x?.createdAt).toLocaleDateString("en-GB", {
                            timeZone: "UTC",
                          })
                        : "--"}
                    </td>
                    <td>
                      <Box
                        className="status-box"
                        style={{ background: bgColors[x - 1] }}
                      >
                        <p style={{ color: colors[x - 1] }}>{status}</p>
                      </Box>
                    </td>
                    <td>
                      <IconButton
                        aria-label="view"
                        style={iconStyle}
                        onClick={() => {
                          GetProductCategory(
                            x?.ProductCategoryName,
                            x?._id,
                            x?.ProductType,
                            x?.ListingType,
                            x?.VoucherType,
                            x?.ProductSubCategory
                          );
                        }}
                      >
                        <VisibilityIcon sx={{ color: "#445FD2" }} />
                      </IconButton>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: {
              xl: "100%",
              lg: "100%",
              md: "100%",
              sm: "100%",
              xs: "100%",
            },
            mx: "auto",
          }}
          mt={2}
        >
          <Stack>
            <StyledPagination
              size="medium"
              count={initialSellPaginationCount}
              color="primary"
              showFirstButton
              showLastButton
              page={sellCurrentPage}
              onChange={handleSellPageChange}
              strokeWidth={sellCurrentPage}
            />
          </Stack>
        </Box>
      </div>
    );
  };
  return (
    <div>
      <Box
        className="page-heading-section"
        style={{ justifyContent: "start", gap: "20px" }}
      >
        <Box className="page-heading">
          <h5>Invoice Details</h5>
          <Typography>View the invoices for all transactions.</Typography>
        </Box>
        <Tabs
          className="page-filters-tabs"
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Purchase"
          />
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "rgba(68, 95, 210, 1) !important",
                border: "1px solid rgba(68, 95, 210, 1) !important",
              },
              textTransform: "capitalize",
            }}
            className="inner-tab-label"
            label="Sale"
          />
        </Tabs>
        <Searchbar width={"55%"} onSendData={handleChildData} />
      </Box>
      <Divider />
      <Box sx={{ background: "rgba(250, 251, 252, 1)", p: 2 }}>
        <Box className="products-filters-row">
          {activeTab === 0 ? (
            <Box sx={buddge}>
              <Typography sx={textBudge}>
                Total Invoice(s) : {invoiceData?.TotalCount || 0}
              </Typography>
            </Box>
          ) : (
            <Box sx={buddge}>
              <Typography sx={textBudge}>
                Total Invoice(s) : {saleData?.TotalCount || 0}
              </Typography>
            </Box>
          )}
        </Box>
        {activeTab === 0 && <div>{renderPurchaseItems()}</div>}
        {activeTab === 1 && <div>{renderSaleItems()}</div>}
      </Box>
      <CommonDelete
        open={isDeleteModalOpen}
        onClose={handleClose}
        onDelete={() => {
          handleDelete(deleteProductId);
        }}
        text="Are you sure you want to delete this invoice?"
        ButtonLabel="Delete"
        cancelButtonLabel="Cancel"
      />
      <CommonEdit
        key={productEditData?._id}
        open={isEditModalOpen}
        onClose={handleEditClose}
        onSave={() => {
          handleEditSave(productEditData);
        }}
        text="Edit Invoice"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        fieldOne="Amount"
        fieldTwo="Quantity"
        fieldThree="Date"
        fieldFour="Type Of Invoice"
        initialProductData={productEditData}
        refetch={invoiceRefetch}
      />
    </div>
  );
};

export default Invoice;
const iconStyle = {
  color: "rgba(68, 95, 210, 1)",
};

const buddge = {
  display: "flex",
  height: 40,
  padding: 2,
  justifyContent: "center",
  alignItems: "center",
  gap: 5,
  borderRadius: 20,
  background: "var(--White, #FFF)",
};

const textBudge = {
  color: "var(--Grey-Blue-60, #6B7A99)",
  fontFeatureSettings: "clig off, liga off",
  fontFamily: "Poppins",
  fontSize: 12,
  fontStyle: "normal",
  fontWeight: 600,
};
