import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { GetTokenFunc } from "../../utils/GetToken";

const UpdateData = async (data) => {
  // console.log('apidata', data);
  await axios.put(
    `/api/v1/admin//AdminCompanyDetails/EditAddToCardProduct?id=${data?.id}`,
    {
      Price: data.TotalPrice,
      Quantity: data.ProductQuantity,
    },
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
};
export const useUpdateData = () => {
  return useMutation(UpdateData);
};

const bingoDealsData = async (dataTypes, currentPage, search) => {
  return await axios.get(
    `/api/v1/admin/AdminCompanyDetails/ProductsOnAddToCard?${dataTypes}=${dataTypes}&page=${currentPage}&search=${search}`,
    {
      headers: {
        authorization: `bearer ${GetTokenFunc()}`,
      },
    }
  );
};
export const useFetchData = (dataTypes, currentPage, search) => {
  return useQuery(["bingoDealsData", dataTypes, currentPage, search], () =>
    bingoDealsData(dataTypes, currentPage, search)
  );
};
