import React, { useState, useEffect } from "react";
import "/node_modules/react-vis/dist/style.css";
import {
  Paper,
  Box,
  Typography,
  Grid,
  Modal,
  Button,
  Tabs,
  Tab,
  Divider,
  Stack,
} from "@mui/material";
import { useFetchTopProducts } from "../../Hooks/AllRecords/useAllRecords";
import axios from "axios";
import PageLoader from "../CommonActions/PageLoader";
import CompanyTypeComp from "../AllRecords/CompanyTypeComp";

const TopProduct = () => {
  const [category, setCategory] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [filterType, setFilterType] = useState("");
  const [AllCompanyName, setAllCompanyName] = useState([]);

  const filterByCompany = async () => {
    await axios
      .get(`product/get_allproducts_bycompany`, { withCredentials: true })
      .then((res) => {
        setAllCompanyName(res.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    filterByCompany();
  }, []);

  const {
    data: fetchTopProducts,
    isLoading: TopProductsLoading,
    refetch: TopProductsRefetch,
  } = useFetchTopProducts(category, selectedCompany, filterType);

  const handleCompanyChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedCompany(selectedValue);
  };

  useEffect(() => {
    TopProductsRefetch();
  }, [selectedCompany, category, filterType]);

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={HeadingStyle}>
        <Typography sx={TokenText}>Top Products</Typography>
        <Box className="products-filters-row">
          <Box className="category-filter">
            <Typography
              sx={{
                width: "40px",
                fontSize: "10px",
                fontWeight: 600,
              }}
            >
              Category
            </Typography>
            <Box
              sx={{
                width: "auto",
              }}
            >
              <select
                className="selectDropdownStyle"
                style={{ border: "1px solid #E6E9EE" }}
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">All</option>
                <option value="63e38bddcc4c02b8a0c94b8a">Others</option>
                <option value="63e38b9ccc4c02b8a0c94b6f">Lifestyle</option>
                <option value="63e38bb9cc4c02b8a0c94b7b">FMCG</option>
                <option value="63e38b91cc4c02b8a0c94b69">Media</option>
                <option value="63e38ba3cc4c02b8a0c94b72">Hotel</option>
                <option value="63e38bbfcc4c02b8a0c94b7e">
                  Airlines Tickets
                </option>
                <option value="63e38bc6cc4c02b8a0c94b81">Office Supply</option>
                <option value="63e38bb3cc4c02b8a0c94b78">Mobility</option>
                <option value="63e38bcecc4c02b8a0c94b84">Electronics</option>
                <option value="63e38b96cc4c02b8a0c94b6c">Textile</option>
                <option value="64218b189fe1b6ae750c11bd">
                  Entertainment & Events
                </option>
                <option value="63e38bd5cc4c02b8a0c94b87">QSR</option>
              </select>
            </Box>
          </Box>

          <Box className="category-filter">
            <Typography
              sx={{
                width: "40px",
                fontSize: "10px",
                fontWeight: 600,
              }}
            >
              Company
            </Typography>
            <Box
              sx={{
                width: "auto",
              }}
            >
              <select
                className="selectDropdownStyle"
                style={{ border: "1px solid #E6E9EE" }}
                onChange={handleCompanyChange}
                value={selectedCompany}
              >
                <option value="">All</option>
                {AllCompanyName?.map((name, index) => (
                  <option key={index} value={name?.companyName}>
                    {name?.companyName}
                  </option>
                ))}
              </select>
            </Box>
          </Box>

          <Box className="category-filter">
            <Typography
              sx={{
                width: "20px",
                fontSize: "10px",
                fontWeight: 600,
              }}
            >
              Type
            </Typography>
            <Box
              sx={{
                width: "auto",
              }}
            >
              <select
                className="selectDropdownStyle"
                style={{ border: "1px solid #E6E9EE" }}
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
              >
                <option value="">All</option>
                <option value="BestSellar">BestSellar</option>
                <option value="ReadyStock">ReadyStock</option>
              </select>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={0} sx={{ p: 0.5 }}>
        <Grid
          container
          sx={{
            width: "auto",
            height: "auto",
            minHeight: "38px",
            borderRadius: "12px",
            bgcolor: "rgba(228, 206, 238, 1)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            px: "10px",
          }}
        >
          <Grid
            item
            xl={0.5}
            lg={0.5}
            md={0.5}
            sm={0.5}
            xs={0.5}
            sx={{ ...text, fontWeight: 600 }}
          >
            No.
          </Grid>
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={5}
            sx={{ ...text, fontWeight: 600 }}
          >
            Product Name
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            sx={{ ...text, fontWeight: 600 }}
          >
            Company Name
          </Grid>
          <Grid
            item
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={2}
            sx={{ ...text, fontWeight: 600 }}
          >
            Price
          </Grid>
          <Grid
            item
            xl={1.5}
            lg={1.5}
            md={1.5}
            sm={1.5}
            xs={1.5}
            sx={{ ...text, fontWeight: 600 }}
          >
            Buy Count
          </Grid>
        </Grid>
        {TopProductsLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mt: 10,
              ml: 35,
            }}
          >
            <PageLoader />
          </Box>
        ) : (
          <Box
            sx={{
              overflow: "scroll",
              maxHeight: "300px",
              overflow: "scroll",
              mt: 1.5,
            }}
          >
            {fetchTopProducts?.map((data, idx) => {
              return (
                <Grid
                  container
                  sx={{
                    width: "auto",
                    height: "auto",
                    minHeight: "38px",
                    borderRadius: "12px",
                    bgcolor: "rgba(250, 251, 252, 1)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    px: "10px",
                  }}
                  mt={1}
                >
                  <Grid
                    item
                    xl={0.5}
                    lg={0.5}
                    md={0.5}
                    sm={0.5}
                    xs={0.5}
                    sx={{ ...text, fontWeight: 600 }}
                  >
                    {idx + 1}
                  </Grid>
                  <Grid
                    item
                    xl={5}
                    lg={5}
                    md={5}
                    sm={5}
                    xs={5}
                    sx={{
                      ...text,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={
                        data?.VoucherImages?.at(0)?.url ||
                        data?.ProductImages?.at(0)?.url
                      }
                      sx={{
                        width: "auto",
                        height: "20px",
                        border: "1px solid black",
                      }}
                    />
                    <Typography sx={text}>
                      <span
                        style={{
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {data?.ProductName}
                      </span>
                      <span
                        style={{
                          ...text,
                          color: "rgba(114, 114, 114, 1)",
                        }}
                      >
                        <CompanyTypeComp companyTypeId={data?.ProductType} />
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={3}
                    xs={3}
                    sx={{
                      ...text,
                      color: "rgba(114, 114, 114, 1)",
                      display: "-webkit-box",
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data?.SellerCompanyName}
                  </Grid>
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    sx={{
                      ...text,
                      color: "rgba(114, 114, 114, 1)",
                    }}
                  >
                    {data?.ProductsVariantions?.at(0)?.DiscountedPrice} ₹
                  </Grid>
                  <Grid
                    item
                    xl={1.5}
                    lg={1.5}
                    md={1.5}
                    sm={1.5}
                    xs={1.5}
                    sx={{
                      ...text,
                      color: "rgba(114, 114, 114, 1)",
                    }}
                  >
                    {data?.ProductAnalysis?.ProductPurchaseCount}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        )}{" "}
      </Box>
    </Box>
  );
};

export default TopProduct;

const opt = {
  height: "35px",
  borderRadius: "12px",
  border: "1px solid #E6E9EE",
  paddingLeft: "15px",
  paddingRight: "15px",
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#AFAFAF",
  maxWidth: 90,
  whiteSpace: "nowrap",
  "& fieldset": { border: "none" },
  background: "#fff",
  boxShadow: "0px 4px 8px rgba(220, 220, 220, 1)",
  borderRadius: "12px 12px 12px 12px",
};

const text = {
  fontFamily: "Poppins",
  fontSize: "11px",
  fontWeight: 500,
  textAlign: "left",
};

const HeadingStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  p: 1,
};

const TokenText = {
  fontSize: { xl: "22px", lg: "22px", md: "22px", sm: "20px", xs: "18px" },
  fontWeight: 600,
  fontFamily: "Poppins",
  color: "#393D5E",
  textAlign: "left",
};
