import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './Popup.Module.css';

const Popup = ({
  openPopup,
  width,
  handleClose,
  popupTitle = '',
  popupContent = '',
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
    handleClose(isOpen);
  };

  useEffect(() => {
    setIsOpen(openPopup);
  }, [openPopup]);

  return (
    <>
      <Dialog
        open={isOpen}
        className="custom-popup"
      //  onClose={togglePopup} 
        fullWidth
        maxWidth={false}
        PaperProps={{
          style: {
            width: width ? width : '18%',
            borderRadius: '13px',
            color: '#6B7A99',
            // Add any other styles you want to customize the dialog
          },
        }}
      >
        <DialogTitle>
          {popupTitle}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 25,
              top: 8,
              zIndex: 1,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ p: 2, display: 'flex', justifyContent: 'ceneter' }}
        >
          {popupContent}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Popup;
