import axios from "axios";
import React, { useEffect, useState } from "react";

const TaxInvoiceAmountCalculator = (props) => {
  const [membershipData, setMembershipData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { Data } = props;
  const { OrderData } = Data;

  const buyerId = OrderData?.IsVoucher
    ? OrderData?.BuyerCompanyId
    : OrderData?.BuyerDetails?.BuyerCompanyId || OrderData?.BuyerCompanyId;

  useEffect(() => {
    const getCompanyByID = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`/company/get_company/${buyerId}`, {
          withCredentials: true,
        });
        const data =
          response.data.memberships[response.data.memberships.length - 1]
            .MembershipPlan;
        setMembershipData(data);
      } catch (error) {
        setError("Failed to fetch company data");
      } finally {
        setIsLoading(false);
      }
    };

    getCompanyByID();
  }, [buyerId]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>--</div>;

  const calculateCharges = () => {
    const charges = [];

    if (!OrderData?.IsMedia && !OrderData?.IsVoucher) {
      charges.push(
        {
          name: "Packaging charges",
          gst: Number(OrderData?.PackagingData?.packagingcostgstfee || 0),
          hsn: "998540",
          taxableValue: Number(OrderData?.PackagingData?.packagingcosts || 0),
          totalTaxAmount:
            (Number(OrderData?.PackagingData?.packagingcosts || 0) *
              Number(OrderData?.PackagingData?.packagingcostgstfee || 0)) /
            100,
        },
        {
          name: "Logistics Charges",
          gst:
            OrderData?.BuyerChoosedTransportation?.TransportationType ===
            "notransportation"
              ? 0
              : Number(OrderData?.Transportationdata?.gstFee || 0),
          hsn: "996519",
          taxableValue:
            OrderData?.BuyerChoosedTransportation?.TransportationType ===
            "notransportation"
              ? 0
              : Number(OrderData?.Transportationdata?.transportationfee || 0),
          totalTaxAmount:
            OrderData?.BuyerChoosedTransportation?.TransportationType ===
            "notransportation"
              ? 0
              : (Number(OrderData?.Transportationdata?.transportationfee || 0) *
                  Number(OrderData?.Transportationdata?.gstFee || 0)) /
                100,
        },
        {
          name: "Insurance Cost",
          gst: Number(OrderData?.INRDetails?.InsuranceCostGst || 0),
          hsn: "997135",
          taxableValue: Number(OrderData?.INRDetails?.InsuranceCost || 0),
          totalTaxAmount:
            (Number(OrderData?.INRDetails?.InsuranceCost || 0) *
              Number(OrderData?.INRDetails?.InsuranceCostGst || 0)) /
            100,
        }
      );
    }

    return charges;
  };

  const calculateBXICommission = () => {
    const bxiCommission =
      OrderData?.PurchaseOrderData?.CouponData?.BXICommission ||
      Number(membershipData?.GST);

    let taxableValue = 0;
    let totalTaxAmount = 0;

    if (OrderData?.IsVoucher) {
      const totalTokanAmount = OrderData?.PurchaseOrderData?.ProductData.reduce(
        (sum, item) => sum + item.TotalPriceWithoutGST,
        0
      );
      const adCostBxiTotal = OrderData?.PurchaseOrderData?.ProductData.reduce(
        (sum, item) => {
          return (
            sum +
            (
              item.AdditionalCost?.AdditionCostArrayData ||
              item?.AdditionCost?.AdditionCostArrayData ||
              []
            ).reduce((adSum, adCost) => {
              if (adCost.currencyType === "BXITokens") {
                return (
                  adSum +
                  (adCost.AdCostApplicableOn === "PerUnit"
                    ? adCost.PriceWithoutGST * item.ProductQuantity
                    : adCost.PriceWithoutGST)
                );
              }
              return adSum;
            }, 0)
          );
        },
        0
      );

      taxableValue =
        ((totalTokanAmount + adCostBxiTotal) * bxiCommission) / 100;
      totalTaxAmount = (taxableValue * 18) / 100;
    } else {
      taxableValue =
        (Number(OrderData?.PITotals.TotalCoinsWithAdditionalCost || 0) *
          bxiCommission) /
        100;
      totalTaxAmount = (taxableValue * 18) / 100;
    }

    return {
      name: "BXI Commission",
      gst: 18,
      commission: bxiCommission,
      hsn: "996211",
      taxableValue,
      totalTaxAmount,
    };
  };

  const calculateTotalAmount = () => {
    const charges = calculateCharges();
    const bxiCommission = calculateBXICommission();

    const productTaxableAmount =
      OrderData?.PurchaseOrderData?.ProductData.reduce((sum, item) => {
        let amount;
        if (OrderData?.IsMedia) {
          amount =
            item.DiscountedPrice *
            item.TimelineToBought *
            item.ProductQuantity *
            item.BoughtSeconds;
        } else if (OrderData?.IsVoucher) {
          amount = item.PriceWithoutGST * item.ProductQuantity;
        } else {
          amount = item.PriceWithoutGST * item.ProductQuantity;
        }
        return sum + amount;
      }, 0);

    const adCostTaxableAmount =
      OrderData?.PurchaseOrderData?.ProductData.reduce((sum, item) => {
        const itemAdCost = (
          item.AdditionalCost?.AdditionCostArrayData ||
          item?.AdditionCost?.AdditionCostArrayData ||
          []
        ).reduce((adSum, adCost) => adSum + adCost.TotalTaxableAmount, 0);
        return sum + itemAdCost;
      }, 0);

    const chargesTaxableValue = charges.reduce(
      (sum, charge) => sum + charge.taxableValue,
      0
    );
    const chargesTaxAmount = charges.reduce(
      (sum, charge) => sum + charge.totalTaxAmount,
      0
    );

    const amountBeforeTax =
      chargesTaxableValue +
      productTaxableAmount +
      adCostTaxableAmount +
      bxiCommission.taxableValue;

    let totalTaxAmount;
    if (OrderData?.IsMedia) {
      let totaladditionalcostGst = 0;
      OrderData?.PurchaseOrderData?.ProductData?.map((item) => {
        item?.AdditionCost?.AdditionCostArrayData?.map((res) => {
          totaladditionalcostGst +=
            (res?.TotalTaxableAmount * res?.AdCostGST) / 100;
        });
      });
      totalTaxAmount =
        OrderData?.PITotals.TotalGstAmount +
        totaladditionalcostGst +
        bxiCommission.totalTaxAmount;
    } else if (OrderData?.IsVoucher) {
      const totalGstValue = OrderData?.PurchaseOrderData?.ProductData.reduce(
        (sum, item) => sum + item.TotalGST,
        0
      );
      const totalAdCostGstAmount =
        OrderData?.PurchaseOrderData?.ProductData.reduce((sum, item) => {
          return (
            sum +
            (
              item.AdditionalCost?.AdditionCostArrayData ||
              item?.AdditionCost?.AdditionCostArrayData ||
              []
            ).reduce((adSum, adCost) => {
              return (
                adSum +
                Number(
                  adCost.AdCostApplicableOn === "PerUnit"
                    ? (adCost.PriceWithoutGST *
                        item.ProductQuantity *
                        adCost.AdCostGST) /
                        100
                    : (adCost.PriceWithoutGST * adCost.AdCostGST) / 100
                )
              );
            }, 0)
          );
        }, 0);
      totalTaxAmount =
        totalGstValue +
        totalAdCostGstAmount +
        chargesTaxAmount +
        bxiCommission.totalTaxAmount;
    } else {
      totalTaxAmount =
        OrderData?.PITotals.TotalGstAmount +
        OrderData?.PITotals.TotalAdCostGstAmount +
        chargesTaxAmount +
        bxiCommission.totalTaxAmount;
    }

    return amountBeforeTax + totalTaxAmount;
  };

  const invoiceTotalAmount = calculateTotalAmount();

  return <div>{invoiceTotalAmount ? invoiceTotalAmount.toFixed(2) : "--"}</div>;
};

export default TaxInvoiceAmountCalculator;
