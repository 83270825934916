import { useQuery, useMutation } from "react-query";
import axios from "axios";

/* COMPANY DETAILS */

const CompanyDetails = async (data) => {
  return await axios.post("/", { ...data });
};
export const useCompanyDetails = () => {
  return useMutation(CompanyDetails);
};

// GET COMPMAY DETAILS
export const useGetCompanyDetails = () => {
  return useQuery(["companydetails"], async () => {
    return await axios.get("api/v1/onboard-company");
  });
};
const resendOtp = async (data) => {
  return await axios.put(`api/v1/auth/resend-otp/`, { ...data });
};
export const useResendOtp = () => {
  return useMutation(resendOtp);
};

const FetchCompanyByID = async (id) => {
  return await axios.get(`api/v1/company/get_company/${id}`);
};

export const useGetCompanyById = (id, refetchInterval) => {
  return useQuery(
    ["getcompanybyid", id],
    async () => {
      return await FetchCompanyByID(id);
    },
    {
      refetchInterval: refetchInterval ?? false,
    }
  );
};

