import { createSlice } from "@reduxjs/toolkit";

export const GetTotalMemberShip = createSlice({
    name: "MemberShips",
    initialState: {
        MemberShipCounts: []
    },
    reducers: {
        membership_count_Request: (state) => {
            state.loading = true;
            state.MemberShipCounts = [];
        },
        membership_count_Success: (state, action) => {
            state.loading = false;
            state.MemberShipCounts = action.payload;
        },
        membership_count_Fail: (state, action) => {
            state.loading = false;
            state.MemberShipCounts = action.payload
        }
    }
})

export const { membership_count_Fail, membership_count_Request, membership_count_Success } = GetTotalMemberShip.actions

export default GetTotalMemberShip.reducer