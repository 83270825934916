import {
  Box,
  Button,
  Fade,
  Grid,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
  IconButton,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GetTokenFunc } from "../../utils/GetToken";
import PageLoader from "../CommonActions/PageLoader";
import React, { useEffect, useRef, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { StyledPagination } from "../../common/StyledPagination";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIconDialog from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import Searchbar from "../../common/Searchbar";

const AddCouponCode = () => {
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const [AllCompanyName, setAllCompanyName] = useState([]);
  const [FirstselectedCompany, setFirstSelectedCompany] = useState("");
  const [SecondselectedCompany, setSecondSelectedCompany] = useState("");
  const [OnetoMany, setOnetoMany] = useState("");
  const [CouponCode, setCouponCode] = useState("");
  const [TokenAmount, setTokenAmount] = useState("");
  const [BXICommission, setBXICommission] = useState("");
  const [AllCoupon, setAllCoupon] = useState();
  const [TotalPages, setTotalPages] = useState();
  const [Loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [CouponId, setCouponId] = useState();
  const [CouponData, setCouponData] = useState();
  const [CouponNote, setCouponNote] = useState();
  const [ChangeEdit, setChangeEdit] = useState(true);
  const [searchData, setSearchData] = useState("");
  const [initialPaginationCount, setInitialPaginationCount] = useState(
    currentPage || 1
  );

  // Callback function to receive data from child component
  const handleChildData = (dataFromChild) => {
    // Handle data received from child component
    setSearchData(dataFromChild);
  };

  const navigate = useNavigate();

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleClose = () => {
    setOpen(false);
    setCouponNote();
    setChangeEdit(true);
  };

  const handleClickOpen = (data) => {
    setOpen(true);
    setCouponId(data?._id);
    setCouponData(data);
  };

  const filterByCompany = async () => {
    await axios
      .get(`/api/v1/admin/FetchAllCompanys`, {
        headers: {
          authorization: `Bearer ${GetTokenFunc()}`,
        },
      })
      .then((res) => {
        setAllCompanyName(res.data);
      })
      .catch((err) => {});
  };

  const GetallCoupon = async () => {
    setLoader(true);
    await axios
      .get(
        `/private_deal/get_all_coupon?page=${currentPage}&search=${searchData}`,
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        setAllCoupon(res?.data);
        setTotalPages(res?.data?.totalPages);
        setLoader(false);
      })
      .catch((err) => {});
  };

  const AddCouponData = async () => {
    if (
      TokenAmount === "" ||
      BXICommission === "" ||
      FirstselectedCompany === "" ||
      SecondselectedCompany === "" ||
      OnetoMany === ""
    ) {
      toast.error("Please Fill Proper Information", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      await axios
        .post(
          `/private_deal/create_coupon`,
          {
            BuyerCompany: FirstselectedCompany,
            SellerCompany: SecondselectedCompany,
            TokenAmount: TokenAmount,
            BXICommission: BXICommission,
            OnetoMany: OnetoMany,
          },
          {
            headers: {
              authorization: `Bearer ${GetTokenFunc()}`,
            },
          }
        )
        .then((res) => {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setCouponCode("");
          setTokenAmount("");
          setBXICommission("");
          setSecondSelectedCompany("");
          setFirstSelectedCompany("");
          GetallCoupon();
        })
        .catch((err) => {});
    }
  };

  const handleUpdateCouponStatus = async (e, id) => {
    await axios
      .put(
        `/private_deal/update_coupon/${id}`,
        {
          isActiveKey: e.target.checked,
        },
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        GetallCoupon();
      })
      .catch((err) => {});
  };

  const handleAddCouponNote = async () => {
    await axios
      .post(
        `/private_deal/add_coupon_note/${CouponId}`,
        {
          Note: CouponNote,
        },
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleClose();
        setCouponNote();
        GetallCoupon();
      })
      .catch((err) => {});
  };

  const handleUpdateCouponNote = async () => {
    await axios
      .put(
        `/private_deal/update_coupon_note/${CouponId}`,
        {
          Note: CouponNote,
        },
        {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        }
      )
      .then((res) => {
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handleClose();
        setCouponNote();
        GetallCoupon();
        setCouponData();
      })
      .catch((err) => {});
  };

  const handleDeleteCoupon = async (id) => {
    let alert = window.confirm("Are you sure you want to delete this coupon?");
    if (alert) {
      await axios
        .delete(`/private_deal/delete_coupon/${id}`, {
          headers: {
            authorization: `Bearer ${GetTokenFunc()}`,
          },
        })
        .then((res) => {
          toast.success(res.data.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          GetallCoupon();
        })
        .catch((err) => {});
    } else {
      console.log("not deleted");
    }
  };

  const handleFirstCompanyChange = (event) => {
    const selectedValue = event.target.value;
    setFirstSelectedCompany(selectedValue);
  };
  const handleSecondCompanyChange = (event) => {
    const selectedValue = event.target.value;
    setSecondSelectedCompany(selectedValue);
  };

  useEffect(() => {
    if (TotalPages) {
      setInitialPaginationCount(TotalPages);
    }
  }, [TotalPages]);

  useEffect(() => {
    filterByCompany();
  }, []);

  useEffect(() => {
    GetallCoupon();
  }, [currentPage, searchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchData]);

  return (
    <Paper
      sx={{
        width: "100%",
        bgcolor: "transparent",
        maxHeight: "90vh",
        overflow: "scroll",
      }}
      elevation={0}
    >
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} sx={BoxStyle}>
        <Typography
          sx={{
            width: "100%",
            textAlign: "center",
            color: "#6b7a99",
            fontSize: "16px",
            fontWeight: "bold",
            alignItems: "center",
            height: "30px",
          }}
        >
          Generate Coupon
        </Typography>
        <Box
          sx={{
            ...BoxStyle,
            width: "100%",
            flexDirection: "row",
            justifyContent: "center",
            gap: "5%",
          }}
        >
          <Box
            className="marketplace-filters"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={LabelStyle}>Select Buyer Company</label>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <select
                onChange={handleFirstCompanyChange}
                value={FirstselectedCompany}
              >
                <option disabled value="">
                  --- Select ---
                </option>
                {AllCompanyName.filter(
                  (el) => el?._id !== SecondselectedCompany
                ).map((el, index) => {
                  return (
                    <option key={index} value={el?._id}>
                      {el?.companyName}
                    </option>
                  );
                })}
              </select>
            </Box>
          </Box>
          <Box
            className="marketplace-filters"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={LabelStyle}>Select Seller Company</label>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <select
                onChange={handleSecondCompanyChange}
                value={SecondselectedCompany}
              >
                <option disabled value="">
                  --- Select ---
                </option>
                {AllCompanyName.filter(
                  (el) => el?._id !== FirstselectedCompany
                ).map((el, index) => {
                  return (
                    <option key={index} value={el?._id}>
                      {el?.companyName}
                    </option>
                  );
                })}
              </select>
            </Box>
          </Box>
          <Box
            className="marketplace-filters"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={LabelStyle}>Add Amount of Token</label>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                variant="outlined"
                min={0}
                value={TokenAmount}
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, "");
                  setTokenAmount(result);
                }}
                name="TokenAmount"
              />
            </Box>
          </Box>
          <Box
            className="marketplace-filters"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={LabelStyle}>Add BXI Commission</label>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                variant="outlined"
                min={0}
                maxLength={2}
                value={BXICommission}
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, "");
                  setBXICommission(result);
                }}
                name="Commission"
              />
            </Box>
          </Box>
          <Box
            className="marketplace-filters"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <label style={LabelStyle}>One to One/Many</label>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <select
                value={OnetoMany}
                onChange={(e) => {
                  setOnetoMany(e.target.value);
                }}
              >
                <option disabled value="">
                  --- Select ---
                </option>

                <option value={false}>One to One</option>
                <option value={true}>One to Many</option>
              </select>
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            ...BoxStyle,
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            sx={planbtn}
            onClick={() => {
              AddCouponData();
            }}
          >
            Generate Coupon
          </Button>
        </Box>
        <Searchbar width={"100%"} onSendData={handleChildData} />
      </Grid>
      {Loader ? (
        <Box
          mt={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            pl: "45%",
          }}
        >
          <PageLoader />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            maxWidth: "80vw",
            minWidth: "80vw",
            height: "auto",
          }}
        >
          <Paper
            elevation={0}
            sx={{
              width: "100%",
            }}
          >
            <TableContainer sx={TableContainerStyle}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>No.</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Buyer Company
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Seller Company
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Coupon Code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Order Id</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        Amount of Token
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>
                        BXI Commission
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Active</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Is Used</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>One to</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Date</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Used Date</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Delete</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Note</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableTitleStyle}>Agreement</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {AllCoupon?.data?.length ? (
                    AllCoupon?.data?.map((item, idx) => {
                      return (
                        <TableRow key={idx}>
                          <TypographyComponent>{idx + 1}</TypographyComponent>
                          <TypographyComponent width={250}>
                            {item?.buyerCompanyData === "All"
                              ? "All"
                              : item?.buyerCompanyData?.companyName}
                          </TypographyComponent>
                          <TypographyComponent width={250}>
                            {item?.sellerCompanyData === "All"
                              ? "All"
                              : item?.sellerCompanyData?.companyName}
                          </TypographyComponent>
                          <TypographyComponent>
                            {item?.CouponCode}
                          </TypographyComponent>
                          <TypographyComponent>
                            {item?.OrderId ? item?.OrderId : "--"}
                          </TypographyComponent>
                          <TypographyComponent width={150}>
                            {item?.TokenAmount}
                          </TypographyComponent>
                          <TypographyComponent width={100}>
                            {item?.BXICommission}
                          </TypographyComponent>
                          <TypographyComponent>
                            <FormControlLabel
                              control={
                                <IOSSwitch
                                  checked={item?.IsActive}
                                  onChange={(e) => {
                                    handleUpdateCouponStatus(e, item?._id);
                                  }}
                                  name="isActive"
                                />
                              }
                            />
                          </TypographyComponent>
                          <TypographyComponent width={100}>
                            <span
                              style={{
                                fontWeight: 600,
                                color:
                                  item?.IsUsed === true ? "red" : "#78778B",
                              }}
                            >
                              {item?.IsUsed === true ? "Used" : "Not Used"}
                            </span>
                          </TypographyComponent>
                          <TypographyComponent width={100}>
                            {item?.OnetoMany === true ? "Many" : "One"}
                          </TypographyComponent>
                          <TypographyComponent width={100}>
                            {new Date(item?.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )}
                          </TypographyComponent>
                          <TypographyComponent width={100}>
                            {item?.UsedDate
                              ? new Date(item?.UsedDate).toLocaleDateString(
                                  "en-GB",
                                  {
                                    year: "2-digit",
                                    month: "2-digit",
                                    day: "2-digit",
                                  }
                                )
                              : "--"}
                          </TypographyComponent>
                          <TypographyComponent>
                            <IconButton
                              aria-label=""
                              style={{ color: "rgba(68, 95, 210, 1)" }}
                            >
                              <Delete
                                onClick={() => {
                                  handleDeleteCoupon(item?._id);
                                }}
                              />
                            </IconButton>
                          </TypographyComponent>
                          <TypographyComponent>
                            {item?.Note ? (
                              <IconButton
                                aria-label=""
                                style={{ color: "rgba(68, 95, 210, 1)" }}
                                onClick={() => {
                                  handleClickOpen(item);
                                  setCouponNote(item?.Note);
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                aria-label=""
                                style={{ color: "rgba(68, 95, 210, 1)" }}
                                onClick={() => {
                                  handleClickOpen(item);
                                }}
                              >
                                <Edit />
                              </IconButton>
                            )}
                          </TypographyComponent>
                          <TypographyComponent>
                            {item?.Agreements?.length > 0 && item?.IsUsed ? (
                              <IconButton
                                aria-label=""
                                style={{
                                  color: "rgba(68, 95, 210, 1)",
                                }}
                                onClick={() => {
                                  navigate(`/admindashboard/creditterms`, {
                                    state: {
                                      CouponData: item,
                                    },
                                  });
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ) : (
                              "--"
                            )}
                          </TypographyComponent>
                        </TableRow>
                      );
                    })
                  ) : (
                    <Typography
                      sx={{
                        width: "100%",
                        textAlign: "center",
                        color: "#6b7a99",
                      }}
                    >
                      No Data Available
                    </Typography>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"xs"}
        sx={{
          backdropFilter: "blur(2px)",
        }}
        PaperProps={{
          sx: {
            maxHeight: 600,
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "80%",
                mx: "auto",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "28px",
                  color: "#6B7A99",
                  textAlign: "center",
                }}
              >
                {CouponData?.Note ? "Note" : "Add Note"}
              </Typography>
            </Box>
            <Box>
              <CloseIconDialog
                sx={{
                  color: "#667085",
                  fontSize: "25px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleClose();
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "scroll",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "auto",
              display: "flex",
              flexDirection: "row",
              justifyContent: CouponData?.Note ? "space-between" : "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <input
              autoFocus
              type="text"
              disabled={CouponData?.Note ? ChangeEdit : false}
              value={CouponNote}
              onChange={(e) => setCouponNote(e.target.value)}
              style={{
                width: "80%",
                height: "40px",
                p: 1,
                background: ChangeEdit ? "transparent" : "#f3f6f9",
                color: "#1f1f1f",
                borderRadius: 7,
                border: ChangeEdit ? "1px solid grey" : "1px solid #f3f6f9",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontSize: "13px",
              }}
              placeholder="Eg. Hello World"
            />
            {CouponData?.Note ? (
              <Edit
                style={{ cursor: "pointer", color: "rgba(68, 95, 210, 1)" }}
                onClick={() => {
                  if (ChangeEdit === false) {
                    setChangeEdit(true);
                  } else {
                    setChangeEdit(false);
                  }
                }}
              />
            ) : null}
          </Box>
        </DialogContent>
        <DialogTitle id="responsive-dialog-title">
          {CouponData?.Note ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {ChangeEdit ? null : (
                <>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleUpdateCouponNote();
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      background: "none",
                      border: "0.7px solid #EBEDEE",
                      color: "#445FD2",
                    }}
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  handleAddCouponNote();
                }}
              >
                Add
              </Button>
              <Button
                variant="outlined"
                sx={{
                  background: "none",
                  border: "0.7px solid #EBEDEE",
                  color: "#445FD2",
                }}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          )}
        </DialogTitle>
      </Dialog>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 1,
        }}
      >
        {AllCoupon?.data?.length ? (
          <StyledPagination
            size="medium"
            count={initialPaginationCount}
            color="primary"
            showFirstButton
            showLastButton
            page={currentPage}
            onChange={handlePageChange}
            strokeWidth={currentPage}
          />
        ) : null}
      </Box>
    </Paper>
  );
};

export default AddCouponCode;

const BoxStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const planbtn = {
  bgcolor: "#445FD2",
  boxShadow: "none",
  textTransform: "none",
  width: "150px",
  height: "30px",
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  my: 2,
};

const TableTextStyle = {
  fontFamily: "Kumbh Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "12px",
  color: "#78778B",
};

const LabelStyle = {
  color: "#6b7a99",
  fontFamily: "Poppins",
  fontWeight: 500,
  fontSize: "10px",
  width: "auto",
  display: "inline-block",
};

const TableTitleStyle = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "21px",
  color: "#6B7A99",
};

const TextStyleCommon = {
  fontFamily: "Poppins",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "18px",
  color: "#6B7A99",
  width: "auto",
};

const TypographyComponent = ({ children, ...props }) => {
  return (
    <TableCell>
      <Typography sx={{ ...TextStyleCommon, width: props.width }}>
        {children}
      </Typography>
    </TableCell>
  );
};

const TableContainerStyle = {
  minHeight: "500px",
  maxHeight: "500px",
  overflowY: "auto",
  "::-webkit-scrollbar": {
    width: "3px",
    display: "flex",
    borderRadius: "3px",
    maxHeight: "6px",
    minHeight: "6px",
    minWidth: "6px",
    bgcolor: "#e8eaf1",
  },
  "::-webkit-scrollbar-thumb": {
    background: "#445fd2",
    borderRadius: "3px",
    cursor: "pointer",
  },
  "::-webkit-scrollbar-thumb:vertical": {
    maxWidth: "6px",
    minWidth: "6px",
  },
  "::-webkit-scrollbar-thumb:horizontal": {
    maxHeight: "6px",
    minHeight: "6px",
  },
};
