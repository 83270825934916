export const chatText = {
  color: '#6B7A99',
  fontFamily: 'Poppins',
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  letterSpacing: -0.042,
};
export const chatTextSub = {
  color: 'rgba(107, 122, 153, 0.60)',
  fontFamily: 'Poppins',
  fontSize: 10,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
};
export const btnDesign = {
  display: 'flex',
  alignItems: 'center',
  gap: 5.636,
  borderRadius: '10px',
  border: '0.705px solid #E6E9EE',
  color: '#B5BCCC',
  fontFamily: 'Poppins',
  fontSize: 11.273,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  color: 'rgba(181, 188, 204, 1)',
  textTransform: 'none',
};
export const opt = {
  height: '35px',
  borderRadius: '12px',
  border: '1px solid #E6E9EE',
  paddingLeft: '15px',
  paddingRight: '15px',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  color: '#AFAFAF',
  background: 'rgba(243, 246, 249, 1)',
  maxWidth: 90,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};
export const subTextData = {
  color: 'var(--text-color-text-3, #78778B)',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};
export const containerStyles = {
  mt: 1,
  background: '#fff',
  height: 'auto',
  minHeight : '80px' ,
  borderRadius: '4px',
  border: '1px solid #E9EFF4',
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
};
export const headTableText = {
  color: 'var(--gray, #3A3A49)',
  fontFamily: 'Poppins',
  fontSize: 13.433,
  fontStyle: 'normal',
  fontWeight: 400,
};
export const textHead = {
  color: 'var(--text-color-text-2, #929EAE)',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};
export const MapingData = {
  color: 'var(--text-color-text-2, #929EAE)',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  width: '100%',
  height: 'auto',
  overflowWrap: 'break-word',
};
export const headTextDropDown = {
  fontSize: '12px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  color: 'var(--grey-blue-60, #6B7A99)',
  fontFeatureSettings: 'clig off, liga off',
  fontFamily: 'Poppins',
  fontStyle: 'normal',
  fontWeight: 500,
};
export const headProductText = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
  color: 'var(--text-color-text-1, #1B212D)',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
};
export const commonText = {
  color: 'var(--gray, #3A3A49)',
  fontFamily: 'Poppins',
  fontSize: 13.433,
  fontStyle: 'normal',
  fontWeight: 400,
};
export const restoreBtn = {
  borderRadius: '5px',
  background: '#445FD2',
  boxShadow:
    '1.0333333015441895px 2.066666603088379px 3.0999999046325684px 0px rgba(7, 17, 47, 0.41)',
  color: 'var(--background-color, #FAFAFB)',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  textTransform: 'none',
  width: '80px',
  // ml: '40px',
  '&:hover': {
    background: '#445FD2',
  },
};
export const LinkStyles = {
  textDecoration: 'none',
};
export const dateStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  alignContent: 'center',
  border: ' 0.705px solid #E6E9EE',
  background: '#fff',
  padding: '7px',
  alignItems: 'center',
  color: '#B5BCCC',
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  width: '130px',
  height: '40px',
  marginLeft: '20px',
  borderRadius: 10.455,
  cursor: 'pointer',
};
export const filtersDesign = {
  display: 'inline-flex',
  height: 32,
  width: 72,
  padding: '8.652px 12.36',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 1.18,
  flexShrink: 0,
  borderRadius: 14.23,
  border: '0.618px solid #6B7A99',
  background: '#FFF',
  color: 'var(--text-color-text-1, #1B212D)',
  fontFamily: 'Kumbh Sans',
  fontSize: 8.652,
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  cursor: 'pointer',
};
export const selectText = {
  color: 'var(--grey-blue-60, #6B7A99)',
  fontFeatureSettings: 'clig off, liga off',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 500,
  mt: 1.6,
};

export const selectDesign = {
  display: 'flex',
  flexDirection: 'row',
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 0,
  },
  minWidth: 20,
  width: '220px',
  height: '40px',
};

export const tableSubText = {
  color: '#1B212D',
  fontFamily: 'Poppins',
  fontSize: 12,
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
};
